// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../../svg";
import { currencyChange } from "../../../store/currency";
import { localeChange } from "../../../store/locale";
import { mobileMenuClose } from "../../../store/mobile-menu";
import Hlogo from "./../../../img/logo.png";
import { Link } from "react-router-dom";
// data stubs
import currencies from "../../../data/shopCurrencies";
import mobileMenuLinks from "../../../data/mobileMenu";






//
import { FormattedMessage } from "react-intl";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

function MobileMenu(props) {
    const { mobileMenuState, closeMobileMenu, changeLocale, changeCurrency } = props;

    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.open,
    });


    const links = [
        { title:"Home" , url: "/" },
        { title: "About Us", url: "/about-us" },
        { title: "Our Services" , url: "/service" },
        { title:"Contact Us", url: "/contact-us" },
        { title: "Downloade App", url: "https://play.google.com/store/apps/details?id=com.hogist" },
    ];

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === "language") {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === "currency") {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === "link") {
            closeMobileMenu();
        }
        // closeMobileMenu()
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">
                        {" "}
                        <Link to="/" className="mobile-header__logo">
                            {" "}
                            <img src={Hlogo} alt="logo" style={{width:"70%"}}/>
                        </Link>
                    </div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">


                <List>
                {/* {links.map((text, index) => ( */}
                    <ListItem disablePadding>
                        <ListItemButton>
                          
                            <Link className="topbar-link" to= "/" target="_self" >
                                {" "}
                                <ListItemText className="dawer-txt" primary="Home" onClick={closeMobileMenu} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                          
                            <Link className="topbar-link" to= "/about-us" target="_self" >
                                {" "}
                                <ListItemText className="dawer-txt" primary="About Us" onClick={closeMobileMenu} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                          
                            <Link className="topbar-link" to= "/service" target="_self" >
                                {" "}
                                <ListItemText className="dawer-txt" primary="Our Services" onClick={closeMobileMenu} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                          
                            <Link className="topbar-link" to= "/contact-us" target="_self" >
                                {" "}
                                <ListItemText className="dawer-txt" primary="Contact Us" onClick={closeMobileMenu} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                          
                            <a className="topbar-link" href= "https://play.google.com/store/apps/details?id=com.hogist" target="_blank" rel="noopener noreferrer" >
                                {" "}
                                <ListItemText className="dawer-txt" primary="Downloade App" onClick={closeMobileMenu} />
                            </a>
                        </ListItemButton>
                    </ListItem>
                  
                {/* ))} */}
            </List>
                    {/* <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} /> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
