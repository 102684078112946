import { ADDODCUSER_CHANGE, REMOVEODCUSER_CHANGE } from "./addodcuserActionTypes";

// eslint-disable-next-line import/prefer-default-export
export const addodcuserChange = (addodcuser) => ({
    type: ADDODCUSER_CHANGE,
    addodcuser,
});

export const removeodcuserChange = () => ({
    type: REMOVEODCUSER_CHANGE,
});
