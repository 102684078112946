import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";

function Career() {
    return (
        <div>
            <Helmet>
                <title>{`Careers — ${theme.name}`}</title>
            </Helmet>
            <h4 className="faq-tit-txt" style={{ backgroundColor: "aliceblue", marginBottom: "2%" }}>
                <span style={{ color: "#c60800" }}>C</span>areers
            </h4>

            <div
                style={{
                    backgroundImage:
                        'url("https://png.pngtree.com/background/20220726/original/pngtree-indian-cuisine-sketch-pattern-background-picture-image_1814318.jpg")',
                }}
            >
                <div style={{ backgroundColor: "#ffffffed" }}>
                    <div className="container-fluid">
                        <div style={{ padding: "0% 2%" }}>
                            <div
                                className="card "
                                style={{
                                    filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)",
                                    padding: "4%",
                                    marginBottom: "4%",
                                }}
                            >
                                <div className="row">
                                    <div className="col-lg-5">
                                        <img
                                            src="https://www.meghmanifinechem.com/wp-content/uploads/2020/05/carer.jpg"
                                            alt=""
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div className="col-lg-7">
                                        <h4>
                                            Interested in food? Interested in selling food? Interested in technology?
                                        </h4>
                                        <p>
                                            If your answer to the above questions is YES, then we at HOGIST would
                                            welcome you with open arms! We at HOGIST are looking for talented
                                            professionals who love food, technology, and food services. Your career at
                                            HOGIST will be a combination of online Food Service Providing + Marketing +
                                            Selling + Eating + Earning! If these terms fascinate you, then join us to
                                            serve the foodies! We are one of the country’s first Food Tech Start-up who
                                            links consumers from all platforms. Be it a corporate event or a family
                                            wedding or a day to day parcel service, we at HOGIST have taken all the
                                            steps to make sure that we link and connect with consumers from all
                                            platforms. Whoever needs a food service will now have an online platform
                                            where they can demand their needs and we on the other go will fulfill their
                                            needs. Be it online catering or a simple bulk order, you place it and leave
                                            the rest on us.
                                        </p>
                                        <p>
                                            Happy with the concept but unaware of our work culture? We at HOGIST have a
                                            very simple strategy – to make our employees happy. Simply because a happy
                                            employee can produce happy customers. You don’t have to stick to the normal
                                            9 to 5 culture here. All we expect from you is your determination and smart
                                            work – not timings.
                                        </p>
                                        <p>
                                            We at HOGIST believe in giving ample opportunities to our employees where
                                            they can grow. In simple terms, our strategy is divided into 4 parts.
                                        </p>

                                        <p>
                                            <b>Feed your guests –</b> Make sure your customers are happy and their
                                            tummies are satisfied and full.
                                        </p>
                                        <p>
                                            <b>Feed your employee – </b> Make sure you grow by learning the tips and
                                            tricks of the business and at the same time educate your colleagues so that
                                            they grow along with you.
                                        </p>
                                        <p>
                                            <b>Feed your delegates –</b> Keeping your clients happy is equally important
                                            as keeping your customer happy. Good customers come only if there are good
                                            clients present with you.
                                        </p>
                                        <p>
                                            <b> Earn Reward points –</b> Indulge in team discussions and the ones who
                                            bring up the craziest and brightest idea gets a reward!
                                        </p>

                                        <p>
                                            Currently, we are looking for Vendor Support Executive and Customer Support
                                            Executive. Please drop your resumes at <a mail to ="kousalya.s@hogist.com" >kousalya.s@hogist.com</a>, we will
                                            review and get back to you.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;
