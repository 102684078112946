import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";
//

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";

import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiurl } from "../../../api/api_url";
import { formatTime, formattedDateTime } from "../../../helper/dateformate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelOrderData() {
    const { id } = useParams();
    const [menulistapi, setMenulistapi] = useState([]);

    const [show, setShow] = useState(false);
    const [weightage, setWeightage] = useState(-1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handelWeight = (index) => {
        setWeightage(index === weightage ? -1 : index);
    };
    const handleClosed = (index) => {
        setWeightage(index === weightage ? -1 : index);
    };

    useEffect(() => {
        getApiData();
    }, []);

    const getApiData = async () => {
        // const { id } = props;
        // const { id } = useParams( myorderlist._id);
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const result = await fetch(`${apiurl}/order/${id}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(result.data);
        // console.log(result.data);
    };

    // const address = menulistapi.customer.address? menulistapi.customer.address: 'not found';
    console.log(menulistapi);
    const date = new Date(menulistapi && menulistapi.createdAt);
    const date1 = new Date(menulistapi.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    return (
        <div className="container txt-colr">
            <form>
                <MDBRow>
                    <MDBCol lg={12} className="mrbt">
                        <MDBCard className="service-tab-style border-styles" style={{ textAlign: "center" }}>
                            <h4 className="fw-bold mb-1 order-details-header">My Cancelled Order Details</h4>
                            <MDBCardBody>
                                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                    <ListItem>
                                        <ListItemText> Ordered Date </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}>
                                            {" "}
                                            {`${day} -${month} -${year}`}{" "}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Ordered Time </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText> {formatTime(menulistapi.createdAt)} </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Service Date </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText> {`${day1} -${month1} -${year1}`} </ListItemText>
                                    </ListItem>
                                    {/* <ListItem>
       <ListItemText> Kitchen Pickup Time   </ListItemText> 
       <ListItemText style={{marginLeft: '-63px'}}> : </ListItemText>
        <ListItemText style={{textAlign:'start',}}> N/A </ListItemText>
      </ListItem>
      <ListItem>
       <ListItemText> Delivered Time   </ListItemText> 
       <ListItemText style={{marginLeft: '-19px'}}> : </ListItemText>
        <ListItemText style={{textAlign:'start',}}> N/A</ListItemText>
      </ListItem> */}

                                    <ListItem>
                                        <ListItemText> Delivery Address</ListItemText>
                                        <ListItemText>: </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}>
                                            {" "}
                                            {menulistapi.customer && menulistapi.customer.address}{" "}
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Services</ListItemText>
                                        <ListItemText>: </ListItemText>

                                        <ListItemText>
                                            {menulistapi.services &&
                                                menulistapi.services.map((item, index) => (
                                                    <span key={index}> {item.label} ,</span>
                                                ))}
                                        </ListItemText>
                                    </ListItem>

                                    {menulistapi.services &&
                                        menulistapi.services.map((item, index) => (
                                            <div key={index}>
                                                <ListItem>
                                                    <ListItemText> {`${item.label} Count`} </ListItemText>
                                                    <ListItemText>: </ListItemText>
                                                    <ListItemText>{item.count} </ListItemText>
                                                </ListItem>
                                            </div>
                                        ))}
                                    <ListItem>
                                        <ListItemText> Order Status </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}>
                                            <Button className="dc-view-btn">{menulistapi.status}</Button>
                                        </ListItemText>
                                    </ListItem>
                                    {/* <ListItem>
       <ListItemText> Order Status  </ListItemText>
        <ListItemText style={{paddingLeft: '5%'}}> : </ListItemText>
        <ListItemText style={{textAlign:'start',paddingLeft: '4%'}} ><Button  className='dc-view-btn' >Cancelled</Button></ListItemText>
      </ListItem> */}
                                    <ListItem>
                                        <ListItemText> Reason for Cancel </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}>{menulistapi.reason}</ListItemText>
                                    </ListItem>
                                </List>

                                {/* <div className='dash-line'></div> */}

                                <h3 className=" mb-4 mt-4" style={{ color: "#000" }}>
                                    <span style={{ color: " #c60800" }}>B</span>ill Details
                                </h3>

                                {menulistapi.services &&
                                    menulistapi.services.map((item, index) => (
                                        <div key={index}>
                                            <div className="inline-flex-st">
                                                <img
                                                    className="veg-details-img"
                                                    src={
                                                        item.vegType === "VEG" ? "images/veg.png" : "images/nonveg.png"
                                                    }
                                                    alt=""
                                                />
                                                <h4 style={{ color: "#000" }}> {item.label}</h4>
                                            </div>
                                            <div >
                                                        <div className="food-item-details">
                                                        {item &&
                                                item.menuItems &&
                                                item.menuItems.map((items, indexed) => 
                                                            <span  key={indexed} style={{ padding: "1px 7px" }}> {items.label} ({items.size}{items.unit}) , </span>
                                                            )}
                                                        </div>
                                                    </div>
                                            <div className="container">
                                                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                    <div className="dash-line"></div>
                                                    <ListItem className="count-ord-btn">
                                                        <ListItemText> Quantity </ListItemText>
                                                        <ListItemText> Price </ListItemText>
                                                        <ListItemText> Total </ListItemText>
                                                    </ListItem>
                                                    <div className="dash-line"></div>
                                                    <ListItem className="count-ord-btn">
                                                        <ListItemText> {item.count}</ListItemText>
                                                        <ListItemText style={{ paddingLeft: "5%" }}>
                                                            <span>₹ </span> {item.price}{" "}
                                                        </ListItemText>
                                                        <ListItemText>
                                                            {" "}
                                                            <span>₹ </span> {item.count * item.price}{" "}
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>

                                                <div style={{ marginBottom: "7%" }}>
                                                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                        <ListItem>
                                                            <ListItemText> COVID check </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText style={{ textAlign: "start" }}>
                                                                <Button
                                                                    className={
                                                                        item.dlchallan.status === "true"
                                                                            ? "details-btn-done"
                                                                            : "details-btn-pending"
                                                                    }
                                                                >
                                                                    {item.dlchallan.status === "true"
                                                                        ? "Completed"
                                                                        : "Pending"}
                                                                </Button>
                                                            </ListItemText>
                                                        </ListItem>

                                                        {/* <ListItem>
         <ListItemText> Delivery Challan  </ListItemText>
          <ListItemText style={{marginLeft: '-35px'}}> : </ListItemText>
          <ListItemText style={{textAlign:'start',}} ><Button onClick={handleShow} className='dc-view-btn' >View DC</Button></ListItemText>
        </ListItem> */}
                                                    </List>
                                                </div>
                                                <Button
                                                    style={{ marginBottom: "7%" }}
                                                    className="details-btn-pending weight-btn"
                                                    onClick={() => handelWeight(index)}
                                                >
                                                    {" "}
                                                    See Food Weight
                                                </Button>

                                                <Modal show={weightage === index} onHide={handleClosed}>
                                                    <form>
                                                        <Modal.Header className="pop_head">
                                                            <h3
                                                                className=" mb-4 mt-4"
                                                                style={{ color: "#fff", marginLeft: "27%" }}
                                                            >
                                                                <span style={{ color: " #000" }}>S</span>ee Food Weight
                                                            </h3>

                                                            <Button
                                                                className="cls-btn"
                                                                style={{ color: "#fff" }}
                                                                onClick={handleClosed}
                                                            >
                                                                X
                                                            </Button>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div style={{ color: "#000" }}>
                                                                <div className="inline-flex-st">
                                                                    <img
                                                                        style={{ width: "5%", height: "5%" }}
                                                                        className="veg-details-img"
                                                                        src={
                                                                            item.vegType === "VEG"
                                                                                ? "images/veg.png"
                                                                                : "images/nonveg.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <h4> {item.label}</h4>
                                                                </div>
                                                                <div >
                                                        <div className="food-item-details">
                                                        {item &&
                                                item.menuItems &&
                                                item.menuItems.map((items, indexed) => 
                                                            <span  key={indexed} style={{ padding: "1px 7px" }}> {items.label} ({items.size}{items.unit}) , </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                                <div className="container">
                                                                    <List
                                                                        sx={{
                                                                            width: "100%",
                                                                            bgcolor: "background.paper",
                                                                        }}
                                                                    >
                                                                        <div className="dash-line"></div>
                                                                        <ListItem className="count-ord-btn">
                                                                            <ListItemText> Quantity </ListItemText>
                                                                            <ListItemText> Price </ListItemText>
                                                                            <ListItemText> Total </ListItemText>
                                                                        </ListItem>
                                                                        <div className="dash-line"></div>
                                                                        <ListItem className="count-ord-btn">
                                                                            <ListItemText>{item.count}</ListItemText>
                                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                                <span>₹ </span>
                                                                                {item.price}
                                                                            </ListItemText>
                                                                            <ListItemText>
                                                                                {" "}
                                                                                <span>₹ </span>{" "}
                                                                                {item.count * item.price}{" "}
                                                                            </ListItemText>
                                                                        </ListItem>
                                                                        <div
                                                                            style={{
                                                                                textAlign: "inherit",
                                                                                color: "#585858b3",
                                                                            }}
                                                                        >
                                                                            {/* <ListItemText > {menulistapi.foodWeight? menulistapi.foodWeight:' Food Weight Not Mentioned'} </ListItemText> */}
                                                                            {/* <ListItemText >    Enter Liquide Food Count (Litres) </ListItemText> */}
                                                                        </div>
                                                                    </List>

                                                                    {/* <Button  onClick={handelWeight} style={{marginLeft: '35% ', marginTop:'20px'}} className='safety-check-popup-btn'  > Ok</Button> */}
                                                                </div>
                                                                <List
                                                                    sx={{ width: "100%", bgcolor: "background.paper" }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            textAlign: "inherit",
                                                                            color: "#585858b3",
                                                                        }}
                                                                    >
                                                                        {item.foodWeight.map((items, indexed) => (
                                                                            <div key={indexed}>
                                                                                <div className="food-item-details">
                                                                                    <ListItemText>
                                                                                        <span
                                                                                            style={{
                                                                                                padding: "1px 7px",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            {items.item} {items.size}
                                                                                        </span>{" "}
                                                                                        ,
                                                                                    </ListItemText>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </List>
                                                            </div>
                                                        </Modal.Body>

                                                        {/* <Modal.Footer style={{ borderTop: "none" }}>
                          <Button  onClick={handleClose} className="rgbtn1" variant="primary">
                             Ok
                          </Button>
                      </Modal.Footer> */}
                                                    </form>
                                                </Modal>
                                            </div>
                                        </div>
                                    ))}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                <Modal show={show} onHide={handleClose}>
                    <form>
                        <Modal.Header className="pop_head">
                            <img className="detail-popup-logo" src={Hlogo} alt="logo" />

                            <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClose}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <img
                                    src="https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/delivery-challan/index/delivery-challan-material.webp"
                                    alt="Delivery Challan"
                                />
                            </div>
                        </Modal.Body>

                        <Modal.Footer style={{ borderTop: "none" }}>
                            <Button onClick={handleClose} className="rgbtn1" variant="primary">
                                Ok
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </form>
        </div>
    );
}
