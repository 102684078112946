import addmenucountReducer from "./addmenucountReducer";

// export * from './adduserActions';

export const ADDMENUCOUNT = "ADDMENUCOUNT";
export const addmenucountChange = (addmenucount) => ({
    type: ADDMENUCOUNT,
    addmenucount,
});

export default addmenucountReducer;
