/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { url } from "../../api/api_url";
import { toast } from "react-toastify";

function LoginBanner() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [registerinfo, setRegisterinfo] = useState([]);
    const [errors, setErrors] = useState({});

    const initialFormValue = {
        fullName: "",
        email: "",
        mobile: "",
        address: "",
        password: "1234",
    };




    const validateForm = () => {
        let formIsValid = true;
        const errors = {};
    

        // if (!fullName) {
        //     errors.fullName = "Enter your Name";
        //     formIsValid = false;
        //   } 
          if (!fullName) {
            errors.fullName = "Enter your Name";
            formIsValid = false;
          } else if (!/^[A-Za-z\s]+$/.test(fullName)) {
            errors.fullName = "Name should only contain letters and spaces";
            formIsValid = false;
          }
        // Validate Email
        if (!email) {
          errors.email = "Email is required";
          formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.email = "Email is invalid";
          formIsValid = false;
        }

        if (!mobile) {
          errors.mobile = "Enter your Mobile Number";
          formIsValid = false;
        } else if (!/^\d{10}$/.test(mobile)) {
          errors.mobile = "Mobile Number should be 10 digits";
          formIsValid = false;
        }
    
        // if (!mobile) {
        //     errors.mobile = "Enter your Mobile Number";
        //     formIsValid = false;
        //   } 
          if (!address) {
            errors.address = "Enter your Address";
            formIsValid = false;
          } 
        // // Validate Password
        // if (!password) {
        //   errors.password = "Password is required";
        //   formIsValid = false;
        // } 
       
      
    
        setErrors(errors);
        return formIsValid;
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log(email);
        // console.log(password);
        if (validateForm()) {
        await fetch(`${url}/auth/register`, {
            method: "POST",
            body: JSON.stringify({ fullName, email, mobile, address, password }),
            headers: {
                "Content-type": "application/json",
                // "Authorization":localStorage.getItem("token")
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                // localStorage.setItem("token", json.data.token);
                // localStorage.setItem("user",json.data.user);
                const user = json.data;
                // dispatch(adduserChange(user))

                // if(user.ctype==='Industrial'){
                //     history.push("/industry/home");

                // }

                // if(user.ctype==='Corporate'){
                //     history.push("/corporate/home");
                // }

                console.log(user);
            });
        }
        if (validateForm()) {
        setShow(false);
       
        toast.success("Your Registered Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };

    return (
        <>
            <button variant="primary" onClick={handleShow} type="submit" className="rgbtn">
                Register
            </button>

            <Modal show={show} onHide={handleClose}>
                <form>
                    <Modal.Header className="pop_head">
                        <Modal.Title className="rg-popup-tit">Register</Modal.Title>

                        <button className="cls-btn" onClick={handleClose}>
                            X
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{textAlign:"center"}}>
                        <div className="form-group">
                            <input
                                name="fullname"
                                type="text"
                                onChange={(e) => setFullName(e.target.value)}
                                className="form-control rginputtxt"
                                placeholder="Enter Your Name"
                                required
                            />
                             {errors.fullName && <p style={{ color: "red" }}>{errors.fullName}</p>}
                        </div>
                        <div className="form-group">
                            <input
                                name="email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control rginputtxt"
                                placeholder="Enter Your Mail ID"
                                required
                            />
                             {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                        </div>
                        <div className="form-group">
                            <input
                                name="mobile"
                                type="number"
                                onChange={(e) => setMobile(e.target.value)}
                                className="form-control rginputtxt"
                                placeholder="Enter Your Mobile No"
                                required
                            />
                             {errors.mobile && <p style={{ color: "red" }}>{errors.mobile}</p>}
                        </div>
                        <div className="form-group">
                            <input
                                name="address"
                                type="text"
                                onChange={(e) => setAddress(e.target.value)}
                                className="form-control rginputtxt"
                                placeholder="Address"
                                required
                            />
                             {errors.address && <p style={{ color: "red" }}>{errors.address}</p>}
                        </div>
                        <div className="form-group">
                            <input
                                name="password"
                                type="password"
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control rginputtxt"
                                placeholder="password"
                                required
                            />
                        </div>
                        <p className="rg-info-txt">Already Have a Account ? Please Login </p>

                        {/* <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           
              <Form.Control
                type="text"
                placeholder="Enter Your Name"
                className='rginputtxt'
                required
                autoFocus
              
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
             
              <Form.Control
                type="email"
                placeholder="Enter Your Email ID"
                className='rginputtxt'
                required
               
                
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            
              <Form.Control
                type="number"
                placeholder="Enter Your Phone No"
                className='rginputtxt'
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          
              <Form.Control
                type="password"
                placeholder="Enter Your Password"
                className='rginputtxt'
              
                required
              />
            </Form.Group>
            <p className='rg-info-txt'>Already Have a Account Please Login </p>
           
          </Form> */}
                    </Modal.Body>

                    <Modal.Footer style={{ borderTop: "none" }}>
                        <button onClick={handleSubmit} className="rgbtn1" variant="primary">
                            Register
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default LoginBanner;
