// eslint-disable-next-line
// react
import React from "react";

// third-party
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// application
import Indicator from "./Indicator";
import { Cart20Svg } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import { apiurl } from "../../api/api_url";
import {  toast } from "react-toastify";
import { ToggleButton } from "@mui/material";


function IndicatorCart(props) {
    const { menuadded, odcuser, guestuser } = props;
    let dropdown;
    const history = useHistory();

    const menusAdded = odcuser && odcuser.fullName ? menuadded && menuadded.length : menuadded.length;
    const calculatesubTotalPrice = () => {
        let subtotal = 0;
        menuadded.forEach((item, indxed) => {
            const menuId = item._id;
            const count = item.count ? item.count : 25;
            const price = item.price;
            subtotal += count * price;
        });
        return subtotal;
    };


    const toggleVisibility = async (e) => {
        e.preventDefault();
        const services = menuadded.map((item) => ({ _id: item._id, count: item.count }));
        console.log(services);
        if (odcuser && odcuser.fullName) {
            await fetch(`${apiurl}/odc-cart`, {
                method: "POST",
                body: JSON.stringify({
                    services,
                    serviceDate: guestuser.date,
                    // additional: checkedValues,
                }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: localStorage.getItem("tokenodc"),
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                });

            history.push("/odc/checkout");
        } else {
            toast.error("Please Login First", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push("/odc/login");
        }
    };

    const toggleVisibility1=()=>{
        history.push("/odc/addcart");
    }

    if (menusAdded > 0) {
        dropdown = (
            <div className="dropcart" style={{ color: "#000" }}>
                <div className="dropcart">
                    {/* <div className="dropcart__products-list">{items}</div> */}

                    <div className="dropcart__totals">
                        <ul style={{ padding: "0px 0px" }}>
                            {menuadded.map((item, indxed) => (
                                <li
                                    key={indxed}
                                    style={{
                                        backgroundColor: "aliceblue",
                                        padding: "5%",
                                        borderRadius: " 7px",
                                        marginBottom: "4%",
                                        boxShadow: "1px 1px 5px #b9b9b9",
                                    }}
                                >
                                    <div className="row odc-cartic-list-main">
                                        <div className="col-lg-8">{item.label}</div>
                                        <div className="col-lg-4 number-font">{` ₹ ${item.price}`}</div>
                                    </div>
                                    <div className="row odc-cartic-list-sub" style={{ color: "#c60800" }}>
                                        <div className="col-lg-8 number-font">
                                            {item.count ? item.count : 25} Counts{" "}
                                        </div>
                                        <div className="col-lg-4 number-font">{` ₹ ${
                                            item.tprice ? item.tprice : 25 * item.price
                                        }`}</div>
                                    </div>
                                </li>
                            ))}
                            <hr />
                            <li>
                                <div className="row row odc-cartic-list-main">
                                    <div className="col-lg-8">Subtotal</div>
                                    <div className="col-lg-4 number-font"> ₹ {calculatesubTotalPrice()}</div>
                                </div>{" "}
                            </li>
                        </ul>
                    </div>

                    <div className="dropcart__buttons">
                        <a className="btn btn-primary"  onClick={toggleVisibility1} >
                            View Cart
                        </a>
                        <a className="btn btn-success" onClick={(e) => toggleVisibility(e)}>
                            Checkout
                        </a>
                    </div>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart" style={{ color: "#000" }}>
                <div className="dropcart">
                    {/* <div className="dropcart__products-list">{items}</div> */}

                    <div className="dropcart__totals">
                        <div style={{ display: "flex", justifyContent: "center", paddingBottom: "10%" }}>
                            <img src="images/notfound.png" alt="Menu" style={{ width: "60%" }} />
                        </div>
                    </div>

                   
                </div>
            </div>
        );
    }

    return (
        <Indicator
            className="cart-icon-style"
            dropdown={dropdown}
            value={menusAdded}
            icon={<Cart20Svg />}
        />
    );
}
IndicatorCart.propTypes = {
    menuadded: PropTypes.array.isRequired,
    odcuser: PropTypes.object.isRequired,
    guestuser: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
    menuadded: state.menuadded,
    odcuser: state.odcuser,
    guestuser: state.guestuser,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
