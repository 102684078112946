/* eslint-disable no-unused-vars */
// react
import React, { useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
// import { Formik } from 'formik';
// import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';

// application
import PageHeader from "../industry/shared/PageHeader";
import { Check9x7Svg } from "../../svg";
import Hlogo from "./../../img/logo.png";
import LoginBanner from "./LoginBanner";
// import HomePageOne from "./HomePageOne";
import { ToastContainer, toast } from "react-toastify";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";


// data stubs
import theme from "../../data/theme";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { wishlistAddItem } from '../../../store/wishlist';
import { adduserChange } from "../../store/adduser";
import { url } from "../../api/api_url";
// import { ToastContainer, toast } from "react-toastify";

export default function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [posts, setPosts] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const initialFormValue = {
        email: "",
        password: "",
    };


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateForm = () => {
        let formIsValid = true;
        const errors = {};
    
        // Validate Email
        if (!email) {
          errors.email = "Email is required";
          formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.email = "Email is invalid";
          formIsValid = false;
        }
    
        // Validate Password
        if (!password) {
          errors.password = "Password is required";
          formIsValid = false;
        } 
        // else if (password.length < 8) {
        //   errors.password = "Password must be at least 8 characters long";
        //   formIsValid = false;
        // }
        //  else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password)) {
        //   errors.password =
        //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
        //   formIsValid = false;
        // }
    
      
    
        setErrors(errors);
        return formIsValid;
      };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            await fetch(`${url}/auth/company-login`, {
                method: "POST",
                body: JSON.stringify({ email, password }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    localStorage.setItem("token", json.data.token);
                    // localStorage.setItem("user",json.data.user);
                    const user = json.data.user;
                    dispatch(adduserChange(user));
                    if (user.ctype === "Industrial") {
                        history.push("/industry/home");
                    } else if (user.ctype === "Corporate") {
                        history.push("/corporate/home");
                    }
                //    else if(email !== user.fullName ){

                //  alert(" hi")
                //    }
    
                    //    console.log(user);
                })
                .catch(error=>{
                    // alert("User Name Or Password is Wrong",error)
                    toast.error("User Name Or Password is Wrong", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    console.error(error);
                })
          }
      
      
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <div>
                <div className="container-fluid">
                    <div className="row" style={{ minHeight: 600 }}>
                        <div
                            className="col-md-6 "
                            style={{ backgroundImage: 'url("images/banners/lgbanner.png")', backgroundSize: " cover" }}
                        >
                            {/* <Link to="/" className="block-banner__body"> */}

                            <div className="rgtxtarea">
                                <div className="login-header__logo">
                                    <Link to="/">
                                        <img src={Hlogo} alt="logo" className="lg-style-logo-img-w"  />
                                    </Link>
                                </div>

                                <div className="lgtext" style={{ color: "#fff",paddingBottom: "8%" }}>
                                    <div className="block-banner__title lgtext-title">
                                        " Order from your favourite vendor and track <br />
                                        your order on the go with the all new "
                                        <br />
                                        <span className="lgname">HOGIST APP</span>
                                    </div>
                                    <div className="block-banner__text">
                                        If you don't have a account Pleace Register.
                                    </div>
                                    <div className="block-banner__button">
                                        {/* <Link to='/'><button type="submit" className="rgbtn">
                                            Register
                                        </button></Link> */}
                                        <LoginBanner />
                                    </div>
                                </div>
                            </div>
                            {/* </Link> */}
                        </div>
                        <div
                            className="col-md-6 d-flex mt-4 mt-md-0"
                            style={{ backgroundImage: 'url("images/banners/lgbg.png")', backgroundSize: " cover" }}
                        >
                            <div className="card flex-grow-1 mb-md-0  logcard">
                                <div className="card-body">
                                    <div className="container">
                                        <h3 className="card-title lg-style-txt-mt" >
                                            Login
                                        </h3>

                                     
                                        <form>
                                            <div className="form-group">
                                                {/* <label htmlFor="login-email">Email address</label> */}

                                                <input
                                                    id="login-email"
                                                    type="email"
                                                    // value={email}
                                                    name="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    className="form-control lginputtxt"
                                                    placeholder="Enter email"
                                                    required
                                                />
                                                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                                            </div>
                                            <div className="form-group">
                                                {/* <label htmlFor="login-password">Password</label> */}
                                                <IconButton
                                                    className="password-icon-style"
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                       
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                <input
                                                id="login-password"
                                                type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    // value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="form-control lginputtxt"
                                                    placeholder="Password"
                                                    required
                                                />
                                                {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                                <br />
                                                {/* <div className="forgrt_text" >
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <span className="form-check-input input-check">
                                                                <span className="input-check__body">
                                                                    <input
                                                                        id="login-remember"
                                                                        type="checkbox"
                                                                        className="input-check__input"
                                                                    />
                                                                    <span className="input-check__box" />
                                                                    <Check9x7Svg className="input-check__icon" />
                                                                </span>
                                                            </span>
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="login-remember"
                                                                style={{ color: "black",fontSize: 16 }}
                                                            >
                                                                Remember me
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <small className="form-text text-muted ft-password" >
                                                        <Link to="/">Forgotten Password ?</Link>
                                                    </small>
                                                </div> */}
                                            </div>

                                            <button onClick={handleSubmit} type="submit" className="logbtn">
                                                Login
                                            </button>
                                           
                                        </form>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
