/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import "./ODC.scss";
import BG from "../../../img/odcimg1.png";
import Resturents from "./Resturents";

function Homepage() {
    //-------  ODC  OTP end -------->

    return (
        <div>
            <Helmet>
                <title>{`Out Door Catering — ${theme.name}`}</title>
            </Helmet>
            {/* <!-------  ODC Header Part Start --------> */}
            <div>
                <div className="container12">
                    <img src={BG} alt="Avatar" className="image" style={{ width: "100%", height: "100%" }} />

                    <div className="maintex" style={{ color: "#fff" }}>
                        <h1>Choose Your Favourite Menu</h1>
                        <br />
                        <p>From your favourite Places</p>
                    </div>
                </div>
            </div>

            <Resturents />
        </div>
    );
}

export default Homepage;
