/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { Link } from "react-router-dom";
import FactoryIcon from "@mui/icons-material/Factory";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import { useSelector } from "react-redux";
import LocalDiningIcon from '@mui/icons-material/LocalDining';

export default function MainDropdownButton() {
    const user = useSelector((state) => state.user);
    const [mainHomePage, setMainHomePage] = useState(false);
    const [industryHomePage, setIndustryHomePage] = useState(false);
    const [corporateHomePage, setCorporateHomePage] = useState(false);
    const [odcHomePage, setOdcHomePage] = useState(false);

    useEffect(() => {
        
        setMainHomePage(window.location.pathname === "/");
        setIndustryHomePage(window.location.pathname === "/industry/home");
        setCorporateHomePage(window.location.pathname === "/corporate/home");
        setOdcHomePage(window.location.pathname === "/odc/mainhome "||"/odc/home");
         // eslint-disable-next-line
    }, [window.location]);


    return (
        <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
                <React.Fragment>
                   <Button {...bindTrigger(popupState)}>
                        {/* <FactoryIcon fontSize="large" className="nav-menubar-inustry-icon rotate"/> */}
                        {mainHomePage?(
                            <HomeIcon fontSize="large" className="nav-menubar-inustry-icon rotate" />
                        ):industryHomePage ? (
                            <FactoryIcon fontSize="large" className="nav-menubar-inustry-icon rotate" />
                        ) : corporateHomePage ? (
                            <BusinessIcon fontSize="large" className="nav-menubar-inustry-icon rotate" />
                        ) : odcHomePage ? (
                            <LocalDiningIcon fontSize="large" className="nav-menubar-inustry-icon1 rotate" />
                        ) :
                        (
                            <HomeIcon fontSize="large" className="nav-menubar-inustry-icon rotate" />
                        )}
                    </Button>

                    <Menu  {...bindMenu(popupState)} style={{ marginLeft: -50 }}>
                        {/* <div className='dropdown-btn-menu' > */}
                        <Link to="/site/login-page">
                            {" "}
                            <MenuItem className={industryHomePage ? "active" : ""} onClick={popupState.close}>
                                Industrial Catering
                            </MenuItem>
                        </Link>
                        <Link to="/site/login-page">
                            {" "}
                            <MenuItem className={corporateHomePage ? "active" : ""} onClick={popupState.close}>
                                Corporate Catering
                            </MenuItem>
                        </Link>
                        {/* <Link to="/site/login-page">
                            {" "}
                            <MenuItem onClick={popupState.close}>Cafeteria</MenuItem>
                        </Link> */}
                        <Link to="/mainhome/odc">
                            {" "}
                            <MenuItem onClick={popupState.close}>Outdoor Catering (ODC)</MenuItem>
                        </Link>
                        {/* </div> */}
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
}
