/* eslint-disable no-unused-vars */
// import React from "react";
// import { Map, GoogleApiWrapper } from "google-maps-react";
// const Livemap = (props) => {
//     // const { orderdetails } = props;
//     const mapStyles = {
//         width: "100%",
//         height: "400px",
//     };

//     return (
//         <Map google={props.google} zoom={14} style={mapStyles} initialCenter={{  lat: 13.055125, lng: 80.222121 }} />
//     );
// };

// export default GoogleApiWrapper({
//     apiKey: "AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w",
// })(Livemap);

// import React, { useState, useEffect, useCallback } from "react";
// import { Map, GoogleApiWrapper, Marker, DirectionsRenderer } from "google-maps-react";

// const Livemap = (props) => {
//     const [origin, setOrigin] = useState(null);
//     /* eslint-disable no-unused-vars */
//     const [destination, setDestination] = useState({ lat: 13.0545014, lng: 80.2114219 });
//     const [directions, setDirections] = useState(null);

//     const calculateDistance = useCallback(
//         (origin, destination) => {
//             const { google } = props;
//             const service = new google.maps.DistanceMatrixService();
//             service.getDistanceMatrix(
//                 {
//                     origins: [origin],
//                     destinations: [destination],
//                     travelMode: "DRIVING",
//                 },
//                 (response, status) => {
//                     if (status === "OK") {
//                         const distance = response.rows[0].elements[0].distance.text;
//                         console.log("Distance:", distance);
//                     }

//                 }
//             );
//         },
//         [props]
//     );

//     const watchPosition = useCallback(() => {
//         // Watch the user's current position in real-time
//         const id = navigator.geolocation.watchPosition(
//             (position) => {
//                 const { latitude, longitude } = position.coords;
//                 setOrigin({ lat: latitude, lng: longitude });
//             },
//             (error) => {
//                 console.error(error);
//             },
//             { enableHighAccuracy: true }
//         );
//         return () => {
//             // Clear the watch when the component unmounts
//             navigator.geolocation.clearWatch(id);
//         };
//     }, []);

//     useEffect(() => {
//         watchPosition();
//     }, [watchPosition]);

//     useEffect(() => {
//         if (origin) {
//             calculateDistance(origin, destination);
//         }
//     }, [origin, destination, calculateDistance]);

//     const mapStyles = {
//         width: "100%",
//         height: "500px",
//     };

//     return (
//         <Map google={props.google} zoom={12} style={mapStyles} initialCenter={destination}>
//              <Marker position={origin}  label={{
//                 text:"My Location",
//               color: "#000",
//               fontWeight: "bold",

//             }}/>
//         <Marker position={destination}  />
//             {directions && <DirectionsRenderer directions={directions} />}
//         </Map>
//     );
// };

// export default GoogleApiWrapper({
//     apiKey: "AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w",
// })(Livemap);

//! working ! //

// import React, { useState } from "react";
// import {
//   GoogleMap,
//   DirectionsService,
//   DirectionsRenderer,
//   Marker,
//   LoadScript
// } from "@react-google-maps/api";

// function Livemap() {
//   const [destination, setDestination] = useState(null);
//   const [directions, setDirections] = useState(null);
//   const [currentLocation, setCurrentLocation] = useState(null);

//   const handleGetDirections = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         setCurrentLocation({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       });
//     }
//   };

//   const handleDestinationChange = (event) => {
//     setDestination(event.target.value);
//   };

//   const handleDirectionsClick = () => {
//     if (destination && currentLocation) {
//       setDirections(null);
//       const directionsService = new window.google.maps.DirectionsService();
//       directionsService.route(
//         {
//           origin: currentLocation,
//           destination: destination,
//           travelMode: "DRIVING",
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           }
//         }
//       );
//     }
//   };

//   return (
//     <LoadScript
//     googleMapsApiKey="AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w"
//   >
//     <GoogleMap
//       mapContainerStyle={{ height: "100vh", width: "100%" }}
//       zoom={20}
//       center={currentLocation || { lat: 13.061773, lng: 80.246764 }}
//     >
//       {currentLocation && (
//         <Marker position={currentLocation} title="Current Location" />
//       )}

//       <DirectionsService
//         options={{
//           destination: destination,
//           origin: currentLocation,
//           travelMode: "DRIVING",
//         }}
//         callback={(response) => {
//           if (response !== null) {
//             setDirections(response);
//           }
//         }}
//       />

//       {directions && <DirectionsRenderer directions={directions} />}

//       <div style={{ position: "absolute", top: "10px", left: "10px" }}>

//         <input
//           type="text"
//           placeholder="Enter Destination Address or Location"
//           value={destination || ""}
//           onChange={handleDestinationChange}
//         />
//         <button onClick={handleGetDirections}>My Location</button>
//         <button onClick={handleDirectionsClick}>Get Directions</button>

//       </div>
//     </GoogleMap>
//     </LoadScript>
//   );
// }

// export default Livemap;

//! relaely working  map!//

// import React, { useState } from "react";
// import {
//   GoogleMap,
//   DirectionsService,
//   DirectionsRenderer,
//   Marker,
//   LoadScript
// } from "@react-google-maps/api";
// import { useParams } from "react-router-dom";

// function Livemap() {
//   //  const { orderdetails } = props;
//    const { lat,long } = useParams();
//   const [destination, setDestination] = useState(null);
//   const [directions, setDirections] = useState(null);
//   const [currentLocation, setCurrentLocation] = useState(null);
//     const [destinationMarker, setDestinationMarker] = useState(null);
//   const [mapInstance, setMapInstance] = useState(null);

//   const handleGetDirections = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         setCurrentLocation({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       });
//     }
//   };

//   const handleDestinationClick = () => {
//         const destLocation = { lat: Number(lat), lng: Number(long) };
//         // const destLocation = { lat: 37.421998333333335, lng:-122.084  };
//         setDestination(destLocation);
//         setDestinationMarker(
//           new window.google.maps.Marker({
//             position: destLocation,
//             map: mapInstance,
//           })
//         );
//       };
//   const handleDirectionsClick = () => {
//     if (destination && currentLocation) {
//       setDirections(null);
//       const directionsService = new window.google.maps.DirectionsService();
//       directionsService.route(
//         {
//           origin: currentLocation,
//           destination: destination,
//           travelMode: "DRIVING",
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           }
//         }
//       );
//     }
//   };

//   return (
//   <div>
//     {lat&&long ?
//     <div>
//    <div style={{margin:"1%"}}>

//        <button className="safety-check-popup-btn" onClick={handleGetDirections}>My Location</button>
//        <button className="safety-check-popup-btn" onClick={handleDestinationClick}>Delivery Man</button>
//        <button className="safety-check-popup-btn" onClick={handleDirectionsClick}>Get Directions</button>

//      </div>
//     <LoadScript
//     googleMapsApiKey="AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w"
//   >
//     <GoogleMap
//       mapContainerStyle={{ height: "200vh", width: "100%" }}
//       zoom={20}
//       // center={currentLocation || { lat: 13.061773, lng: 80.246764 }}
//       center={currentLocation || { lat: 13.061773, lng: 80.246764 }}
//     >
//       {currentLocation && (
//         <Marker position={currentLocation} title="Current Location" />
//       )}
//        {destinationMarker && (
//           <Marker position={destinationMarker.getPosition()} title="Destination" />
//         )}

//       <DirectionsService
//         options={{
//           origin: currentLocation,
//           destination: destination,

//           travelMode: "DRIVING",
//         }}
//         callback={(response) => {
//           if (response !== null) {
//             setDirections(response);
//           }
//         }}
//       />

//       {directions && <DirectionsRenderer directions={directions} />}

//     </GoogleMap>
//     </LoadScript>
//     </div>
//     :'Delivery not Started Yet!'}
//     </div>
//   );
// }

// export default Livemap;




// <--- Already working Map start --->
  

// import { Loader } from "@googlemaps/js-api-loader";
// import { useState, useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";
// import Geocode from "react-geocode";

// const loader = new Loader({
//     apiKey: "AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w",
//     version: "weekly",
// });
// Geocode.setApiKey("AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w");
// export default function Livemap() {
//     const { lat, long, address } = useParams();
//     const [location, setLocation] = useState({ lat: 13.061773, lng: 80.246764 });
//     const [destination, setDestination] = useState(null);
//     const [destinationMarker, setDestinationMarker] = useState(null);
//     const [mapInstance, setMapInstance] = useState(null);
//     const [latitude1, setLatitude1] = useState();
//     const [longitude1, setLongitude1] = useState();
//     const mapRef = useRef(null);
//     const directionsServiceRef = useRef(null);
//     const directionsRendererRef = useRef(null);

//     useEffect(() => {
//         loader.load().then(() => {
//             const map = new window.google.maps.Map(mapRef.current, {
//                 center: location,
//                 zoom: 14,
//             });
//             const directionsService = new window.google.maps.DirectionsService();
//             const directionsRenderer = new window.google.maps.DirectionsRenderer({
//                 map: map,
//             });
//             directionsServiceRef.current = directionsService;
//             directionsRendererRef.current = directionsRenderer;
//         });
//     }, [location]);

//     useEffect(() => {
//         if (location && destination) {
//             const directionsService = directionsServiceRef.current;
//             const directionsRenderer = directionsRendererRef.current;
//             const request = {
//                 origin: location,
//                 destination: destination,
//                 travelMode: "DRIVING",
//             };
//             directionsService.route(request, (result, status) => {
//                 if (status === "OK") {
//                     directionsRenderer.setDirections(result);
//                 }
//             });
//         }
//     }, [location, destination]);

//     useEffect(() => {
//         Geocode.fromAddress(address).then(
//             (response) => {
//                 const { lat, lng } = response.results[0].geometry.location;
               
//                 setLatitude1(lat);
//                 setLongitude1(lng);
//             },
//             (error) => {
//                 console.error(error);
//             }
//         );
//     }, [address]);

//     function handleLocationClick() {
//         navigator.geolocation.getCurrentPosition(
//             (position) => {
//                 setLocation({
//                     lat: Number(latitude1),
//                     lng: Number(longitude1),
//                 });
//             },
//             (error) => {
//                 console.log(error);
//             }
//         );
//     }

//     const handleDestinationClick = () => {
//         const destLocation = { lat: Number(lat), lng: Number(long) };

       
//         setDestination(destLocation);
//         setDestinationMarker(
//             new window.google.maps.Marker({
//                 position: destLocation,
//                 map: mapInstance,
//             })
//         );
//     };

//     function handleDirectionsClick() {
//         const geocoder = new window.google.maps.Geocoder();
//         geocoder.geocode({ address: toString(destination) }, (results, status) => {
//             if (status === "OK") {
//                 setDestination(results[0].geometry.location);
//             }
//         });
//     }

//     return (
//         <div>
//             <div style={{ margin: "1%" }}>
//                 <button className="safety-check-popup-btn" onClick={handleLocationClick}>
//                     Use my location
//                 </button>

//                 <button className="safety-check-popup-btn" onClick={handleDestinationClick}>
//                     Delivery Man
//                 </button>
//                 <button className="safety-check-popup-btn" onClick={handleDirectionsClick}>
//                     Get directions
//                 </button>
//             </div>
//             <div style={{ height: "200vh", width: "100%" }} ref={mapRef} />
//         </div>
//     );
// }

// <--- Already working Map End --->






// <---- New One -->


import { Loader } from "@googlemaps/js-api-loader";
import { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import Geocode from "react-geocode";

const loader = new Loader({
    // apiKey: "Your Key",
    apiKey:"AIzaSyBSHDxKrKJXokOeEKYaLdklrDqO2GsNVRI",
    version: "weekly",
});
// Geocode.setApiKey("Your Key");
Geocode.setApiKey("AIzaSyBSHDxKrKJXokOeEKYaLdklrDqO2GsNVRI")

export default function Livemap() {
    const { lat, long } = useParams();
    const location1 = useLocation()
    const address = location1.search.split("=")[1];
    const [location, setLocation] = useState({ lat: 13.061773, lng: 80.246764 });
    const [destination, setDestination] = useState(null);
    const [destinationMarker, setDestinationMarker] = useState(null);
    const [mapInstance, setMapInstance] = useState(null);
    const [latitude1, setLatitude1] = useState();
    const [longitude1, setLongitude1] = useState();
    const mapRef = useRef(null);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const [hasReachedDestination, setHasReachedDestination] = useState(false);
    const [isTimeExpired, setIsTimeExpired] = useState(false);

    useEffect(() => {
        loader.load().then(() => {
            const map = new window.google.maps.Map(mapRef.current, {
                center: location,
                zoom: 14,
            });
            const directionsService = new window.google.maps.DirectionsService();
            const directionsRenderer = new window.google.maps.DirectionsRenderer({
                map: map,
            });
            directionsServiceRef.current = directionsService;
            directionsRendererRef.current = directionsRenderer;
        });
        // console.log(location1);
    }, [location]);

    useEffect(() => {
        if (location && destination && !hasReachedDestination && !isTimeExpired) {
            const directionsService = directionsServiceRef.current;
            const directionsRenderer = directionsRendererRef.current;
            const request = {
                origin: location,
                destination: destination,
                travelMode: "DRIVING",
            };
            console.log("Sending location request to API...");
            directionsService.route(request, (result, status) => {
                if (status === "OK") {
                    directionsRenderer.setDirections(result);
                    const leg = result.routes[0].legs[0];
                    const duration = leg.duration.value; // Duration in seconds
                    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                    const startTime = Math.floor(currentTime - duration);

                    if (currentTime >= startTime + 3600) {
                        // 5 minutes have passed, time expired
                        setIsTimeExpired(true);
                    }

                    if (currentTime >= startTime && !hasReachedDestination) {
                        // Update reached destination status
                        setHasReachedDestination(true);
                        // Perform any desired action when destination is reached
                        console.log("Destination reached!");
                    }
                }
            });
        }
    }, [location, destination, hasReachedDestination, isTimeExpired]);

    useEffect(() => {
        Geocode.fromAddress(address).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setLatitude1(lat);
                setLongitude1(lng);
            },
            (error) => {
                console.error(error);
            }
        );
    }, [address]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!hasReachedDestination && !isTimeExpired) {
                // Only fetch delivery man location every 1 minute
                console.log("Fetching delivery man location...");
                // Perform the necessary API request here to get the delivery man location
            }
        }, 5* 60 * 1000); // Interval of 1 minute (in milliseconds)

        return () => {
            clearInterval(interval);
        };
    }, [hasReachedDestination, isTimeExpired]);

    function handleLocationClick() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    lat: Number(latitude1),
                    lng: Number(longitude1),
                });
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleDestinationClick = () => {
        const destLocation = { lat: Number(lat), lng: Number(long) };
        setDestination(destLocation);
        setDestinationMarker(
            new window.google.maps.Marker({
                position: destLocation,
                map: mapInstance,
            })
        );
    };

    function handleDirectionsClick() {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: toString(destination) }, (results, status) => {
            if (status === "OK") {
                setDestination(results[0].geometry.location);
            }
        });
    }

    useEffect(() => {
        if (hasReachedDestination) {
            console.log("Destination reached!");
        }
        if (isTimeExpired) {
            console.log("5-minute time limit expired.");
        }
    }, [hasReachedDestination, isTimeExpired]);

    return (
        <div>
            <div style={{ margin: "1%" }}>
                <button className="safety-check-popup-btn" onClick={handleLocationClick}>
                    Use my location
                </button>
                <button className="safety-check-popup-btn" onClick={handleDestinationClick}>
                    Delivery Man
                </button>
                <button className="safety-check-popup-btn" onClick={handleDirectionsClick}>
                    Get directions
                </button>
            </div>
            <div style={{ height: "200vh", width: "100%" }} ref={mapRef} />
        </div>
    );
}





// <---- New one End ---->











// import React, { useState } from "react";
// import {
//   GoogleMap,
//   DirectionsService,
//   DirectionsRenderer,
//   Marker,
//   LoadScript
// } from "@react-google-maps/api";

// function Livemap() {
//   const [destination, setDestination] = useState(null);
//   const [directions, setDirections] = useState(null);
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [destinationMarker, setDestinationMarker] = useState(null);
//   const [mapInstance, setMapInstance] = useState(null);

//   const handleGetDirections = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         setCurrentLocation({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       });
//     }
//   };

//   const handleDestinationClick = () => {
//     const destLocation = { lat: 13.058142, lng: 80.247334 };
//     setDestination(destLocation);
//     setDestinationMarker(
//       new window.google.maps.Marker({
//         position: destLocation,
//         map: mapInstance,
//       })
//     );
//   };

//   const handleDirectionsClick = () => {
//     if (destination && currentLocation) {
//       setDirections(null);
//       const directionsService = new window.google.maps.DirectionsService();
//       directionsService.route(
//         {
//           origin: currentLocation,
//           destination: destination,
//           travelMode: "DRIVING",
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections(result);
//           }
//         }
//       );
//     }
//   };

//   const mapContainerStyle = {
//     height: "100vh",
//     width: "100%",
//   };

//   const center = currentLocation ;

//   return (
//     <LoadScript
//       googleMapsApiKey="AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w"
//       libraries={["places"]}
//     >
//       <GoogleMap
//         mapContainerStyle={mapContainerStyle}
//         zoom={20}
//         center={center}
//         onLoad={(map) => {
//           // Save the map instance in a state variable for later use
//           setMapInstance(map);
//         }}
//       >
//         {currentLocation && (
//           <Marker position={currentLocation} title="Current Location" />
//         )}

//         {destinationMarker && (
//           <Marker position={destinationMarker.getPosition()} title="Destination" />
//         )}

//         <DirectionsService
//           options={{
//             destination: destination,
//             origin: currentLocation,
//             travelMode: "DRIVING",
//           }}
//           callback={(response) => {
//             if (response !== null) {
//               setDirections(response);
//             }
//           }}
//         />

//         {directions && <DirectionsRenderer directions={directions} />}

//         <div style={{ position: "absolute", top: "10px", left: "10px" }}>
//           <button onClick={handleGetDirections}>My Location</button>
//           <button onClick={handleDestinationClick}>Destination</button>
//           <button onClick={handleDirectionsClick}>Get Directions</button>
//         </div>
//       </GoogleMap>
//     </LoadScript>
//   );
// }

// export default Livemap;

// import React, { useState, useEffect } from "react";
// import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
// import Geocode from "react-geocode";

// function Livemap() {
//   const [currentPosition, setCurrentPosition] = useState({ lat:null , lng: null });
//   const [currentLocation, setCurrentLocation] = useState(null);
//   const [destination, setDestination] = useState(null);
//   const [directions, setDirections] = useState(null);

//   const handleGetDirections = () => {
//         if (navigator.geolocation) {
//           navigator.geolocation.getCurrentPosition((position) => {
//             setCurrentLocation({
//               lat: position.coords.latitude,
//               lng: position.coords.longitude,
//             });
//           });
//         }
//       };

//   // Set up Geocode configuration
//   Geocode.setApiKey("AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w");
//   Geocode.setLanguage("en");

//   // Get current location on mount
//   useEffect(() => {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         const { latitude, longitude } = position.coords;
//         setCurrentPosition({ lat: latitude, lng: longitude });
//       },
//       (error) => console.error(error)
//     );
//   }, []);

//   // Function to handle address lookup
//   const handleAddressLookup = (address) => {
//     Geocode.fromAddress(address).then(
//       (response) => {
//         const { lat, lng } = response.results[0].geometry.location;
//         setDestination({ lat, lng });
//       },
//       (error) => {
//         console.error(error);
//       }
//     );
//   };

//   // Function to handle direction lookup
//   const handleDirectionLookup = () => {
//     if (currentLocation && destination) {
//       setDirections({ loading: true });

//       const directionsService = new window.google.maps.DirectionsService();

//       directionsService.route(
//         {
//           origin: currentLocation,
//           destination,
//           travelMode: "DRIVING",
//         },
//         (result, status) => {
//           if (status === window.google.maps.DirectionsStatus.OK) {
//             setDirections({ result });
//           } else {
//             console.error(`Directions request failed due to ${status}`);
//           }
//         }
//       );
//     }
//   };

//   return (
//     <div>
//       <input type="text" placeholder="Enter a destination address" onChange={(e) => handleAddressLookup(e.target.value)} />
//       <button onClick={handleGetDirections}>My Location</button>
//       <button onClick={handleDirectionLookup}>Get Directions</button>

//       <LoadScript googleMapsApiKey="AIzaSyCEIGyw5zZam6dKoQYHTOB1O6cX_RNF68w">
//         <GoogleMap mapContainerStyle={{ height: "1000px", width: "100%" }} center={currentLocation} zoom={12}>
//           <Marker position={currentLocation} />
//           {destination && <Marker position={destination} />}
//           {directions && <DirectionsRenderer directions={directions.result} />}
//         </GoogleMap>
//       </LoadScript>
//     </div>
//   );
// }

// export default Livemap;

// import React, { useState, useEffect } from "react";
// import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";

// const Livemap = () => {
//   const [map, setMap] = useState(null);
//   const [liveLocation, setLiveLocation] = useState(null);
//   const [destination, setDestination] = useState(null);
//   const [directions, setDirections] = useState(null);

//   useEffect(() => {
//     navigator.geolocation.watchPosition(
//       (position) => {
//         setLiveLocation({
//           lat: position.coords.latitude,
//           lng: position.coords.longitude,
//         });
//       },
//       (error) => console.error(error),
//       { enableHighAccuracy: true }
//     );
//   }, []);

//   const handleMapClick = (event) => {
//     setDestination({
//       lat: event.latLng.lat(),
//       lng: event.latLng.lng(),
//     });
//   };

//   const calculateDirections = (origin, destination) => {
//     const directionsService = new window.google.maps.DirectionsService();
//     directionsService.route(
//       {
//         origin: origin,
//         destination: destination,
//         travelMode: "DRIVING",
//       },
//       (result, status) => {
//         if (status === "OK") {
//           setDirections(result);
//         } else {
//           console.error(`error fetching directions ${result}`);
//         }
//       }
//     );
//   };

//   return (
//     <div style={{ height: "100vh", width: "100%" }}>
//       <GoogleMap
//         mapContainerStyle={{ height: "100%", width: "100%" }}
//         center={liveLocation}
//         zoom={14}
//         onClick={handleMapClick}
//         onLoad={(map) => setMap(map)}
//         options={{
//           disableDefaultUI: true,
//           zoomControl: true,
//         }}
//       >
//         {liveLocation && <Marker position={liveLocation} />}
//         {destination && <Marker position={destination} />}
//         {directions && <DirectionsRenderer directions={directions} />}
//       </GoogleMap>
//       <button
//         onClick={() =>
//           calculateDirections(
//             { lat: liveLocation.lat, lng: liveLocation.lng },
//             { lat: destination.lat, lng: destination.lng }
//           )
//         }
//         disabled={!liveLocation || !destination}
//         style={{
//           position: "absolute",
//           top: "10px",
//           left: "10px",
//           zIndex: 1,
//         }}
//       >
//         Show Directions
//       </button>
//     </div>
//   );
// };

// export default Livemap;
