// react
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// application
import Indicator from "./Indicator";
import { Person20Svg } from "../../svg";
import { useDispatch } from "react-redux";
import { removeuserChange } from "../../store/adduser";
import { useLocation } from "react-router-dom";
import { removeodcuserChange } from "../../store/addodcuser";
import { ToastContainer, toast } from "react-toastify";

function IndicatorAccount(props) {
    const { odcuser } = props;
    const location = useLocation();
    const [isHomePage, setIsHomePage] = useState(false);
    const [isHomePage1, setIsHomePage1] = useState(false);
    const [isHomePage2, setIsHomePage2] = useState(false);
    // const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        setIsHomePage(location.pathname === "/industry/home" || location.pathname === "/corporate/home");
        setIsHomePage1(location.pathname === "/");
        setIsHomePage2(location.pathname === "/odc/mainhome " || "/odc/home");
    }, [location]);

    const dispatch = useDispatch();
    // const { user } = props;

    const history = useHistory();

    const handleLogin = (e) => {
        e.preventDefault();

        history.push("/site/login-page");
    };
    const odchandleLogin = (e) => {
        e.preventDefault();

        history.push("/odc/login");
    };
    const odchandleSignin = (e) => {
        e.preventDefault();

        history.push("/odc/signin");
    };
    const handleViewProfile = (e) => {
        if (odcuser && odcuser.fullName) {
            history.push("/odc/profile");
        } else {
            toast.error("Oops Please Login", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push("/odc/login");
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();

        dispatch(removeuserChange());

        history.push("/");
    };
    const handleLogoutodc = (e) => {
        e.preventDefault();

        dispatch(removeodcuserChange());
        // localStorage.removeItem('tokenodc');
        history.push("/mainhome/odc");
    };

    const currentPath = window.location.pathname;
    const firstPath = "/odc"; // Specify the first path here

    // Check if the currentPath starts with the firstPath
    const shouldShowDesign = currentPath.startsWith(firstPath);
    // console.log(odcuser);

    //    console.log(JSON.stringify(userinfo.fullName));
    const dropdown = (
        <div>
            <div className="account-menu">
                <div className="account-menu__divider" />
                <div style={{ padding: "10px", textAlign: "center" }}>
                    <div style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                        <img src="images/avatars/avatar-4.png" alt="" style={{ width: " 75px", marginBottom: "5%" }} />
                    </div>
                    <div className="account-menu__user-info">
                        <div
                            className="account-menu__user-name"
                            style={{ fontWeight: "600", wordBreak: "break-all", width: "190px", margin: "5% 0%" }}
                        >
                            {props && props.user && props.user.fullName ? (
                                props.user.fullName
                            ) : odcuser && odcuser.fullName ? (
                                odcuser && odcuser.fullName
                            ) : (
                                <p style={{ fontWeight: "600", fontSize: "21px", display: "inline" }}>Guest</p>
                            )}
                        </div>
                        <div className="account-menu__user-email" style={{ wordBreak: "break-all", width: "190px" }}>
                            {props.user && props.user.email ? props.user.email : null}
                        </div>
                    </div>
                </div>

                {shouldShowDesign ? (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <button className="proview-btn mb-3" onClick={handleViewProfile}>
                            {" "}
                            View Profile
                        </button>
                        <div className="account-menu__divider" />
                        <ul className="account-menu__links">
                            {/* <li className="userinfo-txt">
                    Category : {props.user && props.user.ctype ? props.user.ctype : "No Category"}
                </li> */}
                            <li>
                                {odcuser && odcuser.fullName ? (
                                    <button className="odc-sg-btn" onClick={handleLogoutodc}>
                                        Logout
                                    </button>
                                ) : (
                                    <div style={{ display: "flex" }}>
                                        <button className="odc-lg-btn" onClick={odchandleLogin}>
                                            Login
                                        </button>
                                        <button className="odc-sg-btn" onClick={odchandleSignin}>
                                            Sign Up
                                        </button>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div>
                        <div className="account-menu__divider" />
                        <ul className="account-menu__links">
                            <li className="userinfo-txt">
                                Category : {props.user && props.user.ctype ? props.user.ctype : "No Category"}
                            </li>
                            <li>
                                {props.user && props.user.fullName ? (
                                    <div className="userinfo-txt" onClick={handleLogout}>
                                        Logout
                                    </div>
                                ) : (
                                    <div className="userinfo-txt" onClick={handleLogin}>
                                        Login
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );

    return (
        <Indicator
            className={isHomePage2 ? "cart-icon-style" : "home-nav-panel123"}
            dropdown={dropdown}
            icon={<Person20Svg />}
        />
    );
}
IndicatorAccount.propTypes = {
    odcuser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    odcuser: state.odcuser,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
