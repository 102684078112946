import { makeIdGenerator } from "../utils";
import brandsData from "./brands";
import { categoriesListData, prepareCategory } from "./categories";

const getId = makeIdGenerator();

const attributesDef = [
    {
        name: "Color",
        slug: "color",
        values: [
            { name: "White", slug: "white" },
            { name: "Silver", slug: "silver" },
            { name: "Light Gray", slug: "light-gray" },
            { name: "Gray", slug: "gray" },
            { name: "Dark Gray", slug: "dark-gray" },
            { name: "Coal", slug: "coal" },
            { name: "Black", slug: "black" },
            { name: "Red", slug: "red" },
            { name: "Orange", slug: "orange" },
            { name: "Yellow", slug: "yellow" },
            { name: "Pear Green", slug: "pear-green" },
            { name: "Green", slug: "green" },
            { name: "Emerald", slug: "emerald" },
            { name: "Shamrock", slug: "shamrock" },
            { name: "Shakespeare", slug: "shakespeare" },
            { name: "Blue", slug: "blue" },
            { name: "Dark Blue", slug: "dark-blue" },
            { name: "Violet", slug: "violet" },
            { name: "Purple", slug: "purple" },
            { name: "Cerise", slug: "cerise" },
        ],
    },
    {
        name: "Speed",
        slug: "speed",
        values: [{ name: "750 RPM", slug: "750-rpm" }],
    },
    {
        name: "Power Source",
        slug: "power-source",
        values: [{ name: "Cordless-Electric", slug: "cordless-electric" }],
    },
    {
        name: "Battery Cell Type",
        slug: "battery-cell-type",
        values: [{ name: "Lithium", slug: "lithium" }],
    },
    {
        name: "Voltage",
        slug: "voltage",
        values: [{ name: "20 Volts", slug: "20-volts" }],
    },
    {
        name: "Battery Capacity",
        slug: "battery-capacity",
        values: [{ name: "2 Ah", slug: "2-Ah" }],
    },
];

const productsDef = [
    {
        slug: "electric-planer-brandix-kl370090g-300-watts",
        name: "Electric Planer Brandix KL370090G 300 Watts",
        price: 749,
        images: ["images/products/product-1.jpg", "images/products/product-1-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 12,
        availability: "in-stock",
        brand: "brandix",
        categories: ["screwdrivers"],
        attributes: [
            { slug: "color", values: "yellow" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],
        //
        orderDate: "1",
        orderMonth: "February 2023",
        orderDay: "Wen",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#001",
        orderVenu: "01/02/2023",
    },
    {
        slug: "undefined-tool-iradix-dps3000sy-2700-watts",
        name: "Undefined Tool IRadix DPS3000SY 2700 Watts",
        price: 1019,
        images: ["images/products/product-2.jpg", "images/products/product-2-1.jpg"],
        badges: "hot",
        rating: 5,
        reviews: 3,
        availability: "in-stock",
        brand: "zosch",
        categories: ["instruments"],
        attributes: [
            { slug: "color", values: ["silver", "cerise"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "2",
        orderMonth: "February 2023",
        orderDay: "Thu",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#001",
        orderVenu: "01/02/2023",
    },
    {
        slug: "drill-screwdriver-brandix-alx7054-200-watts",
        name: "Drill Screwdriver Brandix ALX7054 200 Watts",
        price: 850,
        images: ["images/products/product-3.jpg", "images/products/product-3-1.jpg"],
        rating: 4,
        reviews: 8,
        availability: "in-stock",
        brand: "brandix",
        categories: ["power-tools"],
        attributes: [
            { slug: "color", values: "yellow" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],
        //
        orderDate: "3",
        orderMonth: "February 2023",
        orderDay: "Fri",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#002",
        orderVenu: "01/02/2023",
    },
    {
        slug: "drill-series-3-brandix-ksr4590pqs-1500-watts",
        name: "Drill Series 3 Brandix KSR4590PQS 1500 Watts",
        price: 949,
        compareAtPrice: 1189,
        images: ["images/products/product-4.jpg", "images/products/product-4-1.jpg"],
        badges: "sale",
        rating: 3,
        reviews: 15,
        availability: "in-stock",
        brand: "brandix",
        categories: [],
        attributes: [
            { slug: "color", values: "white" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "4",
        orderMonth: "February 2023",
        orderDay: "Sat",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#003",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-router-power-tool-2017erxpk",
        name: "Brandix Router Power Tool 2017ERXPK",
        price: 1700,
        images: ["images/products/product-5.jpg", "images/products/product-5-1.jpg"],
        rating: 5,
        reviews: 2,
        availability: "in-stock",
        brand: "wakita",
        categories: [],
        attributes: [
            { slug: "color", values: "dark-blue" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "5",
        orderMonth: "February 2023",
        orderDay: "Sun",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#004",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-drilling-machine-dm2019kw4-4kw",
        name: "Brandix Drilling Machine DM2019KW4 4kW",
        price: 3199,
        images: ["images/products/product-6.jpg", "images/products/product-6-1.jpg"],
        rating: 3,
        reviews: 21,
        availability: "in-stock",
        brand: "wakita",
        categories: [],
        attributes: [
            { slug: "color", values: "orange" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "6",
        orderMonth: "February 2023",
        orderDay: "Mon",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#005",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Breakfast Combo",
        name: "Breakfast Combo",
        price: 80,
        images: ["images/products/combo3.png", "images/products/combo3.png"],
        rating: 5,
        reviews: 1,
        availability: "in-stock",
        brand: "wevalt",
        categories: [],
        attributes: [
            { slug: "color", values: "red" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "7",
        orderMonth: "February 2023",
        orderDay: "Tus",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#006",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Lunch Combo",
        name: "Lunch Combo",
        price: 130,
        images: ["images/products/combo4.png", "images/products/combo4.png"],
        rating: 4,
        reviews: 5,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: ["pear-green", "blue"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "8",
        orderMonth: "February 2023",
        orderDay: "Wen",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#007",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Snacks Combo",
        name: "Snacks Combo",
        price: 90,
        images: ["images/products/combo5.png", "images/products/combo5.png"],
        rating: 4,
        reviews: 34,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: "green" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "9",
        orderMonth: "February 2023",
        orderDay: "Thu",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#008",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Dinner Combo",
        name: "Dinner Combo",
        price: 140,
        images: ["images/products/combo6.png", "images/products/combo6.png"],
        rating: 5,
        reviews: 3,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: "gray" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "10",
        orderMonth: "February 2023",
        orderDay: "Fri",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#009",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Supper Combo",
        name: "Supper Combo",
        price: 149,
        images: ["images/products/combo7.png", "images/products/combo7.png"],
        rating: 4,
        reviews: 7,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: "black" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "11",
        orderMonth: "February 2023",
        orderDay: "Sat",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#010",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Best Combo",
        name: "Best Combo",
        price: 95,
        images: ["images/products/combo1.png", "images/products/combo1.png"],
        rating: 5,
        reviews: 17,
        availability: "in-stock",
        brand: "mitasia",
        categories: [],
        attributes: [
            { slug: "color", values: "violet" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "12",
        orderMonth: "February 2023",
        orderDay: "Sun",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#011",
        orderVenu: "01/02/2023",
    },
    {
        slug: "Spicel Order Combo",
        name: "Spicel Order Combo",
        price: 200,
        images: ["images/products/combo2.png", "images/products/combo2.png"],
        badges: "new",
        rating: 4,
        reviews: 12,
        availability: "in-stock",
        brand: "brandix",
        categories: ["screwdrivers"],
        attributes: [
            { slug: "color", values: "yellow" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "13",
        orderMonth: "February 2023",
        orderDay: "Mon",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#012",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-air-compressor-deltakx500",
        name: "Brandix Air Compressor DELTAKX500",
        price: 1800,
        images: ["images/products/product-14.jpg", "images/products/product-14-1.jpg"],
        rating: 3,
        reviews: 14,
        availability: "in-stock",
        brand: "brandix",
        categories: [],
        attributes: [
            { slug: "color", values: ["light-gray", "emerald"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "14",
        orderMonth: "February 2023",
        orderDay: "Tus",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#013",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-electric-jigsaw-jig7000bq",
        name: "Brandix Electric Jigsaw JIG7000BQ",
        price: 290,
        images: ["images/products/product-15.jpg", "images/products/product-15-1.jpg"],
        rating: 2,
        reviews: 1,
        availability: "in-stock",
        brand: "brandix",
        categories: [],
        attributes: [
            { slug: "color", values: ["coal", "shamrock"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "15",
        orderMonth: "February 2023",
        orderDay: "Wen",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#014",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-screwdriver-screw1500acc",
        name: "Brandix Screwdriver SCREW1500ACC",
        price: 1499,
        images: [
            "images/products/product-16.jpg",
            "images/products/product-16-1.jpg",
            "images/products/product-16-2.jpg",
            "images/products/product-16-3.jpg",
            "images/products/product-16-4.jpg",
        ],
        rating: 5,
        reviews: 3,
        availability: "in-stock",
        brand: "metaggo",
        categories: [],
        attributes: [
            { slug: "color", values: ["dark-gray", "shakespeare"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "16",
        orderMonth: "February 2023",
        orderDay: "Thu",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#015",
        orderVenu: "01/02/2023",
    },
    {
        slug: "electric-planer-brandix-kl370090g-300-watts",
        name: "Electric Planer Brandix KL370090G 300 Watts",
        price: 749,
        images: ["images/products/product-1.jpg", "images/products/product-1-1.jpg"],
        badges: "new",
        rating: 4,
        reviews: 12,
        availability: "in-stock",
        brand: "brandix",
        categories: ["screwdrivers"],
        attributes: [
            { slug: "color", values: "yellow" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "17",
        orderMonth: "February 2023",
        orderDay: "Fri",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#016",
        orderVenu: "01/02/2023",
    },
    {
        slug: "undefined-tool-iradix-dps3000sy-2700-watts",
        name: "Undefined Tool IRadix DPS3000SY 2700 Watts",
        price: 1019,
        images: ["images/products/product-2.jpg", "images/products/product-2-1.jpg"],
        badges: "hot",
        rating: 5,
        reviews: 3,
        availability: "in-stock",
        brand: "zosch",
        categories: ["instruments"],
        attributes: [
            { slug: "color", values: ["silver", "cerise"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "18",
        orderMonth: "February 2023",
        orderDay: "Sat",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#017",
        orderVenu: "01/02/2023",
    },
    {
        slug: "drill-screwdriver-brandix-alx7054-200-watts",
        name: "Drill Screwdriver Brandix ALX7054 200 Watts",
        price: 850,
        images: ["images/products/product-3.jpg", "images/products/product-3-1.jpg"],
        rating: 4,
        reviews: 8,
        availability: "in-stock",
        brand: "brandix",
        categories: ["power-tools"],
        attributes: [
            { slug: "color", values: "yellow" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "19",
        orderMonth: "February 2023",
        orderDay: "Sun",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#018",
        orderVenu: "01/02/2023",
    },
    {
        slug: "drill-series-3-brandix-ksr4590pqs-1500-watts",
        name: "Drill Series 3 Brandix KSR4590PQS 1500 Watts",
        price: 949,
        compareAtPrice: 1189,
        images: ["images/products/product-4.jpg", "images/products/product-4-1.jpg"],
        badges: "sale",
        rating: 3,
        reviews: 15,
        availability: "in-stock",
        brand: "brandix",
        categories: [],
        attributes: [
            { slug: "color", values: "white" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "20",
        orderMonth: "February 2023",
        orderDay: "Mon",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#019",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-router-power-tool-2017erxpk",
        name: "Brandix Router Power Tool 2017ERXPK",
        price: 1700,
        images: ["images/products/product-5.jpg", "images/products/product-5-1.jpg"],
        rating: 4,
        reviews: 2,
        availability: "in-stock",
        brand: "wakita",
        categories: [],
        attributes: [
            { slug: "color", values: "dark-blue" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "21",
        orderMonth: "February 2023",
        orderDay: "Tus",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#020",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-drilling-machine-dm2019kw4-4kw",
        name: "Brandix Drilling Machine DM2019KW4 4kW",
        price: 3199,
        images: ["images/products/product-6.jpg", "images/products/product-6-1.jpg"],
        rating: 3,
        reviews: 21,
        availability: "in-stock",
        brand: "wakita",
        categories: [],
        attributes: [
            { slug: "color", values: "orange" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "22",
        orderMonth: "February 2023",
        orderDay: "Wen",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#021",
        orderVenu: "01/02/2023",
    },
    {
        slug: "brandix-pliers",
        name: "Brandix Pliers",
        price: 24,
        images: ["images/products/product-7.jpg", "images/products/product-7-1.jpg"],
        rating: 2,
        reviews: 1,
        availability: "in-stock",
        brand: "wevalt",
        categories: [],
        attributes: [
            { slug: "color", values: "red" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "24",
        orderMonth: "February 2023",
        orderDay: "Thu",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#022",
        orderVenu: "01/02/2023",
    },
    {
        slug: "water-hose-40cm",
        name: "Water Hose 40cm",
        price: 15,
        images: ["images/products/product-8.jpg", "images/products/product-8-1.jpg"],
        rating: 2,
        reviews: 5,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: ["pear-green", "blue"] },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "25",
        orderMonth: "February 2023",
        orderDay: "Fri",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#023",
        orderVenu: "01/02/2023",
    },
    {
        slug: "spanner-wrench",
        name: "Spanner Wrench",
        price: 19,
        images: ["images/products/product-9.jpg", "images/products/product-9-1.jpg"],
        rating: 4,
        reviews: 34,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: "green" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "26",
        orderMonth: "February 2023",
        orderDay: "Sat",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#024",
        orderVenu: "01/02/2023",
    },
    {
        slug: "water-tap",
        name: "Water Tap",
        price: 15,
        images: ["images/products/product-10.jpg", "images/products/product-10-1.jpg"],
        rating: 5,
        reviews: 3,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: "gray" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "27",
        orderMonth: "February 2023",
        orderDay: "Sun",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#025",
        orderVenu: "01/02/2023",
    },
    {
        slug: "hand-tool-kit",
        name: "Hand Tool Kit",
        price: 149,
        images: ["images/products/product-11.jpg", "images/products/product-11-1.jpg"],
        rating: 4,
        reviews: 7,
        availability: "in-stock",
        brand: "hammer",
        categories: [],
        attributes: [
            { slug: "color", values: "black" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "28",
        orderMonth: "February 2023",
        orderDay: "Mon",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#026",
        orderVenu: "01/02/2023",
    },
    {
        slug: "ash-s-chainsaw-3.5kw",
        name: "Ash's Chainsaw 3.5kW",
        price: 666.99,
        images: ["images/products/product-12.jpg", "images/products/product-12-1.jpg"],
        rating: 5,
        reviews: 17,
        availability: "in-stock",
        brand: "mitasia",
        categories: [],
        attributes: [
            { slug: "color", values: "violet" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "1",
        orderMonth: "March 2023",
        orderDay: "Tus",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#027",
        orderVenu: "01/02/2023",
    },
    {
        slug: "ash-s-chainsaw-3.5kw",
        name: "Ash's Chainsaw 3.5kW",
        price: 666.99,
        images: ["images/products/product-12.jpg", "images/products/product-12-1.jpg"],
        rating: 5,
        reviews: 17,
        availability: "in-stock",
        brand: "mitasia",
        categories: [],
        attributes: [
            { slug: "color", values: "violet" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "2",
        orderMonth: "March 2023",
        orderDay: "Wen",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#028",
        orderVenu: "01/02/2023",
    },
    {
        slug: "ash-s-chainsaw-3.5kw",
        name: "Ash's Chainsaw 3.5kW",
        price: 666.99,
        images: ["images/products/product-12.jpg", "images/products/product-12-1.jpg"],
        rating: 5,
        reviews: 17,
        availability: "in-stock",
        brand: "mitasia",
        categories: [],
        attributes: [
            { slug: "color", values: "violet" },
            { slug: "speed", values: "750-rpm", featured: true },
            { slug: "power-source", values: "cordless-electric", featured: true },
            { slug: "battery-cell-type", values: "lithium", featured: true },
            { slug: "voltage", values: "20-volts", featured: true },
            { slug: "battery-capacity", values: "2-Ah", featured: true },
        ],

        //
        orderDate: "3",
        orderMonth: "March 2023",
        orderDay: "Thu",
        serviceOne: "Breackfast",
        serviceTwo: "Lunch",
        serviceThree: "Dinner",
        otherServices: ["Supper", "Snacks"],
        services: ["Breackfast", "Lunch", "Dinner", "Supper", "Snacks"],
        url: "/site/my-order-count",
        orderId: "#029",
        orderVenu: "01/02/2023",
    },
];

const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === "string" ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || [])
        .map((productAttributeDef) => {
            const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

            if (!attributeDef) {
                return null;
            }

            let valuesDef = [];

            if (typeof productAttributeDef.values === "string") {
                valuesDef = [productAttributeDef.values];
            } else if (productAttributeDef.values) {
                valuesDef = productAttributeDef.values;
            }

            const values = valuesDef
                .map((valueSlug) => {
                    const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

                    if (!valueDef) {
                        return null;
                    }

                    return {
                        ...valueDef,
                        customFields: {},
                    };
                })
                .filter((x) => x !== null);

            if (!values.length) {
                return null;
            }

            return {
                name: attributeDef.name,
                slug: attributeDef.slug,
                featured: !!productAttributeDef.featured,
                values,
                customFields: {},
            };
        })
        .filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: "83690/32",
        slug: productDef.slug,
        price: productDef.price,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
        //
        orderDate: productDef.orderDate,
        orderMonth: productDef.orderMonth,
        orderDay: productDef.orderDay,
        serviceOne: productDef.serviceOne,
        serviceTwo: productDef.serviceTwo,
        serviceThree: productDef.serviceThree,
        otherServices: productDef.otherServices.slice(),
        services: productDef.services.slice(),
        url: productDef.url,
        orderId: productDef.orderId,
        orderVenu: productDef.orderVenu,
    };
});

export default productsData;
