import React from "react";
import ServiceSlideContent from "./ServiceSlideContent";

function ServiceSlide() {
    return (
        <div>
            <ServiceSlideContent />
        </div>
    );
}

export default ServiceSlide;
