import React, { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";


function Mainhome2() {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const incrementProgress = () => {
            setProgress((prevProgress) => {
                // Increment the progress by 1 and reset to 0 if it reaches 100
                const newProgress = (prevProgress + 1) % 102;
                return newProgress;
            });
        };

        // Complete the progress from 0 to 100
        const progressInterval = setInterval(incrementProgress, 150); // Update the progress every 100 milliseconds

        return () => {
            clearInterval(progressInterval); // Clean up the progress interval on component unmount
        };
    }, []);

    const steps = [
        {
            imgSrc: "images/choose.png",
            title: "Choose your Menu",
        },
        {
            imgSrc: "images/ordered.png",
            title: "Order",
        },
        {
            imgSrc: "images/pay.png",
            title: "Pay",
        },
        {
            imgSrc: "images/delivered.png",
            title: "Delivery",
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        duration: 30,
        autoplay: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    return (
        <div>
            <div className="mi-main-style">
                {/* Top */}
                <div>
                    <div
                        style={{
                            backgroundImage: "url('images/ex12.png')",
                            backgroundSize: "cover",
                            // backgroundAttachment: "fixed",
                        }}
                    >
                        <div className="container-fluid">
                            <div className="just-up-text intro-homebanner-style">
                                <h1 style={{ fontSize: "60px", marginBottom: "30px" }}>
                                    Discover Your Bulk Food from Hogist
                                </h1>
                                <p className="para-fontsize">
                                    We have Vendors for all your requirements,Order your bulk foods Now.
                                </p>
                                <button
                                    style={{
                                        backgroundColor: "#c60800",
                                        color: "#fff",
                                        textAlign: "center",
                                        padding: "10px 28px",
                                        border: "none",
                                        borderRadius: "7px",
                                        marginTop: "4%",
                                    }}
                                >
                                    Register Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* About Start */}
                <div
                    style={{
                        backgroundImage: "linear-gradient(154deg, #323232, #000000)",
                    }}
                >
                    {/* <span className="ds-4 ">
                    <img src="https://html.softtechitltd.com/khadyo/khadyo/assets/images/shapes/27.png" alt="" />
                </span>
                <span className="ds-5 ">
                    <img src="https://html.softtechitltd.com/khadyo/khadyo/assets/images/shapes/sm-tomatto.png" alt="" />
                </span>
                <span className="ds-6 ">
                    <img src="https://html.softtechitltd.com/khadyo/khadyo/assets/images/shapes/24.png" alt="" />
                </span> */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="main-about-img-style">
                                    {" "}
                                    <img src="images/ab-img.png" alt="" style={{ width: "100%", height: "100%" }} />
                                </div>
                            </div>
                            <div className="col-lg-7 " style={{ color: "#fff" }}>
                                {/* <img src="images/icon-b1.png" alt="" className="main-about-title-icon" /> */}
                                <div style={{ marginTop: "15%" }}>
                                    <div style={{ marginTop: "5%" }}>
                                        <h1 className="ab-service">
                                            Effectively handle all your catering needs in a best way{" "}
                                        </h1>
                                    </div>

                                    <p className="para-fontsize" style={{ textAlign: "justify" }}>
                                        Hogist is the one-stop solution for all your event food needs. Get the best
                                        catering services through us.
                                    </p>
                                    <ul>
                                        <li style={{ padding: "2% 0%" }}>
                                            <CheckCircleOutlineIcon style={{ color: "#c60800", marginRight: "1%" }} />
                                            Experienced Chef's
                                        </li>
                                        <li style={{ padding: "2% 0%" }}>
                                            <CheckCircleOutlineIcon style={{ color: "#c60800", marginRight: "1%" }} />
                                            Best Taste
                                        </li>
                                        <li style={{ padding: "2% 0%" }}>
                                            <CheckCircleOutlineIcon style={{ color: "#c60800", marginRight: "1%" }} />
                                            Fresh Food
                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="row">
                                <div className="col-lg-4" style={{ textAlign: "center" }}>
                                    <img src="images/micon3.png" alt="" style={{ width: "55%" }} />
                                    <p style={{ color: "#fff" }}>Experienced Chef's</p>
                                </div>
                                <div className="col-lg-4" style={{ textAlign: "center" }}>
                                    <img src="images/micon2.png" alt="" style={{ width: "55%" }} />
                                    <p style={{ color: "#fff" }}>Best Taste</p>
                                </div>
                                <div className="col-lg-4" style={{ textAlign: "center" }}>
                                    <img src="images/micon1.png" alt="" style={{ width: "55%" }} />
                                    <p style={{ color: "#fff" }}>Fresh Food</p>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* About End */}

                {/* {Order step start} */}
                <div style={{ backgroundColor: "#000", paddingBottom: "10%" }}>
                    <div className="container">
                        <h1 className="mainhome-ordersteps-style">
                            Just follow the easy steps to <span style={{ color: "#c60800" }}>Order</span>
                        </h1>
                        <p
                            className="para-fontsize"
                            style={{ color: "#fff", textAlign: "center", marginBottom: "10%" }}
                        >
                            Your delicious food is just waiting for your order. Just follow the easy steps to get it
                            delivered.
                        </p>

                        <div className="mb-order-steps">
                            <ProgressBar
                                percent={progress}
                                filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
                            >
                                {steps.map((step, index) => (
                                    <Step key={index} transition="scale">
                                        {({ accomplished }) => (
                                            <div style={{ textAlign: "center" }}>
                                                <span
                                                    style={{
                                                        fontWeight: accomplished ? "bold" : "normal",
                                                        color: accomplished ? "#c60800" : "#fff",
                                                    }}
                                                >
                                                    {step.title}
                                                </span>
                                                <div style={{ marginTop: "10px" }}>
                                                    <img
                                                        style={{
                                                            filter: `grayscale(${accomplished ? 0 : 80}%)`,
                                                            width: "80px",
                                                        }}
                                                        src={step.imgSrc}
                                                        alt={`Step ${index + 1}`}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Step>
                                ))}
                            </ProgressBar>
                        </div>
                    </div>
                </div>
                {/* order steps end */}
                {/* Services */}

                <div>
                    <div className="container-fluid ">
                        <h2 className="txt-align-center  " style={{ margin: "5% 0%" }}>
                            <span style={{ color: "#c60800" }}>O</span>ur Services
                        </h2>
                        <div className="row">
                            <div className="col-lg-3 mb-4">
                                <div className="card card-bg-style p-4 ">
                                    <div className="sv-card-images">
                                        <img
                                            src="https://www.lagunarestaurant.com/wp-content/uploads/2020/09/Why-choose-Laguna-for-Indian-catering-services-image.jpg"
                                            alt="Industrial Catering"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div>
                                        <h4 className="mt-3 mb-2"> Industrial Catering</h4>
                                        <p>
                                            Hogist is the one-stop solution for all your industrial food needs. Get the
                                            best catering services through us. We offer best quality with different
                                            taste in our catering services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-4">
                                <div className="card card-bg-style  p-4">
                                    <div className="sv-card-images">
                                        <img
                                            src="https://img.freepik.com/premium-photo/scooping-food-buffet-food-restaurant-catering_41969-567.jpg"
                                            alt="Corporate Catering"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div>
                                        <h4 className="mt-3 mb-2"> Corporate Catering</h4>
                                        <p>
                                            Hogist is the one-stop solution for all your corporate food needs. Get the
                                            best catering services through us. We offer best quality with different
                                            taste in our catering services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-4">
                                <div className="card card-bg-style  p-4">
                                    <div className="sv-card-images">
                                        <img
                                            src="https://t4.ftcdn.net/jpg/04/02/54/77/360_F_402547721_wvysjmZNlLIuZSdAfX5s3ZkRiQXICjly.jpg"
                                            alt="Out Door Catering"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div>
                                        <h4 className="mt-3 mb-2"> Out Door Catering</h4>
                                        <p>
                                            Hogist is the one-stop solution for all your event food needs. Get the best
                                            catering services through us. We offer best quality with different taste in
                                            our catering services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-4">
                                <div className="card card-bg-style  p-4">
                                    <div className="sv-card-images">
                                        <img
                                            src="https://www.vmcdn.ca/f/files/via/images/food/round-up-cafe-interior.jpg"
                                            alt="Cafeteria Catering"
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                    <div>
                                        <h4 className="mt-3 mb-2"> Cafeteria Catering</h4>
                                        <p>
                                            Hogist is the one-stop solution for all your cafeteria needs. Get the best
                                            catering services through us. We offer best quality with different taste in
                                            our catering services.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* mission */}
                {/* <div
                    style={{
                        paddingBottom: "4%",
                    }}
                >
                    <div
                        className="container mt-4 "
                        style={{
                            border: "2px dotted #c60800",
                            borderRadius: "2.5rem",
                            padding: "4%",
                            backgroundImage:
                                "url(https://img.freepik.com/premium-photo/top-view-classic-spaghetti-pasta-with-tomato-sauce-dark-plate-copy-space_67155-5968.jpg)",
                            backgroundSize: "cover",
                        }}
                    >
                        <Slider {...settings}>
                            <div>
                                <h2 className="txt-align-center mb-4">
                                    <span style={{ color: "#c60800" }}>C</span>ore Values (4E)
                                </h2>
                                <p className="para-fontsize">
                                    {" "}
                                    <span className="spiner-span-title">
                                        <span style={{ color: "#ffd707" }}> E</span>mployee First
                                    </span>
                                    We are serious about creating a productive, cooperative,safe & rewarding environment
                                    for our team.
                                </p>
                                <p className="para-fontsize">
                                    <span className="spiner-span-title">
                                        <span style={{ color: "#ffd707" }}> E</span>thics
                                    </span>
                                    We want to play a fair game and not make any compromises concerning integrity and
                                    ethics.
                                </p>
                                <p className="para-fontsize">
                                    <span className="spiner-span-title">
                                        <span style={{ color: "#ffd707" }}> E</span>xtra Mile
                                    </span>
                                    While we want to maintain the highest levels of hygiene, taste, and customer service
                                    in our food experiences, we want to go that extra mile in delighting our customers.
                                </p>
                                <p className="para-fontsize">
                                    <span className="spiner-span-title">
                                        <span style={{ color: "#ffd707" }}> E</span>ntertainment
                                    </span>
                                    Food is entertainment. Food is experience. Food is fun. We will always keep this in
                                    mind when we deliver our services.
                                </p>
                            </div>
                            <div>
                                <h2 className="txt-align-center mb-4">
                                    {" "}
                                    <span style={{ color: "#c60800" }}>M</span>ission
                                </h2>
                                <p className="para-fontsize">
                                    {" "}
                                    To create delicious,hygienic, entertaining food experiences at affordable prices
                                    with impeccable service in a professional, consistent, flexible,and timely manner
                                    catering to the requirements of groups of people - corporates and individuals.
                                </p>
                            </div>
                            <div>
                                <h2 className="txt-align-center mb-4">
                                    <span style={{ color: "#c60800" }}>V</span>ission
                                </h2>
                                <p className="para-fontsize">
                                    {" "}
                                    To be a leading market place for all bulk food requirments- providing hygienic &
                                    quality food with impeccable, versatile, and flexible customer service with
                                    consistency and presentation that will leave a memorable experience.
                                </p>
                            </div>
                        </Slider>
                    </div>
                </div> */}
                {/* mission */}

                {/* Testimoniel Start */}
                <div style={{ padding: "5% 5%" }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 itemcenter">
                                <div>
                                    <h2>See what our clients say</h2>
                                    <p>
                                        Our clients say it best. Here’s a small sample of the feedback we get every day.
                                    </p>
                                    <button className="cnt-btn-style-main">Contact Us</button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card card-bg-style p-4">
                                    <Slider {...settings}>
                                        <div>
                                            <h4>Feedback</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src="images/avatars/avatar-4.png"
                                                    alt="user"
                                                    style={{ width: "8%" }}
                                                />
                                                <p>Ram Kumar</p>
                                                {/* <p>(Software devloper)</p> */}
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Feedback</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src="images/avatars/avatar-4.png"
                                                    alt="user"
                                                    style={{ width: "8%" }}
                                                />
                                                <p>Sarath</p>
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Feedback</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src="images/avatars/avatar-4.png"
                                                    alt="user"
                                                    style={{ width: "8%" }}
                                                />
                                                <p>Vijay</p>
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Feedback</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src="images/avatars/avatar-4.png"
                                                    alt="user"
                                                    style={{ width: "8%" }}
                                                />
                                                <p>Praveen</p>
                                            </div>
                                        </div>
                                        <div>
                                            <h4>Feedback</h4>
                                            <p>
                                                Hogist is the one-stop solution for all your cafeteria needs. Get the
                                                best catering services through us. We offer best quality with different
                                                taste in our catering services.
                                            </p>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src="images/avatars/avatar-4.png"
                                                    alt="user"
                                                    style={{ width: "8%" }}
                                                />
                                                <p>Vikram</p>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Testimoniel Start */}
                {/* App into */}

                <div style={{ padding: "4% 0%" }}>
                    <div className="container">
                        <div className="app-bg-style">
                            <div className="row">
                                <div className="col-lg-6 itemcenter" style={{ textAlign: "center" }}>
                                    <div className="app-txt-top-style">
                                        <h2>Download our app to Get Exclusive Discounts!</h2>
                                        <div style={{ marginTop: "2%" }}>
                                            <img src="images/apple-store.png" alt="" style={{ width: "30%" }} />
                                            <a
                                                className="topbar-link"
                                                href="https://play.google.com/store/apps/details?id=com.hogist"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src="images/play-store.png" alt="" style={{ width: "30%" }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 mb-style-mt itemcenter">
                                    <img src="images/mobile12.png" alt="Hogist App" style={{ width: "67%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mainhome2;
