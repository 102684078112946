import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";

//

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { formatTime, formattedDateTime } from "../../../helper/dateformate";
import { apiurl } from "../../../api/api_url";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SpecialOrderData() {
    const { state } = useLocation();

    const { id } = useParams();
    const [menulistapi, setMenulistapi] = useState([]);
    const [sporderlist, setSporderlist] = useState(state);
    const [show, setShow] = useState(false);
    const [weightage, setWeightage] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handelWeight = () => {
        setWeightage(true);
    };
    const handleClosed = () => {
        setWeightage(false);
    };

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    useEffect(() => {
        getApiData();
    }, []);

    const getApiData = async () => {
        // const { id } = props;
        // const { id } = useParams( myorderlist._id);
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const result = await fetch(`${apiurl}/order/${id}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(result.data);
        // console.log(result.data);
    };

    const date = new Date(menulistapi.createdAt);
    const date1 = new Date(menulistapi && menulistapi.special && menulistapi.special.eventDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    return (
        <div className="container txt-colr">
            <form>
                <MDBRow>
                    <MDBCol lg={12} className="mrbt">
                        <MDBCard className="service-tab-style border-styles" style={{ textAlign: "center" }}>
                            <h4 className="fw-bold mb-1 order-details-header">My Special Order Status</h4>
                            <MDBCardBody>
                                <div>
                                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                        <ListItem>
                                            <ListItemText> Ordered Date </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText style={{ textAlign: "start" }}>
                                                {" "}
                                                {`${day} -${month} -${year}`}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Ordered Time </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText> {formatTime(menulistapi.createdAt)}</ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Event Name </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {" "}
                                                {menulistapi.special && menulistapi.special.eventName}{" "}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Event Type </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {" "}
                                                {menulistapi.special && menulistapi.special.eventType}{" "}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Event Date </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {" "}
                                                {`${day1} -${month1} -${year1}`}
                                            </ListItemText>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText> Event Time </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {menulistapi.special && menulistapi.special.eventTime}{" "}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Total Count </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {" "}
                                                {menulistapi.special && menulistapi.special.count}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Name </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {menulistapi.special && menulistapi.special.name}{" "}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Menu Suggestion </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {" "}
                                                {menulistapi.special && menulistapi.special.menuInfo}
                                            </ListItemText>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText> Additional Services </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                {" "}
                                                {menulistapi &&
                                                    menulistapi.special &&
                                                    menulistapi.special.addInfo &&
                                                    menulistapi.special.addInfo.map((item, index) => (
                                                        <span key={index}>{item.value} , </span>
                                                    ))}{" "}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Delivery Address</ListItemText>
                                            <ListItemText>: </ListItemText>
                                            <ListItemText style={{ textAlign: "start" }}>
                                                {" "}
                                                {menulistapi.special && menulistapi.special.address}{" "}
                                            </ListItemText>
                                        </ListItem>
                                        {/* <ListItem>
       <ListItemText> COVID check </ListItemText>
       <ListItemText> : </ListItemText>

       {menulistapi.services && menulistapi.services.map((item, index) => (
       <ListItemText style={{textAlign:'start',}}><Button className={item.dlchallan.status === 'true'? 'details-btn-done': 'details-btn-pending'}>{item.dlchallan.status === 'true'? 'Completed': 'Pending'}</Button></ListItemText>
       ))}
       </ListItem> */}
                                        <ListItem>
                                            <ListItemText> Order Status </ListItemText>
                                            <ListItemText> : </ListItemText>

                                            <ListItemText style={{ textAlign: "start" }}>
                                                <Button className="details-btn-pending1" onClick={toggleVisibility}>
                                                    View Status{" "}
                                                </Button>
                                            </ListItemText>
                                        </ListItem>
                                        {isVisible === true ? (
                                            <div>
                                                <h2
                                                    className=" mb-4 mt-2"
                                                    style={{ textAlign: "center", color: "#dc0000" }}
                                                >
                                                    Order Status
                                                </h2>

                                                {menulistapi.status==="Ordered" ?"Waiting for admin Approvel": menulistapi.services.map((items) => (
                                                          <StatusSteps orderdetails={items} />
                                                      ))
                                                     }
                                            </div>
                                        ) : null}
                                    </List>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </form>
        </div>
    );
}
