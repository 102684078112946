// import React, { useState, useEffect } from 'react';
// import { useLocation, useHistory } from 'react-router-dom';
// import { PaytmCheckout } from 'paytm-checkout';

// const Payment1 = () => {
//   const location = useLocation();
//   const history = useHistory();
  
//   const [orderId, setOrderId] = useState('');

//   useEffect(() => {
//     // Fetch order details from your backend
//     // and get the order ID

//     // For example:
//     const fetchOrderDetails = async () => {
//       try {
//         const response = await fetch('/api/getOrder');
//         const data = await response.json();
//         setOrderId(data.orderId);
//       } catch (error) {
//         console.error('Error fetching order details:', error);
//       }
//     };

//     fetchOrderDetails();
//   }, []);

//   const handlePayment = () => {
//     const paytmParams = {
//       orderId,
//       amount: '100.00', // Replace with your actual amount
//       customerId: 'CUSTOMER_ID', // Replace with your customer ID
//       callbackUrl: 'YOUR_CALLBACK_URL', // Replace with your callback URL
//       merchantId: 'YOUR_MERCHANT_ID', // Replace with your merchant ID
//       checksumGenerationUrl: '/api/generateChecksum', // Replace with your backend URL for checksum generation
//       checksumValidationUrl: '/api/validateChecksum', // Replace with your backend URL for checksum validation
//       channel: 'WEB',
//       industryType: 'Retail',
//       website: 'WEBSTAGING', // For staging, replace with your staging website
//       theme: 'DEFAULT',
//     };

//     PaytmCheckout(paytmParams)
//       .then((response) => {
//         console.log('Payment Response:', response);
//         // Redirect to success page or handle success
//       })
//       .catch((error) => {
//         console.error('Error processing payment:', error);
//         // Redirect to error page or handle error
//       });
//   };

//   return (
//     <div>
//       <h1>Payment Page</h1>
//       <button onClick={handlePayment}>Proceed to Pay</button>
//     </div>
//   );
// };

// export default Payment1;
import React from 'react'

function Payment1() {
  return (
    <div>Payment1</div>
  )
}

export default Payment1
