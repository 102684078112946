/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginIcon from "@mui/icons-material/Login";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import OTPInput, { ResendOTP } from "otp-input-react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { apiurl } from "../../../api/api_url";
import { addodcuserChange } from "../../../store/addodcuser";
import { useDispatch } from "react-redux";
import firebase from "./firebase";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Timer = ({ duration, onTimeout }) => {
    const [seconds, setSeconds] = useState(duration);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 0) {
                    clearInterval(interval);
                    onTimeout(); // Call the callback when the timer reaches 0
                    return prevSeconds;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [duration, onTimeout]);

    return <div className="mt-2"> OTP Timer : {seconds}s</div>;
};

function Login1(props) {
        const { guestuser  } = props;
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [vcode, setVcode] = useState("");
    const [errors, setErrors] = useState({});
    const [OTP, setOTP] = useState("");
    const [resendClicked, setResendClicked] = useState(false);
    const [isLightOn, setLightOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const toggleVisibility = (e) => {
        setIsVisible(!isVisible);
        // setIsVisible(false);
    };
    const toggleVisibility1 = (e) => {
        setIsVisible(!isVisible);
        // setIsVisible(false);
    };
console.log(guestuser);
    const odchandleSignin = (e) => {
        e.preventDefault();
        history.push("/odc/signin");
    };

    const handleTimeout = () => {
        setResendClicked(false);
        console.log("Timer reached 0, handle timeout logic here");
    };

    const handleResendClick = () => {
        setResendClicked(true);
        console.log("Resend clicked");
    };

    const validateForm = () => {
        let formIsValid = true;
        const errors = {};

        // Validate Email
        if (!email) {
            errors.email = "Email is required";
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Email is invalid";
            formIsValid = false;
        }

        // Validate Password
        // if (!password) {
        //   errors.password = "Password is required";
        //   formIsValid = false;
        // }
        // else if (password.length < 8) {
        //   errors.password = "Password must be at least 8 characters long";
        //   formIsValid = false;
        // }
        //  else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password)) {
        //   errors.password =
        //     "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
        //   formIsValid = false;
        // }

        setErrors(errors);
        return formIsValid;
    };

    const validateForm1 = () => {
        let formIsValid = true;
        const errors = {};

        // Validate Email
        if (!mobile) {
            errors.mobile = "Email or Mobile No is required";
            formIsValid = false;
        } 

        // Validate Password
        // if (!password) {
        //     errors.password = "Password is required";
        //     formIsValid = false;
        // } else if (password.length < 8) {
        //     errors.password = "Password must be at least 8 characters long";
        //     formIsValid = false;
        // } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password)) {
        //     errors.password =
        //         "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
        //     formIsValid = false;
        // }
        if (!password) {
            errors.password = "Password is required";
            formIsValid = false;
          } 

        setErrors(errors);
        return formIsValid;
    };

    const handleSubmitOTP = async (e) => {
        e.preventDefault();

        await fetch(`${apiurl}/auth/update-password`, {
            method: "POST",
            body: JSON.stringify({ email: email, password: newPassword, code: vcode, type: "customer" }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("tokenodc"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                // localStorage.setItem("tokenodc", json.data.token);
                // const odcuser = json.data.user;
                // dispatch(addodcuserChange(odcuser));
                if (json.status === true) {
                    setOpen(false);

                    setIsVisible(!isVisible);

                    setLoading(true);
                    toast.success("Password Changed !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setLoading(false);
                } else {
                    toast.error("Invalid Access code", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });

       
    };

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        // Perform the necessary actions with the OTP value
        toast.success("You Login Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        history.goBack();
        //   console.log("OTP submitted:", OTP);
    };


    const handleClickVerify = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            await fetch(`${apiurl}/auth/reset-password`, {
                method: "POST",
                body: JSON.stringify({ email: email, type: "customer" }),
                headers: {
                    "Content-type": "application/json",
                    // Authorization: localStorage.getItem("tokenodc"),
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    if (json.status === true) {
                        toast.success("Access code sent to your mail", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setOpen(true);
                    }else{
                        toast.error(json.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }); 
                    
                    }
                   
                });
        }
    };

    const handleClickOpen = async (e) => {
        e.preventDefault();
        // setOpen(true);
        if (validateForm1()) {
        await fetch(`${apiurl}/auth/odc-login`, {
            method: "POST",
            body: JSON.stringify({ userName: mobile, password: password }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("tokenodc"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                if(json.status===false){
                    // console.log("hello");
                    toast.error(json.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }); 
                
                }
                localStorage.setItem("tokenodc", json.data.token);
                const odcuser = json.data.user;
                dispatch(addodcuserChange(odcuser));
                if(!guestuser&&odcuser.ctype === "ODC"){
                    history.goBack();
                }
                else if (guestuser&&odcuser.ctype === "ODC") {
                    history.push("/odc/addcart");
                }
                
            }) 
        }
          
    };
    const handleClose = () => {
        toast.error("Oops not Verified", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        setOpen(false);
    };
    if (loading) {
        return (
            <div style={{ width: "100%", height: "100%", textAlign: "center", margin: "20% 0%" }}>
                {" "}
                <CircularProgress color="success" />
            </div>
        );
    }

    return (
        <div>
            <div
                className="sigin-page-style "
                style={{
                    backgroundImage:
                    'url("../images/signinbg.png")',
                    backgroundSize: "cover",
                }}
            >
                <div className="singin-center-stylelg1">
                    <form>
                        <div id="sign-in-button"></div>

                        {isVisible === true ? (
                            <div>
                                <div className="singin-tl-style">Verify Email</div>

                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        // value={password}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-control rg-txt-input-style mt-4"
                                        placeholder="Enter Verification Email ID"
                                        required
                                    />

                                    {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                                    {/* <IconButton
                                        className="password-icon-style1 "
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password1"
                                        // value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="form-control rg-txt-input-style mt-4"
                                        placeholder="Confirm Password"
                                        required
                                    /> */}
                                </div>

                                {/* <p className="rg-info-txt" style={{ color: "#000", fontWeight: "600" }}>
                                    Enter mail id to send a verification code for reset password.
                                    <Link to="#" onClick={odchandleSignin} style={{ marginLeft: "2%" }}>
                                        Pleace Register.
                                    </Link>{" "}
                                </p> */}
                                <p className="rg-info-txt" style={{ color: "#000", fontWeight: "600" }}>
                                    <Link to="#" onClick={toggleVisibility1} style={{ marginLeft: "2%" }}>
                                        Back
                                    </Link>{" "}
                                </p>
                                {/* <button className="singin-rgbtn" onClick={handleClickOpen}>
                               Login <LoginIcon style={{ fontSize: "20px" }} />
                           </button> */}
                                <button className="singin-rgbtn" onClick={handleClickVerify}>
                                    Verify <CheckCircleIcon style={{ fontSize: "20px" }} />
                                </button>
                            </div>
                        ) : (
                            <div>
                                <div className="singin-tl-style">Login</div>

                                <div className="form-group">
                                    <input
                                        name="mobile"
                                        type="text"
                                        onChange={(e) => setMobile(e.target.value)}
                                        className="form-control rg-txt-input-style"
                                        placeholder="Enter Mobile Number or Email"
                                        required
                                    />
                                    {errors.mobile && <p style={{ color: "red" }}>{errors.mobile}</p>}
                                    <IconButton
                                        className="password-icon-style1 "
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        // value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="form-control rg-txt-input-style mt-4"
                                        placeholder="Password"
                                        required
                                    />
                                     {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                </div>

                                <p className="rg-info-txt" style={{ color: "#000", fontWeight: "600" }}>
                                    If you don't have a account
                                    <Link to="#" onClick={odchandleSignin} style={{ marginLeft: "2%" }}>
                                        Please Register.
                                    </Link>{" "}
                                </p>
                                <p className="rg-info-txt" style={{ color: "#000", fontWeight: "600" }}>
                                    <Link to="#" onClick={toggleVisibility} style={{ marginLeft: "2%" }}>
                                        Forgot Password
                                    </Link>{" "}
                                </p>
                                <button className="singin-rgbtn" onClick={handleClickOpen}>
                                    Login <LoginIcon style={{ fontSize: "20px" }} />
                                </button>
                                {/* <button className="singin-rgbtn">
                                    Login <LoginIcon style={{ fontSize: "20px" }} />
                                </button> */}
                            </div>
                        )}

                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className="opt-resp"
                        >
                            <DialogTitle style={{ textAlign: "center" }}>{"Please Verify "}</DialogTitle>
                            <DialogContent>
                                <div style={{ textAlign: "center" }}>
                                    <div className="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-lg-12">
                                                <p style={{ alignItems: "center" }}>
                                                    Verification code sent to your registered E-mail ID.
                                                </p>

                                                <div className="form-group">
                                                    <input
                                                        name="email"
                                                        type="email"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        className="form-control rg-txt-input-style"
                                                        placeholder="Enter Your Mail ID"
                                                        required
                                                    />
                                                </div>

                                                <IconButton
                                                    className="password-icon-style3 "
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                                <input
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    // value={password}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    className="form-control rg-txt-input-style mt-4"
                                                    placeholder="Enter New Password"
                                                    required
                                                />
                                                <div className="form-group">
                                                    <input
                                                        name="vcode"
                                                        type="text"
                                                        onChange={(e) => setVcode(e.target.value)}
                                                        className="form-control rg-txt-input-style"
                                                        placeholder="Enter Your verification code"
                                                        required
                                                    />
                                                </div>
                                                {/* <OTPInput
                                                    value={OTP}
                                                    onChange={setOTP}
                                                    style={{ display: "flex", justifyContent: " center" }}
                                                    autoFocus
                                                    OTPLength={6}
                                                    otpType="number"
                                                    disabled={false}
                                                    secure={isLightOn === true ? false : true} // Disable secure prop to show entered value
                                                /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <button className="otp-btn" onClick={handleSubmitOTP}>
                                        Submit
                                    </button>
                                    {/* {resendClicked ? (
                                        <div>Resending Code...</div>
                                    ) : (
                                        <button className="otp-rebtn" onClick={handleResendClick}>
                                            Resend Code
                                        </button>
                                    )} */}
                                    {/* <Timer duration={60} onTimeout={handleTimeout} /> */}
                                </div>
                            </DialogContent>
                        </Dialog>
                    </form>
                </div>
            </div>
        </div>
    );
}

Login1.propTypes = {
   
    guestuser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
 
    guestuser: state.guestuser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Login1);

// export default Login1;
