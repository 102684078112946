import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function CancellationAndRefund() {
    return (
        <div>
            <Helmet>
                <title>{`Cancellation and Refund — ${theme.name}`}</title>
            </Helmet>
            <h4 className="faq-tit-txt" style={{ backgroundColor: "aliceblue", marginBottom: "2%" }}>
                <span style={{ color: "#c60800" }}>C</span>ancellation and Refund
            </h4>

            <div
                style={{
                    backgroundImage:
                        'url("https://png.pngtree.com/background/20220726/original/pngtree-indian-cuisine-sketch-pattern-background-picture-image_1814318.jpg")',
                }}
            >
                <div style={{ backgroundColor: "#ffffffed" }}>
                    <div className="container">
                        <div>
                            <div className="row">
                                <div className="col-lg-12 mt-4" style={{ marginBottom: "6%" }}>
                                    <div
                                        className="card "
                                        style={{
                                            filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)",
                                            padding: "4%",
                                            marginBottom: "4%",
                                        }}
                                    >
                                        <h4>1. Cancellation</h4>
                                        <p>
                                            As a general rule you shall not be entitled to cancel your order once you
                                            have received confirmation of the same. If you cancel your order, you must
                                            notify Hogist immediately preferably by phone and quote your order number.
                                            Hogist shall have a right to charge you cancellation fee from the total
                                            order value, with a right to either not to refund the order value or recover
                                            from your subsequent order, the complete/ deficit cancellation fee, as
                                            applicable, to compensate our vendors and delivery partners. Hogist shall
                                            also have right to charge you cancellation fee for the orders cancelled by
                                            Hogist for the reasons specified under clause of this cancellation and
                                            refunds policy. In case of cancellations for the reasons attributable to
                                            Hogist or its vendors and delivery partners, Hogist shall not charge you any
                                            cancellation fee.
                                        </p>

                                        <ul>
                                            <li>
                                                <b>i )</b>
                                                However, in the unlikely event of an item on your order being
                                                unavailable, we will contact you on the phone number provided to us at
                                                the time of placing the order and inform you of such unavailability. In
                                                such an event you will be entitled to cancel the entire order and shall
                                                be entitled to a refund in accordance with our refund policy
                                            </li>
                                            <li>
                                                <b>ii )</b>
                                                We reserve the sole right to cancel your order in the following
                                                circumstance:
                                                <ul>
                                                    <li>
                                                        <ArrowRightIcon className="footer-icon-style" />
                                                        in the event of the designated address falls outside the
                                                        delivery zone offered by us
                                                    </li>
                                                    <li>
                                                        <ArrowRightIcon className="footer-icon-style" />
                                                        failure to contact you by phone or email at the time of
                                                        confirming the order booking;
                                                    </li>
                                                    <li>
                                                        <ArrowRightIcon className="footer-icon-style" />
                                                        failure to deliver your order due to lack of information,
                                                        direction or authorization from you at the time of delivery; or
                                                    </li>
                                                    <li>
                                                        <ArrowRightIcon className="footer-icon-style" />
                                                        unavailability of all the items ordered by you at the time of
                                                        booking the order;
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <b>iii )</b>
                                                Cancellation Fees
                                                <p>
                                                    This policy is valid for orders through online, email and phone
                                                    catering orders.
                                                </p>
                                            </li>
                                        </ul>
                                        <p>
                                            <b>Cancellation fees for day to day orders</b>
                                        </p>

                                        <div className="cancel-fee-table mb-4">
                                            <table>
                                                <tr>
                                                    <th>Medium of Service</th>
                                                    <th>Order Quantity</th>
                                                    <th>Cancellation Fees</th>
                                                </tr>
                                                <tr>
                                                    <td>Caterers</td>
                                                    <td>Min 25 - Max (Defined by Customer Needs)</td>
                                                    <td>5% from the total Order Value</td>
                                                </tr>
                                                <tr>
                                                    <td>Home Chef</td>
                                                    <td>Min 5 - Max (Defined by Customer Needs)</td>
                                                    <td>Rs. 100</td>
                                                </tr>
                                                <tr>
                                                    <td>Restaurant</td>
                                                    <td>Min 5 - Max (Defined by Customer Needs)</td>
                                                    <td>Rs. 100</td>
                                                </tr>
                                                <tr>
                                                    <td>Chef</td>
                                                    <td>Min 25 - Max (Defined by Customer Needs)</td>
                                                    <td>5% from the total Order Value</td>
                                                </tr>
                                            </table>
                                        </div>

                                        <p>
                                            <b>Cancellation fee for catering services for an event</b>
                                        </p>

                                        <div className="cancel-fee-table mb-4">
                                            <table>
                                                <tr>
                                                    <th>Timeline</th>
                                                    <th>Cancellation charges</th>
                                                </tr>
                                                <tr>
                                                    <td>Cancellation Within 7 days</td>
                                                    <td>25% from the total order value</td>
                                                </tr>
                                                <tr>
                                                    <td>Cancellation within 3 days</td>
                                                    <td>50% of the total order value</td>
                                                </tr>
                                                <tr>
                                                    <td>Cancellation within 1 days</td>
                                                    <td>100% of the total order value</td>
                                                </tr>
                                            </table>
                                        </div>

                                        <p>
                                            <b>Specialty food items are non-refundable.</b>
                                        </p>
                                        <p>
                                            ** Note refunds are the sole discretion of Hogist. Although an event may be
                                            cancelled or rescheduled, Hogist reserves the right to allow for consulting
                                            and coordinating when or if determining to allow for a cancellation or
                                            refund of any amount or for any reason.
                                        </p>

                                        <h4>2. Refunds</h4>
                                        <ul>
                                            <li>
                                                {" "}
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You shall be entitled to a refund only if you pre-pay for your order at
                                                the time of placing your order on the Platform and only in the event of
                                                any of the following circumstances:
                                            </li>
                                            <li>
                                                {" "}
                                                <ArrowRightIcon className="footer-icon-style" />
                                                your order packaging has been tampered or damaged at the time of
                                                delivery;
                                            </li>
                                            <li>
                                                {" "}
                                                <ArrowRightIcon className="footer-icon-style" />
                                                us cancelling your order due to (A) your delivery location following
                                                outside our designated delivery zones; (B) failure to contact you by
                                                phone or email at the time of confirming the order booking; or (C)
                                                failure to contact you by phone or email at the time of confirming the
                                                order booking; or
                                            </li>
                                            <li>
                                                {" "}
                                                <ArrowRightIcon className="footer-icon-style" />
                                                you cancelling the order at the time of confirmation due to
                                                unavailability of the items you ordered for at the time of booking.
                                            </li>
                                            <li>
                                                {" "}
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Our decision on refunds shall be at our sole discretion and shall be
                                                final and binding.
                                            </li>
                                            <li>
                                                {" "}
                                                <ArrowRightIcon className="footer-icon-style" />
                                                All refund amounts shall be credited to your account within 5-7 business
                                                days in accordance with the terms that may be stipulated by the bank
                                                which has issued the credit / debit card.
                                            </li>
                                        </ul>

                                        <div className="cancel-fee-table mb-4">
                                            <table>
                                                <tr>
                                                    <th>Process</th>
                                                    <th>Payment Method</th>
                                                    <th>Refund Source</th>
                                                    <th>TAT</th>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td>Net Banking</td>
                                                    <td>Source</td>
                                                    <td>5-7 Business Days</td>
                                                </tr>
                                                <tr>
                                                    <td>Order Failure</td>
                                                    <td>Debit/Credit Cards</td>
                                                    <td>Source</td>
                                                    <td>5-7 Business Days</td>
                                                </tr>
                                                <tr>
                                                    <td>Edit/ Cancellation/ IGCC/ Payment</td>
                                                    <td>Debit/Credit Cards</td>
                                                    <td>Source</td>
                                                    <td>5-7 Business Days</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td> UPI</td>
                                                    <td>Source</td>
                                                    <td>5-7 Business Days</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <h4>
                                            3. In case of payment at the time of delivery, you will not be required to
                                            pay for:
                                        </h4>
                                        <ul>
                                            <li>
                                                <b>i )</b>
                                                orders where the packaging has been tampered or damaged by us;
                                            </li>
                                            <li>
                                                <b>ii )</b>
                                                wrong order being delivered; or
                                            </li>
                                            <li>
                                                <b>iii )</b>
                                                Items missing from your order at the time of delivery.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CancellationAndRefund;
