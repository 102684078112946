/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";

import "./ODC.scss";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CelebrationIcon from "@mui/icons-material/Celebration";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Slider from "react-slick";
//
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useHistory } from "react-router-dom";
import SidebarNav from "./../../header/SidebarNav";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import PhotoAlbum from "react-photo-album";
//
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import TimePicker from "react-time-picker";
// import TimePicker from "react-timepicker-c";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import debounce from "lodash/debounce";
//
import { enGB } from "date-fns/locale";
import { DatePicker } from "react-nice-dates";
import "react-nice-dates/build/style.css";
//
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
//
import { formattedDate, formatTime } from "../../../helper/dateformate";
import { apiurl } from "../../../api/api_url";
import { guestuserChange } from "../../../store/guestuser";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import Servicetab from "./Servicetab";

function Location1() {
    const [open, setOpen] = React.useState(false);
    const [location, setLocation] = useState("");
    const [eventCount, setEventCount] = useState(25);
    const [mobileNo, setMobileNo] = useState();
    const [eventName, setEventName] = useState("");
    const [restaurants, setRestaurants] = useState([]);
    const [currentLocation, setCurrentLocation] = useState("");
    const history = useHistory();
    const [value, setValue] = React.useState("1");
    const [currentStep, setCurrentStep] = useState(0);
    const [autoCompleteTime, setAutoCompleteTime] = useState(10);
    const [selectedDate, setSelectedDate] = useState(null);
    // const [selectedTime, setSelectedTime] = useState(null);
    // const [selectedTime, setSelectedTime] = useState(null);
    // const [timeValue, setTimeValue] = useState("");
    const [errors, setErrors] = useState({});
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const autocompleteRef = useRef(null);
    //
    const [selectedDay, setSelectedDay] = useState(null);
    //
    const [date, setDate] = useState();
    const [timeValue, setTimeValue] = useState("");
    const [selectedTime, setSelectedTime] = useState(null);
    const [guestData, setGuestData] = useState(null);
    const dispatch = useDispatch();
    const [locationData, setLocationData] = useState({
        inputAddress: "",
        coordinates: {
            fromAddress: null,
            currentLocation: null,
        },
        addresses: {
            fromCoordinates: null,
            currentLocation: null,
        },
    });
    const [culatitude, setCulatitude] = useState(null);
    const [culongitude, setCulongitude] = useState(null);
    const [address, setAddress] = useState("");
    //

    const handleDateChange = (newDate) => {
        if (newDate) {
            setDate(newDate);
        }
        console.log(date);
    };

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
        console.log(newTime);
    };

    // useEffect(() => {
    //     // Initialize the Google Places Autocomplete service
    //     const autocomplete = new window.google.maps.places.AutocompleteService();
    //     autocompleteRef.current = autocomplete;
    // }, []);


    

    const handleAddressChange = (e) => {
        const newAddress = e.target.value;
        setLocationData((prevLocationData) => ({
            ...prevLocationData,
            inputAddress: newAddress,
        }));
        setAddress(newAddress);
        // Convert the input address to coordinates
        // convertAddressToCoordinates(newAddress, "fromAddress");
    };
    // const convertAddressToCoordinates = async (address, type) => {
    //     try {
    //         // Replace 'YOUR_API_KEY' with your actual Google Maps API key
    //         const apiKey = "Your API KEY";
    //         const response = await fetch(
    //             `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`
    //         );
    //         const data = await response.json();

    //         if (data.results.length > 0) {
    //             const { lat, lng } = data.results[0].geometry.location;

    //             setLocationData((prevLocationData) => ({
    //                 ...prevLocationData,
    //                 coordinates: {
    //                     ...prevLocationData.coordinates,
    //                     [type]: { latitude: lat, longitude: lng },
    //                 },
    //                 addresses: {
    //                     ...prevLocationData.addresses,
    //                     [type === "fromAddress" ? "fromCoordinates" : "currentLocation"]: address,
    //                 },
    //             }));
    //             setCulatitude(lat);
    //             setCulongitude(lng);
    //         } else {
    //             setLocationData((prevLocationData) => ({
    //                 ...prevLocationData,
    //                 coordinates: {
    //                     ...prevLocationData.coordinates,
    //                     [type]: null,
    //                 },
    //                 addresses: {
    //                     ...prevLocationData.addresses,
    //                     [type === "fromAddress" ? "fromCoordinates" : "currentLocation"]: null,
    //                 },
    //             }));
    //         }
    //     } catch (error) {
    //         console.error("Error converting address to coordinates:", error);
    //     }
    // };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;

                    // Get address using reverse geocoding
                    try {
                        const apiKey = "AIzaSyBSHDxKrKJXokOeEKYaLdklrDqO2GsNVRI"; // Replace with your Google Maps API key
                        const response = await fetch(
                            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
                        );
                        const data = await response.json();

                        if (data.results.length > 0) {
                            const address = data.results[0].formatted_address;
                            setLocationData((prevLocationData) => ({
                                ...prevLocationData,
                                coordinates: {
                                    ...prevLocationData.coordinates,
                                    currentLocation: { latitude, longitude },
                                },
                                addresses: {
                                    ...prevLocationData.addresses,
                                    currentLocation: address,
                                },
                                inputAddress: address, // Update input address with current location address
                            }));
                            setCulatitude(latitude);
                            setCulongitude(longitude);
                        } else {
                            setLocationData((prevLocationData) => ({
                                ...prevLocationData,
                                coordinates: {
                                    ...prevLocationData.coordinates,
                                    currentLocation: null,
                                },
                                addresses: {
                                    ...prevLocationData.addresses,
                                    currentLocation: null,
                                },
                            }));
                        }
                    } catch (error) {
                        console.error("Error getting address:", error);
                        setLocationData((prevLocationData) => ({
                            ...prevLocationData,
                            coordinates: {
                                ...prevLocationData.coordinates,
                                currentLocation: null,
                            },
                            addresses: {
                                ...prevLocationData.addresses,
                                currentLocation: null,
                            },
                        }));
                    }
                },
                (error) => {
                    console.error("Error getting location:", error.message);
                    setLocationData((prevLocationData) => ({
                        ...prevLocationData,
                        coordinates: {
                            ...prevLocationData.coordinates,
                            currentLocation: null,
                        },
                        addresses: {
                            ...prevLocationData.addresses,
                            currentLocation: null,
                        },
                    }));
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    const handleChange1 = (event, newValue) => {
        setValue(newValue);
    };

    //

    const steps = [
        {
            image: "images/order3.gif",
            content: <p>Choose Your Order</p>,
        },
        {
            image: "images/pay.gif",
            content: <p>Pay</p>,
        },
        {
            image: "images/delivery.gif",
            content: <p>Get Delivered</p>,
        },
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            if (autoCompleteTime > 0) {
                setAutoCompleteTime(autoCompleteTime - 1);
            } else {
                clearInterval(timer);
                handleNextStep();
            }
        }, 10000000000000);
        return () => clearInterval(timer);
    }, [autoCompleteTime, handleNextStep]);

    // eslint-disable-next-line
    function handleNextStep() {
        const isLastStep = currentStep === steps.length - 1;
        if (isLastStep) {
            setCurrentStep(0);
        } else {
            setCurrentStep(currentStep + 1);
        }
        setAutoCompleteTime(10);
    }

    const year = date && date.getFullYear();
    const month = date && date.getMonth() + 1; // Month is zero-based, so add 1
    const day = date && date.getDate();

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = selectedTime ? selectedTime.format("h:mm A") : "No time selected";

    const isMobileNumberValid = (number) => {
        // Use a regular expression to validate the mobile number format
        const mobileNumberPattern = /^[0-9]{10}$/; // Assumes a 10-digit format

        return mobileNumberPattern.test(number);
    };



    const gvalidateForm1 = () => {
        let formIsValid = true;
        const errors = {};
    

        // if (!fullName) {
        //     errors.fullName = "Enter your Name";
        //     formIsValid = false;
        //   } 
        //   if (!locationData.inputAddress) {
        //     errors.locationData.inputAddress = "Enter your Name";
        //     formIsValid = false;
        //   } 
        // Validate Email
        if (!date) {
          errors.date = "Email is required";
          formIsValid = false;
        }

        if (!mobileNo) {
          errors.mobileNo = "Enter your Mobile Number";
          formIsValid = false;
        } else if (!/^\d{10}$/.test(mobileNo)) {
          errors.mobileNo = "Mobile Number should be 10 digits";
          formIsValid = false;
        }
    
        if (!selectedTime) {
            errors.selectedTime = "Enter your Mobile Number";
            formIsValid = false;
          } 
          if (!eventCount) {
            errors.eventCount = "Enter your Mobile Number";
            formIsValid = false;
          } 
        
        // // Validate Password
        // if (!password) {
        //   errors.password = "Password is required";
        //   formIsValid = false;
        // } 
       
      
    
        setErrors(errors);
        return formIsValid;
      };







    const handleLocationsubmit = async () => {
        // if (!locationData.inputAddress || !date || !selectedTime || !eventCount || !isMobileNumberValid(mobileNo)) {
        //     alert("Please fill in all required fields.");
        //     return;
        // }

        // Convert the input address to coordinates
        // await convertAddressToCoordinates(locationData.inputAddress, "fromAddress");

        // Ensure that the coordinates are available
        // if (!culatitude || !culongitude) {
        //     alert('Unable to get coordinates for the provided address.');
        //     return;
        // }

        // const formattedDate = date.toISOString().split('T')[0];
        // const formattedTime = selectedTime ? selectedTime.format("HH:mm:ss") : "00:00:00";

        try {
            // Make the API call to submit the data
if(gvalidateForm1()){

            const response = await fetch(`${apiurl}/odc-guest`, {
                method: "POST",
                body: JSON.stringify({
                    mobile: mobileNo,
                    date: formattedDate,
                    time: formattedTime,
                    count: eventCount,
                    address: locationData.inputAddress,
                }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: localStorage.getItem("tokenodc"),
                },
            });

            if (response.ok) {
                const json = await response.json();
                const guestData = json.data;
                dispatch(guestuserChange(guestData));
                console.log(json);
                history.push("/odc/home");
            } else {
                alert("Error submitting the data. Please try again.");
            }
        }
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("An error occurred. Please try again.");
        }
    };

    // const convertAddressToCoordinates = async (address, type) => {
    //     try {
    //         const apiKey = "Your API KEY";
    //         const response = await fetch(
    //             `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`
    //         );
    //         const data = await response.json();

    //         if (data.results.length > 0) {
    //             const { lat, lng } = data.results[0].geometry.location;

    //             setLocationData((prevLocationData) => ({
    //                 ...prevLocationData,
    //                 coordinates: {
    //                     ...prevLocationData.coordinates,
    //                     [type]: { latitude: lat, longitude: lng },
    //                 },
    //                 addresses: {
    //                     ...prevLocationData.addresses,
    //                     [type === "fromAddress" ? "fromCoordinates" : "currentLocation"]: address,
    //                 },
    //             }));
    //             setCulatitude(lat);
    //             setCulongitude(lng);
    //         } else {
    //             setLocationData((prevLocationData) => ({
    //                 ...prevLocationData,
    //                 coordinates: {
    //                     ...prevLocationData.coordinates,
    //                     [type]: null,
    //                 },
    //                 addresses: {
    //                     ...prevLocationData.addresses,
    //                     [type === "fromAddress" ? "fromCoordinates" : "currentLocation"]: null,
    //                 },
    //             }));
    //         }
    //     } catch (error) {
    //         console.error("Error converting address to coordinates:", error);
    //         setLocationData((prevLocationData) => ({
    //             ...prevLocationData,
    //             coordinates: {
    //                 ...prevLocationData.coordinates,
    //                 [type]: null,
    //             },
    //             addresses: {
    //                 ...prevLocationData.addresses,
    //                 [type === "fromAddress" ? "fromCoordinates" : "currentLocation"]: null,
    //             },
    //         }));
    //     }
    // };

    // const handleLocationsubmit = async () => {

    //     // if (!locationData.inputAddress || !date || !selectedTime || !eventCount || !isMobileNumberValid(mobileNo)) {
    //     //     alert('Please fill in all required fields.');
    //     //     return;
    //     //   }
    //     //   else{
    //         await fetch(`${apiurl}/odc-guest`, {
    //             method: "POST",
    //             body: JSON.stringify({
    //                 mobile: mobileNo,
    //                 date: formattedDate,
    //                 time: formattedTime,
    //                 count: eventCount,
    //                 address: locationData.inputAddress,
    //             }),
    //             headers: {
    //                 "Content-type": "application/json",
    //                 Authorization: localStorage.getItem("tokenodc"),
    //             },
    //         })
    //             .then((response) => response.json())
    //             .then((json) => {
    //                 const guestData = json.data;
    //                 console.log(json);
    //                 dispatch(guestuserChange(guestData));

    //                 history.push("/odc/home");
    //             });

    //     //   }

    // };
    const currentStepImage = steps[currentStep]?.image || "";
    const currentStepContent = steps[currentStep]?.content || "";

    const odchandleLogin = (e) => {
        e.preventDefault();

        history.push("/odc/login");
    };
    const odchandleSignin = (e) => {
        e.preventDefault();

        history.push("/odc/signin");
    };

    const photos = [
        {
            src: "images/img/g1.png",
            width: 800,
            height: 600,
        },
        {
            src: "images/img/g2.png",
            width: 1600,
            height: 900,
        },
        {
            src: "images/img/g3.png",
            width: 800,
            height: 600,
        },
        {
            src: "images/img/g4.png",
            width: 1600,
            height: 900,
        },
        {
            src: "images/img/g5.png",
            width: 800,
            height: 600,
        },
        {
            src: "images/img/g6.png",
            width: 1600,
            height: 900,
        },
        { src: "images/img/g7.png", width: 800, height: 600 },
        {
            src: "images/img/g8.png",
            width: 1600,
            height: 900,
        },
        {
            src: "images/img/g9.png",
            width: 800,
            height: 600,
        },
        {
            src: "images/img/g10.png",
            width: 1600,
            height: 900,
        },
    ];

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        duration: 30,
        autoplay: true,
    };

    var settings1 = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        duration: 30,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    // width: 1408,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };

    // console.log(culatitude,culongitude);

    return (
        <div className="unsite">
            <Helmet>
                <title>{`${theme.name} - Outdoor Catering Services`}</title>
            </Helmet>
            <div
                style={{
                    backgroundImage:
                        'url("../images/odc-formbg.png")',
                }}
            >
                <div style={{ backgroundColor: "rgb(255 255 255 / 99%)" }}>
                    <div className="container-fluid">
                        {/* test */}
                        {/* <div className="row">
                            <div
                                className=" des-flex "
                                style={{
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    boxShadow: " 3px -1px 7px 1px rgba(0, 0, 0, 0.38)",
                                    position: "fixed",
                                    zIndex: " 1",
                                }}
                            >
                                <div className="col-lg-4 col-md-6">
                                    <Link to="/">
                                        <img src="images/logo-red.png" alt="" className="odc-head-logo" />
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-1"></div>
                                <div className="col-lg-5 col-md-5">
                                    <div
                                        style={{
                                            justifyContent: "end",
                                            alignItems: "center",
                                            display: "flex",
                                            textAlign: "center",
                                        }}
                                    >
                                        <div className="col-lg-2">
                                            <button className="mainpage-login-btn" onClick={odchandleLogin}>
                                                Login
                                            </button>
                                        </div>
                                        <div className="col-lg-3">
                                            <button className="mainpage-signup-btn" onClick={odchandleSignin}>
                                                Sign Up
                                            </button>
                                        </div>
                                        <div className="col-lg-2">
                                            <SidebarNav />
                                        </div>
                                        <div className="col-lg-5">
                                            <Link to="/become-a-vendor">
                                                <button className="vjoinbtn">Partner with Us</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* test */}

                        <div className="row">
                            <div className="col-lg-7 mt-4" style={{ textAlign: "center" }}>
                                <div className="container odc-home-top-padding-style pt-5">
                                    {/* <div className="row  des-flex " style={{ alignItems: "center" }}>
                                        <div className="col-lg-4">
                                            <Link to="/">
                                                <img src="images/logo-red.png" alt="" style={{ width: "85%" }} />
                                            </Link>
                                        </div>
                                        <div className="col-lg-8">
                                            <div
                                                className="row"
                                                style={{ justifyContent: "end", alignItems: "center" }}
                                            >
                                                <div className="col-lg-2">
                                                    <button className="mainpage-login-btn" onClick={odchandleLogin}>
                                                        Login
                                                    </button>
                                                </div>
                                                <div className="col-lg-3">
                                                    <button className="mainpage-signup-btn" onClick={odchandleSignin}>
                                                        Sing Up
                                                    </button>
                                                </div>
                                                <div className="col-lg-2">
                                                    <SidebarNav />
                                                </div>
                                                <div className="col-lg-5">
                                                    <Link to="/become-a-vendor">
                                                        <button className="vjoinbtn">Partner with Us</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <h1
                                        style={{
                                            textAlign: "start",
                                            marginTop: "10%",
                                            fontSize: "52px",
                                            fontWeight: "bolder",
                                        }}
                                    >
                                        <b>
                                            A one-stop place for all your
                                            <span style={{ color: "#c60800" }}> Food and Party Orders.</span>
                                        </b>
                                    </h1>
                                    <p style={{ textAlign: "start" }}>
                                        Hogist brings restaurants and catering providers to you. It offers Delivery and
                                        Order Ahead services from hundreds of restaurants nearby with a seamless
                                        experience.
                                        <span style={{ color: "#c60800" }}>
                                            {" "}
                                            Order a day before the event. Place any order starting from 25pax.
                                        </span>
                                    </p>
                                    <div className="container mt-4" style={{ padding: "0% 9%" }}>
                                        <form>
                                            <div>
                                                <div className="address-input-container">
                                                    <input
                                                        type="text"
                                                        value={locationData.inputAddress}
                                                        className="odc-adress-lg-input-style1"
                                                        onChange={handleAddressChange}
                                                        placeholder="Delivery location"
                                                        required
                                                    />
                                                    <LocationOnSharpIcon
                                                        className="address-icon"
                                                        type="button"
                                                        onClick={handleGetCurrentLocation}
                                                    />
                                                    {/* {errors.locationData.inputAddress && <p style={{ color: "red" }}>{errors.locationData.inputAddress}</p>} */}
                                                </div>
                                            </div>

                                            <div>
                                                <DatePicker
                                                    date={date}
                                                    onDateChange={(newDate) => handleDateChange(newDate)}
                                                    locale={enGB}
                                                    minimumDate={new Date()}
                                                >
                                                    {({ inputProps, focused }) => (
                                                        <input
                                                            className="odc-adress-lg-input-style1 mt-4 number-font"
                                                            {...inputProps}
                                                            placeholder="Date of event"
                                                            required
                                                        />
                                                    )}
                                                </DatePicker>
                                                {errors.date && <p style={{ color: "red" }}>{errors.date}</p>}
                                            </div>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <div>
                                                        <MobileTimePicker
                                                            value={selectedTime}
                                                            onChange={handleTimeChange}
                                                            // inputprops={{ placeholder: "Select Your Time" }}
                                                            renderInput={(props) => (
                                                                <input
                                                                    {...props}
                                                                    value={
                                                                        selectedTime
                                                                            ? selectedTime.format("h:mm A")
                                                                            : null
                                                                    }
                                                                    // onChange={handleTimeChange}
                                                                    placeholder="Delivery Time"
                                                                    required
                                                                    className={`odc-adress-lg-input-style1 mt-4 number-font ${
                                                                        selectedTime ? "" : "error"
                                                                    }`}
                                                                    error={selectedTime ? undefined : "true"} // Change to "true" or undefined
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </LocalizationProvider>
                                                {errors.selectedTime && <p style={{ color: "red" }}>{errors.selectedTime}</p>}
                                            </div>

                                            <div>
                                                <input
                                                    className="odc-adress-lg-input-style1 mt-4 number-font"
                                                    placeholder="Pax. Minimum 25"
                                                    min={25}
                                                    value={eventCount}
                                                    onChange={(e) => setEventCount(e.target.value)}
                                                    type="number"
                                                    required
                                                />
                                                {errors.eventCount && <p style={{ color: "red" }}>{errors.eventCount}</p>}
                                            </div>
                                            <div>
                                                <input
                                                    className="odc-adress-lg-input-style1 mt-4 number-font"
                                                    placeholder="Contact number"
                                                    value={mobileNo}
                                                    onChange={(e) => setMobileNo(e.target.value)}
                                                    type="text"
                                                    required
                                                />
                                                 {errors.mobileNo && <p style={{ color: "red" }}>{errors.mobileNo}</p>}
                                            </div>

                                            <br />

                                            <Button
                                                variant="contained"
                                                className="location-sub-btn"
                                                endIcon={<SendIcon />}
                                                onClick={handleLocationsubmit}
                                            >
                                                PROCEED TO MENU
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* {address:address,eventdate:date,eventtime:selectedTime,counte:eventCount,contactno:mobileNo} */}
                            <div className="col-lg-5">
                                <div className="odc-header-img-width">
                                    {" "}
                                    <img
                                        // src="https://img.freepik.com/free-photo/top-view-pakistan-meal-composition_23-2148821528.jpg"
                                        src="images/odc-main-head.jpg"
                                        alt="ODC Home"
                                        // style={{ width: "100%", height: "100%" }}
                                        className="odc-header-meimg-width"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Main Home Header End*/}

                    {/* Service Tab End */}

                    <div
                        style={{
                            backgroundImage: "url('images/line.png')",
                            backgroundSize: "cover",
                            // backgroundColor: "#ffe4e2",
                            textAlign: "center",
                        }}
                    >
                        <div>
                            <div className="container pb-4" style={{ paddingTop: "4%" }}>
                                <div className="row ">
                                    <div className="col-lg-4">
                                        <div className="container123">
                                            <img src="images/order3.png" alt="Avatar" className="steps-image-style" />
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "3% 0%",
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#000",
                                                }}
                                            >
                                                Choose your Order
                                            </div>
                                            <p className="text1">
                                                Browse through our selection by cuisine, price or rating.​ View pictures
                                                of delicious dishes and select your choices by adding to your cart.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="container123 odc-mb-aline">
                                            <img src="images/payment.png" alt="Avatar" className="steps-image-style" />
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "3% 0%",
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#000",
                                                }}
                                            >
                                                Make Payment
                                            </div>
                                            <p className="text1">
                                                Choose the payment you are most comfortable with - Cash, Card or UPI
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="container123 ">
                                            <img src="images/delivery.png" alt="Avatar" className="steps-image-style" />
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    padding: "3% 0%",
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    color: "#000",
                                                }}
                                            >
                                                Get Delivered
                                            </div>
                                            <p className="text1">
                                                We collect your order, once the food is ready and deliver it to your
                                                location. Order on the go, skip the line and save time on takeaway
                                                orders. Yes, it's that SIMPLE! Try it now!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Client list start */}
                    <div className="container pt-4 pb-4" >
                        <div className="mt-4 " style={{textAlign:"center"}}>
                            <h1>Our Trusted Clients</h1>
                            <p>Trusted by the world's best companies. Join the ranks of the elite.</p>
                        </div>
                        {/* <div style={{display:"flex",justifyContent:"center"}}> */}
                        <Slider  {...settings1} className="odcclient-style">
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client1.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client2.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client3.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client4.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client5.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client6.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client7.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client8.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client9.jpg" alt="Slide 1" />
                            </div>
                            <div className="m3-clinte-img-style">
                                <img src="images/client/i-client10.jpg" alt="Slide 1" />
                            </div>
                        </Slider>
                        {/* </div> */}
                    </div>

                    {/* Client list End */}

                    {/* Service Steps End */}

                    <div className="container-fluid mt-4 odc-service-tab-height">
                        <div className="mt-4 ">
                            <h1>What We Have?</h1>
                            <p>When it comes to food, you do your thing and leave the rest to Hogist!</p>
                        </div>
                        <div className="mt-4 ">
                            <Box sx={{ width: "100%", typography: "body1" }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList onChange={handleChange1} className="odc-service-tab-style1">
                                            <Tab label="Restaurant" value="1" className="service-tab123" />
                                            <Tab label="Caterer" value="2" className="service-tab123" />
                                            <Tab label="Home Cook" value="3" className="service-tab123" />
                                            <Tab label="Chef" value="4" className="service-tab123" />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <img
                                                    src="images/resturent.png"
                                                    alt="Restaurant"
                                                    style={{ width: "55%" }}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <p className="tab-txt-changes">
                                                    We have the best and clean Restaurants for your choice . You can
                                                    order Favorite dishes from your favourite resturents for your events
                                                    and Parties with best price from us.
                                                </p>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <img src="images/caterer.png" alt="Caterer" style={{ width: "55%" }} />
                                            </div>
                                            <div className="col-lg-6">
                                                <p className="tab-txt-changes">
                                                    We have the best and clean Caterer's for your choice . You can order
                                                    Favorite dishes from your favourite Caterers for your events and
                                                    parts with best price from us.
                                                </p>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <img
                                                    src="images/homecook.png"
                                                    alt="Home Cook"
                                                    style={{ width: "55%" }}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <p className="tab-txt-changes">
                                                    We have the best and clean Home Cook's for your choice . You can
                                                    order Favorite dishes with best home style tast for your events and
                                                    parts with best price from us.
                                                </p>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="4">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <img src="images/chef.png" alt="Chef" style={{ width: "55%" }} />
                                            </div>
                                            <div className="col-lg-6 ">
                                                <p className="tab-txt-changes">
                                                    We have the best and clean Chef's for your choice . You can order
                                                    Favorite dishes for your events and parts with best price from us.
                                                </p>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>

                        {/* <Servicetab/> */}
                    </div>

                    {/* ISo banner start */}
                    <div className="mt-4 mb-4">
                        <div
                            style={{
                                background: "linear-gradient(163deg, rgb(198, 8, 0) 43.26%, rgb(0 0 0) 154.38%)",
                                height: "100%",
                            }}
                        >
                            <div className="container" style={{ paddingTop: "3% ", paddingBottom: "3%" }}>
                                <div className="row">
                                    <div className="col-lg-7 iso-txt">
                                        <h4
                                            style={{
                                                fontSize: "20px",
                                                lineHeight: "40px",
                                                textAlign: "start",
                                                padding: "0% 0%",
                                            }}
                                        >
                                           Acquiring two ISO standards in the food industry demonstrates our commitment to ensuring the highest quality and safety standards in our food production processes.
                                        </h4>
                                    </div>
                                    <div className="col-lg-5" style={{textAlign:"center"}}>
                                        <img
                                            src="images/iso9.png"
                                            alt="iso9001"
                                            style={{ width: "35%", padding: "0% 6%" }}
                                        />
                                        <img
                                            src="images/iso22.png"
                                            alt="iso22000"
                                            style={{ width: "37%", padding: "0% 6%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ISo banner end */}

                    {/* Gallery start */}
                    <div>
                        <div

                        // style={{
                        //     backgroundImage: 'url("images/mainhome-ab-bg.png")',
                        //     // backgroundAttachment: "fixed",
                        // }}
                        >
                            <h1 className="about__service_tit" style={{ color: "#000" }}>
                                Our <span style={{ color: " #c60800" }}> Gallery</span>
                            </h1>
                            <div className="container-fluide gallery-img">
                                <PhotoAlbum layout="rows" photos={photos} />
                            </div>
                        </div>
                    </div>

                    {/* Gallery End */}

                    {/* App Download */}
                    <div
                        style={{
                            backgroundImage:
                                'url("../images/odc-appbg.png")',
                            backgroundSize: "cover",
                            height: "510px",
                            width: "100%",
                            backgroundAttachment: "fixed",
                        }}
                    >
                        <div
                            className="row"
                            style={{
                                textAlign: "center",

                                backgroundColor: "rgb(0 0 0 / 74%)",
                                height: "100%",
                                width: "100%",

                                color: "#fff",
                            }}
                        >
                            <div className="col-lg-6">
                                <img src="images/mobile-app1.png" alt="" className="rotate " style={{ width: "72%" }} />
                            </div>
                            <div
                                className="col-lg-6"
                                style={{ textAlign: "center", paddingTop: "14%", height: "100%" }}
                            >
                                <h2>Download our app to Get Exclusive Discounts !</h2>
                                <div style={{ marginTop: "2%" }}>
                                    {/* <img src="images/apple-store.png" alt="" style={{ width: "25%" }} /> */}
                                    <a
                                        className="topbar-link"
                                        href="https://play.google.com/store/apps/details?id=com.hogist"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {" "}
                                        <img src="images/play-store.png" alt="" style={{ width: "25%" }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginBottom: "4%" }}>
                        <div className="container-fluid odc-clint-say-style">
                            <div className="row">
                                <div className="col-lg-6 itemcenter">
                                    <div>
                                        <p className="m3-spacer-small m3-font-600">Testimonial</p>
                                        <h1>Read our customers' stories</h1>
                                        <p className="mt-3">
                                            Best Catering Services in Chennai & Coimbatore. Hogist - Delivering
                                            Flexibility & Consistency along with tasty food.
                                        </p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <h2 className="number-font" style={{ color: "#c60800" }}>
                                                    <CountUp end={10000} duration={40} enableScrollSpy="true" /> +
                                                </h2>
                                                <p>
                                                    <b>Food served Everyday</b>
                                                </p>
                                            </div>
                                            <div className="col-lg-6">
                                                <h2 className="number-font" style={{ color: "#c60800" }}>
                                                    <CountUp end={50} duration={40} enableScrollSpy="true" /> +
                                                </h2>
                                                <p>
                                                    <b>Corporates & Industrials clients</b>
                                                </p>
                                            </div>
                                            <div className="col-lg-6">
                                                <h2 className="number-font" style={{ color: "#c60800" }}>
                                                    <CountUp end={2} />{" "}
                                                </h2>
                                                <p>
                                                    <b>Times awarded the best food partner</b>
                                                </p>
                                            </div>
                                            <div className="col-lg-6">
                                                <h2 className="number-font" style={{ color: "#c60800" }}>
                                                    <CountUp end={2} />{" "}
                                                </h2>
                                                <p>
                                                    <b>ISO standards obtained in the years of 2022, 2023 & 2024</b>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    {/* <div className="aline-center-footer-txt "> */}
                                    <div
                                        className="odc-testimonial-bg-style "
                                        style={{
                                            backgroundImage: "url(./images/client-bg.png)",
                                            backgroundSize: "cover",
                                            // height: "510px",
                                            // width: "100%",
                                            // backgroundAttachment: "fixed",
                                        }}
                                    >
                                        <Slider className="testimonial" {...settings}>
                                            <div className="testimonial_content">
                                                <p>
                                                    " The food is excellent and service is also super taste and today
                                                    bread halwa is very very tasty. All variety rice , raasam, kara
                                                    Kulzambu and vegetables are good taste and serve in hot conditions.
                                                    Butter milk is good really. Over all I love the food taste,
                                                    presentation, service and conditions all excellent to the core. "
                                                </p>
                                                <img src="images/5s.png" alt="star" style={{ width: "15%" }} />
                                                <p>
                                                    <b>Srinivasa Sampathkumar</b>
                                                </p>
                                            </div>
                                            <div className="testimonial_content">
                                                <p>
                                                    "For the first time we came across someone from online who were very
                                                    professional from taking the food order,specifying the tariff and
                                                    dispatching the food delivery on time. Food was very tasty and
                                                    maintained good standards. Pricing was reasonably good and best
                                                    service. Thanks to Hogist team for Organizing dinner menu for our
                                                    Anniversary party for 50 members. I give 5 star ratings.. "
                                                </p>
                                                <img src="images/4s.png" alt="star" style={{ width: "15%" }} />
                                                <p>
                                                    <b>Brinda Ghanesh</b>
                                                </p>
                                            </div>
                                            <div className="testimonial_content">
                                                <p>
                                                    " Recently we had a small bday party at home for just 20 people and
                                                    we're struggling to get the catering done for the same. Then we came
                                                    across Hogist and then got in touch with them. They were blessing in
                                                    disguise. They provided amazing food and the service was also great.
                                                    The delivered the food on time and all our guests loved the food.
                                                    The food was delicious and yummy. Thank you so much."
                                                </p>
                                                <img src="images/5s.png" alt="star" style={{ width: "15%" }} />
                                                <p>
                                                    <b>swati menon</b>
                                                </p>
                                            </div>
                                            <div className="testimonial_content">
                                                <p>
                                                    " Hi I am JANOSE BERDEEN from chennai, Professional approach, decent
                                                    pricing , excellent quality of food with commitment on the timings.
                                                    Hassle-free Ordering experience. Hogist, the best small party
                                                    catering services in chennai ever experienced."
                                                </p>
                                                <img src="images/5s.png" alt="star" style={{ width: "15%" }} />
                                                <p>
                                                    <b>JANOSE BERDEEN I</b>
                                                </p>
                                            </div>
                                            <div className="testimonial_content">
                                                <p>
                                                    " When it comes to corporate catering in chennai . I would recommend
                                                    100% Hogist. Their service and industrial catering app would helps
                                                    us getting good quality and food and save our time."
                                                </p>
                                                <img src="images/4s.png" alt="star" style={{ width: "15%" }} />
                                                <p>
                                                    <b>Mr. Sanzeeth</b>
                                                </p>
                                            </div>
                                            <div className="testimonial_content">
                                                <p>
                                                    "Food was excellent.... i will reach out to them even for the
                                                    upcoming events. The foods were clean, tasty and also was on time
                                                    with quality. Kudos to the Hogist team.speacial mention Mrs.Renuka
                                                    for the arrangements and Mr Alex and Satish for the timely
                                                    delivery."
                                                </p>
                                                <img src="images/4s.png" alt="star" style={{ width: "15%" }} />
                                                <p>
                                                    <b>yamunashankar krishnan</b>
                                                </p>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Location1;
