import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Faqs() {
    return (
        <>
            <Helmet>
                <title>{`FAQ's — ${theme.name}`}</title>
            </Helmet>
            <div
                style={{
                    backgroundImage:
                        'url("https://png.pngtree.com/background/20220726/original/pngtree-indian-cuisine-sketch-pattern-background-picture-image_1814318.jpg")',
                }}
            >
                <div style={{ backgroundColor: "#fffffffa" }}>
                    <div className="container-fluid ">
                        <div style={{ margin: "6% 9%" }}>
                            <h4 className="faq-tit-txt">
                                <span style={{ color: "#c60800" }}>F</span>AQ's
                            </h4>
                            <div>
                                <h4 className="mb-4">General Info</h4>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <b>Can I use Hogist’s online services for free?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Hogist’s services are free. We will never charge you beyond the pricing
                                            listed on the website for the service opted by you. Our online catering
                                            service is free of cost and after every successful transaction, you will be
                                            receiving the complete breakup of charges incurred. There are no hidden
                                            charges.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>What kind of food can I get?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            You name it and we have it. We have collaborated with a wide range of
                                            restaurants, caterers, renowned chefs, Homecooks. All you have to do is get
                                            in touch with them, choose the product, set your quantity and make the
                                            payment. If you have a caterer in mind and you don’t find them on Hogist,
                                            please feel free to drop their details at support@hogist.com. and we will
                                            get in touch with them.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you have home delivery option or I will have to pick it up?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            All the orders will be delivered to you at your doorstep.Anyways, if you
                                            wish to pick the order by yourself, then you can set your order as a
                                            takeaway and pick it up from the Vendor’s place.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Can I expect 100% satisfaction from Hogist?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Definitely with Top Class Customer Service! You can contact us anytime if
                                            you feel our services are not as expected.We will make sure that we deliver
                                            our services with 100% customer satisfaction. Also, if you find it
                                            appropriate, we can even serve your guests on your behalf.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you follow a secure process for accepting orders?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Yes. We use 128-bit SSL encryption method to capture your data. The
                                            information you provide us is stored using secured mediums so that there is
                                            no chance of data theft. Refer our Privacy Policy to get more information on
                                            how we store and use your information.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <h4 className="mb-4">Orders</h4>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <b>Can I use Hogist’s online services for free?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Hogist’s services are free. We will never charge you beyond the pricing
                                            listed on the website for the service opted by you. Our online catering
                                            service is free of cost and after every successful transaction, you will be
                                            receiving the complete breakup of charges incurred. There are no hidden
                                            charges.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>What kind of food can I get?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            You name it and we have it. We have collaborated with a wide range of
                                            restaurants, caterers, renowned chefs, Homecooks. All you have to do is get
                                            in touch with them, choose the product, set your quantity and make the
                                            payment. If you have a caterer in mind and you don’t find them on Hogist,
                                            please feel free to drop their details at support@hogist.com. and we will
                                            get in touch with them.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you have home delivery option or I will have to pick it up?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            All the orders will be delivered to you at your doorstep.Anyways, if you
                                            wish to pick the order by yourself, then you can set your order as a
                                            takeaway and pick it up from the Vendor’s place.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Can I expect 100% satisfaction from Hogist?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Definitely with Top Class Customer Service! You can contact us anytime if
                                            you feel our services are not as expected.We will make sure that we deliver
                                            our services with 100% customer satisfaction. Also, if you find it
                                            appropriate, we can even serve your guests on your behalf.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you follow a secure process for accepting orders?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Yes. We use 128-bit SSL encryption method to capture your data. The
                                            information you provide us is stored using secured mediums so that there is
                                            no chance of data theft. Refer our Privacy Policy to get more information on
                                            how we store and use your information.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <h4 className="mb-4">My Account</h4>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <b>Can I use Hogist’s online services for free?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Hogist’s services are free. We will never charge you beyond the pricing
                                            listed on the website for the service opted by you. Our online catering
                                            service is free of cost and after every successful transaction, you will be
                                            receiving the complete breakup of charges incurred. There are no hidden
                                            charges.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>What kind of food can I get?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            You name it and we have it. We have collaborated with a wide range of
                                            restaurants, caterers, renowned chefs, Homecooks. All you have to do is get
                                            in touch with them, choose the product, set your quantity and make the
                                            payment. If you have a caterer in mind and you don’t find them on Hogist,
                                            please feel free to drop their details at support@hogist.com. and we will
                                            get in touch with them.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you have home delivery option or I will have to pick it up?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            All the orders will be delivered to you at your doorstep.Anyways, if you
                                            wish to pick the order by yourself, then you can set your order as a
                                            takeaway and pick it up from the Vendor’s place.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Can I expect 100% satisfaction from Hogist?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Definitely with Top Class Customer Service! You can contact us anytime if
                                            you feel our services are not as expected.We will make sure that we deliver
                                            our services with 100% customer satisfaction. Also, if you find it
                                            appropriate, we can even serve your guests on your behalf.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you follow a secure process for accepting orders?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Yes. We use 128-bit SSL encryption method to capture your data. The
                                            information you provide us is stored using secured mediums so that there is
                                            no chance of data theft. Refer our Privacy Policy to get more information on
                                            how we store and use your information.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="mt-4">
                                <h4 className="mb-4">Pricing & Payment</h4>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>
                                            <b>Can I use Hogist’s online services for free?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Hogist’s services are free. We will never charge you beyond the pricing
                                            listed on the website for the service opted by you. Our online catering
                                            service is free of cost and after every successful transaction, you will be
                                            receiving the complete breakup of charges incurred. There are no hidden
                                            charges.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>What kind of food can I get?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            You name it and we have it. We have collaborated with a wide range of
                                            restaurants, caterers, renowned chefs, Homecooks. All you have to do is get
                                            in touch with them, choose the product, set your quantity and make the
                                            payment. If you have a caterer in mind and you don’t find them on Hogist,
                                            please feel free to drop their details at support@hogist.com. and we will
                                            get in touch with them.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you have home delivery option or I will have to pick it up?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            All the orders will be delivered to you at your doorstep.Anyways, if you
                                            wish to pick the order by yourself, then you can set your order as a
                                            takeaway and pick it up from the Vendor’s place.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Can I expect 100% satisfaction from Hogist?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Definitely with Top Class Customer Service! You can contact us anytime if
                                            you feel our services are not as expected.We will make sure that we deliver
                                            our services with 100% customer satisfaction. Also, if you find it
                                            appropriate, we can even serve your guests on your behalf.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ paddingBottom: "1%" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>
                                            <b>Do you follow a secure process for accepting orders?</b>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            Yes. We use 128-bit SSL encryption method to capture your data. The
                                            information you provide us is stored using secured mediums so that there is
                                            no chance of data theft. Refer our Privacy Policy to get more information on
                                            how we store and use your information.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Faqs;
