/* eslint-disable no-unused-vars */
import orderdataReducer from "./orderdataReducer";

import { createStore } from "redux";

const initialState = { count: 25, count1: 25, count2: 25 };

export function reducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_COUNT":
            return { ...state, count: action.payload };
        case "UPDATE_COUNT1":
            return { ...state, count1: action.payload };
        case "UPDATE_COUNT2":
            return { ...state, count2: action.payload };
        default:
            return state;
    }
}

const store = createStore(reducer);

export * from "./orderdataAction";
export default orderdataReducer;
