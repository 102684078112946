/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import Carousel from "react-bootstrap/Carousel";
//
import Slider1 from "./../../../img/eslide1.png";
import Slider2 from "./../../../img/eslide2.png";
import Slider3 from "./../../../img/eslide3.png";
import Corpslider1 from "./../../../img/slide1.png";
import Corpslider2 from "./../../../img/slide2.png";
import Corpslider3 from "./../../../img/slide3.png";

export default function HomeSlider() {
  
    const [mainHomePage, setMainHomePage] = useState(false);
    const [industryHomePage, setIndustryHomePage] = useState(false);
    const [corporateHomePage, setCorporateHomePage] = useState(false);

    useEffect(() => {
        setMainHomePage(window.location.pathname === "/");
        setIndustryHomePage(window.location.pathname === "/industry/home");
        setCorporateHomePage(window.location.pathname === "/corporate/home");
        // eslint-disable-next-line
    }, [window.location]);

    return (
        <div>
            <Carousel controls={false} fade={true} interval={1000} className="home-slide-carousel-style" >
                <Carousel.Item>
                    <img className="d-block w-100" src={corporateHomePage ? Corpslider3 : Slider3} alt="First slide" />
                    {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={corporateHomePage ? Corpslider2 : Slider2} alt="Second slide" />
                    {/* <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100" src={corporateHomePage ? Corpslider1 : Slider1} alt="Third slide" />
                    {/* <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
                </Carousel.Item>
            </Carousel>

            {/* // <MDBCarousel showControls dark fade> */}
            {/* <MDBCarousel showIndicators dark fade style={{    height: 555,  marginTop: -54}} >
      <MDBCarouselItem
        className='w-100 d-block hight-100'
        itemId={1}
        
        src= {corporateHomePage ? Corpslider3: Slider3} 
        alt='...'
      >
      
      </MDBCarouselItem>
      <MDBCarouselItem
        className='w-100 d-block hight-100'
        itemId={2}
        src= {corporateHomePage ? Corpslider2: Slider2}  
        alt='...'
      >
      
      </MDBCarouselItem>

      <MDBCarouselItem
        className='w-100 d-block hight-100'
        itemId={3}
        src={corporateHomePage ? Corpslider1: Slider1} 
        alt='...'
      >
       
      </MDBCarouselItem>
    </MDBCarousel> */}
        </div>
    );
}
