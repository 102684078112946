/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// import Ratings from "./Ratings";
import StarIcon from "@mui/icons-material/Star";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

// import Select from "@mui/material/Select";
import Select from "react-select";
import Chip from "@mui/material/Chip";
//

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";

import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";
// import Modal from "react-bootstrap/Modal";
import { Image, Modal } from "react-bootstrap";
import Hlogo from "./../../../img/logo.png";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiurl } from "../../../api/api_url";

import { formatTime, formattedDateTime } from "../../../helper/dateformate";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDetailsList() {
    // const { id } = props;
    // const orderdetails = useSelector(state => state.orderdetails);
    const { id } = useParams();
    const [menulistapi, setMenulistapi] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(false);
    const [weightage, setWeightage] = useState(-1);
    const [isVisible, setIsVisible] = useState(-1);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handelWeight = (index) => {
        setWeightage(index === isVisible ? -1 : index);
    };
    const handleClosed = (index) => {
        setWeightage(index === isVisible ? -1 : index);
    };

    const handleClose = () => {
        toast.error("Oops your Order is not Success!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
    };

    const notify = () => {
        toast.success("Your Order is Success!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
        // counted('');
    };

    const weightcheck =
        menulistapi.services && menulistapi.services.map((item, index) => <span key={index}>{item}</span>);
    console.log(weightcheck);
    const toggleVisibility = (index) => {
        setIsVisible(index === isVisible ? -1 : index);
    };

    //Ratings

    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [sreview, setSreview] = useState("");
    const [compliance, setCompliance] = useState("");
    const [cimage, setCimage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [imshow, setImshow] = useState(false);
    const [subreview, setSubreview] = useState([]);

    const imageSubmite = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setCimage(file);
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handleView = () => {
        setImshow(true);
    };
    const handleUnview = () => {
        setImshow(false);
    };

    const submiteReview = (e) => {
        setSubreview(...subreview, { sreview, compliance, cimage, rating });
        // setRating(0);
    };

    //   console.log(cimage);
    const handleRatingClick = (newRating) => {
        setRating(newRating);
    };

    const handleRatingHover = (hoveredRating) => {
        setHoverRating(hoveredRating);
    };

    const handleRatingHoverLeave = () => {
        setHoverRating(0);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span
                    key={i}
                    className={i <= hoverRating || i <= rating ? "filled-star" : "empty-star"}
                    onClick={() => handleRatingClick(i)}
                    onMouseEnter={() => handleRatingHover(i)}
                    onMouseLeave={() => handleRatingHoverLeave()}
                >
                    &#9733;
                </span>
            );
        }
        return stars;
    };

    const getRatingColor = () => {
        switch (rating) {
            case 1:
                return "#F44336";
            case 2:
                return "#FFC107";
            case 3:
                return "#FFEB3B";
            case 4:
                return "#CDDC39";
            case 5:
                return "#8BC34A";
            default:
                return "#9E9E9E";
        }
    };

    const theme = useTheme();
    const [complianMenulistitem, setComplianMenulistitem] = React.useState([]);

    // const handleChange3 = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setComplianMenulistitem(
    //         // On autofill we get a stringified value.
    //         typeof value === "string" ? value.split(",") : value
    //     );
    // };
    const handleChange3 = (selected) => {
        setComplianMenulistitem(selected);
    };

    const option = [
        { value: "No complaints. Good Service", label: "No complaints. Good Service" },
        { value: "presence of foreign particles", label: "presence of foreign particles" },
        { value: "Less count than ordered", label: "Less count than ordered" },
        { value: "Late Food Delivery", label: "Late Food Delivery" },
        { value: "Not Tastier", label: "Not Tastier" },
    ];

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    const getApiData = async () => {
        // const { id } = props;
        // const { id } = useParams( myorderlist._id);
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const result = await fetch(`${apiurl}/order/${id}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(result.data);
        console.log(result.data);
    };

    const date = new Date(menulistapi.createdAt);
    const date1 = new Date(menulistapi.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    return (
        <div className="container ">
            <MDBRow>
                <MDBCol lg={12} className="mrbt">
                    <MDBCard className="service-tab-style border-styles" style={{ textAlign: "center" }}>
                        <h4 className="fw-bold mb-1 order-details-header">My Order Status</h4>
                        <MDBCardBody>
                            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                <ListItem>
                                    <ListItemText> Ordered Date </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText> {`${day} -${month} -${year}`}</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText> Ordered Time </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText> {formatTime(menulistapi.createdAt)} </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText> Service Date </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText> {`${day1} -${month1} -${year1}`}</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText> Delivery Address</ListItemText>
                                    <ListItemText>: </ListItemText>
                                    <ListItemText>
                                        {" "}
                                        {menulistapi.customer && menulistapi.customer.address}{" "}
                                    </ListItemText>
                                </ListItem>
                                {/* {menulistapi.customer.address}  */}
                                <ListItem>
                                    <ListItemText> Services</ListItemText>
                                    <ListItemText>: </ListItemText>

                                    <ListItemText>
                                        {menulistapi.services &&
                                            menulistapi.services.map((item, index) => (
                                                <span key={index}> {item.label} ,</span>
                                            ))}
                                    </ListItemText>
                                </ListItem>

                                {menulistapi.services &&
                                    menulistapi.services.map((item, index) => (
                                        <div key={index}>
                                            <ListItem>
                                                <ListItemText> {`${item.label} Count`} </ListItemText>
                                                <ListItemText>: </ListItemText>
                                                <ListItemText>{item.count} </ListItemText>
                                            </ListItem>
                                        </div>
                                    ))}

                                {/* <ListItem>
                                        <ListItemText> COVID check </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText>
                                            <div className="details-btn-pending">{menulistapi.covidcheck}</div>
                                        </ListItemText>
                                    </ListItem> */}

                                {/* <ListItem>
                                        <ListItemText> Order Status </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText>
                                            <Button className="details-btn-pending" onClick={toggleVisibility}>
                                            {orderdetails.orderStatus}
                                            </Button>
                                        </ListItemText>
                                    </ListItem> */}
                            </List>

                            {/* <div className='dash-line'></div> */}
                            <div>
                                <h3 className=" mb-4 mt-4" style={{ color: "#000" }}>
                                    <span style={{ color: " #c60800" }}>B</span>ill Details
                                </h3>

                                {menulistapi.services &&
                                    menulistapi.services.map((item, index) => (
                                        <div key={index}>
                                            <div className="inline-flex-st">
                                                <img
                                                    className="veg-details-img"
                                                    src={
                                                        item.vegType === "VEG" ? "images/veg.png" : "images/nonveg.png"
                                                    }
                                                    alt=""
                                                />
                                                <h4 style={{ color: "#000" }}> {item.label}</h4>
                                            </div>
                                            <div >
                                                        <div className="food-item-details">
                                                        {item &&
                                                item.menuItems &&
                                                item.menuItems.map((items, indexed) => 
                                                            <span  key={indexed} style={{ padding: "1px 7px" }}> {items.label} ({items.size}{items.unit}) , </span>
                                                            )}
                                                        </div>
                                                    </div>
                                            <div className="container">
                                                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                    <div className="dash-line"></div>
                                                    <ListItem className="count-ord-btn">
                                                        <ListItemText> Quantity </ListItemText>
                                                        <ListItemText> Price </ListItemText>
                                                        <ListItemText> Total </ListItemText>
                                                    </ListItem>
                                                    <div className="dash-line"></div>
                                                    <ListItem className="count-ord-btn">
                                                        <ListItemText> {item.count}</ListItemText>
                                                        <ListItemText style={{ paddingLeft: "5%" }}>
                                                            <span>₹ </span> {item.price}
                                                        </ListItemText>
                                                        <ListItemText>
                                                            <span>₹ </span> {item.count * item.price}
                                                        </ListItemText>
                                                    </ListItem>
                                                </List>
                                            </div>

                                            <div style={{ margin: "30px 0px" }}>
                                                <p style={{ fontSize: "18px", fontWeight: " 600" }}>
                                                    View Order Status
                                                </p>
                                                <Button
                                                     className={item && item.delivered && item.delivered.status ?"details-btn-done stywdth50":"details-btn-pending"}
                                                    onClick={() => toggleVisibility(index)}
                                                >
                                                     {item && item.delivered && item.delivered.status ? "Delivered" : "pending"}
                                                </Button>
                                            </div>
                                            {isVisible === index ? (
                                                <div>
                                                    <h2
                                                        className=" mb-4 mt-2"
                                                        style={{ textAlign: "center", color: "#dc0000" }}
                                                    >
                                                        Order Status
                                                    </h2>

                                                    <StatusSteps orderdetails={item} userdetails={menulistapi} />
                                                </div>
                                            ) : null}

                                            <Button
                                                className="details-btn-pending weight-btn"
                                                onClick={() => handelWeight(index)}
                                                style={{ marginTop: "5%", marginBottom: "10%" }}
                                            >
                                                {" "}
                                                See Food Weight
                                            </Button>

                                            {/* { if(weightage === index){ */}

                                            <Modal show={weightage === index} onHide={handleClosed}>
                                                <form>
                                                    <Modal.Header className="pop_head">
                                                        <h3
                                                            className=" mb-4 mt-4"
                                                            style={{ color: "#fff", marginLeft: "27%" }}
                                                        >
                                                            <span style={{ color: " #000" }}>S</span>ee Food Weight
                                                        </h3>

                                                        <Button
                                                            className="cls-btn"
                                                            style={{ color: "#fff" }}
                                                            onClick={handleClosed}
                                                        >
                                                            X
                                                        </Button>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div style={{ color: "#000" }}>
                                                            {/* {menulistapi.services && menulistapi.services.map((item, index) => ( */}
                                                            <div key={index}>
                                                                <div className="inline-flex-st">
                                                                    <img
                                                                        style={{ width: "5%", height: "5%" }}
                                                                        className="veg-details-img"
                                                                        src={
                                                                            item.vegType === "VEG"
                                                                                ? "images/veg.png"
                                                                                : "images/nonveg.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <h4> {item.label}</h4>
                                                                </div>
                                                                <div >
                                                        <div className="food-item-details">
                                                        {item &&
                                                item.menuItems &&
                                                item.menuItems.map((items, indexed) => 
                                                            <span  key={indexed} style={{ padding: "1px 7px" }}> {items.label} ({items.size}{items.unit}) , </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                                <div className="container">
                                                                    <List
                                                                        sx={{
                                                                            width: "100%",
                                                                            bgcolor: "background.paper",
                                                                        }}
                                                                    >
                                                                        <div className="dash-line"></div>
                                                                        <ListItem className="count-ord-btn">
                                                                            <ListItemText> Quantity </ListItemText>
                                                                            <ListItemText> Price </ListItemText>
                                                                            <ListItemText> Total </ListItemText>
                                                                        </ListItem>
                                                                        <div className="dash-line"></div>
                                                                        <ListItem className="count-ord-btn">
                                                                            <ListItemText> {item.count}</ListItemText>
                                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                                <span>₹ </span>
                                                                                {item.price}
                                                                            </ListItemText>
                                                                            <ListItemText>
                                                                                {" "}
                                                                                <span>₹ </span>{" "}
                                                                                {item.count * item.price}
                                                                            </ListItemText>
                                                                        </ListItem>
                                                                    </List>

                                                                    {/* <Button  onClick={handelWeight} style={{marginLeft: '35% ', marginTop:'20px'}} className='safety-check-popup-btn'  > Ok</Button> */}
                                                                </div>
                                                                <List
                                                                    sx={{ width: "100%", bgcolor: "background.paper" }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            textAlign: "inherit",
                                                                            color: "#585858b3",
                                                                        }}
                                                                    >
                                                                        {item.foodWeight.map((items, indexed) => (
                                                                            <div key={indexed}>
                                                                                <div className="food-item-details">
                                                                                    <ListItemText>
                                                                                        <span
                                                                                            style={{
                                                                                                padding: "1px 7px",
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            {items.item} {items.size}
                                                                                        </span>{" "}
                                                                                        ,
                                                                                    </ListItemText>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </List>
                                                            </div>

                                                            {/* // ))} */}
                                                        </div>
                                                    </Modal.Body>
                                                </form>
                                            </Modal>
                                            {/* }} */}
                                        </div>
                                    ))}
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>
    );
}
