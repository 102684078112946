
import { ADDGUESTUSER_CHANGE } from "./guestActionTypes";

export const guestuserChange = (guestData) => ({
    type: ADDGUESTUSER_CHANGE,
   guestData,
});
// export const updateMenuCount = (guestData,clatitude,clongitude) => ({
//     type: UPDATEMENU_COUNT,
//     payload: { guestData,clatitude,clongitude },
//   });