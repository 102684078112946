// react
import React, { useCallback, useState, useEffect, useReducer } from "react";
// import React, {  useState } from 'react';
// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from "react-router-dom";

// application
// import Pagination from "../shared/Pagination";

import MenuDateList from "../shared/OrderDateList";
import MyOrderListData from "../shared/MyOrderListData";
import MyOrderListCancel from "../shared/MyOrderListCancel";
import MyOrderListDelivery from "../shared/MyOrderListDelivery";
// import ProductCard from '../shared/ProductCard';
// import ProductDateList from '../shared/ProductDateList';

// import MonthlyMenu from './MonthlyMenu';
// import WeeklyMenu from './WeeklyMenu';
import { apiurl } from "../../../api/api_url";
import { Filters16Svg, LayoutGrid16x16Svg, LayoutGridWithDetails16x16Svg, LayoutList16x16Svg } from "../../../svg";
import { sidebarOpen } from "../../../store/sidebar";
import MyOrderStatus from "../site/MyOrderStatus";
import Pagination from "@mui/material/Pagination";

function useSetOption(option, filter, dispatch) {
    const callback = useCallback(filter, []);

    return useCallback(
        (data) => {
            dispatch({
                type: "SET_OPTION_VALUE",
                option,
                value: callback(data),
            });
        },
        [option, callback, dispatch]
    );
}

function MyOrderStatusView(props) {
    const {
        isLoading,
        productsList,
        options,
        filters,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        sidebarOpen,
    } = props;
    const [layout, setLayout] = useState(propsLayout);
    const [menulistapi, setMenulistapi] = useState([]);
    const [menulistapi1, setMenulistapi1] = useState([]);
    const [menulistapi2, setMenulistapi2] = useState([]);
    const orderlist = menulistapi;
    const [value, setValue] = useState("1");
    // const  orderlist = menulistapi;

    const [pages, setPages] = useState({ pages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;
    const [pages1, setPages1] = useState({ pages: 1 });
    const [currentPage1, setCurrentPage1] = useState(1);
    const [pages2, setPages2] = useState({ pages: 1 });
    const [currentPage2, setCurrentPage2] = useState(1);


    const handlePageChange = useSetOption("page", parseFloat, dispatch);
    const handleSortChange = useSetOption("sort", (event) => event.target.value, dispatch);
    const handleLimitChange = useSetOption("limit", (event) => parseFloat(event.target.value), dispatch);

    useEffect(() => {
        getApiData(currentPage);
        getApiData1(currentPage1);
        getApiData2(currentPage2);
    }, []);
    // order
    const getApiData = async (pageno) => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const response = await fetch(`${apiurl}/orders?list=ordered&page=${pageno}&limit=${limit}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );
console.log(response);
        // update the state
        setMenulistapi(response.data);
        setPages(response);
    };
    // Delivery
    const getApiData1 = async (pageno1) => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const response = await fetch(`${apiurl}/orders?list=delivered&page=${pageno1}&limit=${limit}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi1(response.data);
        setPages1(response);
    };

    // cancelled order
    const getApiData2 = async (pageno2) => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const response = await fetch(`${apiurl}/orders?list=cancelled&page=${pageno2}&limit=${limit}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi2(response.data);
        setPages2(response);
    };


//pagination
const paginate = (event, value) => {
    getApiData(value);
    setCurrentPage(value);
};

const paginate1 = (event, value) => {
    getApiData1(value);
    setCurrentPage1(value);
};

const paginate2 = (event, value) => {
    getApiData2(value);
    setCurrentPage2(value);
};














    const ordered = orderlist&&orderlist.map((product, index) => (
        // product.orderStatus === "delivered" ?
        <div key={index} className="products-list__item" style={{ display: "flow-root" }}>
            <MyOrderListData myorderlist={product} />
        </div>
        //  :"No More Orders"
    ));

    const delivered = menulistapi1&& menulistapi1.map((product1, index1) => (
        // orderlist.orderStatus === "delivered" ?
        <div key={index1} className="products-list__item" style={{ display: "flow-root" }}>
            <MyOrderListDelivery myorderlist={product1} />
        </div>
        // :"No order is Delivered"
    ));

    const cancelled = menulistapi2&& menulistapi2.map((product2, index2) => (
        // orderlist.orderStatus === "Ordered" ?
        <div key={index2} className="products-list__item" style={{ display: "flow-root" }}>
            <MyOrderListCancel myorderlist={product2} />
        </div>
        // :"No order is Cancelled"
    ));

    const handleResetFilters = useCallback(() => {
        dispatch({ type: "RESET_FILTERS" });
    }, [dispatch]);

    const filtersCount = Object.keys(filters)
        .map((x) => filters[x])
        .filter((x) => x).length;
    let viewModes = [
        // { key: 'grid', title: 'Grid', icon: <LayoutGrid16x16Svg /> },
        // { key: 'grid-with-features', title: 'Grid With Features', icon: <LayoutGridWithDetails16x16Svg /> },
        { key: "list", title: "List", icon: <LayoutList16x16Svg /> },
    ];

    viewModes = viewModes.map((viewMode) => {
        const className = classNames("layout-switcher__button", {
            "layout-switcher__button--active": layout === viewMode.key,
        });

        return (
            <button
                key={viewMode.key}
                title={viewMode.title}
                type="button"
                className={className}
                onClick={() => setLayout(viewMode.key)}
            >
                {viewMode.icon}
            </button>
        );
    });

    const productsListItems = productsList.items.map((product) => (
        <div key={product.id} className="products-list__item">
            <MyOrderListData product={product} />
        </div>
    ));

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });

    let content;

    if (productsListItems.length > 0) {
   

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        console.log(orderlist);
        content = (
            <div className="products-view__content">
                <div className="serv-bg-color ">
                    <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab className="pd-right1 service-tab-txt" label="My Order" value="1" />
                                    <Tab className="pd-right1 service-tab-txt" label="Delivered Orders" value="2" />
                                    <Tab className="pd-right1 service-tab-txt" label="Canceled Orders" value="3" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <div className="block block-banner">
                                    <div className="container-fluid">
                                        {
                                            ordered && ordered.length > 0 ? ordered : "No More Orders"
                                            // orderlist.map((product, index) => (
                                            //     // product.orderStatus === "delivered" ?
                                            //     <div
                                            //         key={index}
                                            //         className="products-list__item"
                                            //         style={{ display: "flow-root" }}
                                            //     >
                                            //         <MyOrderListData myorderlist={product} />
                                            //     </div>
                                            //     //  :"No More Orders"
                                            // ))
                                        }
                                    </div>

                                    <Pagination
                                    count={pages.pages}
                                    page={currentPage}
                                    onChange={paginate}
                                    variant="outlined"
                                    color="primary"
                                    style={{ justifyContent: "center", display: "flex", marginTop: "6%" }}
                                />

                                </div>
                            </TabPanel>

                            <TabPanel value="2">
                                <div className="block block-banner">
                                    <div className="container-fluid">
                                        {/* {orderlist.map((product1,index1) => ( */}
                                        {delivered && delivered.length > 0 ? delivered : "No order is Delivered"}
                                    </div>
                                    <Pagination
                                    count={pages1.pages}
                                    page={currentPage1}
                                    onChange={paginate1}
                                    variant="outlined"
                                    color="primary"
                                    style={{ justifyContent: "center", display: "flex", marginTop: "6%" }}
                                />

                                </div>
                            </TabPanel>

                            <TabPanel value="3">
                                <div className="block block-banner">
                                    <div className="container-fluid">
                                        {cancelled && cancelled.length > 0 ? cancelled : "No order is Cancelled"}
                                    </div>

                                    <Pagination
                                    count={pages2.pages}
                                    page={currentPage2}
                                    onChange={paginate2}
                                    variant="outlined"
                                    color="primary"
                                    style={{ justifyContent: "center", display: "flex", marginTop: "6%" }}
                                />
                                </div>
                                
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>

                {/* <div className="products-view__pagination">
                    <Pagination
                        current={options.page || productsList.page}
                        siblings={2}
                        total={productsList.pages}
                        onPageChange={handlePageChange}
                    />
                </div> */}
            </div>
        );
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">No matching items</div>
                <div className="products-view__empty-subtitle">Try resetting the filters</div>
                <button type="button" className="btn btn-primary btn-sm" onClick={handleResetFilters}>
                    Reset filters
                </button>
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />

            {content}
        </div>
    );
}

MyOrderStatusView.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    productsList: PropTypes.object,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(["grid-3-sidebar", "grid-4-full", "grid-5-full"]),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

MyOrderStatusView.defaultProps = {
    layout: "grid",
    grid: "grid-3-sidebar",
    offcanvas: "mobile",
};

const mapDispatchToProps = {
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(MyOrderStatusView);
