/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
//
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import StarIcon from "@mui/icons-material/Star";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import StatusSteps from "../shared/StatusSteps";
import { Image, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { apiurl } from "../../../api/api_url";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import { formatTime, formattedDate,convertTime } from "../../../helper/dateformate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import EggIcon from "@mui/icons-material/Egg";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import imageCompression from "browser-image-compression";
import CircularProgress from '@mui/material/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Orderdetailsdata() {
    // const { id } = props;
    // const orderdetails = useSelector(state => state.orderdetails);
    const { id } = useParams();
    const [menulistapi, setMenulistapi] = useState([]);
    const [menulistapi1, setMenulistapi1] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(false);
    const [weightage, setWeightage] = useState(false);
    const [isVisible, setIsVisible] = useState(-1);
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(-1);
    const [imageurl, setImageUrl] = useState(null);
    const history = useHistory();
    const [openMenu, setOpenMenu] = React.useState(false);
    const[loading,setLoading]=useState(false)

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    const getApiData = async () => {
        // const { id } = props;
        setLoading(true);
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("tokenodc") };
        const result = await fetch(`${apiurl}/odc-order/${id}`, { headers, method: "GET" }).then((response) =>
            response.json()
            
        );

        // update the state
        setMenulistapi1(result.data);
        setLoading(false);
    };
    console.log(menulistapi1);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handelWeight = () => {
        setWeightage(true);
    };
    const handleClosed = (index) => {
        setWeightage(false);
    };

    const handleClose = () => {
        toast.error("Oops your Order is not Success!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
    };

    const notify = () => {
        toast.success("Your Order is Success!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
        // counted('');
    };

    const weightcheck =
        menulistapi.services && menulistapi.services.map((item, index) => <span key={index}>{item}</span>);
    // console.log(weightcheck);

    const toggleVisibility = (index) => {
        // setIsVisible(!isVisible ? true : false);
        // setIsVisible1(index === isVisible1 ? -1 : index);
        setIsVisible(index === isVisible ? -1 : index);
    };

    const toggleVisibility1 = () => {
        // setIsVisible(index === isVisible ? -1 : index);
        setIsVisible1(!isVisible1 ? true : false);
    };
    const handleClickOpenMenu = (index) => {
        setIsVisible2(index === isVisible2 ? -1 : index);
        setOpenMenu(true);
    };

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };
    //Ratings

    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [sreview, setSreview] = useState("");
    const [compliance, setCompliance] = useState("");
    const [cimage, setCimage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [imshow, setImshow] = useState(false);
    const [subreview, setSubreview] = useState([]);

    const imageSubmite = async (e) => {
        const uploadfile = e.target.files[0];
        let file = null;
        try {
            const options = {
                maxSizeMB: 1, // Set the target file size in megabytes (1MB in this case)
                maxWidthOrHeight: 1024, // Set the maximum width or height of the compressed image
            };

            const blobFile = new Blob([uploadfile], { type: uploadfile.type });

            const compressedFile = await imageCompression(blobFile, options);

            console.log(compressedFile); // Compressed image file

            file = new File([compressedFile], uploadfile.name, {
                type: compressedFile.type,
            });

            // Do something with the compressed image file, such as uploading it to a server or displaying it in your UI
        } catch (error) {
            console.error("Image compression failed:", error);
        }
        if (file) {
            // const modifyfile = file
            const reqOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ filename: uploadfile.name, type: uploadfile.type }),
            };

            fetch(`${apiurl}/upload/url`, reqOptions)
                .then((res) => res.json())
                .then((data) => {
                    fetch(data.url, {
                        method: "PUT",
                        body: file,
                    }).then((rs) => {
                        if (rs.ok) {
                            const image_url = rs.url.split("?")[0];
                            setImageUrl(image_url);
                            console.log(image_url);
                        }
                    });
                });
        }

        // let reader = new FileReader();
        // let file = e.target.files[0];
        // reader.onloadend = () => {
        //     setCimage(file);
        //     setPreview(reader.result);
        // };
        // reader.readAsDataURL(file);
    };
    const handleView = () => {
        setImshow(true);
    };
    const handleUnview = () => {
        setImshow(false);
    };

    const submiteReview = async(e) => {
        e.preventDefault();
        setSubreview(...subreview, { sreview, compliance, cimage, rating });
        // console.log(subreview);
        // setRating(0);

        await fetch(`${apiurl}/review-odc-order/${id}`, {
            method: "PUT",
            body: JSON.stringify({
                text: sreview,
                compliant: compliance,
                image: imageurl,
                star: rating,
            }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("tokenodc"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                toast.success("Your Review Sended Successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSreview("");
                setCompliance("");
                setCimage("");
                setRating("");

            });
        // setRating(0);
      



    };

    //   console.log(cimage);
    const handleRatingClick = (newRating) => {
        setRating(newRating);
    };

    const handleRatingHover = (hoveredRating) => {
        setHoverRating(hoveredRating);
    };

    const handleRatingHoverLeave = () => {
        setHoverRating(0);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span
                    key={i}
                    className={i <= hoverRating || i <= rating ? "filled-star" : "empty-star"}
                    onClick={() => handleRatingClick(i)}
                    onMouseEnter={() => handleRatingHover(i)}
                    onMouseLeave={() => handleRatingHoverLeave()}
                >
                    &#9733;
                </span>
            );
        }
        return stars;
    };

    const getRatingColor = () => {
        switch (rating) {
            case 1:
                return "#F44336";
            case 2:
                return "#FFC107";
            case 3:
                return "#FFEB3B";
            case 4:
                return "#CDDC39";
            case 5:
                return "#8BC34A";
            default:
                return "#9E9E9E";
        }
    };

    const theme = useTheme();
    const [complianMenulistitem, setComplianMenulistitem] = React.useState([]);

    const handleChange3 = (e) => {
        setCompliance(e.target.value);
        // console.log(compliance);
    };







    const finalTotel = menulistapi1.total - menulistapi1.paid;
    const paydate = menulistapi1.createdAt;


    const initePayment = (data) => {
        var options = {
            key: "rzp_test_Del0NbJI09NNDc",
            amount: data.payreq.amount,
            image: "https://www.hogist.com/images/hogist-wlogo.png",
            currency: "INR",
            name: "Hogist ",
            order_id:data.payreq.id,
            description: `Hogist Order ID : ${data.id}  |   Name : ${data.customer.fullName}   |   Event Date : ${data.serviceDate}`,
            handler: async function (response) {
               
                console.log(response);

                await fetch(`${apiurl}/odc-order-pay/${data._id}`, {
                    method: "PUT",
                    // should send razerpay data in this keys
                    body: JSON.stringify( response),
                    headers: {
                        "Content-type": "application/json",
                        Authorization: localStorage.getItem("tokenodc"),
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json);
                          if(json.status===true){
                        history.push("/odc/profile");
                       
                          }
                        // after success redirect to order page
                    });
            },
            prefill: {
                name: data.customer.fullName,
                email: data.customer.email,
                contact: data.customer.mobile,
            },
            notes: {
                address: data.customer.address,
            },
            theme: {
                color: "#c60800",
            },
            method: {
                netbanking: "true",
                card: "true",
                upi: "true",
                wallet: "true",
            },
        };
        var pay = new window.Razorpay(options);
        pay.open();
    };
    const handelRepayment = async() => {
        // history.push(`/payment/${menulistapi1._id}/${paydate}/${finalTotel}`);
        await fetch(`${apiurl}/odc-repayment/${id}`, {
            method: "PUT",
            body: JSON.stringify({
               
                amount:finalTotel,
            }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("tokenodc"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json.data);
             
                const paymentData = json.data;
                const paydate = json.data.createdAt;
                // {paymentOption === "advance" && minPay!== 0 ? initePayment():(alert("Enter your Amount "))}

                // should clear the cart usedispatch;
                // dispatch(clearCart());
                initePayment(paymentData);

                // console.log(paymentData.total);
                // history.push(`/payment/${paymentData._id}/${paydate}/${paymentData.total}`);
            });
    };

    const option = [
        { value: "No complaints. Good Service", label: "No complaints. Good Service" },
        { value: "presence of foreign particles", label: "presence of foreign particles" },
        { value: "Less count than ordered", label: "Less count than ordered" },
        { value: "Late Food Delivery", label: "Late Food Delivery" },
        { value: "Not Tastier", label: "Not Tastier" },
    ];

    const date = new Date(menulistapi.createdAt);
    const date1 = new Date(menulistapi.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    const breadcrumb = [
        { title: "My Orders", url: "/odc/profile" },
        { title: "Order Details", url: "" },
        // { title: "Checkout", url: "" },
    ];

    if(loading){
        return <div style={{width:"100%",height:"100%", textAlign:"center" ,margin:"20% 0%"}}> <CircularProgress color="success"  /></div>
    }

    console.log(id);
    return (
        <div>
            <Helmet>
                <title>{`My Order Details — ${theme.name}`}</title>
            </Helmet>
            <div>
                <PageHeader header="Checkout" breadcrumb={breadcrumb} style={{ margin: "0px 110px" }} />
            </div>
            <div className="container ">
                <MDBRow>
                    <MDBCol lg={12} className="mrbt">
                        <MDBCard className="service-tab-style border-styles" style={{ textAlign: "center" }}>
                            <h4 className="fw-bold mb-1 order-details-header">My Order Status</h4>
                            <MDBCardBody>
                                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                    <ListItem>
                                        <ListItemText> Ordered Date </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText> {formattedDate(menulistapi1.createdAt)}</ListItemText>
                                        {/* <ListItemText> {`${day} -${month} -${year}`}</ListItemText> */}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Ordered Time </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText>{formatTime(menulistapi1.createdAt)} </ListItemText>
                                        {/* <ListItemText> {formatTime(menulistapi.createdAt)} </ListItemText> */}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Service Date </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText> {formattedDate(menulistapi1.serviceDate)}</ListItemText>
                                        {/* <ListItemText> {`${day1} -${month1} -${year1}`}</ListItemText> */}
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText> Service Time </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText> {convertTime(menulistapi1.serviceTime)}</ListItemText>
                                        {/* <ListItemText> {`${day1} -${month1} -${year1}`}</ListItemText> */}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Delivery Address</ListItemText>
                                        <ListItemText>: </ListItemText>
                                        <ListItemText>{menulistapi1.dlAddress} </ListItemText>
                                    </ListItem>
                                    {/* {menulistapi.customer.address}  */}

                                    <ListItem>
                                        <ListItemText> Order Status</ListItemText>
                                        <ListItemText>: </ListItemText>

                                        <ListItemText>
                                            <button className="odc-details-orbtn"> {menulistapi1.status} </button>
                                        </ListItemText>
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText> Payment Status</ListItemText>
                                        <ListItemText>: </ListItemText>

                                        <ListItemText>
                                            <button className={menulistapi1.paymentStatus==="Unpaid"?"odc-details-paybtn":"odc-details-orbtn"}>{menulistapi1.paymentStatus}</button>
                                        </ListItemText>
                                    </ListItem>
                                </List>

                                <div>
                                    <h3 className=" mb-4 mt-4" style={{ color: "#000" }}>
                                        <span style={{ color: " #c60800" }}>B</span>ill Details
                                    </h3>

                                    <div>
                                        {menulistapi1.services &&
                                            menulistapi1.services.map((item, index) => (
                                                <div key={index}>
                                                    <div className="container">
                                                        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                            <div className="dash-line"></div>
                                                            <ListItem className="count-ord-btn">
                                                                <ListItemText>Menu Name </ListItemText>
                                                                <ListItemText> View Menu </ListItemText>
                                                                <ListItemText> Menu Type </ListItemText>
                                                                <ListItemText> Quantity </ListItemText>
                                                                <ListItemText> Price </ListItemText>
                                                                <ListItemText> Total </ListItemText>
                                                            </ListItem>

                                                            <div>
                                                                <div className="dash-line"></div>
                                                                <ListItem className="count-ord-btn">
                                                                    <ListItemText> {item.label} </ListItemText>
                                                                    <ListItemText>
                                                                        {/* Menu <VisibilityIcon className="ml-2" /> */}
                                                                        <button
                                                                            className="odc-view-menu-btn d-flex"
                                                                            onClick={() => handleClickOpenMenu(index)}
                                                                        >
                                                                            Menu <VisibilityIcon className="ml-2" />
                                                                        </button>
                                                                    </ListItemText>
                                                                    <ListItemText>
                                                                        {" "}
                                                                        <img
                                                                            style={{ width: "15%" }}
                                                                            src={
                                                                                item.vegType === "VEG"
                                                                                    ? "images/veg.png"
                                                                                    : "images/nonveg.png"
                                                                            }
                                                                            // src="images/veg.png"
                                                                            alt=""
                                                                        />{" "}
                                                                    </ListItemText>
                                                                    <ListItemText>{item.count} </ListItemText>
                                                                    <ListItemText style={{ paddingLeft: "5%" }}>
                                                                        <span>₹ </span> {item.price}
                                                                    </ListItemText>
                                                                    <ListItemText>
                                                                        <span>₹ </span> {item.total}
                                                                    </ListItemText>
                                                                </ListItem>
                                                            </div>
                                                        </List>
                                                        <div className="dash-line1"></div>

                                                        <div style={{ margin: "30px 0px" }}>
                                                            <p style={{ fontSize: "18px", fontWeight: " 600" }}>
                                                                View Order Status
                                                            </p>
                                                            <Button
                                                                className={
                                                                    item && item.delivered && item.delivered.status
                                                                        ? "details-btn-done stywdth50"
                                                                        : "details-btn-pending"
                                                                }
                                                                onClick={() => toggleVisibility(index)}
                                                            >
                                                                {/* {item && item.delivered && item.delivered.status ? "Completed" : "pending"} */}
                                                                {item && item.delivered && item.delivered.status
                                                                    ? "Delivered"
                                                                    : "pending"}
                                                            </Button>
                                                        </div>
                                                        {isVisible === index ? (
                                                            <div>
                                                                <h2
                                                                    className=" mb-4 mt-2"
                                                                    style={{ textAlign: "center", color: "#dc0000" }}
                                                                >
                                                                    Order Status
                                                                </h2>

                                                                <StatusSteps
                                                                    orderdetails={item}
                                                                    userdetails={menulistapi}
                                                                />
                                                            </div>
                                                        ) : null}
                                                        {isVisible2 === index ? (
                                                            <Dialog
                                                                open={openMenu}
                                                                TransitionComponent={Transition}
                                                                keepMounted
                                                                onClose={handleCloseMenu}
                                                                aria-describedby="alert-dialog-slide-description"
                                                                className="opt-resp"
                                                                // sx={{ border: "2px solid #c60800" }}
                                                            >
                                                                <DialogTitle
                                                                    style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "#c60800",
                                                                        color: "#fff",
                                                                        borderRadius: "10px",
                                                                    }}
                                                                >
                                                                    {"Your Menu"}{" "}
                                                                </DialogTitle>
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={handleCloseMenu}
                                                                    sx={{
                                                                        position: "absolute",
                                                                        right: 26,
                                                                        top: 33,
                                                                        color: "#fff",
                                                                    }}
                                                                >
                                                                    <CancelIcon />
                                                                </IconButton>
                                                                <DialogContent>
                                                                    <ul className="odc-cart-menu-list-item-style">
                                                                        {item &&
                                                                            item.menuItems &&
                                                                            item.menuItems.map((menus, menuid) => (
                                                                                // console.log(menus);
                                                                                <li key={menuid}>
                                                                                    <span>
                                                                                        <EggIcon
                                                                                            style={{
                                                                                                marginRight: "2%",
                                                                                                color: "#c60800",
                                                                                            }}
                                                                                        />
                                                                                    </span>{" "}
                                                                                    {menus.label} ({menus.size}
                                                                                    {menus.unit})
                                                                                </li>
                                                                            ))}
                                                                    </ul>
                                                                </DialogContent>
                                                            </Dialog>
                                                        ) : null}

                                                        <Modal show={weightage === true} onHide={handleClosed}>
                                                            <form>
                                                                <Modal.Header className="pop_head">
                                                                    <h3
                                                                        className=" mb-4 mt-4"
                                                                        style={{ color: "#fff", marginLeft: "27%" }}
                                                                    >
                                                                        <span style={{ color: " #000" }}>S</span>ee Food
                                                                        Weight
                                                                    </h3>

                                                                    <Button
                                                                        className="cls-btn"
                                                                        style={{ color: "#fff" }}
                                                                        onClick={handleClosed}
                                                                    >
                                                                        X
                                                                    </Button>
                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div style={{ color: "#000" }}>
                                                                        <div>
                                                                            <div className="inline-flex-st">
                                                                                <img
                                                                                    style={{
                                                                                        width: "5%",
                                                                                        height: "5%",
                                                                                    }}
                                                                                    className="veg-details-img"
                                                                                    src="images/veg.png"
                                                                                    alt=""
                                                                                />
                                                                                <h4> Breakfast</h4>
                                                                            </div>
                                                                            <div>
                                                                                <div className="food-item-details">
                                                                                    <span
                                                                                        style={{ padding: "1px 7px" }}
                                                                                    >
                                                                                        {" "}
                                                                                        Idli (2 pcs), Sambar (200ml),
                                                                                        Vadaii (1 pcs), chatiny
                                                                                        (100g),Pongal (300 g)
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="container">
                                                                                <List
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        bgcolor: "background.paper",
                                                                                    }}
                                                                                >
                                                                                    <div className="dash-line"></div>
                                                                                    <ListItem className="count-ord-btn">
                                                                                        <ListItemText>
                                                                                            {" "}
                                                                                            Quantity{" "}
                                                                                        </ListItemText>
                                                                                        <ListItemText>
                                                                                            {" "}
                                                                                            Price{" "}
                                                                                        </ListItemText>
                                                                                        <ListItemText>
                                                                                            {" "}
                                                                                            Total{" "}
                                                                                        </ListItemText>
                                                                                    </ListItem>
                                                                                    <div className="dash-line"></div>
                                                                                    <ListItem className="count-ord-btn">
                                                                                        <ListItemText> 45</ListItemText>
                                                                                        <ListItemText
                                                                                            style={{
                                                                                                paddingLeft: "5%",
                                                                                            }}
                                                                                        >
                                                                                            <span>₹ </span>
                                                                                            35
                                                                                        </ListItemText>
                                                                                        <ListItemText>
                                                                                            {" "}
                                                                                            <span>₹ </span> 1575
                                                                                        </ListItemText>
                                                                                    </ListItem>
                                                                                </List>
                                                                            </div>

                                                                            <h3
                                                                                className=" mb-4 mt-4"
                                                                                style={{
                                                                                    color: "#000",
                                                                                    marginLeft: "27%",
                                                                                }}
                                                                            >
                                                                                <span style={{ color: " #c60800" }}>
                                                                                    F
                                                                                </span>
                                                                                ood Quantity
                                                                            </h3>
                                                                            <div className="container">
                                                                                <List
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        bgcolor: "background.paper",
                                                                                    }}
                                                                                ></List>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Modal.Body>
                                                            </form>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            ))}

                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-4"></div>
                                                <div className="col-lg-8">
                                                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText>
                                                                <b> Addtional service Price</b>
                                                            </ListItemText>
                                                            <ListItemText></ListItemText>
                                                        </ListItem>
                                                        {menulistapi1.additionalService &&
                                                            menulistapi1.additionalService.map((items, indexed) => (
                                                                <ListItem className="count-ord-btn" key={indexed}>
                                                                    <ListItemText style={{ paddingLeft: "5%" }}>
                                                                        <p className="odc-bill-txt"> {items.label}</p>
                                                                    </ListItemText>
                                                                    <ListItemText> : </ListItemText>
                                                                    <ListItemText>
                                                                        <span>₹ </span> {items.price}
                                                                    </ListItemText>
                                                                </ListItem>
                                                            ))}
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt"> Subtotal </p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {menulistapi1.subtotal}
                                                            </ListItemText>
                                                        </ListItem>
                                                        <div className="dash-line1"></div>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt">Tax</p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {menulistapi1.tax}
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt"> Delivery Charges</p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {menulistapi1.deliveryFee}
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt">Discount Amount</p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {menulistapi1.fixedDiscount}
                                                            </ListItemText>
                                                        </ListItem>
                                                        <div className="dash-line1"></div>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt"> Total </p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {menulistapi1.total}
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt"> Paid Amount </p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {menulistapi1.paid}
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt">Pendding Amount</p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <span>₹ </span> {finalTotel}
                                                            </ListItemText>
                                                        </ListItem>

                                                        <div className="dash-line1"></div>
                                                        <ListItem className="count-ord-btn">
                                                            <ListItemText style={{ paddingLeft: "5%" }}>
                                                                <p className="odc-bill-txt"> Final Payment Amount </p>
                                                            </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            {finalTotel === 0 ? (
                                                                <ListItemText>
                                                                    <p style={{ color: "#00d731" }}>
                                                                        All Payment Done Successfully
                                                                    </p>
                                                                </ListItemText>
                                                            ) : (
                                                                <ListItemText>
                                                                    <span>₹ </span> {finalTotel}{" "}
                                                                    <button
                                                                        className="odc-details-orbtn"
                                                                        onClick={handelRepayment}
                                                                        style={{ marginLeft: "20%" }}
                                                                    >
                                                                        Pay Now
                                                                    </button>
                                                                </ListItemText>
                                                            )}
                                                        </ListItem>
                                                        <div className="dash-line1"></div>
                                                        {finalTotel === 0 ? (
                                                            <ListItem className="count-ord-btn">
                                                                <ListItemText style={{ paddingLeft: "5%" }}>
                                                                    <p className="odc-bill-txt">
                                                                        {" "}
                                                                        Please Add your reviews
                                                                    </p>
                                                                </ListItemText>
                                                                <ListItemText> : </ListItemText>
                                                                <ListItemText>
                                                                <button  onClick={() => toggleVisibility1()} className="odc-star-review-btn-style">
                                                                Add Review <StarRateRoundedIcon />
                                    </button>
                                                                    
                                                                </ListItemText>
                                                            </ListItem>
                                                        ) : null}
                                                    </List>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {isVisible1 === true ? (
                                        <div>
                                            <div className="mb-4 mt-2" style={{ paddingTop: "25px" }}>
                                                <h3 className=" mb-4 mt-4" style={{ color: "#000" }}>
                                                    <span style={{ color: " #c60800" }}>Y</span>our Reviews
                                                </h3>
                                                <form>
                                                    <List>
                                                        <ListItem>
                                                            <ListItemText> Review Our Service </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <div className="form-group">
                                                                    <textarea
                                                                        id="form-message"
                                                                        value={sreview}
                                                                        name="sreview"
                                                                        onChange={(e) => setSreview(e.target.value)}
                                                                        placeholder="Write Review About Service"
                                                                        className="form-control"
                                                                        rows="2"
                                                                        required
                                                                    />
                                                                </div>
                                                            </ListItemText>
                                                        </ListItem>
                                                        <ListItem>
                                                            <ListItemText> Your Compliance </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <div className="form-group">
                                                                <select
                                                        id="select-field"
                                                        className="form-control menu-change-reason-input"
                                                        style={{ padding: "10px" }}
                                                        placeholder="Current Menu service"
                                                        value={compliance}
                                                        onChange={handleChange3}
                                                    >
                                                        {option &&
                                                            option.map((item, index) => (
                                                                <option key={index} value={item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))}
                                                    </select>
                                                                </div>
                                                            </ListItemText>
                                                        </ListItem>

                                                        <ListItem>
                                                            <ListItemText> Upload Compliance Image </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <div className="form-group">
                                                                    <IconButton
                                                                        color="primary"
                                                                        aria-label="upload picture"
                                                                        component="label"
                                                                    >
                                                                        <input
                                                                            hidden
                                                                            name="cimage"
                                                                            // value={cimage}
                                                                            onChange={imageSubmite}
                                                                            accept="image/*"
                                                                            type="file"
                                                                            required
                                                                        />

                                                                        {/* <Ratings/> */}
                                                                        <PhotoCamera />
                                                                    </IconButton>
                                                                    <Button variant="primary" onClick={handleView}>
                                                                        View Image
                                                                    </Button>
                                                                    <Modal show={imshow} onHide={handleUnview}>
                                                                        <Modal.Header>
                                                                            <Modal.Title
                                                                                style={{
                                                                                    color: "#000",
                                                                                    textAlign: "center",
                                                                                }}
                                                                            >
                                                                                Image Preview
                                                                            </Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body
                                                                            style={{
                                                                                color: "#000",
                                                                                textAlign: "center",
                                                                            }}
                                                                        >
                                                                            {imageurl ? (
                                                                                <Image
                                                                                    style={{
                                                                                        width: "100%",
                                                                                    }}
                                                                                    src={imageurl}
                                                                                    rounded
                                                                                />
                                                                            ) : (
                                                                                "No image is Uploaded"
                                                                            )}
                                                                        </Modal.Body>
                                                                        <Modal.Footer>
                                                                            <Button onClick={handleUnview}>
                                                                                Close
                                                                            </Button>
                                                                        </Modal.Footer>
                                                                    </Modal>
                                                                </div>
                                                            </ListItemText>
                                                        </ListItem>

                                                        <ListItem>
                                                            <ListItemText> Rate Us </ListItemText>
                                                            <ListItemText> : </ListItemText>
                                                            <ListItemText>
                                                                <div>
                                                                    <div
                                                                        className="star-rating"
                                                                        style={{
                                                                            color: getRatingColor(),
                                                                        }}
                                                                        onMouseLeave={() => handleRatingHoverLeave()}
                                                                        required
                                                                    >
                                                                        {renderStars()}
                                                                    </div>
                                                                    {rating > 0 && (
                                                                        <p>
                                                                            You have rated us {rating}{" "}
                                                                            {rating === 1 ? "star" : "stars"}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </ListItemText>
                                                        </ListItem>
                                                    </List>
                                                    {/* <Ratings/> */}
                                                    {/* <Button className='details-btn-pending' style={{color:'#fff'}} endIcon={<StarIcon />} >Submite Review</Button> */}
                                                    <Button
                                                        className="details-btn-pending rating-btn"
                                                        onClick={submiteReview}
                                                        endIcon={<StarIcon />}
                                                    >
                                                        Submit Review
                                                    </Button>
                                                </form>
                                            </div>
                                        </div>
                                    ) : null}
                                    {/* ))} */}
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
        </div>
    );
}
