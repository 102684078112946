// react

import React from "react";
import HomeTop from "./componets/HomeTop";
// import AppBanner from "../industry/blocks/AppBanner";
import Mobile from "./../../img/Mobile-app1.png";
import PlayStore from "./../../img/play-store1.png";
import AppleStore from "./../../img/apple-store1.png";
// import MobileEffect from '../industry/blocks/MobileEffect';
// import Mobile from './../../img/Mobile-app.png'
import { Link } from "react-router-dom";
import BlockBrands from "../industry/blocks/BlockBrands";
// import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
// import { MDBIcon } from "mdb-react-ui-kit";
// import { MDBTooltip } from "mdb-react-ui-kit";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Popover from "react-bootstrap/Popover";

function HomeMain() {
    // const address = (
    //     <Popover id="popover-basic">
    //         <Link to="#">
    //             {" "}
    //             <Popover.Body>
    //                 <strong> HOGIST TECHNOLOGIES PRIVATE LIMITED.</strong>
    //                 <br />
    //                 110, Lower Ground Floor,
    //                 <br /> Uthamar Gandhi Road, Nungambakkam,
    //                 <br /> Chennai, Tamil Nadu 600034.
    //             </Popover.Body>{" "}
    //         </Link>
    //     </Popover>
    // );

    // const mobile = (
    //     <Popover id="popover-basic">
    //         <Link to="#">
    //             {" "}
    //             <Popover.Body>+91 - 9962667733</Popover.Body>{" "}
    //         </Link>
    //     </Popover>
    // );

    // const email = (
    //     <Popover id="popover-basic">
    //         <Link to="#">
    //             {" "}
    //             <Popover.Body>support@hogist.com</Popover.Body>{" "}
    //         </Link>
    //     </Popover>
    // );

    return (
        <div style={{ color: "#000", backgroundImage: 'url("images/body-bg.jpg")', backgroundSize: " 100% 100%" }}>
            <div>
                <HomeTop />
            </div>
            {/* Services */}

            <div style={{ marginTop: "5%" }}>
                <div className="container">
                    <div className="heading_container heading_center">
                        <div>
                            <h2>Our Best Popular Services</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4 mx-auto">
                            <div className="box">
                                <div className="img-box">
                                    <img
                                        src="https://img.freepik.com/premium-photo/idli-vada-with-sambar-pr-sambhar-also-called-medu-wada-rice-cake_466689-78734.jpg?w=360"
                                        className="box-img serv-style-img zoom-effect"
                                        alt=""
                                    />
                                </div>
                                <div className="detail-box">
                                    <h4 style={{ textAlign: "center" }} className="mt-4">
                                        Breakfast
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 mx-auto">
                            <div className="box">
                                <div className="img-box">
                                    <img
                                        src="https://t3.ftcdn.net/jpg/02/75/39/28/360_F_275392807_Hoqw6KdwQHbJM3n5bZjIla67ukxkikVp.jpg"
                                        className="box-img serv-style-img zoom-effect"
                                        alt=""
                                    />
                                </div>
                                <div className="detail-box">
                                    <h4 style={{ textAlign: "center" }} className="mt-4">
                                        Lunch
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 mx-auto">
                            <div className="box">
                                <div className="img-box">
                                    <img
                                        src="https://images.unsplash.com/photo-1585937421612-70a008356fbe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW5kaWFuJTIwZm9vZHxlbnwwfHwwfHw%3D&w=1000&q=80"
                                        className="box-img serv-style-img zoom-effect"
                                        alt=""
                                    />
                                </div>
                                <div className="detail-box">
                                    <h4 style={{ textAlign: "center" }} className="mt-4">
                                        Dinner
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* App */}

            <div
                style={{
                    color: "#fff",
                    backgroundImage: 'url("images/app-bg.png")',
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    padding: "225px 0 175px 0",
                }}
            >
                <div className="container">
                    <div className="col-md-9 mx-auto">
                        <div className="row mt-3 mb-4">
                            <div className="col-md-7 col-lg-8 mt-4">
                                <div className="detail-box txt-align-center">
                                    <h2 className="txt-align-center" style={{ fontSize: "54px" }}>
                                        <span style={{ fontSize: "29px" }}> Get the All New</span> <br />
                                        Hogist App
                                    </h2>
                                    <p>
                                        Order from your favourite vendor and track your order on the go with the all new
                                        HOGIST App.
                                    </p>
                                    <div className="app_btn_box">
                                        <Link to="#" className="mr-1">
                                            <img className=" " src={PlayStore} alt="" />
                                        </Link>
                                        <Link to="#">
                                            <img className=" " src={AppleStore} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-lg-4">
                                <div>
                                    <img className="rotate mbwidth" src={Mobile} alt="Example Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* About */}
            <div>
                <div className="container">
                    <div className="col-md-11 col-lg-10 mx-auto">
                        <div className="heading_container heading_center">
                            <h2>About Us</h2>
                        </div>
                        <div className="box">
                            <div className="col-md-7 mx-auto">
                                <div className="img-box">
                                    <img src="images/about-img.png" className="box-img1" alt="" />
                                </div>
                            </div>
                            <div className="detail-box">
                                <div className="txt-align-center">
                                    <h1>Welcome To Hogist</h1>
                                    <p className="about__text">The Best Catering service in Chennai</p>
                                </div>
                                <div className="typography abpara">
                                    <p>
                                        We have a strong team to take care of complete operations & technology enabling
                                        the customer to sit and relax. We connect with people from all walks of life. Be
                                        it a small-scale caterer to a five-star hotel chef, anybody who is looking out
                                        for food would feel HOGIST beneficial. With HOGIST providing a platform for
                                        online bulk booking, it becomes easier for people to choose and plan their
                                        events accordingly without having to depend on anyone. With customer
                                        satisfaction being our goal, we at HOGIST strive hard to bring multiple cuisines
                                        under one roof at an affordable cost with top-class customer service.
                                    </p>
                                </div>
                                <Link className="nav-arrow txt-align-center" to="/about-us">
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Clints */}

            <div style={{ marginTop: "8%" }}>
                <div className="container">
                    <div className="heading_container heading_center">
                        <h2>Our Successfull Clients</h2>
                    </div>

                    <BlockBrands />
                </div>
            </div>
        </div>
    );
}

export default HomeMain;
