import { ORDERDETAILS } from "./index";

const initialState = null;

export default function orderdetailsReducer(state = initialState, action) {
    if (action.type === ORDERDETAILS) {
        return action.orderdetails;
    }

    return state;
}
