import orderdetailsReducer from "./orderdetailsReducer";

// export * from './adduserActions';

export const ORDERDETAILS = "ORDERDETAILS";
export const orderdetailsChange = (orderdetails) => ({
    type: ORDERDETAILS,
    orderdetails,
});

export default orderdetailsReducer;
