export default [
    {
        id: 1,
        name: "Power Tools",
        url: "",
        children: [
            { id: 2, name: "Engravers", url: "" },
            { id: 3, name: "Drills", url: "" },
            { id: 4, name: "Wrenches", url: "" },
            { id: 5, name: "Plumbing", url: "" },
            { id: 6, name: "Wall Chaser", url: "" },
            { id: 7, name: "Pneumatic Tools", url: "" },
            { id: 8, name: "Milling Cutters", url: "" },
        ],
    },
    {
        id: 9,
        name: "Hand Tools",
        url: "",
        children: [
            { id: 10, name: "Screwdrivers", url: "" },
            { id: 11, name: "Handsaws", url: "" },
            { id: 12, name: "Knives", url: "" },
            { id: 13, name: "Axes", url: "" },
            { id: 14, name: "Multitools", url: "" },
            { id: 15, name: "Paint Tools", url: "" },
        ],
    },
    {
        id: 16,
        name: "Garden Equipment",
        url: "",
        children: [
            { id: 17, name: "Motor Pumps", url: "" },
            { id: 18, name: "Chainsaws", url: "" },
            { id: 19, name: "Electric Saws", url: "" },
            { id: 20, name: "Brush Cutters", url: "" },
        ],
    },
    {
        id: 21,
        name: "Machine Tools",
        url: "",
        children: [
            { id: 22, name: "Thread Cutting", url: "" },
            { id: 23, name: "Chip Blowers", url: "" },
            { id: 24, name: "Sharpening Machines", url: "" },
            { id: 25, name: "Pipe Cutters", url: "" },
            { id: 26, name: "Slotting machines", url: "" },
            { id: 27, name: "Lathes", url: "" },
        ],
    },
    {
        id: 28,
        name: "Instruments",
        url: "",
        children: [
            { id: 29, name: "Welding Equipment", url: "" },
            { id: 30, name: "Power Tools", url: "" },
            { id: 31, name: "Hand Tools", url: "" },
            { id: 32, name: "Measuring Tool", url: "" },
        ],
    },
    { id: 33, name: "Workbenches", url: "" },
    { id: 34, name: "Presses", url: "" },
    { id: 35, name: "Spray Guns", url: "" },
    { id: 36, name: "Riveters", url: "" },
];
