/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SpecialOrderForm from "./SpecialOrderForm";
import { Link } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";
import Modal from "react-bootstrap/Modal";
import Tooltip from "@mui/material/Tooltip";
import Select from "react-select";
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import { apiurl } from "../../../api/api_url";
import { useHistory } from "react-router-dom";
import { formattedDate, convertTime } from "../../../helper/dateformate";
import imageCompression from "browser-image-compression";
import Pagination from "@mui/material/Pagination";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SpecialOrderAdd() {
    const history = useHistory();
    const [menulistapi, setMenulistapi] = useState([]);
    const [menulistapi1, setMenulistapi1] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [showed, setShowed] = React.useState(false);
    //
    const [ename, setEname] = useState("");
    const [etype, setEtype] = useState("");
    const [edate, setEdate] = useState("");
    const [etime, setEtime] = useState("");
    const [bcount, setBcount] = useState("");
    const [mobile, setMobile] = useState("");
    const [cname, setCname] = useState("");
    const [address, setAddress] = useState("");
    const [menusuggestion, setMenusuggestion] = useState(null);
    const [exinformation, setExinformation] = useState("");
    const [sporderlist, setSporderlist] = useState([]);
    const [errors, setErrors] = useState({});
    const [imageurl, setImageUrl] = useState(null);
    //

    const [pages, setPages] = useState({ pages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;

    const fileSubmite = async (e) => {
        const selectedFile = e.target.files[0];

        if (!selectedFile) {
            console.error("No file selected.");
            return;
        }

        // Prepare the request payload
        const reqOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ filename: selectedFile.name, type: selectedFile.type }),
        };

        try {
            // Request a pre-signed URL for uploading the file
            const response = await fetch(`${apiurl}/upload/url`, reqOptions);
            const data = await response.json();

            if (data.url) {
                // Upload the file using the pre-signed URL
                const uploadResponse = await fetch(data.url, {
                    method: "PUT",
                    body: selectedFile,
                });

                if (uploadResponse.ok) {
                    const fileUrl = data.url.split("?")[0];
                    setMenusuggestion(fileUrl); // Set the file URL in your state or wherever needed
                    console.log(fileUrl);
                } else {
                    console.error("File upload failed.");
                }
            } else {
                console.error("Failed to get pre-signed URL for upload.");
            }
        } catch (error) {
            console.error("File upload process failed:", error);
        }
    };

    //validation
    const validateForm = () => {
        let formIsValid = true;
        const errors = {};

        if (!ename) {
            errors.ename = "Enter your Event Name";
            formIsValid = false;
        }
        if (!etype) {
            errors.etype = "Enter your Event Type";
            formIsValid = false;
        }
        if (!edate) {
            errors.edate = "Enter your Event Date";
            formIsValid = false;
        }
        if (!etime) {
            errors.etime = "Enter your Event Time";
            formIsValid = false;
        }
        if (!bcount) {
            errors.bcount = "Enter your Count";
            formIsValid = false;
        }
        if (!mobile) {
            errors.mobile = "Enter your Mobile Number";
            formIsValid = false;
        }
        if (!cname) {
            errors.cname = "Enter your Name";
            formIsValid = false;
        }
        if (!address) {
            errors.address = "Enter your Address";
            formIsValid = false;
        }
        if (!menusuggestion) {
            errors.menusuggestion = "Enter your Menu";
            formIsValid = false;
        }
        if (!exinformation) {
            errors.exinformation = "Enter your Service Need";
            formIsValid = false;
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmite = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setOpen(true);
        }
    };
    const handleClosed = () => {
        setShowed(false);
        setEname("");
        setEtype("");
        setEdate("");
        setEtime("");
        setBcount("");
        setMobile("");
        setCname("");
        setAddress("");
        setMenusuggestion("");
        setExinformation("");
    };

    // const notify = () => {
    const notify = async () => {
        const detail_list = {
            ename,
            etype,
            edate,
            etime,
            bcount,
            mobile,
            cname,
            address,
            menusuggestion,
            exinformation,
        };

        setSporderlist([
            ...sporderlist,
            {
                ename,
                etype,
                edate,
                etime,
                bcount,
                mobile,
                cname,
                address,
                menusuggestion,
                exinformation,
            },
        ]);
        if (validateForm()) {
            setShowed(true);
        }
        setOpen(false);
        setIsVisible(false);
        // {
        //     state = [sporderlist];
        // }
        if (validateForm()) {
            await fetch(`${apiurl}/order-special`, {
                method: "POST",
                body: JSON.stringify({
                    eventName: ename,
                    eventType: etype,
                    eventDate: edate,
                    eventTime: convertTime(etime),
                    count: bcount,
                    name: cname,
                    mobile: mobile,
                    address: address,
                    menuInfo: menusuggestion,
                    addInfo: exinformation,
                }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                    setMenulistapi(json);
                });

            // console.log(etime);
        }
    };
    // console.log(menulistapi);
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    //

    useEffect(() => {
        getApiData(currentPage);
    }, []);
    // order
    const getApiData = async (pageno) => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const response = await fetch(`${apiurl}/orders?list=special&page=${pageno}&limit=${limit}`, {
            headers,
            method: "GET",
        }).then((response) => response.json());

        // update the state
        setMenulistapi1(response.data);
        setPages(response);
    };

    const paginate = (event, value) => {
        getApiData(value);
        setCurrentPage(value);
    };

    // const handleChange1 = (e) => {
    //     setEname(e.target.value);
    // };
    // const handleChange2 = (e) => {
    //     setEtype(e.target.value);
    // };
    // const handleChange3 = (e) => {
    //     setEdate(e.target.value);
    // };
    // const handleChange4 = (e) => {
    //     setEtime(e.target.value);
    // };
    // const handleChange5 = (e) => {
    //     setBcount(e.target.value);
    // };
    // const handleChange6 = (e) => {
    //     setMobile(e.target.value);
    // };
    // const handleChange7 = (e) => {
    //     setCname(e.target.value);
    // };
    // const handleChange8 = (e) => {
    //     setMenusuggestion(e.target.value);
    // };
    // const handleChange9 = (e) => {
    //     setExinformation(e.target.value);
    // };

    const handleSelectChange = (selected) => {
        setExinformation(selected);
    };

    const handleSelectTime = (e) => {
        setEtime(e.target.value);
    };

    const option = [
        { value: "No Service Needed", label: "No Service Needed" },
        { value: "Leaf service", label: "Leaf service" },
        { value: "Buffey Service", label: "Buffey Service" },
        { value: "Plate Service", label: "Plate Service" },
        { value: "Servcie Persons", label: "Servcie Persons" },
    ];
    // console.log(exinformation);

    // // const myorderlist=menulistapi1.map(item=>item.serviceDate)
    const myorderlist1 = menulistapi1.map((item) => item.createdAt);
    // // const specialordername=menulistapi1.map(item=>item.special)
    // const filtered = menulistapi1.filter(service => {
    //     return service.createdAt
    //   });
    // console.log(myorderlist);
    const orderdate = (currentDate) => {
        const date = new Date(currentDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        return day - month - year;
    };

    // console.log(myorderlist1);

    //   const date1 = new Date(myorderlist);
    // Extract the date and time components using Date methods

    //
    //   const year1 = date1.getFullYear();
    //   const month1 = date1.getMonth() + 1;
    //   const day1 = date1.getDate();

    const menuDetails = (itemid) => {
        // dispatch(orderdetailsChange( myorderlist))

        // const menucountdata =
        // menudate.services.filter(item=> item.id=="your ID")

        // history.push("/site/my-order-count", menucountdata={ menudateid }) ;
        history.push("/site/special-order-details/" + itemid);

        // console.log(menucountdata);
    };

    return (
        <div>
            <Card>
                <CardContent>
                    <Box style={{ textAlign: "center", marginTop: "35px", marginBottom: "55px" }}>
                        <Tooltip title=" Create Your Special Order">
                            <Fab className="order-add-btn" aria-label="add" onClick={toggleVisibility}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                        <p className="mt-4">Order Your Special Orders 7 Day before.</p>
                        {isVisible === true ? (
                            <div>
                                <form>
                                    <div className="container">
                                        <div className=" row event-add-form">
                                            <Card className="col-lg-6">
                                                <CardContent>
                                                    <Typography
                                                        sx={{ flex: 1 }}
                                                        variant="h6"
                                                        component="div"
                                                        className="sporder-tit-txt"
                                                    >
                                                        Add event details
                                                    </Typography>
                                                    <div className="form-group">
                                                        <input
                                                            id="event-name"
                                                            value={ename}
                                                            name=" event_name"
                                                            type="text"
                                                            className="form-control rginputtxt"
                                                            placeholder="Event Name"
                                                            onChange={(e) => setEname(e.target.value)}
                                                            // required
                                                        />
                                                        {errors.ename && <p style={{ color: "red" }}>{errors.ename}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id="event-type"
                                                            value={etype}
                                                            type="text"
                                                            name="event_type"
                                                            className="form-control rginputtxt"
                                                            placeholder="Menu Type"
                                                            onChange={(e) => setEtype(e.target.value)}
                                                            // required
                                                        />
                                                        {errors.etype && <p style={{ color: "red" }}>{errors.etype}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id="event-date"
                                                            value={edate}
                                                            name=" event_date"
                                                            type="date"
                                                            className="form-control rginputtxt"
                                                            placeholder="Event Date"
                                                            onChange={(e) => setEdate(e.target.value)}
                                                            // required
                                                        />
                                                        {errors.edate && <p style={{ color: "red" }}>{errors.edate}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id=" event_time"
                                                            type="time"
                                                            name="event_time"
                                                            value={etime}
                                                            className="form-control rginputtxt"
                                                            placeholder="Event Time"
                                                            onChange={handleSelectTime}
                                                            // required
                                                        />
                                                        {errors.etime && <p style={{ color: "red" }}>{errors.etime}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id="box_count"
                                                            value={bcount}
                                                            name="box_count"
                                                            type="number"
                                                            className="form-control rginputtxt"
                                                            placeholder="Total Count"
                                                            onChange={(e) => setBcount(e.target.value)}
                                                            // required
                                                        />
                                                        {errors.bcount && (
                                                            <p style={{ color: "red" }}>{errors.bcount}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id="mobile"
                                                            type="phone"
                                                            name="mobile"
                                                            value={mobile}
                                                            className="form-control rginputtxt"
                                                            placeholder="Mobile Number"
                                                            onChange={(e) => setMobile(e.target.value)}
                                                            // required
                                                        />
                                                        {errors.mobile && (
                                                            <p style={{ color: "red" }}>{errors.mobile}</p>
                                                        )}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id="client_name"
                                                            type="name"
                                                            name="client_name"
                                                            value={cname}
                                                            className="form-control rginputtxt"
                                                            placeholder="Enter your Name"
                                                            onChange={(e) => setCname(e.target.value)}
                                                            // required
                                                        />
                                                        {errors.cname && <p style={{ color: "red" }}>{errors.cname}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            id="address"
                                                            type="text"
                                                            name="address"
                                                            value={address}
                                                            className="form-control rginputtxt"
                                                            placeholder="Your Address"
                                                            onChange={(e) => setAddress(e.target.value)}
                                                        />
                                                        {errors.address && (
                                                            <p style={{ color: "red" }}>{errors.address}</p>
                                                        )}
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <input
                                                            id="menu_suggestion"
                                                            type="text"
                                                            name="menu_suggestion"
                                                            value={menusuggestion}
                                                            className="form-control rginputtxt"
                                                            placeholder="Menu Suggestion"
                                                            onChange={(e) => setMenusuggestion(e.target.value)}
                                                        />
                                                        {errors.menusuggestion && (
                                                            <p style={{ color: "red" }}>{errors.menusuggestion}</p>
                                                        )}
                                                    </div> */}

                                                    {/* test */}

                                                    {/* test */}
                                                    <div className="form-group">
                                                        {/* <input
                                                        id="additional_information"
                                                        type="text"
                                                        name="additional_information"
                                                        value={exinformation}
                                                        className="form-control rginputtxt"
                                                        placeholder="Additional Information"
                                                        onChange={(e) => setExinformation(e.target.value)}
                                                    /> */}

                                                        <Select
                                                            options={option}
                                                            value={exinformation}
                                                            onChange={handleSelectChange}
                                                            isMulti={true}
                                                            className=" menu-change-reason-input txt-align-intial addinfotxt"
                                                            placeholder="Additional Services"
                                                        />
                                                        {errors.exinformation && (
                                                            <p style={{ color: "red" }}>{errors.exinformation}</p>
                                                        )}
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Uploade your menu</label>

                                                        <input
                                                            name="menusuggestion"
                                                            // value={menusuggestion}
                                                            onChange={fileSubmite}
                                                            // accept="image/*"
                                                            type="file"
                                                            className="sp-fileuploade-input-style"
                                                            // required
                                                        />

                                                        {errors.menusuggestion && (
                                                            <p style={{ color: "red" }}>{errors.menusuggestion}</p>
                                                        )}
                                                    </div>

                                                    <div
                                                        style={{
                                                            textAlign: "center",
                                                            paddingBottom: "14%",
                                                            paddingTop: "5%",
                                                        }}
                                                    >
                                                        <Button
                                                            autoFocus
                                                            color="inherit"
                                                            type="submit"
                                                            className="safety-check-popup-btn"
                                                            onClick={handleSubmite}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) : null}
                    </Box>

                    <div>
                        {menulistapi1?.length > 0
                            ? menulistapi1.map((item, index) => {
                                  return (
                                      <div key={index} onClick={() => menuDetails(item._id)}>
                                          <div className="product-card order-list-mu-style mrg-style">
                                              <div className=" container- fluid row ">
                                                  <div display-flex="true" className="col-lg-6 order-details-style">
                                                      <div style={{ marginTop: "35px" }}>
                                                          <MDBIcon
                                                              fas
                                                              icon="calendar-check"
                                                              style={{ marginRight: "25px", fontSize: "24px" }}
                                                          />
                                                          <span>Service Date</span> : {formattedDate(item.serviceDate)}
                                                      </div>

                                                      <div style={{ marginTop: "-66px" }}>
                                                          <div
                                                              className="product-card__name"
                                                              style={{ marginTop: "17%", marginBottom: "9%" }}
                                                          >
                                                              <MDBIcon
                                                                  fas
                                                                  icon="utensils"
                                                                  style={{
                                                                      marginRight: "25px",
                                                                      color: "#000",
                                                                      fontSize: "24px",
                                                                  }}
                                                              />
                                                              <div className="menu-txt"> {item.special.eventType}</div>
                                                              {/* <div className="menu-txt">Dinner</div> */}
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className=" col-lg-6 product-card__info order-details-style">
                                                      <div className="product-card__name txt-colr">
                                                          <div style={{ marginTop: "30px" }}>
                                                              <MDBIcon
                                                                  far
                                                                  icon="calendar-alt"
                                                                  style={{ marginRight: "25px", fontSize: "24px" }}
                                                              />
                                                              <span>Ordered Date</span> :{" "}
                                                              {formattedDate(item.createdAt)}
                                                          </div>
                                                          <div style={{ marginTop: "26px" }}>
                                                              <MDBIcon
                                                                  fas
                                                                  icon="boxes"
                                                                  style={{ marginRight: "25px", fontSize: "24px" }}
                                                              />
                                                              <span>
                                                                  {`${item.special.eventType} Count`} :{" "}
                                                                  {item.special.count}{" "}
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}

                        <Pagination
                            count={pages.pages}
                            page={currentPage}
                            onChange={paginate}
                            variant="outlined"
                            color="primary"
                            style={{ justifyContent: "center", display: "flex", marginTop: "6%" }}
                        />
                    </div>
                </CardContent>
            </Card>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Please Confirm your Order"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you need to confirm this order count.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" onClick={handleClose}>
                        No
                    </Button>
                    <Button type="submit" onClick={notify}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showed}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    {" "}
                    <MDBIcon fas icon="check-circle success-mbd-icon" />
                    {"Ordered Success!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Your Order Request Sended Successfully !
                    </DialogContentText>
                </DialogContent>

                <Button
                    type="submit"
                    style={{ textAlign: "center" }}
                    className="sporder-confirm-btn-style"
                    onClick={handleClosed}
                >
                    ok
                </Button>
            </Dialog>
        </div>
    );
}
