import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Link } from "react-router-dom";

export default function ServiceSlideContent() {
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="serv-bg-color ">
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab className="pd-right service-tab-txt" label="Industrial Catering" value="1" />
                            <Tab className="pd-right service-tab-txt" label="Corporate Catering" value="2" />
                            <Tab className="pd-right service-tab-txt" label="Out Door Catering" value="3" />
                            <Tab className="pd-right service-tab-txt" label="Cafeteria" value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className="block block-banner">
                            <div className="container-fluid">
                                <div className="row pdside pt-4">
                                    <div className="col-lg-8">
                                        <h1>Best Industrial Catering service in Chennai</h1>

                                        <div className="typography abpara">
                                            <p>
                                                <b>Best and the only place for both Outsource & In-house Catering</b>
                                            </p>
                                            <p>
                                                Yes, you found a perfect solution if you are a business of any size
                                                looking to streamline your catering ordering process and provide a
                                                variety of food options for your team and clients. Ready to serve
                                                anywhere in Chennai, Coimbatore & Bangalore. Now expanding in other
                                                metropolitan cities with your Love & Support
                                            </p>
                                            <p>
                                                Industrial caterers providing quality food services to factories and
                                                offices nearby. Specialized in serving large-scale meals to meet the
                                                dietary needs and preferences of employees. Here at Hogist, you can
                                                explore the services offered by all the industrial caterers in Chennai
                                                and many more features.
                                            </p>
                                            <div className="cntbtn">
                                                <Link to="/" className="logbtn">
                                                    Contact Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="teammate__avatar">
                                            <img
                                                src="images/ser1.png"
                                                alt="Industrial Catering"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel value="2">
                        <div className="block block-banner">
                            <div className="container-fluid">
                                <div className="row pdside pt-4">
                                    <div className="col-lg-4">
                                        <div className="teammate__avatar">
                                            <img
                                                src="images/ser2.png"
                                                alt="Corporate Catering"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-8">
                                        <h1>Best Corporate Catering service in Chennai</h1>

                                        <div className="typography abpara">
                                            <p>
                                                Hogist – Corporate catering. Compatible Operations enabled by Advanced
                                                Technology
                                            </p>
                                            <p>
                                                We are Perfect Solution. Now stop worrying about Delivery delays, food
                                                quantity & quality, hygiene, and even staff shortages. Download the Best
                                                Corporate Catering App.
                                            </p>
                                            <div className="cntbtn">
                                                <Link to="/" className="logbtn">
                                                    Contact Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel value="3">
                        <div className="block block-banner">
                            <div className="container-fluid">
                                <div className="row pdside pt-4">
                                    <div className="col-lg-8">
                                        <h1>Best Out Door Catering service in Chennai</h1>

                                        <div className="typography abpara">
                                            <p>
                                                As we all know, whatever the event, food holds the highest value all
                                                the time. It is not an easy job to find the best caterers in Chennai. To
                                                make this part more simple for you, Hogist has introduced the online
                                                catering services booking platform in Chennai, where you can narrow down
                                                the best caterers near your place providing a reliable vegetarian and
                                                non-vegetarian catering service with fulfilling all your requirements.
                                                The appealing part here is that you have an option to mix and match the
                                                menu items that you think will suit your requirements well from our
                                                extensive list. And in addition to that, you can even select any number
                                                of menu items in quantity from these categories. To make you more clear
                                                about what you are ordering, you can see the images of dishes, which
                                                will ease your selection. So, now stop wasting your time searching for
                                                "catering services near me" when HOGIST has the answer for "best
                                                catering services near me".
                                            </p>
                                            <p>
                                                The price per plate would be different for a marriage and a party. It
                                                depends on the type of occasion, plate quantity, and cuisine you are
                                                looking for. So please ensure that you are providing accurate details
                                                while choosing the caterers from Hogist. We believe that booking the
                                                best caterer in Chennai for your next big event should be as simple as
                                                ordering a takeaway on your mobile. This is why we have revamped our
                                                website to make it hassle-free and seamless for you. We consider our
                                                customers to be our backbone. Thus, we treat them with the highest priority
                                                by giving our time by learning and listening to what they like and what
                                                they don't and make them feel like their voice is heard.
                                            </p>
                                            <div className="cntbtn">
                                                <Link to="/" className="logbtn">
                                                    Contact Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="teammate__avatar">
                                            <img
                                                src="images/ser3.png"
                                                alt="Out Door Catering"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel value="4">
                        <div className="block block-banner">
                            <div className="container-fluid">
                                <div className="row pdside pt-4">
                                    <div className="col-lg-4">
                                        <div className="teammate__avatar">
                                            <img
                                                src="images/ser4.png"
                                                alt="Cafeteria"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-8">
                                        <h1>Best Cafeteria service in Chennai</h1>

                                        <div className="typography abpara">
                                            <p>
                                            We provide the best Cafeteria Setup at your Space with technology Support. We are Perfect Solution. Now stop worrying about food
                                                quantity & quality, hygiene, and even staff shortage.
                                            </p>
                                           
                                            <div className="cntbtn">
                                                <Link to="/" className="logbtn">
                                                    Contact Now
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}
