import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import { Link, useHistory } from "react-router-dom";
import theme from "../../../data/theme";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
//
import FormControlLabel from "@mui/material/FormControlLabel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import FastfoodIcon from "@mui/icons-material/Fastfood";
//
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { removemenuChange, updateMenuCount } from "../../../store/selectedmenu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EggIcon from "@mui/icons-material/Egg";
import { apiurl } from "../../../api/api_url";
import { ToastContainer, toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Addcard(props) {
    const { menuadded, odcuser, guestuser } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const [cardState, setCardState] = useState(
        menuadded.length > 0 ? menuadded.map((item) => ({ count: 25, isEditing: false })) : null
    );
    const [isVisible, setIsVisible] = useState(-1);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [openAddservice, setOpenAddservices] = React.useState(false);
    const [checkedValues, setCheckedValues] = useState([]);

    const handleCheckboxChange = (value) => {
        if (checkedValues.includes(value)) {
            setCheckedValues(checkedValues.filter((v) => v !== value));
        } else {
            setCheckedValues([...checkedValues, value]);
        }
    };

    const servicelist = [
        "Only Delivery",
        "Decoration",
        "DJ's",
        "Musicians",
        "Audio-Visual and Technical Support",
        "Transportation",
        "Clean-Up and Waste Management",
        "Birthday Cake Arrangement",
        "Beautician",
        "Costumer",
        "Leaf Service",
        "Banquet Service/Chef & Dish",
        "Service Person",
        "Tables",
    ];

    const breadcrumb = [
        { title: "Home", url: "/odc/home" },
        // { title: "Cart", url: "/odc/addedcart" },
        { title: "Cart", url: "" },
    ];

    const handleInputChange = (indxed, e, item) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 25) {
            const newCardState = [...cardState];
            // newCardState[indxed].count = value;
            newCardState[indxed] = {
                ...newCardState[indxed],
                count: parseInt(value, 10),
                isEditing: true, // Set isEditing to true when count changes
            };
            setCardState(newCardState);
            const menuPrice = cardState[indxed].count * item.price;
            // Dispatch the input count to the Redux store
            dispatch(updateMenuCount(menuadded[indxed]._id, cardState[indxed].count, menuPrice));
        }
        // console.log(cardState);
    };

    const handleIncrement = (indxed, e, item) => {
        const value = e.target.value;
        const newCardState = [...cardState];
        newCardState[indxed].count += 1;
        setCardState(newCardState);
        const menuPrice = cardState[indxed].count * item.price;
        dispatch(updateMenuCount(menuadded[indxed]._id, cardState[indxed].count, menuPrice));
    };

    const handleDecrement = (indxed, e, item) => {
        const value = e.target.value;
        const newCardState = [...cardState];
        newCardState[indxed].count = Math.max(25, newCardState[indxed].count - 1);
        setCardState(newCardState);
        const menuPrice = cardState[indxed].count * item.price;
        dispatch(updateMenuCount(menuadded[indxed]._id, cardState[indxed].count, menuPrice));
    };

    //
    const handleClickOpenMenu = (indxed) => {
        setIsVisible(indxed === isVisible ? -1 : indxed);
        setOpenMenu(true);
    };

    const handelRemovemenu = (id) => {
        const itemId = id;
        dispatch(removemenuChange(itemId));
        // console.log({ itemId });
    };

    const handleClickOpenservice = (e) => {
        e.preventDefault();
        setOpenAddservices(true);
    };

    const handleCloseMenu = () => {
        setOpenMenu(false);
        setOpenAddservices(false);
    };
    const calculateTotalPrice = () => {
        let subtotal = 0;
        cardState &&
            cardState.forEach((item, indxed) => {
                // Check if menuadded[indxed] is defined and contains a 'price' property
                if (menuadded[indxed] && "price" in menuadded[indxed]) {
                    subtotal += item.count * menuadded[indxed].price;
                }
            });
        return subtotal;
    };

    // console.log(menuadded);
    const toggleVisibility = async (e) => {
        e.preventDefault();
        const services = menuadded.map((item) => ({ _id: item._id, count: item.count }));
        console.log(services, odcuser);
        if (odcuser && odcuser.fullName) {
            await fetch(`${apiurl}/odc-cart`, {
                method: "POST",
                body: JSON.stringify({
                    services,
                    serviceDate: guestuser.date,
                    additional: checkedValues,
                }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: localStorage.getItem("tokenodc"),
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);
                });
            history.push("/odc/checkout");
        } else {
            toast.error("Please Login First", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            history.push("/odc/login");
        }
    };

    return (
        <div>
            <Helmet>
                <title>{`Cart — ${theme.name}`}</title>
            </Helmet>
            <div
                style={{
                    backgroundImage:
                    'url("../images/odc-formbg.png")',
                }}
            >
                <div style={{ backgroundColor: "rgb(255 255 255 / 98%)" }}>
                    <div style={{ marginLeft: "-260px" }}>
                        <PageHeader header="Cart" breadcrumb={breadcrumb} />
                    </div>

                    <div className="container-fluid" style={{ padding: "0% 7%" }}>
                        <div className="odc-addcart-menu-list-style">
                            <div className="row mb-4">
                                <div className="col-lg-8">
                                    <p className="min-p-style">** Minimum 25 Count to order **</p>
                                    {menuadded.length > 0 ? (
                                        menuadded &&
                                        menuadded.map((item, indxed) => (
                                            <div key={indxed}>
                                                <div className="odc-cart-slmenucard-style">
                                                    <div className="col-lg-3">
                                                        <img
                                                            src={item.image}
                                                            alt="menu image"
                                                            style={{ width: "100%" }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        {" "}
                                                        <div className="d-flex" style={{ alignItems: "baseline" }}>
                                                            <img
                                                                src={
                                                                    item.vegType === "VEG"
                                                                        ? "images/veg.png"
                                                                        : "images/nonveg.png"
                                                                }
                                                                alt=""
                                                                style={{ width: "7%", marginRight: "2%" }}
                                                            />{" "}
                                                            <h4 style={{ textAlign: "start", paddingTop: "7%" }}>
                                                                {" "}
                                                                {item.label}
                                                            </h4>
                                                        </div>
                                                        <div className="d-flex" style={{ alignItems: "baseline" }}>
                                                            <p
                                                                className="number-font"
                                                                style={{
                                                                    color: "#28a745",
                                                                    fontWeight: "700",
                                                                    fontSize: "18px",
                                                                }}
                                                            >
                                                                {" "}
                                                                {` ₹ ${item.price}`}
                                                            </p>
                                                            <button
                                                                className="odc-view-menu-btn d-flex"
                                                                onClick={() => handleClickOpenMenu(indxed)}
                                                            >
                                                                Menu <VisibilityIcon className="ml-2" />
                                                            </button>
                                                            {isVisible === indxed ? (
                                                                <Dialog
                                                                    open={openMenu}
                                                                    TransitionComponent={Transition}
                                                                    keepMounted
                                                                    onClose={handleCloseMenu}
                                                                    aria-describedby="alert-dialog-slide-description"
                                                                    className="opt-resp"
                                                                    // sx={{ border: "2px solid #c60800" }}
                                                                >
                                                                    <DialogTitle
                                                                        style={{
                                                                            textAlign: "center",
                                                                            backgroundColor: "#c60800",
                                                                            color: "#fff",
                                                                            borderRadius: "10px",
                                                                        }}
                                                                    >
                                                                        {"Your Menu"}{" "}
                                                                    </DialogTitle>
                                                                    <IconButton
                                                                        aria-label="close"
                                                                        onClick={handleCloseMenu}
                                                                        sx={{
                                                                            position: "absolute",
                                                                            right: 26,
                                                                            top: 33,
                                                                            color: "#fff",
                                                                        }}
                                                                    >
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                    <DialogContent>
                                                                        <ul className="odc-cart-menu-list-item-style">
                                                                            {item &&
                                                                                item.items &&
                                                                                item.items.map((menus, menuid) => (
                                                                                    // console.log(menus);
                                                                                    <li key={menuid}>
                                                                                        <span>
                                                                                            <EggIcon
                                                                                                style={{
                                                                                                    marginRight: "2%",
                                                                                                    color: "#c60800",
                                                                                                }}
                                                                                            />
                                                                                        </span>{" "}
                                                                                        {menus.label} ({menus.size}
                                                                                        {menus.unit})
                                                                                    </li>
                                                                                ))}
                                                                        </ul>
                                                                    </DialogContent>
                                                                </Dialog>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="mt-2">
                                                            <button
                                                                onClick={(e) => handleDecrement(indxed, e, item)}
                                                                // value={cardState[indxed].count}
                                                                className="odc-checkout-mines-btn-cart "
                                                            >
                                                                -
                                                            </button>
                                                            <input
                                                                type="number"
                                                                value={cardState[indxed].count}
                                                                onChange={(e) => handleInputChange(indxed, e, item)}
                                                                // min="25"
                                                                className="odc-chechout-input-style"
                                                            />
                                                            <button
                                                                onClick={(e) => handleIncrement(indxed, e, item)}
                                                                // value={cardState[indxed].count}
                                                                className="odc-card-pluse-btn-cart"
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2">
                                                        <button
                                                            style={{ background: "none", border: "none" }}
                                                            onClick={() => handelRemovemenu(item._id)}
                                                        >
                                                            <CloseRoundedIcon />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img src="images/notfound.png" alt="Menu" style={{ width: "30%" }} />
                                        </div>
                                    )}

                                    <div className="cart__actions" style={{ justifyContent: "end" }}>
                                        <div className="cart__buttons">
                                            <Link
                                                to="/odc/home"
                                                className="btn "
                                                style={{ border: "1px solid #c60800", color: " #c60800" }}
                                            >
                                                Back to Menu
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h4>If you Need any Services ?</h4>

                                            <div style={{ display: "grid" }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkedValues.includes("Only Delivery")}
                                                            onChange={() => handleCheckboxChange("Only Delivery")}
                                                        />
                                                    }
                                                    label="Only Delivery"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkedValues.includes("I need menu Changes")}
                                                            onChange={() => handleCheckboxChange("I need menu Changes")}
                                                        />
                                                    }
                                                    label="I need menu Changes"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={checkedValues.includes("I want my own menu")}
                                                            onChange={() => handleCheckboxChange("I want my own menu")}
                                                        />
                                                    }
                                                    label="I want my own menu"
                                                />
                                                <div className="d-flex">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={checkedValues.includes(
                                                                    "I want addtional services"
                                                                )}
                                                                onChange={() =>
                                                                    handleCheckboxChange("I want addtional services")
                                                                }
                                                            />
                                                        }
                                                        label="I want addtional services"
                                                    />

                                                    <button
                                                        className="odc-adservice-view-btn"
                                                        onClick={handleClickOpenservice}
                                                    >
                                                        Add.Services <VisibilityIcon className="ml-2" />
                                                    </button>
                                                    <Dialog
                                                        open={openAddservice}
                                                        TransitionComponent={Transition}
                                                        keepMounted
                                                        onClose={handleCloseMenu}
                                                        aria-describedby="alert-dialog-slide-description"
                                                        className="opt-resp"
                                                        // sx={{ border: "2px solid #c60800" }}
                                                    >
                                                        <DialogTitle
                                                            style={{
                                                                textAlign: "center",
                                                                backgroundColor: "#c60800",
                                                                color: "#fff",
                                                            }}
                                                        >
                                                            {"Addtional Services We Provide"}{" "}
                                                        </DialogTitle>
                                                        <IconButton
                                                            aria-label="close"
                                                            onClick={handleCloseMenu}
                                                            sx={{
                                                                position: "absolute",
                                                                right: 26,
                                                                top: 33,
                                                                color: "#fff",
                                                            }}
                                                        >
                                                            <CancelIcon />
                                                        </IconButton>
                                                        <DialogContent>
                                                            <ul className="odc-cart-menu-list-item-style">
                                                                {servicelist.map((items, index) => (
                                                                    <li key={index}>
                                                                        <span>
                                                                            {/* <i className="fa-sharp fa-regular fa-pan-food" style={{marginRight:"2%",color:"#c60800"}}></i> */}
                                                                            <FastfoodIcon
                                                                                style={{
                                                                                    marginRight: "2%",
                                                                                    color: "#c60800",
                                                                                }}
                                                                            />
                                                                        </span>{" "}
                                                                        {items}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </DialogContent>
                                                    </Dialog>
                                                </div>
                                                <button className="odc-quiry-btn">
                                                    <a href="tel:+91 9962667733" className="odc-quiry-btn-a">
                                                        {" "}
                                                        <SupportAgentIcon /> Call Us
                                                    </a>
                                                </button>
                                            </div>

                                            <div>
                                                <div className="odc-payment-detaile-worning1">
                                                    <div className="odc-payment-detaile-worning">
                                                        <h4 style={{ marginTop: "3%" }}>Payment Details </h4>
                                                        <p style={{ marginTop: "3%", color: "#6e6e6e" }}>
                                                            Addtional service , menu Changes , Own menu creation charges
                                                            or price will not included in your current menu plan payment
                                                            your going to pay. If you need addtional services the amount
                                                            will be included in your final payment , that amount adding
                                                            is based on your requirment and spcifications. All final
                                                            payment will fixed after the discussions and if you have any
                                                            other queries please contact us. We are ready to help you at
                                                            any time at your order.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    {menuadded.length === 0 ? (
                                        <div>
                                            <img
                                                src="images/emcart.png"
                                                alt="empty cart"
                                                style={{
                                                    borderRadius: "7px",
                                                    width: "100%",
                                                    boxShadow: "rgba(0, 0, 0, 0.12) 2px 3px 5px 2px",
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="odc-cart-totel1 p-4">
                                                <h4 className="txt-align-center pt-4 pb-3">Cart Total</h4>
                                                <ul style={{ padding: "0px 0px" }}>
                                                    {menuadded.length > 0 ? (
                                                        menuadded &&
                                                        menuadded.map((item, indxed1) => (
                                                            <li className="mb-2" key={indxed1}>
                                                                <div className="row odc-cartic-list-main">
                                                                    <div className="col-lg-8"> {item.label}</div>
                                                                    <div className="col-lg-4 number-font">{` ₹ ${item.price}`}</div>
                                                                </div>
                                                                <div className="row odc-cartic-list-sub">
                                                                    <div className="col-lg-8 number-font">
                                                                        {cardState[indxed1].count} Counts{" "}
                                                                    </div>
                                                                    <div className="col-lg-4 number-font">
                                                                        ₹ {cardState[indxed1].count * item.price}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-4 number-font">
                                                          ₹ {calculateTotalPrice(item.price)}
                                                              </div> */}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <div style={{ textAlign: "center" }}>Menu Not founded</div>
                                                    )}

                                                    <hr />
                                                    <li>
                                                        <div className="row row odc-cartic-list-main">
                                                            <div className="col-lg-8">Subtotal</div>
                                                            <div className="col-lg-4 number-font">
                                                                ₹ {calculateTotalPrice()}
                                                            </div>
                                                        </div>{" "}
                                                    </li>

                                                    {/* <hr />
                                            <li>
                                                <div className="row row odc-cartic-list-main">
                                                    <div className="col-lg-8">Total</div>
                                                    <div className="col-lg-4">₹ 5500.00</div>
                                                </div>{" "}
                                            </li> */}
                                                    <hr />
                                                </ul>
                                                <button
                                                    className="odc-checkout-btn-style width-100"
                                                    onClick={(e) => toggleVisibility(e)}
                                                >
                                                    Check Out
                                                </button>

                                                <div className="mt-4">
                                                    <p>
                                                        This charges without Tax, delivery charges and additional
                                                        services charges.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
Addcard.propTypes = {
    menuadded: PropTypes.array.isRequired,
    odcuser: PropTypes.object.isRequired,
    guestuser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    menuadded: state.menuadded,
    odcuser: state.odcuser,
    guestuser: state.guestuser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Addcard);
// export default Addcard;
