/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";
//
import { useTheme } from "@mui/material/styles";
import Select from "react-select";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import StarIcon from "@mui/icons-material/Star";
import { Image, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";

import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiurl } from "../../../api/api_url";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
// import Select from '@mui/material/Select';
import { formatTime } from "../../../helper/dateformate";
// import ImageCompressor from 'image-compressor';
import imageCompression from "browser-image-compression";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeliveredOrder() {
    const { id } = useParams();
    const [menulistapi, setMenulistapi] = useState([]);
    const [show, setShow] = useState(false);
    const [weightage, setWeightage] = useState(-1);
    const theme = useTheme();
    // const [complianMenulistitem, setComplianMenulistitem] = useState();
    //Review
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [sreview, setSreview] = useState();
    const [compliance, setCompliance] = useState();
    const [cimage, setCimage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [imshow, setImshow] = useState(false);
    const [subreview, setSubreview] = useState([]);
    const [imageurl, setImageUrl] = useState(null);

    // const compressImage = async (file) => {
    //     try {
    //       const options = {
    //         maxSizeMB: 1, // Set the target file size in megabytes (1MB in this case)
    //         maxWidthOrHeight: 1024, // Set the maximum width or height of the compressed image
    //       };

    //       const blobFile = new Blob([file], { type: file.type });

    //       const compressedFile = await imageCompression(blobFile, options);

    //       console.log(compressedFile); // Compressed image file

    //       // Do something with the compressed image file, such as uploading it to a server or displaying it in your UI
    //     } catch (error) {
    //       console.error('Image compression failed:', error);
    //     }
    //   };
    const imageSubmite = async (e) => {
        const uploadfile = e.target.files[0];
        let file = null;
        try {
            const options = {
                maxSizeMB: 1, // Set the target file size in megabytes (1MB in this case)
                maxWidthOrHeight: 1024, // Set the maximum width or height of the compressed image
            };

            const blobFile = new Blob([uploadfile], { type: uploadfile.type });

            const compressedFile = await imageCompression(blobFile, options);

            console.log(compressedFile); // Compressed image file

            file = new File([compressedFile], uploadfile.name, {
                type: compressedFile.type,
            });

            // Do something with the compressed image file, such as uploading it to a server or displaying it in your UI
        } catch (error) {
            console.error("Image compression failed:", error);
        }
        if (file) {
            // const modifyfile = file
            const reqOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ filename: uploadfile.name, type: uploadfile.type }),
            };

            fetch(`${apiurl}/upload/url`, reqOptions)
                .then((res) => res.json())
                .then((data) => {
                    fetch(data.url, {
                        method: "PUT",
                        body: file,
                    }).then((rs) => {
                        if (rs.ok) {
                            const image_url = rs.url.split("?")[0];
                            setImageUrl(image_url);
                            console.log(image_url);
                        }
                    });
                });
        }

        // let reader = new FileReader();
        // let file = e.target.files[0];
        // reader.onloadend = () => {
        //     setCimage(file);
        //     setPreview(reader.result);
        // };
        // reader.readAsDataURL(file);
    };
    const handleView = () => {
        setImshow(true);
    };
    const handleUnview = () => {
        setImshow(false);
    };

    const submiteReview = async (e) => {
        e.preventDefault();
        setSubreview(...subreview, { sreview, compliance, cimage, rating });

        //api
        await fetch(`${apiurl}/review-order/${menulistapi._id}`, {
            method: "PUT",
            body: JSON.stringify({
                text: sreview,
                compliant: compliance,
                image: imageurl,
                star: rating,
            }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
            });
        // setRating(0);
        toast.success("Your Review Sended Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setSreview("");
        setCompliance("");
        setCimage("");
        setRating("");
    };

    //   console.log(cimage);
    const handleRatingClick = (newRating) => {
        setRating(newRating);
    };

    const handleRatingHover = (hoveredRating) => {
        setHoverRating(hoveredRating);
    };

    const handleRatingHoverLeave = () => {
        setHoverRating(0);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <span
                    key={i}
                    className={i <= hoverRating || i <= rating ? "filled-star" : "empty-star"}
                    onClick={() => handleRatingClick(i)}
                    onMouseEnter={() => handleRatingHover(i)}
                    onMouseLeave={() => handleRatingHoverLeave()}
                >
                    &#9733;
                </span>
            );
        }
        return stars;
    };

    const getRatingColor = () => {
        switch (rating) {
            case 1:
                return "#F44336";
            case 2:
                return "#FFC107";
            case 3:
                return "#FFEB3B";
            case 4:
                return "#CDDC39";
            case 5:
                return "#8BC34A";
            default:
                return "#9E9E9E";
        }
    };

    // const handleChange3 = (event) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setComplianMenulistitem(
    //         // On autofill we get a stringified value.
    //         typeof value === "string" ? value.split(",") : value
    //     );
    // };
    const handleChange3 = (e) => {
        setCompliance(e.target.value);
    };

    const option = [
        { value: "Choose your Complaine", label: "Choose your Complaine " },
        { value: "No complaints. Good Service", label: "No complaints. Good Service" },
        // { value: "presence of foreign particles", label: "presence of foreign particles" },
        { value: "Less count than ordered", label: "Less count than ordered" },
        { value: "Late Food Delivery", label: "Late Food Delivery" },
        { value: "Not Tastier", label: "Not Tastier" },
        { value: "Others", label: "Others" },
    ];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handelWeight = (index) => {
        setWeightage(index === weightage ? -1 : index);
    };
    const handleClosed = (index) => {
        setWeightage(index === weightage ? -1 : index);
    };

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    const getApiData = async () => {
        // const { id } = props;
        // const { id } = useParams( myorderlist._id);
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const result = await fetch(`${apiurl}/order/${id}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(result.data);
        // console.log(result.data);
    };

    // const address = menulistapi.customer.address? menulistapi.customer.address: 'not found';
    // console.log(menulistapi.status);
    const date = new Date(menulistapi.createdAt);
    const date1 = new Date(menulistapi.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    return (
        <div className="container txt-colr">
            <MDBRow>
                <MDBCol lg={12} className="mrbt">
                    <MDBCard className="service-tab-style border-styles" style={{ textAlign: "center" }}>
                        <h4 className="fw-bold mb-1 order-details-header">My Delivered Order Details</h4>
                        <MDBCardBody>
                            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                <ListItem>
                                    <ListItemText> Ordered Date </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText style={{ textAlign: "start" }}>
                                        {" "}
                                        {`${day} -${month} -${year}`}
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText> Ordered Time </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText> {formatTime(menulistapi.createdAt)} </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText> Service Date </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText>{`${day1} -${month1} -${year1}`}</ListItemText>
                                </ListItem>
                                {menulistapi.services &&
                                    menulistapi.services.map((item, index) => (
                                        <div key={index}>
                                            <ListItem>
                                                <ListItemText>{item.label} Kitchen Pickup Time </ListItemText>
                                                <ListItemText> : </ListItemText>
                                                <ListItemText style={{ textAlign: "start" }}>
                                                    <span>{formatTime(item.dispatched.time)} </span>
                                                </ListItemText>
                                            </ListItem>

                                            <ListItem>
                                                <ListItemText>{item.label} Delivered Time </ListItemText>
                                                <ListItemText> : </ListItemText>
                                                <ListItemText style={{ textAlign: "start" }}>
                                                    <span>{formatTime(item.delivered.time)} </span>
                                                </ListItemText>
                                            </ListItem>
                                        </div>
                                    ))}
                                <ListItem>
                                    <ListItemText> Delivery Address</ListItemText>
                                    <ListItemText>: </ListItemText>
                                    <ListItemText style={{ textAlign: "start" }}>
                                        {" "}
                                        {menulistapi.customer && menulistapi.customer.address}
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText> Services</ListItemText>
                                    <ListItemText>: </ListItemText>

                                    <ListItemText>
                                        {menulistapi.services &&
                                            menulistapi.services.map((item, index) => (
                                                <span key={index}> {item.label} ,</span>
                                            ))}
                                    </ListItemText>
                                </ListItem>

                                {menulistapi.services &&
                                    menulistapi.services.map((item, index) => (
                                        <div key={index}>
                                            <ListItem>
                                                <ListItemText> {`${item.label} Count`} </ListItemText>
                                                <ListItemText>: </ListItemText>
                                                <ListItemText>{item.count} </ListItemText>
                                            </ListItem>
                                        </div>
                                    ))}
                                <ListItem>
                                    <ListItemText> Order Status </ListItemText>
                                    <ListItemText> : </ListItemText>
                                    <ListItemText style={{ textAlign: "start" }}>
                                        <Button className="delivered-detail-btn">{menulistapi.status}</Button>
                                    </ListItemText>
                                </ListItem>

                                {/* <ListItem>
       <ListItemText> COVID check </ListItemText>
       <ListItemText> : </ListItemText>
        <ListItemText style={{textAlign:'start',}}><Button className='details-btn-pending'>PENDING</Button></ListItemText>
      </ListItem>
      <ListItem>
       <ListItemText> Order Status  </ListItemText>
        <ListItemText> : </ListItemText>
        <ListItemText style={{textAlign:'start',}}><Button className='delivered-detail-btn' >{menulistapi.status}</Button></ListItemText>
      </ListItem>
      <ListItem>
       <ListItemText> Delivery Challan  </ListItemText>
        <ListItemText style={{marginLeft: '-35px'}}> : </ListItemText>
        <ListItemText style={{textAlign:'start',}} ><Button onClick={handleShow} className='dc-view-btn' >View DC</Button></ListItemText>
      </ListItem> */}
                            </List>

                            {/* <div className='dash-line'></div> */}

                            <h3 className=" mb-4 mt-4" style={{ color: "#000" }}>
                                <span style={{ color: " #c60800" }}>B</span>ill Details
                            </h3>
                            {menulistapi.services &&
                                menulistapi.services.map((item, index) => (
                                    <div key={index}>
                                        <div className="inline-flex-st">
                                            <img
                                                className="veg-details-img"
                                                src={item.vegType === "VEG" ? "images/veg.png" : "images/nonveg.png"}
                                                alt=""
                                            />
                                            <h4 style={{ color: "#000" }}> {item.label}</h4>
                                        </div>
                                        <div >
                                                        <div className="food-item-details">
                                                        {item &&
                                                item.menuItems &&
                                                item.menuItems.map((items, indexed) => 
                                                            <span  key={indexed} style={{ padding: "1px 7px" }}> {items.label} ({items.size}{items.unit}) , </span>
                                                            )}
                                                        </div>
                                                    </div>
                                        <div className="container">
                                            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                <div className="dash-line"></div>
                                                <ListItem className="count-ord-btn">
                                                    <ListItemText> Quantity </ListItemText>
                                                    <ListItemText> Price </ListItemText>
                                                    <ListItemText> Total </ListItemText>
                                                </ListItem>
                                                <div className="dash-line"></div>
                                                <ListItem className="count-ord-btn">
                                                    <ListItemText> {item.count}</ListItemText>
                                                    <ListItemText style={{ paddingLeft: "5%" }}>
                                                        <span>₹ </span> {item.price}{" "}
                                                    </ListItemText>
                                                    <ListItemText>
                                                        {" "}
                                                        <span>₹ </span> {item.count * item.price}{" "}
                                                    </ListItemText>
                                                </ListItem>
                                            </List>

                                            <div style={{ marginBottom: "7%" }}>
                                                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                    <ListItem>
                                                        <ListItemText> Food safety check </ListItemText>
                                                        <ListItemText> : </ListItemText>
                                                        <ListItemText style={{ textAlign: "start" }}>
                                                            <Button
                                                                className={
                                                                    item.dlchallan.status
                                                                        ? "details-btn-done"
                                                                        : "details-btn-pending"
                                                                }
                                                            >
                                                                {item.dlchallan.status ? "Completed" : "Pending"}
                                                            </Button>
                                                        </ListItemText>
                                                    </ListItem>

                                                    {/* <ListItem>
                                                        <ListItemText> Delivery Challan </ListItemText>
                                                        <ListItemText> : </ListItemText>
                                                        <ListItemText style={{ textAlign: "start" }}>
                                                            <Button onClick={handleShow} className="dc-view-btn">
                                                                View DC
                                                            </Button>
                                                        </ListItemText>
                                                    </ListItem> */}
                                                </List>
                                            </div>
                                            <Button
                                                style={{ marginBottom: "7%" }}
                                                className="details-btn-pending weight-btn"
                                                onClick={() => handelWeight(index)}
                                            >
                                                {" "}
                                                See Food Weight
                                            </Button>

                                            <Modal show={weightage === index} onHide={handleClosed}>
                                                <form>
                                                    <Modal.Header className="pop_head">
                                                        <h3
                                                            className=" mb-4 mt-4"
                                                            style={{ color: "#fff", marginLeft: "27%" }}
                                                        >
                                                            <span style={{ color: " #000" }}>S</span>ee Food Weight
                                                        </h3>

                                                        <Button
                                                            className="cls-btn"
                                                            style={{ color: "#fff" }}
                                                            onClick={handleClosed}
                                                        >
                                                            X
                                                        </Button>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div style={{ color: "#000" }}>
                                                            <div className="inline-flex-st">
                                                                <img
                                                                    style={{ width: "5%", height: "5%" }}
                                                                    className="veg-details-img"
                                                                    src={
                                                                        item.vegType === "VEG"
                                                                            ? "images/veg.png"
                                                                            : "images/nonveg.png"
                                                                    }
                                                                    alt=""
                                                                />
                                                                <h4> {item.label}</h4>
                                                            </div>
                                                            <div >
                                                        <div className="food-item-details">
                                                        {item &&
                                                item.menuItems &&
                                                item.menuItems.map((items, indexed) => 
                                                            <span  key={indexed} style={{ padding: "1px 7px" }}> {items.label} ({items.size}{items.unit}) , </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                            <div className="container">
                                                                <List
                                                                    sx={{ width: "100%", bgcolor: "background.paper" }}
                                                                >
                                                                    <div className="dash-line"></div>
                                                                    <ListItem className="count-ord-btn">
                                                                        <ListItemText> Quantity </ListItemText>
                                                                        <ListItemText> Price </ListItemText>
                                                                        <ListItemText> Total </ListItemText>
                                                                    </ListItem>
                                                                    <div className="dash-line"></div>
                                                                    <ListItem className="count-ord-btn">
                                                                        <ListItemText>{item.count}</ListItemText>
                                                                        <ListItemText style={{ paddingLeft: "5%" }}>
                                                                            <span>₹ </span>
                                                                            {item.price}
                                                                        </ListItemText>
                                                                        <ListItemText>
                                                                            {" "}
                                                                            <span>₹ </span> {item.count * item.price}{" "}
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                    <div
                                                                        style={{
                                                                            textAlign: "inherit",
                                                                            color: "#585858b3",
                                                                        }}
                                                                    >
                                                                        {/* <ListItemText > {menulistapi.foodWeight? menulistapi.foodWeight:' Food Weight Not Mentioned'} </ListItemText> */}
                                                                        {/* <ListItemText >    Enter Liquide Food Count (Litres) </ListItemText> */}
                                                                    </div>
                                                                </List>

                                                                {/* <Button  onClick={handelWeight} style={{marginLeft: '35% ', marginTop:'20px'}} className='safety-check-popup-btn'  > Ok</Button> */}
                                                            </div>
                                                            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                                                <div
                                                                    style={{ textAlign: "inherit", color: "#585858b3" }}
                                                                >
                                                                    {item.foodWeight.map((items, indexed) => (
                                                                        <div key={indexed}>
                                                                            <div className="food-item-details">
                                                                                <ListItemText>
                                                                                    <span
                                                                                        style={{ padding: "1px 7px" }}
                                                                                    >
                                                                                        {" "}
                                                                                        {items.item} {items.size}
                                                                                    </span>{" "}
                                                                                    ,
                                                                                </ListItemText>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </List>
                                                        </div>
                                                    </Modal.Body>

                                                    {/* <Modal.Footer style={{ borderTop: "none" }}>
                        <Button  onClick={handleClose} className="rgbtn1" variant="primary">
                           Ok
                        </Button>
                    </Modal.Footer> */}
                                                </form>
                                            </Modal>
                                        </div>
                                    </div>
                                ))}

                            <div className="mb-4 mt-2" style={{ paddingTop: "25px" }}>
                                <h3 className=" mb-4 mt-4" style={{ color: "#000" }}>
                                    <span style={{ color: " #c60800" }}>Y</span>our Reviews
                                </h3>
                                <form>
                                    <List>
                                        <ListItem>
                                            <ListItemText> Review Our Service </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                <div className="form-group">
                                                    <textarea
                                                        id="form-message"
                                                        value={sreview}
                                                        name="sreview"
                                                        onChange={(e) => setSreview(e.target.value)}
                                                        placeholder="Write Review About Service"
                                                        className="form-control"
                                                        rows="2"
                                                        required
                                                    />
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> Your Compliance </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                <div className="form-group">
                                                    <select
                                                        id="select-field"
                                                        className="form-control menu-change-reason-input"
                                                        style={{ padding: "10px" }}
                                                        placeholder="Current Menu service"
                                                        value={compliance}
                                                        onChange={handleChange3}
                                                    >
                                                        {option &&
                                                            option.map((item, index) => (
                                                                <option key={index} value={item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </div>
                                            </ListItemText>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText> Upload Compliance Image </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                <div className="form-group">
                                                    <IconButton
                                                        color="primary"
                                                        aria-label="upload picture"
                                                        component="label"
                                                    >
                                                        <input
                                                            hidden
                                                            name="cimage"
                                                            // value={cimage}
                                                            onChange={imageSubmite}
                                                            accept="image/*"
                                                            type="file"
                                                            required
                                                        />

                                                        {/* <Ratings/> */}
                                                        <PhotoCamera />
                                                    </IconButton>
                                                    <Button variant="primary" onClick={handleView}>
                                                        View Image
                                                    </Button>
                                                    <Modal show={imshow} onHide={handleUnview}>
                                                        <Modal.Header>
                                                            <Modal.Title style={{ color: "#000", textAlign: "center" }}>
                                                                Image Preview
                                                            </Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body style={{ color: "#000", textAlign: "center" }}>
                                                            {imageurl ? (
                                                                <Image
                                                                    style={{ width: "100%" }}
                                                                    src={imageurl}
                                                                    rounded
                                                                />
                                                            ) : (
                                                                "No image is Uploaded"
                                                            )}
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button onClick={handleUnview}>Close</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </ListItemText>
                                        </ListItem>

                                        <ListItem>
                                            <ListItemText> Rate Us </ListItemText>
                                            <ListItemText> : </ListItemText>
                                            <ListItemText>
                                                <div>
                                                    <div
                                                        className="star-rating"
                                                        style={{ color: getRatingColor() }}
                                                        onMouseLeave={() => handleRatingHoverLeave()}
                                                        required
                                                    >
                                                        {renderStars()}
                                                    </div>
                                                    {rating > 0 && (
                                                        <p>
                                                            You have rated us {rating} {rating === 1 ? "star" : "stars"}
                                                        </p>
                                                    )}
                                                </div>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                    {/* <Ratings/> */}
                                    {/* <Button className='details-btn-pending' style={{color:'#fff'}} endIcon={<StarIcon />} >Submite Review</Button> */}
                                    <Button
                                        className="details-btn-pending rating-btn"
                                        onClick={submiteReview}
                                        endIcon={<StarIcon />}
                                    >
                                        Submit Review
                                    </Button>
                                </form>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

            <Modal show={show} onHide={handleClose}>
                <form>
                    <Modal.Header className="pop_head">
                        <img className="detail-popup-logo" src={Hlogo} alt="logo" />

                        <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClose}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <img
                                className="dcimagestyle"
                                src={
                                    menulistapi && menulistapi.dlchallan && menulistapi.dlchallan.url
                                        ? menulistapi.dlchallan.url
                                        : "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/delivery-challan/index/delivery-challan-material.webp"
                                }
                                alt="Delivery Challan"
                            />
                        </div>
                    </Modal.Body>

                    <Modal.Footer style={{ borderTop: "none" }}>
                        <Button onClick={handleClose} className="rgbtn1" variant="primary">
                            Ok
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}
