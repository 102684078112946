/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SendIcon from "@mui/icons-material/Send";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ToastContainer, toast } from "react-toastify";
import OTPInput, { ResendOTP } from "otp-input-react";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { apiurl } from "../../../api/api_url";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Timer = ({ duration, onTimeout }) => {
    const [seconds, setSeconds] = useState(duration);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevSeconds) => {
                if (prevSeconds === 0) {
                    clearInterval(interval);
                    onTimeout(); // Call the callback when the timer reaches 0
                    return prevSeconds;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [duration, onTimeout]);

    return <div className="mt-2"> OTP Timer : {seconds}s</div>;
};

function Singin() {
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [registerinfo, setRegisterinfo] = useState([]);
    const [errors, setErrors] = useState({});
    const [OTP, setOTP] = useState("");
    const [resendClicked, setResendClicked] = useState(false);
    const [isLightOn, setLightOn] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(URL.createObjectURL(file));
    };

    const toggleLight = () => {
        setLightOn(!isLightOn);
    };

    const handleTimeout = () => {
        setResendClicked(false);
        console.log("Timer reached 0, handle timeout logic here");
    };

    const handleResendClick = () => {
        setResendClicked(true);
        console.log("Resend clicked");
    };

    const handleSubmitOTP = (e) => {
        e.preventDefault();
        // Perform the necessary actions with the OTP value
        toast.success("Code is Verified!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        history.goBack();
        console.log("OTP submitted:", OTP);
    };
    const odchandleLogin = (e) => {
        e.preventDefault();

        history.push("/odc/login");
    };


    const validateForm = () => {
        let formIsValid = true;
        const errors = {};
    

        // if (!fullName) {
        //     errors.fullName = "Enter your Name";
        //     formIsValid = false;
        //   } 
          if (!fullName) {
            errors.fullName = "Enter your Name";
            formIsValid = false;
          } else if (!/^[A-Za-z\s]+$/.test(fullName)) {
            errors.fullName = "Name should only contain letters and spaces";
            formIsValid = false;
          }
        // Validate Email
        if (!email) {
          errors.email = "Email is required";
          formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          errors.email = "Email is invalid";
          formIsValid = false;
        }

        if (!mobile) {
          errors.mobile = "Enter your Mobile Number";
          formIsValid = false;
        } else if (!/^\d{10}$/.test(mobile)) {
          errors.mobile = "Mobile Number should be 10 digits";
          formIsValid = false;
        }
    
        // if (!mobile) {
        //     errors.mobile = "Enter your Mobile Number";
        //     formIsValid = false;
        //   } 
        
        // // Validate Password
        if (!password) {
          errors.password = "Password is required";
          formIsValid = false;
        } 
       
      
    
        setErrors(errors);
        return formIsValid;
      };



    const handleClickOpen = async(e) => {
        e.preventDefault();
        if (validateForm()) {
        await fetch(`${apiurl}/auth/odc-register`, {
            method: "POST",
            body: JSON.stringify({fullName:fullName, email:email, mobile:mobile,password:password}),
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                // setMenulistapi(json.data);
                if(json.status===true){
                    history.push("/odc/login");
                }
                else( toast.error(json.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
        )
        
               
            });

        }else( toast.error("Please fill the details", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }))
           
        // setOpen(true);
    };

    const handleClose = () => {
        toast.error("Oops OTP is not Verified", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        setOpen(false);
    };

    return (
        <div>
            <div
                className="sigin-page-style "
                style={{
                    backgroundImage:
                        'url("../images/signinbg.png")',
                    backgroundSize: "cover",
                   
                }}
            >
                <div className="singin-center-style">
                    <form>
                        <div>
                            <div className="singin-tl-style">Register</div>
                            <div className="form-group">
                                <input
                                    name="fullname"
                                    type="text"
                                    onChange={(e) => setFullName(e.target.value)}
                                    className="form-control rg-txt-input-style"
                                    placeholder="Enter Your Name"
                                    required
                                />
                                {errors.fullName && <p style={{ color: "red" }}>{errors.fullName}</p>}
                            </div>

                            <div className="form-group">
                                <input
                                    name="email"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control rg-txt-input-style"
                                    placeholder="Enter Your Mail ID"
                                    required
                                />
                                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                            </div>
                            <div className="form-group">
                                <input
                                    name="mobile"
                                    type="mobile"
                                    onChange={(e) => setMobile(e.target.value)}
                                    className="form-control rg-txt-input-style"
                                    placeholder="Enter Your Mobile No"
                                    required
                                />
                                {errors.mobile && <p style={{ color: "red" }}>{errors.mobile}</p>}
                            </div>
                            <IconButton
                                className="password-icon-style2 "
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                // value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control rg-txt-input-style mt-4"
                                placeholder="Password"
                                required
                            />
                            {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                           
                            <p className="rg-info-txt" style={{ color: "#000", fontWeight: "600" }}>
                            Already have an account ?{" "}
                                <Link to="#" onClick={odchandleLogin}>
                                    Please Login
                                </Link>{" "}
                            </p>
                            <button className="singin-rgbtn" onClick={handleClickOpen}>
                                Submit <SendIcon style={{ fontSize: "20px" }} />
                            </button>
                        </div>   

                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className="opt-resp"
                        >
                            <DialogTitle style={{ textAlign: "center" }}>{"Please Verifiy your OTP"}</DialogTitle>
                            <DialogContent>
                                <div style={{ textAlign: "center" }}>
                                    <div className="container">
                                        <div className="row" style={{ alignItems: "center" }}>
                                            <div className="col-lg-12">
                                                <OTPInput
                                                    value={OTP}
                                                    onChange={setOTP}
                                                    style={{ display: "flex", justifyContent: " center" }}
                                                    autoFocus
                                                    OTPLength={6}
                                                    otpType="number"
                                                    disabled={false}
                                                    secure={isLightOn === true ? false : true} // Disable secure prop to show entered value
                                                />
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <button className="otp-btn" onClick={handleSubmitOTP}>
                                        Submit OTP
                                    </button>
                                    {resendClicked ? (
                                        <div>Resending OTP...</div>
                                    ) : (
                                        <button className="otp-rebtn" onClick={handleResendClick}>
                                            Resend OTP
                                        </button>
                                    )}
                                    <Timer duration={60} onTimeout={handleTimeout} />
                                </div>
                            </DialogContent>
                        </Dialog>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Singin;
