import React from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import EventIcon from "@mui/icons-material/Event";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GroupsIcon from "@mui/icons-material/Groups";
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import theme from "../../../data/theme";
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link } from "react-router-dom";





function Orderlist() {
    const breadcrumb = [
        { title: "Home", url: "/odc/home" },
        { title: "Order List", url: "" },
        // { title: "Checkout", url: "" },
    ];
    return (
        <div>
        <div className="mt-2">
            <div className="container-fluid odc-orderlist-header aline-center-footer-txt mb-4">
                <h2>Your Orders</h2>
            </div>
           
            </div>
            
           
          
            <div className="container-fluid " style={{ padding: "0% 7%" }}>
                <Link to="/odc/order-details" className="odc-link-txt-color">
                <div className="card p-4" style={{ borderRadius: "10px", marginBottom: "2%" }}>
                    <div className="row line-height-order-li">
                        <div className="col-lg-4 number-font">
                            <span>
                                <ViewListIcon />
                            </span>{" "}
                            Order ID : ODC14314244
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <DomainVerificationIcon />
                            </span>{" "}
                            Order Date : 20.09.2023
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <EventIcon />
                            </span>{" "}
                            Sevice Date : 02.10.2023
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <LocalDiningIcon />
                            </span>{" "}
                            Servcies : Breakfast
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <GroupsIcon />
                            </span>{" "}
                            Order Count : 240
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <VerifiedIcon />
                            </span>{" "}
                            Order Status : <button className="odc-ord-list-status-btn">Ordered</button>
                        </div>
                    </div>
                </div>
                </Link>

                <Link to="/odc/order-details" className="odc-link-txt-color">

                <div className="card p-4" style={{ borderRadius: "10px", marginBottom: "2%" }}>
                    <div className="row line-height-order-li">
                        <div className="col-lg-4 number-font">
                            <span>
                                <ViewListIcon />
                            </span>{" "}
                            Order ID : ODC14314245
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <DomainVerificationIcon />
                            </span>{" "}
                            Order Date : 20.09.2023
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <EventIcon />
                            </span>{" "}
                            Sevice Date : 05.10.2023
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <LocalDiningIcon />
                            </span>{" "}
                            Servcies : Lunch
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <GroupsIcon />
                            </span>{" "}
                            Order Count : 408
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <VerifiedIcon />
                            </span>{" "}
                            Order Status : <button className="odc-ord-list-status-btn">Ordered</button>
                        </div>
                        
                    </div>
                </div>
                </Link>

                <Link to="/odc/order-details"  className="odc-link-txt-color">
                <div className="card p-4" style={{ borderRadius: "10px", marginBottom: "2%" }}>
                    <div className="row line-height-order-li">
                        <div className="col-lg-4 number-font">
                            <span>
                                <ViewListIcon />
                            </span>{" "}
                            Order ID : ODC14314246
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <DomainVerificationIcon />
                            </span>{" "}
                            Order Date : 20.09.2023
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <EventIcon />
                            </span>{" "}
                            Sevice Date : 09.10.2023
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <LocalDiningIcon />
                            </span>{" "}
                            Servcies : Breakfast
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <GroupsIcon />
                            </span>{" "}
                            Order Count : 347
                        </div>
                        <div className="col-lg-4 number-font">
                            <span>
                                <VerifiedIcon />
                            </span>{" "}
                            Order Status : <button className="odc-ord-list-status-btn">Ordered</button>
                        </div>
                    </div>
                </div>
                </Link>
            </div>
        </div>
        
    );
}

export default Orderlist;
