import { ADDEDMENU_CHANGE, REMOVEMENU_CHANGE,UPDATEMENU_COUNT,CLEAR_CART } from "./addedmenuActionTypes";

export const addedmenuChange = (selectedItems) => ({
    type: ADDEDMENU_CHANGE,
    selectedItems,
});

export const removemenuChange = (itemId) => ({
    type: REMOVEMENU_CHANGE,
    itemId,
});

export const updateMenuCount = (itemId, count,tprice) => ({
    type: UPDATEMENU_COUNT,
    payload: { itemId, count,tprice },
  });

  export const clearCart = () => ({
    type: CLEAR_CART,
  });