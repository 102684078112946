// react
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import NavPanelOther from "./NavPanelOther";
import Search from "./Search";
import Topbar from "./Topbar";
import Hlogo from "./../../img/logo.png";
import Call from "./../../img/call.png";

function Header(props) {
    const location = useLocation();
    const [isHomePage1, setIsHomePage1] = useState(false);
    const { layout, homelayout } = props;
    let bannerSection;

    useEffect(() => {
        // setIsHomePage(location.pathname === '/industry/home'||location.pathname === '/corporate/home');
        setIsHomePage1(location.pathname === "/"||location.pathname === "/mainhome/odc");
    }, [location]);

    if (layout === "compact") {
        bannerSection = (
            <div className="site-header__middle container-fluid">
                <div className="col-lg-6 ">
                    <div className="site-header__logo">
                        <Link to="/">
                            <img src={Hlogo} alt="logo" />
                        </Link>
                    </div>
                </div>
                {/* <div className="site-header__search">
                    <Search context="header" />
                </div> */}
                <div className="col-lg-6 ">
                    <div className="site-header__phone cmservice">
                        <p>Customer Services</p>
                        <Link className="litxt-color" to="/">
                            <p>+919962667733</p>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {/* <Topbar />
            {bannerSection} */}
            <div className={isHomePage1 ? "site-header__nav-panel1" : "site-header__nav-panel"}>
                <NavPanel layout={homelayout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

export default Header;
