import React from "react";
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";

export default function MenuList() {
    return (
        <MDBRow>
            <MDBCol xl={12} lg={12} className="mb-4">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex align-items-center row">
                            <div className="col-lg-6">
                                <img
                                    src="https://t3.ftcdn.net/jpg/01/86/33/72/360_F_186337209_9rbcMLu3wGCDNaEoK1jO0aNzb0pv7Xs7.jpg"
                                    alt=""
                                    style={{ width: "75px", height: "75px" }}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="ms-3 col-lg-6">
                                <p className="fw-bold mb-1 text__c">Kal Dosa 1</p>
                                {/* <p className='text-muted mb-0'>john.doe@gmail.com</p> */}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol xl={12} lg={12} className="mb-4">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex align-items-center row">
                            <div className="col-lg-6">
                                <img
                                    src="https://t4.ftcdn.net/jpg/04/97/86/19/360_F_497861919_YpD1bljFxozYpF3LNM91zWOubJCPH8hm.jpg"
                                    alt=""
                                    style={{ width: "75px", height: "75px" }}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="ms-3 col-lg-6">
                                <p className="fw-bold mb-1 text__c">Idly 2 </p>
                                {/* <p className='text-muted mb-0'>alex.ray@gmail.com</p> */}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol xl={12} lg={12} className="mb-4">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex align-items-center row">
                            <div className="col-lg-6">
                                <img
                                    src="https://www.indianhealthyrecipes.com/wp-content/uploads/2021/01/pongal-ven-pongal.jpg"
                                    alt=""
                                    style={{ width: "75px", height: "75px" }}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="ms-3 col-lg-6">
                                <p className="fw-bold mb-1 text__c">Pongal</p>
                                {/* <p className='text-muted mb-0'>kate.hunington@gmail.com</p> */}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol xl={12} lg={12} className="mb-4">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex align-items-center row">
                            <div className="col-lg-6">
                                <img
                                    src="https://www.newscrab.com/static/c1e/client/90607/uploaded_original/753039897efa5f28522f897a49328646.png"
                                    alt=""
                                    style={{ width: "75px", height: "75px" }}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="ms-3 col-lg-6">
                                <p className="fw-bold mb-1 text__c">Vada 1</p>
                                {/* <p className='text-muted mb-0'>soraya.letto@gmail.com</p> */}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol xl={12} lg={12} className="mb-4">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex align-items-center row">
                            <div className="col-lg-6">
                                <img
                                    src="https://i.ytimg.com/vi/0zDnzabB8U8/maxresdefault.jpg"
                                    alt=""
                                    style={{ width: "75px", height: "75px" }}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="ms-3 col-lg-6">
                                <p className="fw-bold mb-1 text__c">Sambar</p>
                                {/* <p className='text-muted mb-0'>zeynep.dudley@gmail.com</p> */}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol xl={12} lg={12} className="mb-4">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex align-items-center row">
                            <div className="col-lg-6">
                                <img
                                    src="https://vaya.in/recipes/wp-content/uploads/2018/02/White-Chutney.jpg"
                                    alt=""
                                    style={{ width: "75px", height: "75px" }}
                                    className="rounded-circle"
                                />
                            </div>
                            <div className="ms-3 col-lg-6">
                                <p className="fw-bold mb-1 text__c">Chutney</p>
                                {/* <p className='text-muted mb-0'>ayat.black@gmail.com</p> */}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
}
