import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function BecomeConsultant() {
    return (
        <div>
            <Helmet>
                <title>{`Become a Consultant — ${theme.name}`}</title>
            </Helmet>
            <h4 className="faq-tit-txt" style={{ backgroundColor: "aliceblue", marginBottom: "2%" }}>
                <span style={{ color: "#c60800" }}>B</span>ecome a Consultant
            </h4>

            <div
                style={{
                    backgroundImage:
                        'url("https://png.pngtree.com/background/20220726/original/pngtree-indian-cuisine-sketch-pattern-background-picture-image_1814318.jpg")',
                }}
            >
                <div style={{ backgroundColor: "#ffffffed" }}>
                    <div className="container-fluid">
                        <div style={{ padding: "0% 2%" }}>
                            <div
                                className="card "
                                style={{
                                    filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)",
                                    padding: "4%",
                                    marginBottom: "4%",
                                }}
                            >
                                <div className="row">
                                    <div className="col-lg-5">
                                        <img
                                            src="https://cdn.pixabay.com/photo/2016/03/31/09/22/consulting-1292328_640.jpg"
                                            alt=""
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div className="col-lg-7">
                                        <h4>Who is a Hogist Advisor?</h4>
                                        <p>
                                            In simple terms, a Hogist Advisor is an individual who has the authorization
                                            to sell Hogist Food Services both online and offline. This person will be
                                            responsible for the sales and revenue generation in the company. A Hogist
                                            Advisor holds the accountability to sell any services, be it an existing or
                                            new to its targeted customers and generate revenue for the company.
                                        </p>
                                        <p>
                                            As a Hogist Advisor, you will be the mediator between Hogist, its clients
                                            and its customers and will be held responsible for potential lead
                                            conversions. You as an Advisor will be responsible for educating the clients
                                            & consumers about the services Hogist offers as a company and how these
                                            services will be beneficial to the end customers. You will also be an acting
                                            assistant in helping the clients filling up the relevant forms and
                                            showcasing our services through website and mobile application.
                                        </p>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>Who can become a Hogist Advisor?</h4>

                                        <ul>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You are 18 years or above in age
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> You hold a basic
                                                education certificate/degree [ Minimum qualification: 12th Standard or
                                                equivalent degree / Diploma]
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You are a person who is a fresher and is looking for a challenging
                                                career.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You are a person who is looking for part-time jobs
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You are a retired professional looking for financial benefits
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You are a housewife/degree holder who is waiting for a career comeback
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> Once you fulfill the
                                                above criteria and become a full-fledged Hogist Advisor, you will have
                                                to follow the following steps to kickstart your career:
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> Register yourself on
                                                the website or step into our corporate office to submit your KYC
                                                document. Post submission, you will be allowed to choose your preferred
                                                service who’s advisory you wish to take up.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Post successful registration, your training schedule will be sent to you
                                                individually where you will be given a glimpse of Hogist and its
                                                services. The training session will comprise of both video modules and
                                                face to face classroom sessions. The duration of the training will
                                                depend on the type of consultancy you choose to take up. The Intention
                                                behind your training is to ensure that you are abreast of the company’s
                                                processing and financing details. The sessions undertaken will take you
                                                through the services what Hogist offers and will also show how you as a
                                                Hogist advisor can be a part of the business and earn money along with
                                                generating revenue for the company.
                                            </li>

                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Post successful training, you will be interviewed on the training
                                                concepts. This will be based on your understanding during training. This
                                                can be taken either online or offline.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You become a full-fledged Hogist Advisor once you clear the interview.
                                            </li>
                                        </ul>

                                        <h4>Why choose a career with Hogist?</h4>

                                        <p>
                                            As a Hogist Advisor, you not only reap the monetary benefits, but also get a
                                            chance to be your boss, work as you like and be independent. You get a
                                            chance to get recognized at a global platform and earn attractive rewards
                                            based on your performance, which makes you earn more with doing smart work
                                            than hard work.
                                        </p>

                                        <h4>What does a Hogist Advisor earn?</h4>
                                        <p>
                                            Based on the volume of the deal, the commission rates are set. To maintain
                                            transparency, a commission structure has been set up which will determine
                                            the commission rates. The rates will be calculated based on the performance
                                            and involvement in the sales and lead conversion ratio. These rates,
                                            however, differ from service to service. Apart from these fixed commissions,
                                            a Hogist Advisor will also earn rewards and recognitions for the smart work
                                            towards empowering the business. To know more about the commission structure
                                            and other details, please refer Schedule A of the Advisor Agreement.
                                        </p>

                                        <h4>Advisor jobs for retired persons</h4>
                                        <p>
                                            We at Hogist make sure that people from all walks of life, be it a housewife
                                            or a career-break woman or a retired professional, and get a chance to
                                            rewire their career. Be it a financial strain or mental strain, we at Hogist
                                            believe that everyone deserves a chance.
                                        </p>
                                        <p>
                                            Keeping this in mind, we at Hogist have introduced an advisory program for
                                            retired professionals with attractive commission rates so that no person
                                            falls in a situation where they are helpless financially. This opportunity
                                            is open for all people who are either retired from work or want to start a
                                            new phase of their life by becoming a Hogist Advisor. Want to know the best
                                            part about kick-starting your career? You don’t have to pay a single penny
                                            to Hogist for becoming an Advisor. All we need is your dedication and
                                            determination and the rest are on us.
                                        </p>

                                        <h4>Part-time Job for Student:</h4>
                                        <p>
                                            Interested in making a career with Hogist? All you have to do is sign up and
                                            the rest is on us. We at Hogist offer part-time as well as full – time
                                            advisory options for students. As a Hogist Advisor, you will be able to:
                                        </p>

                                        <ul>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Earn without compromising on your academics
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> Gain real-time
                                                experience by working with live projects
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Unlimited learning by learning the concepts of selling food online
                                                through a digital platform like Hogist
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Get exposure to the business and take back life setting opportunities
                                                where you will be your boss
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Get rewarded for the hard work done during the Advisory tenure
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Learn the art of communicating – learning what to speak and where to
                                                speak to attract the right business and right clients
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> Excellent opportunity
                                                to become an entrepreneur
                                            </li>
                                        </ul>
                                        <p>
                                            Sounds exciting? Sign up now to enter into the world of Digital Food
                                            Platform!
                                        </p>
                                        <h4>How to sell Hogist Services</h4>
                                        <p>
                                            An advisor can sell the Hogist services to customers either online or
                                            offline. We will be providing you with the relevant marketing materials to
                                            establish and create your pool of customers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BecomeConsultant;
