import React from "react";
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function ServiceTab() {
    return (
        <div className="container">
            <MDBRow>
                <MDBCol lg={6} className="mrbt">
                    <Link to="/site/my-menu" className="card-service-tab">
                        <MDBCard
                            className="service-tab-style zoom-effect border-styles"
                            style={{ textAlign: "center" }}
                        >
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center ">
                                    <div className="col-lg-4 ">
                                        <img
                                            // src="https://cdn-icons-png.flaticon.com/512/900/900864.png"
                                            src="images/img/s1.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning "
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1">My Menu</h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>

                <MDBCol lg={6} className="mrbt">
                    <Link to="/site/my-order" className="card-service-tab">
                        <MDBCard className="service-tab-style zoom-effect border-styles">
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center">
                                    <div className="col-lg-4">
                                        <img
                                            src="images/img/s2.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning"
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1" style={{ textAlign: "center" }}>
                                            My Order
                                        </h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>

                <MDBCol xl={6} lg={6} className="mrbt">
                    <Link to="/site/food-safety-check" className="card-service-tab">
                        <MDBCard className="service-tab-style zoom-effect border-styles">
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center">
                                    <div className="col-lg-4">
                                        <img
                                            src="images/img/s3.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning"
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1" style={{ textAlign: "center" }}>
                                            Food Safety Check
                                        </h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>

                <MDBCol xl={6} lg={6} className="mrbt">
                    <Link to="/site/menu-change" className="card-service-tab">
                        <MDBCard className="service-tab-style zoom-effect border-styles">
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center">
                                    <div className="col-lg-4">
                                        <img
                                            src="images/img/s4.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning"
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1" style={{ textAlign: "center" }}>
                                            Count Change
                                        </h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>

                <MDBCol xl={6} lg={6} className="mrbt">
                    <Link to="/site/cancel-orders" className="card-service-tab">
                        <MDBCard className="service-tab-style zoom-effect border-styles">
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center">
                                    <div className="col-lg-4">
                                        <img
                                            src="images/img/s5.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning"
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1" style={{ textAlign: "center" }}>
                                            Cancel Order
                                        </h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>

                <MDBCol xl={6} lg={6} className="mrbt">
                    <Link to="/site/special-orders" className="card-service-tab">
                        <MDBCard className="service-tab-style zoom-effect border-styles">
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center">
                                    <div className="col-lg-4">
                                        <img
                                            src="images/img/s6.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning"
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1" style={{ textAlign: "center" }}>
                                            Special Order
                                        </h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>

                <MDBCol xl={6} lg={6} className="mrbt mrlft23" >
                    <Link to="/site/analytics" className="card-service-tab">
                        <MDBCard className="service-tab-style zoom-effect border-styles">
                            <MDBCardBody>
                                <div className="d-flex1 align-items-center">
                                    <div className="col-lg-4">
                                        <img
                                            src="images/img/s7.png"
                                            alt=""
                                            style={{ width: "90px", height: "90px" }}
                                            className="rounded-circle spinning"
                                        />
                                    </div>
                                    <div className="ms-3 col-lg-8">
                                        <h4 className=" mb-1" style={{ textAlign: "center" }}>
                                        Statement
                                        </h4>
                                    </div>
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </Link>
                </MDBCol>
            </MDBRow>
        </div>
    );
}
