import PhotoAlbum from "react-photo-album";

const photos = [
    { src: "images/event.jpg", width: 800, height: 600 },
    { src: "images/ind1.jpg", width: 1600, height: 900 },
    {
        src: "images/corp1.jpg",
        width: 800,
        height: 600,
    },
    {
        src: "images/corp2.jpg",
        width: 1600,
        height: 900,
    },
    {
        src: "images/g1.png",
        width: 800,
        height: 600,
    },
    { src: "images/g2.png", width: 1600, height: 900 },
    { src: "images/g3.png", width: 800, height: 600 },
    {
        src: "images/g4.png",
        width: 1600,
        height: 900,
    },
    {
        src: "images/g5.png",
        width: 800,
        height: 600,
    },
    {
        src: "images/g6.png",
        width: 1600,
        height: 900,
    },
];

export default function Gallery() {
    return (
        <div className="container-fluide gallery-img">
            <PhotoAlbum layout="rows" photos={photos} />
        </div>
    );
}
