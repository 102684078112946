// import React, { useState, useEffect } from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { formattedDate, formatTime } from "../../../helper/dateformate";


// const Test1 = () => {
//   const [locationData, setLocationData] = useState({
//     inputAddress: '',
//     coordinates: {
//       fromAddress: null,
//       currentLocation: null,
//     },
//     addresses: {
//       fromCoordinates: null,
//       currentLocation: null,
//     },
//   });
//   const [culatitude, setCulatitude] = useState();
//   const [culongitude, setCulongitude] = useState();

//   const [startDate, setStartDate] = useState();

//   const handleInputChange = (event) => {
//     const newAddress = event.target.value;
//     setLocationData((prevLocationData) => ({
//       ...prevLocationData,
//       inputAddress: newAddress,
//     }));

//     // Convert the input address to coordinates
//     convertAddressToCoordinates(newAddress, 'fromAddress');
//   };

//   const convertAddressToCoordinates = async (address, type) => {
//     try {
//       // Replace 'YOUR_API_KEY' with your actual Google Maps API key
//       const apiKey = 'YOUR_API_KEY';
//       const response = await fetch(
//         `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
//           address
//         )}&key=${apiKey}`
//       );
//       const data = await response.json();

//       if (data.results.length > 0) {
//         const { lat, lng } = data.results[0].geometry.location;

//         setLocationData((prevLocationData) => ({
//           ...prevLocationData,
//           coordinates: {
//             ...prevLocationData.coordinates,
//             [type]: { latitude: lat, longitude: lng },
//           },
//           addresses: {
//             ...prevLocationData.addresses,
//             [type === 'fromAddress' ? 'fromCoordinates' : 'currentLocation']: address,
//           },
//         }));
//         setCulatitude(lat);
//         setCulongitude(lng);
//       } else {
//         setLocationData((prevLocationData) => ({
//           ...prevLocationData,
//           coordinates: {
//             ...prevLocationData.coordinates,
//             [type]: null,
//           },
//           addresses: {
//             ...prevLocationData.addresses,
//             [type === 'fromAddress' ? 'fromCoordinates' : 'currentLocation']: null,
//           },
//         }));
//       }
//     } catch (error) {
//       console.error('Error converting address to coordinates:', error);
//     }
//   };

//   const getCurrentLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         async (position) => {
//           const { latitude, longitude } = position.coords;

//           // Get address using reverse geocoding
//           try {
//             const apiKey = 'YOUR_API_KEY'; // Replace with your Google Maps API key
//             const response = await fetch(
//               `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
//             );
//             const data = await response.json();

//             if (data.results.length > 0) {
//               const address = data.results[0].formatted_address;
//               setLocationData((prevLocationData) => ({
//                 ...prevLocationData,
//                 coordinates: {
//                   ...prevLocationData.coordinates,
//                   currentLocation: { latitude, longitude },
//                 },
//                 addresses: {
//                   ...prevLocationData.addresses,
//                   currentLocation: address,
//                 },
//                 inputAddress: address, // Update input address with current location address
//               }));
//               setCulatitude(latitude);
//               setCulongitude(longitude);
//             } else {
//               setLocationData((prevLocationData) => ({
//                 ...prevLocationData,
//                 coordinates: {
//                   ...prevLocationData.coordinates,
//                   currentLocation: null,
//                 },
//                 addresses: {
//                   ...prevLocationData.addresses,
//                   currentLocation: null,
//                 },
//               }));
//             }
//           } catch (error) {
//             console.error('Error getting address:', error);
//             setLocationData((prevLocationData) => ({
//               ...prevLocationData,
//               coordinates: {
//                 ...prevLocationData.coordinates,
//                 currentLocation: null,
//               },
//               addresses: {
//                 ...prevLocationData.addresses,
//                 currentLocation: null,
//               },
//             }));
//           }
//         },
//         (error) => {
//           console.error('Error getting location:', error.message);
//           setLocationData((prevLocationData) => ({
//             ...prevLocationData,
//             coordinates: {
//               ...prevLocationData.coordinates,
//               currentLocation: null,
//             },
//             addresses: {
//               ...prevLocationData.addresses,
//               currentLocation: null,
//             },
//           }));
//         }
//       );
//     } else {
//       console.error('Geolocation is not supported by this browser.');
//     }
//   };

//   console.log({Latitude:culatitude,Longitude:culongitude});



//   const [count, setCount] = useState(25);

//   const handleIncrease = () => {
//     setCount(count + 1);
//   };

//   const handleDecrease = () => {
//     if (count > 0) {
//       setCount(count - 1);
//     }
//   };

//   const handleChange = (event) => {
//     // You can add additional validation or constraints here if needed
//     const newCount = parseInt(event.target.value, 10);
//     setCount(isNaN(newCount) ? 25 : newCount);
//   };


// console.log(formattedDate(startDate));

//   return (
//     <div>
//       <label>
//         Enter Address:
//         <input
//           type="text"
//           value={locationData.inputAddress}
//           onChange={handleInputChange}
//         />
//       </label>
//       <button onClick={getCurrentLocation}>Get Current Location</button>

//       {locationData.coordinates.fromAddress && (
//         <div>
//           <p>Converted Coordinates (from input address):</p>
//           <p>
//             Latitude: {locationData.coordinates.fromAddress.latitude}
//           </p>
//           <p>
//             Longitude: {locationData.coordinates.fromAddress.longitude}
//           </p>
//         </div>
//       )}

//       {locationData.coordinates.currentLocation && (
//         <div>
//           <p>Current Location:</p>
//           <p>
//             Latitude: {culatitude}
//           </p>
//           <p>
//             Longitude: {culongitude}
//           </p>
//           <p>
//             Address: {locationData.addresses.currentLocation}
//           </p>
//         </div>
//       )}


// <div>
//       <label htmlFor="countInput">Count:</label>
//       <input
//         type="number"
//         id="countInput"
//         value={count}
//         onChange={handleChange}
//       />
//       <button onClick={handleIncrease}>Increase</button>
//       <button disabled={count < 26} onClick={handleDecrease}>Decrease</button>
//     </div>
    
//     <DatePicker  className="odc-adress-lg-input-style1 mt-4 number-font" selected={startDate} onChange={(date) => setStartDate(date)}   minDate={new Date()}  placeholderText='Select your date' />



//     </div>
//   );
// };

// export default Test1;


// import React, { useState, useEffect } from 'react';
// import firebase from './firebase';

// const Test1 = () => {
//   const [state, setState] = useState({
//     mobile: '',
//     otp: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setState((prevState) => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const configureCaptcha = () => {
//     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
//       'size': 'invisible',
//       'callback': (response) => {
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         onSignInSubmit();
//         console.log("Recaptcha verified");
//       },
//       defaultCountry: "IN"
//     });
//   };

//   const onSignInSubmit = (e) => {
//     e.preventDefault();
//     configureCaptcha();
//     const phoneNumber = "+91" + state.mobile;
//     console.log(phoneNumber);
//     const appVerifier = window.recaptchaVerifier;
//     firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
//       .then((confirmationResult) => {
//         window.confirmationResult = confirmationResult;
//         console.log("OTP has been sent");
//       })
//       .catch((error) => {
//         console.log("SMS not sent");
//       });
//   };

//   const onSubmitOTP = (e) => {
//     e.preventDefault();
//     const code = state.otp;
//     console.log(code);
//     window.confirmationResult.confirm(code)
//       .then((result) => {
//         const user = result.user;
//         console.log(JSON.stringify(user));
//         alert("User is verified");
//       })
//       .catch((error) => {
//         console.log("User couldn't sign in (bad verification code?)");
//       });
//   };

//   return (
//     <div>
//       <h2>Login Form</h2>
//       <form onSubmit={onSignInSubmit}>
//         <div id="sign-in-button"></div>
//         <input type="number" name="mobile" placeholder="Mobile number" required onChange={handleChange} />
//         <button type="submit">Submit</button>
//       </form>

//       <h2>Enter OTP</h2>
//       <form onSubmit={onSubmitOTP}>
//         <input type="number" name="otp" placeholder="OTP Number" required onChange={handleChange} />
//         <button type="submit">Submit</button>
//       </form>
//     </div>
//   );
// };

// export default Test1;

import React, { useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";

const Test1 = () => {
  const [selectedDay, setSelectedDay] = useState(null);

  return (
    <div>
      <DatePicker
        value={selectedDay}
        onChange={setSelectedDay}
        inputPlaceholder="Select a day"
        shouldHighlightWeekends
      />
      {selectedDay && (
        <p>Selected Day: {`${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`}</p>
      )}
    </div>
  );
};

export default Test1;