import { ADDUSER_CHANGE, REMOVEUSER_CHANGE } from "./adduserActionTypes";

// eslint-disable-next-line import/prefer-default-export
export const adduserChange = (adduser) => ({
    type: ADDUSER_CHANGE,
    adduser,
});

export const removeuserChange = () => ({
    type: REMOVEUSER_CHANGE,
});
