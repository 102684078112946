
export const url = "https://hogist.com/api/v2";
export const apiurl = "https://hogist.com/api/v2";
// export const url = "https://hogisttest.sytes.net/api/v2";
// export const apiurl = "https://hogisttest.sytes.net/api/v2";
// export const url = "http://ec2-54-237-64-41.compute-1.amazonaws.com/api/v2";
// export const apiurl = "http://ec2-54-237-64-41.compute-1.amazonaws.com/api/v2";
// export const url = "https://ec2demo.sytes.net/api/v2";
// export const apiurl = "https://ec2demo.sytes.net/api/v2";

