import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import Hlogo from "./../../img/logo.png";

//
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

export default function MainSideNav() {
    const links = [
        { title: <FormattedMessage id="topbar.home" defaultMessage="Home " />, url: "/" },
        { title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: "/about-us" },
        { title: <FormattedMessage id="topbar.trackOrder" defaultMessage="Our Services" />, url: "/service" },
        { title: <FormattedMessage id="topbar.contacts" defaultMessage="Contact Us" />, url: "/contact-us" },
        { title: <FormattedMessage id="topbar.storeLocation" defaultMessage="Downloade App" />, url: "/" },
    ];

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    //  const linksList = links.map((item, index) => (
    //     <div key={index} className="topbar__item topbar__item--link topbar-main-txt ">
    //         <Link className="topbar-link " to={item.url}>{item.title}</Link>
    //     </div>
    // ));

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            // style={{ backgroundColor: "#c60800", color: "rgb(255 255 255 / 87%)" }}
        >
            <div className="nav-panel__logo drawer-logo">
                <Link to="/">
                    <img src="images/logo-red.png" alt="logo" style={{ width: "73%" }} />
                </Link>
            </div>

            <List>
                {/* {links.map((text, index) => ( */}
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link className="topbar-link" to="/" target="_self">
                            {" "}
                            <ListItemText className="dawer-txt" primary="Home " />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link className="topbar-link" to="/" target="_self">
                            {" "}
                            <ListItemText className="dawer-txt" primary="Reviews" />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link className="topbar-link" to="/" target="_self">
                            {" "}
                            <ListItemText className="dawer-txt" primary="Features" />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link className="topbar-link" to="/hogist-menu-bank/" target="_self">
                            {" "}
                            <ListItemText className="dawer-txt" primary="Menu Bank" />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link className="topbar-link" to="/industrial-catering-services-near-me/" target="_self">
                            {" "}
                            <ListItemText className="dawer-txt" primary="Industrials" />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link className="topbar-link" to="/corporate-catering-services-in-chennai/" target="_self">
                            {" "}
                            <ListItemText className="dawer-txt" primary="Corporate" />
                        </Link>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton className="" >
                        <Link className="topbar-link itemcenter " to="/" target="_self">
                            {" "}
                            <button className="m3-sidebare-btn-style">GET STARTED</button>
                        </Link>
                    </ListItemButton>
                </ListItem>

                {/* ))} */}
            </List>

            {/* <List>
                {links.map((text, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemButton>
                            
                            <Link className="topbar-link" to={text.url}>
                                {" "}
                                <ListItemText className="dawer-txt" primary={text.title} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
            <Divider />
        </Box>
    );

    return (
        <div>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <i className="fas fa-bars nav-menubar-style rotate" />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                       
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
