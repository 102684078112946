import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import StarIcon from "@mui/icons-material/Star";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CancelIcon from "@mui/icons-material/Cancel";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { useParams } from "react-router-dom";
import { apiurl } from "../../../api/api_url";
import { useDispatch } from "react-redux";
import { addedmenuChange } from "../../../store/selectedmenu";
import { removemenuChange } from "../../../store/selectedmenu";
import Rating from "@mui/material/Rating";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Addmenu = () => {

    const { slug } = useParams();
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [openReview, setOpenReview] = useState(false);
    const [sortOrder, setSortOrder] = useState("all");
    const [foodTypeFilter, setFoodTypeFilter] = useState("all");

    const [cardState, setCardState] = useState({ count: 25, isEditing: false });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [totalCardCount, setTotalCardCount] = useState(0);
    const [menulistapi, setMenulistapi] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);


    useEffect(() => {
        getApiData();
    }, []);
    
    const getApiData = async () => {
        const headers = { "Content-type": "application/json" };
        const response = await fetch(`${apiurl}/odc-vendor-with-menu/${slug}`, { headers, method: "GET" }).then(
            (response) => response.json()
        );

        // update the state
        setMenulistapi(response.data);
    };

    // console.log(menulistapi);

    const menuItemlist = menulistapi.menuList;

    const handleAddClick = (item) => {
        // Check if the item is already in the selectedItems array
        const isItemSelected = selectedItems.some((selectedItem) => selectedItem._id === item._id);

        if (!isItemSelected) {
            // If the item is not selected, add it to the selectedItems array
            setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);


            
            // Set the card state for the selected item
            setCardState((prev) => ({
                ...prev,
                [item._id]: { count: 25, isEditing: false },
            }));

            // Set the total card count
            setTotalCardCount(totalCardCount + 1);

            // Set the snackbar to open
            setSnackbarOpen(true);
            console.log(selectedItems,item);
            // Dispatch the selected items
            dispatch(addedmenuChange([...selectedItems, item]));
            // console.log([...selectedItems, item]);
        } else {
            // If the item is already selected, you may want to handle it differently,
            // for example, remove it from the selectedItems array.
            // Modify this part according to your requirements.
            const updatedItems = selectedItems.filter((selectedItem) => selectedItem._id !== item._id);
            setSelectedItems(updatedItems);

            // If you want to update the card state when removing an item, do it here.
            setCardState((prev) => ({
                ...prev,
                [item._id]: { count: 25, isEditing: false },
            }));

            // If you want to update the total card count when removing an item, do it here.
            setTotalCardCount(totalCardCount - 1);

            // Dispatch the selected items
            dispatch(removemenuChange(updatedItems));
        }
  

        // ... rest of your code
    };




    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleCategory = async (category) => {
        setSelectedCategory(category);

        //api
        await fetch(`${apiurl}/odc-menu-list`, {
            method: "POST",
            body: JSON.stringify({ vendor: menulistapi.vendor._id, events: category }),
            headers: {
                "Content-type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);
                // setMenulistapi(json.data);
            });

        // console.log(menulistapi);
    };

    const filteredItems =
        selectedCategory === "All" ? menuItemlist : menuItemlist.filter((item) => item.events === selectedCategory);

    const itemsToRender = Array.isArray(filteredItems) ? filteredItems : [];
    const sortedItems = [...itemsToRender]
        .filter((item) => {
            if (foodTypeFilter === "VEG") {
                return item.vegType === "VEG";
            } else if (foodTypeFilter === "NON-VEG") {
                return item.vegType === "NON-VEG";
            } else {
                return true; // Include all items
            }
        })
        .sort((a, b) => {
            if (sortOrder === "highToLow") {
                return b.price - a.price;
            } else if (sortOrder === "lowToHigh") {
                return a.price - b.price;
            } else {
                return 0; // No sorting if "All" is selected
            }
        });

    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleFoodTypeChange = (event) => {
        setFoodTypeFilter(event.target.value);
    };

    // console.log(filteredItems );
    const breadcrumbs = [
        <a href="/odc/home" key="1" color="inherit">
            Home
        </a>,

        <Typography key="2" color="text.primary">
            {menulistapi && menulistapi.vendor && menulistapi.vendor.category}
        </Typography>,
    ];

    const handleClickOpenreview = (e) => {
        e.preventDefault();
        setOpenReview(true);
    };

    const handleCloseMenu = () => {
        setOpenReview(false);
    };
    // console.log(menulistapi);

    return (
        <div>
            <div>
                <div>
                    <div>
                        <div
                            style={{
                                backgroundImage:
                                    'url("./images/odc-menubg.png")',
                                backgroundSize: "cover",
                                height: "400px",
                            }}
                        >
                            <div className="rs-menu-titel-style">
                                <h2>Your Menu</h2>
                            </div>
                        </div>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            style={{ margin: "1% 2%" }}
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </div>

                    <div className="container resturent_header">
                        <div>
                            <div className="row mt-4">
                                <div className="col-lg-8">
                                    <h2>{menulistapi && menulistapi.vendor && menulistapi.vendor.fullName}</h2>
                                    <p>{menulistapi && menulistapi.vendor && menulistapi.vendor.address}</p>
                                    <p>
                                        <strong> Cuisines : </strong>{" "}
                                        {menulistapi && menulistapi.vendor && menulistapi.vendor.cuisine}
                                    </p>
                                    <p>
                                        <strong>FSSAI License No. :</strong>{" "}
                                        <span className="number-font">
                                            {menulistapi && menulistapi.vendor && menulistapi.vendor.fssai}
                                        </span>
                                    </p>
                                    {/* <button>View Reviews</button> */}
                                </div>
                                <div className="col-lg-4 view-center-footer-style">
                                    <button onClick={handleClickOpenreview} className="odc-star-review-btn-style">
                                        View Reviews <StarRateRoundedIcon />
                                    </button>
                                    <Dialog
                                        open={openReview}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseMenu}
                                        aria-describedby="alert-dialog-slide-description"
                                        className="opt-resp"
                                        // sx={{ width:"100%" }}
                                    >
                                        <DialogTitle
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "#c60800",
                                                color: "#fff",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            {"Customer Reviews"}{" "}
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleCloseMenu}
                                            sx={{
                                                position: "absolute",
                                                right: 26,
                                                top: 33,
                                                color: "#fff",
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                        <DialogContent>
                                            {/* <ul className="odc-cart-menu-list-item-style"> */}
                                            

                                            {menulistapi&&menulistapi.reviewList&&menulistapi.reviewList.length>0?(menulistapi.reviewList.map((items,indexd)=><li key={indexd}
                                                style={{
                                                    backgroundColor: "#fff",
                                                    width: "100%",
                                                    margin: "4% 0%",
                                                    padding: "2% 4%",
                                                    boxShadow: "4px -1px 5px 2px rgba(0, 0, 0, 0.12)",
                                                    borderRadius: "7px",
                                                }}
                                            >
                                               
                                                <h6>{items.customer}</h6>
                                                <Rating
                                                    name="text-feedback"
                                                    value={items.star}
                                                    readOnly
                                                    precision={0.5}
                                                    emptyIcon={
                                                        <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                                                    }
                                                />
                                                {/* <img src="images/review.jpg" alt="" style={{width:"35%"}}/> */}
                                                <p style={{ fontSize: "16px", lineHeight: "21px" }}>
                                                {items.text}
                                                </p>
                                            </li>)):"No Reviews Added"}
                                         
                                            {/* </ul> */}
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <p className="min-p-style">** Minimum 25 Count to order **</p>

            <div style={{ marginTop: " 5%" }}>
                <div className="d-flex">
                    <button
                        // key={category}
                        onClick={() => handleCategory("All")}
                        className={selectedCategory === "All" ? "rs-menu-event-list-active" : "rs-menu-event-list"}
                    >
                        All
                    </button>
                    {menulistapi &&
                        menulistapi.vendor &&
                        menulistapi.vendor.events.map((category, index) => (
                            <button
                                key={index}
                                onClick={() => handleCategory(category)}
                                className={
                                    selectedCategory === category ? "rs-menu-event-list-active" : "rs-menu-event-list"
                                }
                            >
                                {category}
                            </button>
                        ))}
                </div>
                <div style={{ textAlign: "end", marginTop: "2%", paddingRight: "4%" }}>
                    <label style={{ marginRight: "1%" }}>
                        Sort by Price:
                        <select value={sortOrder} onChange={handleSortChange}>
                            <option value="all">All</option>
                            <option value="highToLow">High to Low</option>
                            <option value="lowToHigh">Low to High</option>
                        </select>
                    </label>

                    <label>
                        Food Type:
                        <select value={foodTypeFilter} onChange={handleFoodTypeChange} style={{ paddingLeft: "35px" }}>
                            <option value="all">All</option>
                            <option value="VEG">Veg </option>
                            <option value="NON-VEG">Non-Veg</option>
                        </select>
                        <img
                            src={
                                foodTypeFilter === "VEG"
                                    ? "images/veg.png"
                                    : foodTypeFilter === "NON-VEG"
                                    ? "images/nonveg.png"
                                    : "images/both.png"
                            }
                            alt="menu Type"
                            className="odc-food-type-img"
                        />
                    </label>
                </div>
                <div className="rs-menu-container">
                    <div className="row justify-content-center">
                        {sortedItems.length > 0 ? (
                            sortedItems.map((item, indx) => (
                                <div className="col-lg-4 mb-4 mt-4" key={indx}>
                                    <div className="card-styleborder txt-align-center ">
                                        <div className="row mt-3 mb-2">
                                            <div className="odc-menu-card-img-style">
                                                <img
                                                    src={item.image}
                                                    alt=""
                                                    style={{ width: "100%", height: "100%", borderRadius: "7px" }}
                                                />
                                            </div>
                                            {/* <div className="odc-card-menuname-txt-style"> */}
                                            <h4 className="menu-cambo-h4-style">{item.label}</h4>
                                            {/* </div> */}
                                        </div>
                                        <div className="row menu-cambo-price">
                                            <div className="col-lg-6">
                                                <h4 className="number-font">₹ {item.price}</h4>{" "}
                                            </div>
                                            <div className="col-lg-6">
                                                <img
                                                    src={
                                                        item.vegType === "VEG" ? "images/veg.png" : "images/nonveg.png"
                                                    }
                                                    alt="menu Type"
                                                    style={{ width: "18%" }}
                                                />
                                            </div>
                                        </div>

                                        <ul className="rs-menu-list-ul-style">
                                            {item.items.map((itemsMenu, indexed) => (
                                                <li key={indexed}>
                                                    <span style={{ color: "#c60800", fontSize: "30px" }}>
                                                        <FoodBankIcon />
                                                    </span>{" "}
                                                    {itemsMenu.label} ({itemsMenu.size}
                                                    {itemsMenu.unit})
                                                </li>
                                            ))}
                                        </ul>

                                        <button className="odc-card-add-btn1" onClick={() => handleAddClick(item)}>
                                            {selectedItems &&
                                            selectedItems.some((selectedItem) => selectedItem._id === item._id)
                                                ? "Added"
                                                : "Add"}{" "}
                                            <AddShoppingCartOutlinedIcon />
                                        </button>

                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                            }}
                                            severity="success"
                                            open={snackbarOpen}
                                            //   autoHideDuration={6000}
                                            //   onClose={handleCloseSnackbar}
                                            // message={`Added ${cardState.count} item(s) to ${totalCardCount} card(s)`}
                                            message={`Added ${selectedItems && selectedItems.length} item(s) `}
                                            action={
                                                <React.Fragment>
                                                    <Link to="/odc/addcart">
                                                        {" "}
                                                        <button className="odc-view-card-btn-style">
                                                            View Cart <ShoppingCartCheckoutIcon />
                                                        </button>
                                                    </Link>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="close"
                                                        color="inherit"
                                                        onClick={handleCloseSnackbar}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </React.Fragment>
                                            }
                                        />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src="images/notfound.png" alt="Menu" style={{ width: "60%" }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Addmenu;
