import React from "react";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";
//

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MenuChangedData() {
    const [show, setShow] = useState(false);
    const [weightage, setWeightage] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handelWeight = () => {
        setWeightage(true);
    };
    const handleClosed = () => {
        setWeightage(false);
    };

    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="container txt-colr">
            <form>
                <MDBRow>
                    <MDBCol lg={12} className="mrbt">
                        <MDBCard className="service-tab-style border-styles" style={{ textAlign: "center" }}>
                            <h4 className="fw-bold mb-1 order-details-header">Menu Changed Order ID : #001</h4>
                            <MDBCardBody>
                                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                                    <ListItem>
                                        <ListItemText> Ordered Date </ListItemText>
                                        <ListItemText style={{ marginLeft: "-19px" }}> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}> 01/02/2023 </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Ordered Time </ListItemText>
                                        <ListItemText style={{ marginLeft: "-19px" }}> : </ListItemText>
                                        <ListItemText> 02:30 PM </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Service Date </ListItemText>
                                        <ListItemText style={{ marginLeft: "-19px" }}> : </ListItemText>
                                        <ListItemText> 28/02/2023 </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Current Menu type </ListItemText>
                                        <ListItemText style={{ marginLeft: "-55px" }}> : </ListItemText>
                                        <ListItemText> Breakfast </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Menu Item to be Change </ListItemText>
                                        <ListItemText style={{ marginLeft: "-119px" }}> : </ListItemText>
                                        <ListItemText> Idlii </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> New Menu </ListItemText>
                                        <ListItemText style={{ marginLeft: "-19px" }}> : </ListItemText>
                                        <ListItemText> Pongal </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Order Count </ListItemText>
                                        <ListItemText style={{ marginLeft: "-19px" }}> : </ListItemText>
                                        <ListItemText> 200 </ListItemText>
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText> Delivery Address</ListItemText>
                                        <ListItemText style={{ marginLeft: "-30px" }}>: </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}> Chennai </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> COVID Check </ListItemText>
                                        <ListItemText style={{ paddingLeft: "5%" }}> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start", paddingLeft: "4%" }}>
                                            <Button className="details-btn-pending">Pending</Button>
                                        </ListItemText>
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText> Order Status </ListItemText>
                                        <ListItemText style={{ paddingLeft: "5%" }}> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start", paddingLeft: "4%" }}>
                                            <Button className="details-btn-pending" onClick={toggleVisibility}>
                                                Pending
                                            </Button>
                                        </ListItemText>
                                    </ListItem>
                                    {isVisible === true ? (
                                        <div>
                                            <h2
                                                className=" mb-4 mt-2"
                                                style={{ textAlign: "center", color: "#dc0000" }}
                                            >
                                                Order Status
                                            </h2>

                                            <StatusSteps />
                                        </div>
                                    ) : null}
                                    <ListItem>
                                        <ListItemText style={{ width: "9%" }}> Reason for Change Menu </ListItemText>
                                        <ListItemText> : </ListItemText>
                                        <ListItemText style={{ textAlign: "start" }}>High Cost</ListItemText>
                                    </ListItem>
                                </List>

                                {/* <div className='dash-line'></div> */}
                                {/* 
      <h3 className=' mb-4 mt-4'  style={{ color: '#000'}}><span style={{color:' #c60800'}}>B</span>ill Details</h3>
      <div className='inline-flex-st'>
      <img className='veg-details-img' src='https://i.pinimg.com/originals/e4/1f/f3/e41ff3b10a26b097602560180fb91a62.png' alt=''/>
      <h4 style={{color: '#000'}}> Dinner</h4>
      </div>
     <div className='food-item-details'>
      <span style={{padding: '1px 7px'}} >Chappati 3</span> ,  
      <span style={{padding: '1px 7px'}} >Gravy Variety</span>
      </div> */}
                                {/* <div className='container'>

      <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
      <div className='dash-line'></div>
      <ListItem className='count-ord-btn'>
       <ListItemText> Quantity   </ListItemText> 
       <ListItemText > Price </ListItemText>
        <ListItemText> Total </ListItemText>
      </ListItem>
      <div className='dash-line'></div>
      <ListItem className='count-ord-btn'>
       <ListItemText > 35</ListItemText>
      <ListItemText style={{ paddingLeft: '5%'}}><span>₹ </span> 55 </ListItemText>
        <ListItemText >  <span>₹ </span> 1925 </ListItemText>
      </ListItem>
      
      
     
    </List>
    <Button className='details-btn-pending weight-btn'  onClick={handelWeight} > See Food Weight</Button>
    </div> */}
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                {/* <Modal show={weightage} onHide={handleClosed}>
                <form>
                    <Modal.Header className="pop_head">
                    <h3 className=' mb-4 mt-4'  style={{ color: '#fff', marginLeft:'27%'}}><span style={{color:' #000'}}>S</span>ee Food Weight</h3>

                        <Button className="cls-btn" style={{color:'#fff',}} onClick={handleClosed}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{color: '#000'}}>
                          
                        <div className='inline-flex-st'>
      <img style={{width: '5%',height:'5%'}} className='veg-details-img' src='https://i.pinimg.com/originals/e4/1f/f3/e41ff3b10a26b097602560180fb91a62.png' alt=''/>
      <h4 > Dinner</h4>
      </div>
     <div className='food-item-details'>
      <span style={{padding: '1px 7px'}} >Chappati 3</span> ,  
      <span style={{padding: '1px 7px'}} >Gravy Variety</span>
      </div>
<div className='container'>

      <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
      <div className='dash-line'></div>
      <ListItem className='count-ord-btn'>
       <ListItemText> Quantity   </ListItemText> 
       <ListItemText > Price </ListItemText>
        <ListItemText> Total </ListItemText>
      </ListItem>
      <div className='dash-line'></div>
      <ListItem className='count-ord-btn'>
       <ListItemText > 35</ListItemText>
      <ListItemText style={{ paddingLeft: '5%'}}><span>₹ </span> 55 </ListItemText>
        <ListItemText >  <span>₹ </span> 1925 </ListItemText>
      </ListItem>
      <div style={{textAlign: 'inherit',color:'#585858b3'}}>
      <ListItemText >   Enter Food Count (Numbers)  </ListItemText>
      <ListItemText >    Enter Liquide Food Count (Litres) </ListItemText>
      </div>
     
    </List> */}

                {/* <Button  onClick={handelWeight} style={{marginLeft: '35% ', marginTop:'20px'}} className='safety-check-popup-btn'  > Ok</Button> */}

                {/* </div>

                          </div>
                       

                    </Modal.Body> */}

                {/* <Modal.Footer style={{ borderTop: "none" }}>
                        <Button  onClick={handleClose} className="rgbtn1" variant="primary">
                           Ok
                        </Button>
                    </Modal.Footer> */}
                {/* </form>
            </Modal> */}

                {/* <Modal show={show} onHide={handleClose}>
                <form>
                    <Modal.Header className="pop_head">
                    <img  className='detail-popup-logo' src={Hlogo} alt="logo" />

                        <Button className="cls-btn" style={{color:'#fff',}} onClick={handleClose}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                          <img src='https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/delivery-challan/index/delivery-challan-material.webp' alt='Delivery Challan'/>
                        </div>
                       

                    </Modal.Body>

                    <Modal.Footer style={{ borderTop: "none" }}>
                        <Button  onClick={handleClose} className="rgbtn1" variant="primary">
                           Ok
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal> */}
            </form>
        </div>
    );
}
