// react
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// application
import CartIndicator from "./IndicatorCart";
import Departments from "./Departments";
import IndicatorAccount from "./IndicatorAccount";
import NavLinks from "./NavLinks";
import Hlogo from "./../../img/logo1.png";
import Hlogo1 from "./../../img/logo.png";
import Hlogo2 from "./../../img/logo-red.png";
import SidebarNav from "./SidebarNav";
import MainDropdownButton from "./MainDropdownButton";
//
import Notificationodc from "./Notificationodc";
import { useHistory } from "react-router-dom";

function NavPanel(props) {
    const { layout, user,odcuser } = props;

    const location = useLocation();
    const [isHomePage, setIsHomePage] = useState(false);
    const [isHomePage1, setIsHomePage1] = useState(false);
    const [isHomePage2, setIsHomePage2] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMainHomePage, setIsMainHomePage] = useState(false);
    const [isPaymentPage, setIsPaymentPage] = useState(false);
    //
    const [location12, setLocation] = useState("");
    const [restaurants, setRestaurants] = useState([]);
    const [currentLocation, setCurrentLocation] = useState("");
    const history = useHistory();
    //
   
    useEffect(() => {
        setIsHomePage(location.pathname === "/industry/home" || location.pathname === "/corporate/home");
        // setIsHomePage1(location.pathname === "/");
        setIsHomePage2(location.pathname === "/mainhome/odc");
        setIsMainHomePage(location.pathname === "/");
        // setIsPaymentPage(location.pathname === "/payment");
    }, [location]);

    useEffect(() => {
        function handleScroll() {
            const isScrolled = window.scrollY > 0;
            setIsScrolled(isScrolled);
        }
        //   console.log(user);
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let logo;
    let departments = null;
    let searchIndicator;

    if (layout === "default") {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/">
                    {/* {scrolled ? <img src={Hlogo1} alt="logo" style={{ width: "73%" }} /> : <img src={Hlogo} alt="logo" style={{ width: "73%" }} />} */}
                    {isHomePage ? (
                        isScrolled ? (
                            <img src={Hlogo1} alt="logo" style={{ width: "73%" }} />
                        ) : (
                            <img src={Hlogo} alt="logo" style={{ width: "73%" }} />
                        )
                    ) : isHomePage1 ? (
                        isScrolled ? (
                            <img src={Hlogo1} alt="logo" style={{ width: "73%" }} />
                        ) : (
                            <img src={Hlogo} alt="logo" style={{ width: "73%" }} />
                        )
                    ) : isHomePage2 ? (
                        isScrolled ? (
                            <img src={Hlogo2} alt="logo" style={{ width: "73%" }} />
                        ) : (
                            <img src={Hlogo} alt="logo" style={{ width: "73%" }} />
                        )
                    ) : (
                        <img src={Hlogo1} alt="logo" style={{ width: "73%" }} />
                    )}
                </Link>
            </div>
        );

        // searchIndicator = <IndicatorSearch />;
    }

    const odchandleLogin = (e) => {
        e.preventDefault();

        history.push("/odc/login");
    };
    const odchandleSignin = (e) => {
        e.preventDefault();

        history.push("/odc/signin");
    };


    if (layout === "compact") {
        departments = (
            <div className="nav-panel__departments">
                <Departments />
            </div>
        );
    }

    const currentPath = window.location.pathname;
    const firstPath = "/odc";
    const secondPath = "/mainhome/odc"; // Specify the first path here
    const basePath = "/site";
    const firstPath1 = "/payment";

    // Check if the currentPath starts with the firstPath
    const shouldShowDesign = currentPath.startsWith(firstPath);
    const notshowDesign = currentPath.startsWith(secondPath);
    const inducorp = currentPath.startsWith(basePath);
    const paymentPath = currentPath.startsWith(firstPath1);
    // console.log(user);
    return (
        <div>
            {isHomePage || inducorp ? (
                <div>
                    {/* // <div className={scrolled ? 'navpanelScrolled' : 'nav-panel'}> */}
                    <div className={isHomePage ? (isScrolled ? "navpanelScrolled" : "nav-panel") : "navpanelScrolled"}>
                        <div className="nav-panel__container container-fluid">
                            <div className="nav-panel__row">
                                <div className="col-lg-2">{logo}</div>

                                {/* {departments} */}
                                <div className="col-lg-8">
                                    <div className="nav-panel__nav-links nav-links">
                                        <NavLinks />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="row nav-panel__indicators">
                                        <div className="container" style={{ display: "flex", alignItems: "center" }}>
                                            <div className="col-lg-4"></div>
                                            <div className="col-lg-4">
                                                <MainDropdownButton />
                                            </div>
                                            <div className="col-lg-4">
                                                <IndicatorAccount user={user} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            ) : isMainHomePage ? null
            
            : shouldShowDesign ? (
                <div>
                    <div
                        className={
                            "navpanelScrolled"
                            // isHomePage2 ? (isScrolled ? "navpanelScrolled" : "home-nav-panel") : "navpanelScrolled"
                        }
                        style={{ backgroundColor: "#ffffff" }}
                    >
                        <div className="nav-panel__container container-fluid">
                            <div className="nav-panel__row">
                                <div className="col-lg-2">
                                    <div className="nav-panel__logo">
                                        <Link to="/">
                                            {/* {scrolled ? <img src={Hlogo1} alt="logo" style={{ width: "73%" }} /> : <img src={Hlogo} alt="logo" style={{ width: "73%" }} />} */}
                                            {/* {isHomePage ? (isScrolled ?  <img src={Hlogo1} alt="logo" style={{ width: "73%" }} /> : <img src={Hlogo} alt="logo" style={{ width: "73%" }} />) : <img src={Hlogo1} alt="logo" style={{ width: "73%" }} />}   */}
                                            <img src="images/logo-red.png" alt="logo" style={{ width: "73%" }} />
                                        </Link>
                                    </div>
                                </div>

                                {/* {departments} */}
                                <div className="col-lg-8 header-search-input">
                               
                                </div>
                                <div className="col-lg-2">
                                    <div className="row nav-panel__indicators">
                                        <div className="container" style={{ display: "flex" }}>
                                            
                                            <div className="col-lg-3 item-alinment-style">
                                                <MainDropdownButton />
                                            </div>
                                            <div className="col-lg-3 item-alinment-style">
                                                <CartIndicator />
                                            </div>
                                            <div className="col-lg-3 item-alinment-style">
                                                {/* <SidebarNav /> */}

                                                <Notificationodc/>

                                            </div>
                                            <div className="col-lg-3 item-alinment-style">
                                                <IndicatorAccount user={odcuser} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : notshowDesign ? (
                <div>
                    {/* {odcuser&&odcuser.fullNmae?():()} */}
                     <div className="row">
                            <div
                                className=" des-flex "
                                style={{
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    boxShadow: " 3px -1px 7px 1px rgba(0, 0, 0, 0.38)",
                                    position: "fixed",
                                    zIndex: " 1",
                                }}
                            >
                                <div className="col-lg-4 col-md-6">
                                    <Link to="/">
                                        <img src="images/logo-red.png" alt="" className="odc-head-logo" />
                                    </Link>
                                </div>
                                <div className="col-lg-3 col-md-1"></div>
                                <div className="col-lg-5 col-md-5">
                                    <div
                                        style={{
                                            justifyContent: "end",
                                            alignItems: "center",
                                            display: "flex",
                                            textAlign: "center",
                                        }}
                                    >

                                        
                                        <div className="col-lg-2">
                                            <button className="mainpage-login-btn" onClick={odchandleLogin}>
                                                {/* {odcuser&&odcuser.fullName?"Logout":"Login"} */}
                                                Login
                                            </button>
                                        </div>
                                        <div className="col-lg-3">
                                            <button className="mainpage-signup-btn" onClick={odchandleSignin}>
                                                Sign Up
                                            </button>
                                        </div>
                                        <div className="col-lg-2">
                                            <SidebarNav />
                                        </div>
                                        <div className="col-lg-5">
                                            <Link to="/become-a-vendor">
                                                <button className="vjoinbtn">Partner with Us</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            ): paymentPath? null: (
                <div>
                    <div
                        className={
                            isHomePage1 ? (isScrolled ? "navpanelScrolled" : "home-nav-panel") : "navpanelScrolled"
                        }
                    >
                        <div className="nav-panel__container container-fluid">
                            <div className="nav-panel__row">
                                <div className="col-lg-2">
                                    <div className="nav-panel__logo">
                                        <Link to="/">
                                            {/* {scrolled ? <img src={Hlogo1} alt="logo" style={{ width: "73%" }} /> : <img src={Hlogo} alt="logo" style={{ width: "73%" }} />} */}
                                            {/* {isHomePage ? (isScrolled ?  <img src={Hlogo1} alt="logo" style={{ width: "73%" }} /> : <img src={Hlogo} alt="logo" style={{ width: "73%" }} />) : <img src={Hlogo1} alt="logo" style={{ width: "73%" }} />}   */}
                                            <img src="images/logo.png" alt="logo" style={{ width: "73%" }} />
                                        </Link>
                                    </div>
                                </div>

                                {/* {departments} */}
                                <div className="col-lg-8">
                                    {/* <div className="nav-panel__nav-links nav-links">
                            <NavLinks />
                        </div> */}
                                </div>
                                <div className="col-lg-2">
                                    <div className="row nav-panel__indicators">
                                        <div className="container" style={{ display: "flex" }}>
                                            <div className="col-lg-4 item-alinment-style">
                                                {/* <IndicatorAccount user={user} /> */}
                                            </div>
                                            <div className="col-lg-4 item-alinment-style">
                                                <SidebarNav />
                                            </div>
                                            <div className="col-lg-4 item-alinment-style">
                                                <MainDropdownButton />
                                            </div>
                                            {/* <div className="col-lg-3">
                                                <CartIndicator/>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

NavPanel.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
