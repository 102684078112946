/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import { apiurl } from "../../../api/api_url";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Resturents(props) {
    const {guestuser } = props;
    const [menulistapi, setMenulistapi] = useState([]);
    const history = useHistory();
    const [address, setAddress] = useState(guestuser.address);
    const [coordinates, setCoordinates] = useState(null);

    useEffect(() => {
        // getApiData();
        handleGeocode ();
       
    }, []);



    const getApiData = async (lng,lat) => {
        await fetch(`${apiurl}/odc-vendor-list-near`, {
            method: "POST",
            body: JSON.stringify({long:lng, lat:lat, count:guestuser.count, }),
            headers: {
                "Content-type": "application/json",
                // Authorization: localStorage.getItem("tokenodc"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                setMenulistapi(json.data);
                // const guestData = json.data;
                console.log(json.data);
                // dispatch(guestuserChange(guestData));
    
                // history.push("/odc/home");
            });
        }


    const handleGeocode = async () => {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(guestuser&&guestuser.address)}&key=AIzaSyBSHDxKrKJXokOeEKYaLdklrDqO2GsNVRI`
          );
    
          const result = await response.json();
    
          if (result.status === 'OK') {
            const { lat, lng } = result.results[0].geometry.location;
            setCoordinates({ latitude: lat, longitude: lng });
            getApiData(lng,lat)
          } else {
            setCoordinates(null);
          }
        } catch (error) {
          console.error('Error fetching geocoding data:', error);
          setCoordinates(null);
        }
      };


    const handleSubmite = (item) => {
        // e.preventDefault();
        history.push(`/odc/resturent/${item.slug}`);
        console.log(item.slug);
    };

    const resturentClick = (item) => {
        // e.preventDefault();
        history.push(`/odc/resturent/${item.slug}`);
    };

    // console.log(guestuser);
    console.log(coordinates);
    // console.log(menulistapi);
    return (
        <div>
            <div style={{ color: "#000" }} className="col-lg-12">
                <h1 className="text-center mt-3">Results Nearby</h1>
                <div className="container-fluid mt-4">
                    <div className="row" style={{justifyContent: "center"}}>
                        {menulistapi&&menulistapi.length > 0?(menulistapi&&menulistapi.map((item, index) => (
                            <div key={index} className="col-11 col-md-6 col-lg-3 mx-0 mb-4 " onClick={()=>resturentClick(item)}>
                                <div className="card p-0 overflow-hidden shadow-on">
                                    <img src={item.image} className="card-img-top img-fluid rest-image-wt" alt="" />
                                    <div className="card-body" >
                                        <div className="cdbd-style">
                                            <h5>{item.fullName}</h5>
                                            {/* <h4 className="card-text">₹ {item.price}</h4> */}
                                            <p> {item.area}</p>
                                            <p><b>FSSAI No :</b> {item.fssai}</p>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <span className="star-rating-tab">
                                                        <StarIcon style={{ paddingRight: "10%" }} /> 5
                                                    </span>
                                                </div>
                                                <div className="col-lg-6">
                                                    <p className="card-text">
                                                        <Tooltip title="Cuisines" placement="top" arrow>
                                                            <MenuBookIcon className="menu-icon-style" />
                                                        </Tooltip>{" "}
                                                        {item.cuisine}
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <hr />
                                        <div className="item-alinment-style">
                                            <Button
                                                onClick={()=>handleSubmite(item)}
                                                // onMouseEnter={handleSubmite}
                                                // style={{ position: "absolute", left: "35%" }}
                                            >
                                                {" "}
                                                Quick View
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))):"No Results found in your location."}
                    </div>
                </div>
               
            </div>
        </div>
    );
}
Resturents.propTypes = {
    // menuadded: PropTypes.array.isRequired,
    guestuser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    // menuadded: state.menuadded,
    // odcuser: state.odcuser,
    guestuser: state. guestuser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Resturents);
// export default Resturents;
