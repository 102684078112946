/* eslint-disable no-unused-vars */
// react
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
// third-party
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
// application
import PageHeader from "../shared/PageHeader";

// data stubs
import payments from "../../../data/shopPayments";
import theme from "../../../data/theme";

// next
import Typography from "@mui/material/Typography";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaymentIcon from "@mui/icons-material/Payment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
//
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import WatchLaterSharpIcon from "@mui/icons-material/WatchLaterSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import { apiurl } from "../../../api/api_url";
import { formattedDate, formatTime } from "../../../helper/dateformate";
import { clearCart } from "../../../store/selectedmenu/addedmenuActions";

const Checkout1 = (props) => {
    const { menuadded, guestuser, odcuser } = props;
    const history = useHistory();
    const payments1 = payments;
    const [payment, setPayment] = useState("bank");
    const [activeStep, setActiveStep] = useState(0);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    // const [address, setAddress] = useState("");
    const [password, setPassword] = useState("");
    const [registerinfo, setRegisterinfo] = useState([]);
    const location = useLocation();
    const count = new URLSearchParams(location.search).get("count");
    //
    const [fullpay, setFullpay] = useState(false);
    const [advancepay, setAdvancepay] = useState(false);
    const [minPay, setMinPay] = useState();
    const [paymentOption, setPaymentOption] = useState("fullpay");
    //
    const dispatch = useDispatch();
    const [menulistapi, setMenulistapi] = useState([]);

    useEffect(() => {
        getApiData();
    }, []);
    // order
    const getApiData = async () => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("tokenodc") };
        const response = await fetch(`${apiurl}/odc-cart`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(response.data);
    };

    // console.log( guestuser);
    console.log(guestuser, odcuser, menulistapi);

    //     const calculateTenPercent = () => {
    //         if(advancepay){
    //  const calculatedTenPercent = (10 / 100) * menulistapi&&menulistapi.total;
    //         setTenPercent(calculatedTenPercent);
    //         }

    //       };

    const handleRadioChange = (event) => {
        setPaymentOption(event.target.value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handlePaymentChange = (event) => {
        if (event.target.checked) {
            setPayment(event.target.value);
        }
    };

    //OTP

    const handleClickLogin = (e) => {
        history.push("/odc/login");
    };

    const handleClickSignup = (e) => {
        history.push("/odc/signin");
    };

    const toggleVisibility = () => {
        history.push("/odc/checkout");
    };

    // const { cart } = this.props;

    // if (cart.items.length < 1) {
    //     return <Redirect to="cart" />;
    // }

    const breadcrumb = [
        { title: "Home", url: "/odc/home" },
        { title: "Cart", url: "/odc/addcart" },
        { title: "Checkout", url: "" },
    ];

    const steps = [
        {
            label: "Account",
            description: `To place your order now, log in to your existing account or sign up.`,
            icon: <AccountCircleIcon style={{ fontSize: "41px", marginLeft: "-17%" }} />,
        },
        {
            label: "Delivery address",
            description: "Please Entery your Delivery Address , where you want to deliver your order food",
            icon: <LocationOnIcon style={{ fontSize: "41px", marginLeft: "-17%" }} />,
        },
        {
            label: "Payment",
            description:
                "Choose your payment method and make payment.To proside your order to be delivered at your door step.",
            icon: <PaymentIcon style={{ fontSize: "41px", marginLeft: "-17%" }} />,
        },
    ];

    // tesst
    const [step, setStep] = useState(1);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [deliveryLocation, setDeliveryLocation] = useState("");

    const [address, setAddress] = useState(guestuser.address);
    const [editMode, setEditMode] = useState(false);
    const [newAddress, setNewAddress] = useState(address);
    const [newOrders, setNewOrders] = useState();
    //
    const [date, setDate] = useState(guestuser.date);
    const [time, setTime] = useState(guestuser.time);
    const [editDate, setEditDate] = useState(false);
    const [editTime, setEditTime] = useState(false);
    const [newDate, setNewDate] = useState(date);
    const [newTime, setNewTime] = useState(time);

    const handleEditDate = () => {
        setNewDate(date);
        setEditDate(true);
    };

    const handleEditTime = () => {
        setNewTime(time);
        setEditTime(true);
    };

    const handleSaveDate = () => {
        setDate(newDate);
        setEditDate(false);
    };

    const handleSaveTime = () => {
        setTime(newTime);
        setEditTime(false);
    };

    const handleEdit = () => {
        setNewAddress(address);
        setEditMode(true);
    };

    const handleSave = () => {
        setAddress(newAddress);
        setEditMode(false);
    };

    const handleLogin = () => {
        history.push("/odc/login");
    };
    const handleSignup = () => {
        history.push("/odc/signin");
    };

    const handleDelivery = () => {
        setStep(3);
    };
    const fullpaySubmit = () => {
        setFullpay(true);
        setAdvancepay(false);
    };
    const advanceSubmit = () => {
        setAdvancepay(true);
        setFullpay(false);
    };
    const initePayment = (data) => {
        var options = {
            key: "rzp_test_Del0NbJI09NNDc",//test key
            // key:"rzp_live_4R2HAHA3KbNlbO", //Orignal key
            amount: data.payreq.amount,
            image: "https://www.hogist.com/images/hogist-wlogo.png",
            currency: "INR",
            name: "Hogist ",
            order_id: data.payreq.id,
            description: `Hogist Order ID : ${data.id}  |   Name : ${data.customer.fullName}   |   Event Date : ${data.serviceDate}`,
            handler: async function (response) {
                console.log(response);

                await fetch(`${apiurl}/odc-order-pay/${data._id}`, {
                    method: "PUT",
                    // should send razerpay data in this keys
                    body: JSON.stringify(response),
                    headers: {
                        "Content-type": "application/json",
                        Authorization: localStorage.getItem("tokenodc"),
                    },
                })
                    .then((response) => response.json())
                    .then((json) => {
                        console.log(json);
                        if (json.status === true) {
                            history.push("/odc/profile");
                        }
                        else{
                            history.push("/odc/profile");
                        }
                        // after success redirect to order page
                    });
            },
            prefill: {
                name: data.customer.fullName,
                email: data.customer.email,
                contact: data.customer.mobile,
            },
            notes: {
                address: address,
            },
            theme: {
                color: "#c60800",
            },
            method: {
                netbanking: "true",
                card: "true",
                upi: "true",
                wallet: "true",
            },
        };
        var pay = new window.Razorpay(options);
        pay.open();
    };

    console.log(address);

    console.log(newDate);
    const handlePayment = async () => {
        await fetch(`${apiurl}/odc-order`, {
            method: "POST",
            body: JSON.stringify({
                cart_id: menulistapi._id,
                serviceDate: newDate,
                serviceTime: newTime,
                dlAddress: newAddress,
                amount: paymentOption === "advance" ? minPay : menulistapi.total,
            }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("tokenodc"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json.data);

                const paymentData = json.data;
                const paydate = json.data.createdAt;
                // {paymentOption === "advance" && minPay!== 0 ? initePayment():(alert("Enter your Amount "))}

                // should clear the cart usedispatch;
                dispatch(clearCart());
                initePayment(paymentData);

                // console.log(paymentData.total);
                // history.push(`/payment/${paymentData._id}/${paydate}/${paymentData.total}`);
            });
        // initePayment();
    };

    // const calculatesubTotalPrice = () => {
    //     let subtotal = 0;
    //     menuadded.forEach((item, indxed) => {
    //         const menuId = item._id;
    //         const count = item.count ? item.count : 25;
    //         const price = item.price;
    //         subtotal += count * price;
    //     });
    //     return subtotal;
    // };

    // const tax = 100;
    // const deliveryprice = 500;

    // const calculateTotalPrice = () => {
    //     let totalprice = 0;
    //     menuadded.forEach((item) => {
    //         const count = item.count ? item.count : 25;
    //         const price = item.price;
    //         const subtotal = count * price;
    //         totalprice += subtotal;
    //     });

    //     // Add tax and delivery price to the total
    //     totalprice += tax + deliveryprice;

    //     return totalprice;
    // };

    // console.log( menuadded);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Checkout — ${theme.name}`}</title>
            </Helmet>
            <div
                style={{
                    backgroundImage:
                    'url("../images/odc-formbg.png")',
                }}
            >
                <div style={{ backgroundColor: "rgb(255 255 255 / 98%)" }}>
                    <div style={{ marginLeft: "-260px" }}>
                        {" "}
                        <PageHeader header="Checkout" breadcrumb={breadcrumb} style={{ marginLeft: "-142px" }} />
                    </div>

                    <div className="checkout block">
                        <div className="container-fluid" style={{ padding: "0% 6%" }}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    paddingBottom: "1%",
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    marginBottom: "3%",
                                                }}
                                            >
                                                <Typography>
                                                    <b>
                                                        {" "}
                                                        <AccountCircleIcon /> Account{" "}
                                                    </b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "white", color: "#000" }}>
                                                {odcuser && odcuser.fullName ? (
                                                    odcuser && odcuser.fullName
                                                ) : (
                                                    <div className="m-4">
                                                        <p>Login or sign up to get your order.</p>
                                                        <div className="d-flex"></div>
                                                        <button
                                                            onClick={handleLogin}
                                                            className="odc-final-steps-lg-btn"
                                                        >
                                                            Login
                                                        </button>
                                                        <button
                                                            onClick={handleSignup}
                                                            className="odc-final-steps-lg-btn"
                                                        >
                                                            Sign Up
                                                        </button>
                                                    </div>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    paddingBottom: "1%",
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    marginBottom: "3%",
                                                }}
                                            >
                                                <Typography>
                                                    <b>
                                                        {" "}
                                                        <LocationOnIcon /> Delivery Location
                                                    </b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "white", color: "#000" }}>
                                                <div className="m-4">
                                                    <p>Enter your delivery locatio to get your order delivered.</p>

                                                    {editMode ? (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={newAddress}
                                                                className="odc-final-steps-input"
                                                                onChange={(e) => setNewAddress(e.target.value)}
                                                            />
                                                            <button
                                                                onClick={handleSave}
                                                                className="odc-final-steps-lg-btn"
                                                            >
                                                                Save
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span style={{ fontSize: "18px", fontWeight: "600" }}>
                                                                {address}
                                                            </span>
                                                            <span
                                                                className="edit-icon"
                                                                onClick={handleEdit}
                                                                style={{ padding: "3%" }}
                                                            >
                                                                &#9998;
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    paddingBottom: "1%",
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    marginBottom: "3%",
                                                }}
                                            >
                                                <Typography>
                                                    <b>
                                                        {" "}
                                                        <AccountBalanceIcon /> Payment
                                                    </b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ backgroundColor: "white", color: "#000" }}>
                                                <div className="m-4">
                                                    <p>Make your payment to enjoy your tasty food.</p>
                                                    <button onClick={handlePayment} className="odc-final-steps-lg-btn">
                                                        Payment
                                                    </button>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        <div className="odc-payment-detaile-worning1">
                                            <div className="odc-payment-detaile-worning">
                                                <h4 style={{ marginTop: "3%" }}>Payment Details</h4>
                                                <p style={{ color: "#6e6e6e" }}>
                                                    Addtional service , menu Changes , Own menu creation charges or
                                                    price will not included in your current menu plan payment your going
                                                    to pay. If you need addtional services the amount will be included
                                                    in your final payment , that amount adding is based on your
                                                    requirment and spcifications. All final payment will fixed after the
                                                    discussions and if you have any other queries please contact us. We
                                                    are ready to help you at any time at your order.
                                                </p>
                                            </div>
                                        </div>

                                        <button className="odc-quiry-btn">
                                            <a href="tel:+91 9962667733" className="odc-quiry-btn-a">
                                                {" "}
                                                <SupportAgentIcon /> Call Us
                                            </a>
                                        </button>
                                    </div>
                                </div>

                                <div className="col-lg-6 mt-4 mt-lg-0">
                                    <div className="card mb-0">
                                        <div className="card-body">
                                            {/* <h3 className="card-title">Your Order</h3> */}
                                            <div className="odc-cart-totel p-4">
                                                <ul style={{ padding: "0px 0px" }}>
                                                    <h4
                                                        style={{
                                                            textAlign: "center",
                                                            color: "#c60800",
                                                            marginBottom: "5%",
                                                        }}
                                                    >
                                                        Order Details
                                                    </h4>
                                                    <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">
                                                                <span>
                                                                    <CalendarMonthSharpIcon />
                                                                </span>{" "}
                                                                Serivce date
                                                            </div>
                                                            <div className="col-lg-4 number-font">
                                                                {/* {formattedDate(guestuser.date)} */}
                                                                {editDate ? (
                                                                    <>
                                                                        <input
                                                                            type="date"
                                                                            value={newDate}
                                                                            onChange={(e) => setNewDate(e.target.value)}
                                                                        />
                                                                        <button className="odc-checkout-address-edite" onClick={handleSaveDate}>Save</button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>{formattedDate(date)}</span>
                                                                        <span
                                                                            className="edit-icon"
                                                                            onClick={handleEditDate}
                                                                        >
                                                                            &#9998;
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>{" "}
                                                    </li>

                                                    {/* test */}

                                                    {/* <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">
                                                                <strong>Date:</strong>
                                                                {editDate ? (
                                                                    <>
                                                                        <input
                                                                            type="date"
                                                                            value={newDate}
                                                                            onChange={(e) => setNewDate(e.target.value)}
                                                                        />
                                                                        <button onClick={handleSaveDate}>Save</button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>{date}</span>
                                                                        <span
                                                                            className="edit-icon"
                                                                            onClick={handleEditDate}
                                                                        >
                                                                            &#9998;
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">
                                                                <strong>Time:</strong>
                                                                {editTime ? (
                                                                    <>
                                                                        <input
                                                                            type="time"
                                                                            value={newTime}
                                                                            onChange={(e) => setNewTime(e.target.value)}
                                                                        />
                                                                        <button onClick={handleSaveTime}>Save</button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>{time}</span>
                                                                        <span
                                                                            className="edit-icon"
                                                                            onClick={handleEditTime}
                                                                        >
                                                                            &#9998;
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li> */}

                                                    {/* test */}

                                                    <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">
                                                                {" "}
                                                                <span>
                                                                    <WatchLaterSharpIcon />
                                                                </span>{" "}
                                                                Serivce Time
                                                            </div>
                                                            <div className="col-lg-4 number-font">
                                                            {editTime ? (
                                                                    <>
                                                                        <input
                                                                            type="time"
                                                                            value={newTime}
                                                                            onChange={(e) => setNewTime(e.target.value)}
                                                                        />
                                                                        <button className="odc-checkout-address-edite" onClick={handleSaveTime}>Save</button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>{time}</span>
                                                                        <span
                                                                            className="edit-icon"
                                                                            onClick={handleEditTime}
                                                                        >
                                                                            &#9998;
                                                                        </span>
                                                                    </>
                                                                )}
                                                                </div>
                                                        </div>{" "}
                                                    </li>

                                                    <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">
                                                                <span>
                                                                    <HomeRoundedIcon />
                                                                </span>{" "}
                                                                Delivery Address
                                                            </div>
                                                            <div className="col-lg-4 number-font">
                                                                {editMode ? (
                                                                    <>
                                                                        <input
                                                                            type="text"
                                                                            value={newAddress}
                                                                            onChange={(e) =>
                                                                                setNewAddress(e.target.value)
                                                                            }
                                                                        />
                                                                        <button
                                                                            onClick={handleSave}
                                                                            className="odc-checkout-address-edite"
                                                                        >
                                                                            Save
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span>{address}</span>
                                                                        <span
                                                                            className="edit-icon"
                                                                            onClick={handleEdit}
                                                                            style={{ padding: "3%" }}
                                                                        >
                                                                            &#9998;
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <hr />
                                                    {menulistapi &&
                                                    menulistapi.services &&
                                                    menulistapi.services.length > 0
                                                        ? menulistapi.services.map((item, indxed) => (
                                                              <li className="mb-2" key={indxed}>
                                                                  <div className="row odc-cartic-list-main">
                                                                      <div className="col-lg-8">{item.label}</div>
                                                                      <div className="col-lg-4 number-font">{` ₹ ${item.price}`}</div>
                                                                  </div>
                                                                  <div className="row odc-cartic-list-sub">
                                                                      <div className="col-lg-8">
                                                                          <p
                                                                              style={{
                                                                                  color: "#9d9d9d",
                                                                                  fontWeight: "600",
                                                                              }}
                                                                              className="number-font"
                                                                          >
                                                                              {item.count} Counts
                                                                          </p>{" "}
                                                                      </div>
                                                                      <div className="col-lg-4">
                                                                          <p
                                                                              style={{
                                                                                  color: "#9d9d9d",
                                                                                  fontWeight: "600",
                                                                              }}
                                                                              className="number-font"
                                                                          >
                                                                              {` ₹ ${item.total}`}
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                              </li>
                                                          ))
                                                        : null}

                                                    <hr />
                                                    <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">Subtotal</div>
                                                            <div className="col-lg-4 number-font">
                                                                ₹ {menulistapi && menulistapi.subtotal}
                                                            </div>
                                                        </div>{" "}
                                                    </li>
                                                    <hr />
                                                    {/* <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">Tax</div>
                                                            <div className="col-lg-4 number-font">
                                                                ₹ {menulistapi && menulistapi.tax}
                                                            </div>
                                                        </div>{" "}
                                                    </li> */}
                                                    <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">Delivery Charges</div>
                                                            <div className="col-lg-4 number-font">
                                                                ₹ {menulistapi && menulistapi.deliveryFee}
                                                            </div>
                                                        </div>{" "}
                                                    </li>
                                                    {/* <li>
                                                        <div className="row odc-cartic-list-main">
                                                            <div className="col-lg-8">Additional Services</div>
                                                            <div className="col-lg-4 number-font">Calculating ...</div>
                                                        </div>{" "}
                                                    </li> */}

                                                    <hr />
                                                    <li>
                                                        <div className="row row odc-cartic-list-main">
                                                            <div className="col-lg-8">Total</div>
                                                            <div className="col-lg-4 number-font">
                                                                ₹ {menulistapi && menulistapi.total}
                                                            </div>
                                                        </div>{" "}
                                                    </li>
                                                    <hr />
                                                </ul>
                                            </div>
                                            <div style={{ margin: "2% 0%" }}>
                                                <div
                                                    className={
                                                        paymentOption === "fullpay"
                                                            ? "redio-pay-btn-style1"
                                                            : "redio-pay-btn-style"
                                                    }
                                                >
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="fullpay"
                                                            style={{ marginRight: "12px" }}
                                                            checked={paymentOption === "fullpay"}
                                                            onChange={handleRadioChange}
                                                        />
                                                        Full Payment
                                                    </label>
                                                </div>
                                                {/* <div
                                                    className={
                                                        paymentOption === "advance"
                                                            ? "redio-pay-btn-style1"
                                                            : "redio-pay-btn-style"
                                                    }
                                                >
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="advance"
                                                            style={{ marginRight: "12px" }}
                                                            checked={paymentOption === "advance"}
                                                            onChange={handleRadioChange}
                                                        />
                                                        Advance Payment
                                                    </label>
                                                </div>
                                                {paymentOption === "advance" ? (
                                                    <div>
                                                       
                                                        <div
                                                            className="input-group mb-3"
                                                            style={{
                                                                boxShadow: "7px 4px 5px 2px rgba(0, 0, 0, 0.12)",
                                                                margin: "2% 0%",
                                                            }}
                                                        >
                                                            <div className="input-group-prepend">
                                                                <span className="checkout-min-pay-filed">
                                                                    <AccountBalanceWalletIcon />
                                                                </span>
                                                            </div>
                                                            <input
                                                                type="tel"
                                                                className="form-control"
                                                                onChange={(e) => setMinPay(e.target.value)}
                                                                placeholder="Pay Min 10,0000 "
                                                                name="advancepay"
                                                                min={10000}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null} */}
                                            </div>{" "}
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-xl btn-block"
                                                onClick={handlePayment}
                                            >
                                                Proceed to Payment
                                            </button>
                                            <div className="mt-4">
                                                <p style={{ textAlign: "center", color: "#c60800" }}>
                                                    This charges without additional services and Delivery charges.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

Checkout1.propTypes = {
    menuadded: PropTypes.array.isRequired,
    guestuser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    menuadded: state.menuadded,
    odcuser: state.odcuser,
    guestuser: state.guestuser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout1);
// export default Checkout1;
