import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SidebarNav from "../../header/SidebarNav";
import MainSideNave from "../../header/MainSideNav";
import { mobileMenuOpen } from "../../../store/mobile-menu";
import { Menu18x14Svg } from "../../../svg";
import Hlogo from "./../../../img/logo.png";
import MainDropdownButton from "../../header/MainDropdownButton";
import IndicatorAccount from "../../header/IndicatorAccount";
import { useLocation } from "react-router-dom";
import CartIndicator from "./../../header/IndicatorCart";
import Notificationodc from "../../header/Notificationodc";
import { useHistory } from "react-router-dom";
import HandshakeIcon from '@mui/icons-material/Handshake';

const MobileHeader = (props) => {
    const { openMobileMenu, user } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    // const cart = useSelector(state => state.cart);
    // const wishlist = useSelector(state => state.wishlist);
    // const user = useSelector(state => state.user);
    // const  openMobileMenu = useSelector(state => state. openMobileMenu);
    const history = useHistory();
    const [searchOpen, setSearchOpen] = useState(false);
    const [isMainHomePage, setIsMainHomePage] = useState(false);
    const searchInput = useRef(null);


    useEffect(() => {
        // setIsHomePage(location.pathname === "/industry/home" || location.pathname === "/corporate/home");
        // // setIsHomePage1(location.pathname === "/");
        // setIsHomePage2(location.pathname === "/mainhome/odc");
        setIsMainHomePage(location.pathname === "/");
    }, [location]);

 

    useEffect(() => {
        if (searchOpen && searchInput.current) {
            searchInput.current.focus();
        }
    }, [searchOpen]);

    const handleOpenSearch = () => {
        setSearchOpen(true);
    };

    const handleCloseSearch = () => {
        setSearchOpen(false);
    };

    const odchandleLogin = (e) => {
        e.preventDefault();

        history.push("/odc/login");
    };
    const odchandleSignin = (e) => {
        e.preventDefault();

        history.push("/odc/signin");
    };


    const currentPath = window.location.pathname;
    const firstPath = "/odc"; // Specify the first path here
    const secondPath = "/mainhome/odc"; // Specify the first path here

    // Check if the currentPath starts with the firstPath
    const shouldShowDesign = currentPath.startsWith(firstPath);
    const notshowDesign = currentPath.startsWith(secondPath);


    return (
        <div>
             {isMainHomePage ? ( <div className="mobile-header fixed-header-mobile">
            <div className="m3-mobile-header__panel" style={{ display: "flex", alignItems: "center" }}>
                <div className=" mobile-header__body">
                    {/* <button type="button" className="mobile-header__menu-button" onClick={() => dispatch(mobileMenuOpen)}>
                        <Menu18x14Svg />
                    </button> */}
                      <MainSideNave/>
                    <Link to="/" className="mobile-header__logo">
                        <img style={{ width: "82%" }} src="images/flogo.png" alt="logo" />
                    </Link>
                    <MainDropdownButton />
                    {/* <IndicatorAccount user={user} /> */}
                </div>
            </div>
        </div>):shouldShowDesign ? (<div className="mobile-header">
                <div className="mobile-header__panel">
                    {/* <div className="col-lg-12"> */}
                    {/* <div className="container"> */}
                    <div className=" mobile-header__body">
                        {/* <div className="col-lg-2"> */}
                        {/* <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                            <Menu18x14Svg />
                        </button> */}
                        {/* </div> */}
                        {/* <div className="col-lg-4"> */}
                        <Link to="/" className="mobile-header__logo">
                            {" "}
                            <img style={{ width: "100%" }} src={Hlogo} alt="logo" />
                        </Link>
                        {/* </div> */}
                        {/* <div className="col-lg-3"> */}
                        <MainDropdownButton />
                        
                        {/* </div> */}
                        <div style={{ marginRight:"10px"}}>
                        <CartIndicator  />
                        </div>
                        <Notificationodc/>
                        {/* <div className="col-lg-3"> */}
                        <IndicatorAccount user={user} />
                        {/* </div> */}

                       
                    </div>
                    
                </div>
            </div>): notshowDesign ? (
                <div className="mobile-header">
                <div className="mobile-header__panelodc">
                    {/* <div className="col-lg-12"> */}
                    {/* <div className="container"> */}
                    <div className=" mobile-header__body">
                        {/* <div className="col-lg-2"> */}
                        {/* <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                            <Menu18x14Svg />
                        </button> */}
                        {/* </div> */}
                        {/* <div className="col-lg-4"> */}
                        <Link to="/" className="mobile-header__logo">
                            {" "}
                            <img style={{ width: "80%" }} src="images/logo-red.png" alt="logo" />
                        </Link>
                        {/* </div> */}
                        {/* <div className="col-lg-3"> */}
                        <button className="mainpage-login-btn-odc" onClick={odchandleLogin}>
                                                Login
                                            </button>
                        
                        {/* </div> */}
                        <div style={{ marginRight:"10px"}}>
                        <button className="mainpage-signup-btn-odc" onClick={odchandleSignin}>
                                                Sign Up
                                            </button>
                        </div>
                        <SidebarNav />
                        {/* <div className="col-lg-3"> */}
                        <Link to="/become-a-vendor">
                                                {/* <button className="vjoinbtn"> */}
                                                    <HandshakeIcon style={{fontSize: "25px",marginRight:"10px"}}/>
                                                    {/* </button> */}
                                            </Link>
                        {/* </div> */}

                       
                    </div>
                    
                </div>
            </div>
            ):  (<div className="mobile-header">
                <div className="mobile-header__panel">
                    {/* <div className="col-lg-12"> */}
                    {/* <div className="container"> */}
                    <div className=" mobile-header__body">
                        {/* <div className="col-lg-2"> */}
                        <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                            <Menu18x14Svg />
                        </button>
                        {/* </div> */}
                        {/* <div className="col-lg-4"> */}
                        <Link to="/" className="mobile-header__logo">
                            {" "}
                            <img style={{ width: "100%" }} src={Hlogo} alt="logo" />
                        </Link>
                        {/* </div> */}
                        {/* <div className="col-lg-3"> */}
                        <MainDropdownButton />
                       
                        {/* </div> */}
                       
                        {/* <div className="col-lg-3"> */}
                        <IndicatorAccount user={user} />
                        {/* </div> */}

                       
                    </div>
                    
                </div>
            </div>)
        //     (
        //         <div>
        // <div className="mobile-header">
        //     <div className="mobile-header__panel" style={{ display: "flex", alignItems: "center" }}>
        //         <div className=" mobile-header__body">
                  
        //             <SidebarNav/>
        //             <Link to="/" className="mobile-header__logo">
        //                 <img style={{ width: "80%" }} src={Hlogo} alt="logo" />
        //             </Link>
        //             <MainDropdownButton />
        //             <IndicatorAccount user={user} />
        //         </div>
        //     </div>
        // </div>
        // </div>
        // )
        }
        </div>
    );
};
const mapStateToProps = (state) => ({
   
    user: state.user,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
