import React from "react";
import CounterInput from "react-counter-input";

class PackageCounter extends React.Component {
    render() {
        return (
            <div className="package-counter count-brt">
                <CounterInput min={25} max={2000} onCountChange={(count) => console.log(count)} />
            </div>
        );
    }
}
export default PackageCounter;
