export default {
    name: "HOGIST ",
    fullName: "HOGIST ",
    url: "/",
    author: {
        name: "Kos",
        profile_url: "/",
    },
    contacts: {
        address:
            "HOGIST TECHNOLOGIES PVT. LTD. 2nd Floor, Kakani Towers, No:34 Khader Nawaz Khan Road,Nungambakkam, Chennai 600 006.",
        email: "support@hogist.com",
        phone: "+91 - 9962667733",
    },
};
