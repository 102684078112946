// react
import React from "react";
import { Link } from "react-router-dom";

// application
import SocialLinks from "../industry/shared/SocialLinks";
import theme from "../../data/theme";

export default function FooterInfo() {
    return (
        <div className="site-footer__widget footer-newsletter">
            <h5 className="footer-newsletter__title">Contact Us</h5>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    <Link to="/">
                        {" "}
                        <div className="footer__aline">{theme.contacts.address}</div>
                    </Link>
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    <Link to="/">
                        {" "}
                        <div className="footer__aline"> {theme.contacts.email}</div>
                    </Link>
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    <Link to="/">
                        {" "}
                        <div className="footer__aline">{`${theme.contacts.phone}`}</div>{" "}
                    </Link>
                </li>
                {/* <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Mon-Sat 10:00pm - 7:00pm
                </li> */}
            </ul>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
