// import { UPDATE_COUNT, UPDATE_COUNT1, UPDATE_COUNT2 } from "./orderdataActionTypes";
const initialState = { count: 25, count1: 25, count2: 25 };
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_COUNT":
            return { ...state, count: action.payload };
        case "UPDATE_COUNT1":
            return { ...state, count1: action.payload };
        case "UPDATE_COUNT2":
            return { ...state, count2: action.payload };

        default:
            return state;
    }
}
