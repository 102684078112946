import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

function ServicesOffered() {
    return (
        <div>
            <Helmet>
                <title>{`Services Offered — ${theme.name}`}</title>
            </Helmet>

            <h4 className="faq-tit-txt" style={{ backgroundColor: "aliceblue", marginBottom: "2%" }}>
                <span style={{ color: "#c60800" }}>S</span>ervices Offered
            </h4>
            <div className="container-fluid">
                <div className="service-list-card-style">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Corporate Events</h2>
                            </div>
                            <ul>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Breakfast Meet{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    High Tea
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Training Meals
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Midday Meals Program
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Evening Snacks{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Annual Meetings
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Board Meetings{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Company Milestone Events{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Events{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Executive Treat & Incentive
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Product Launch Events{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Programs
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />R & R - Rewards & Recognition
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Seminar & Conference{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Shareholder Meetings
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Team-Building Events{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Trade Shows{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    All other Corporate Events
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Subscription Services</h2>
                            </div>
                            <ul>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Breakfast{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" /> Lunch
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Evening Snacks
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Dinner{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Fresh Juice & Shakes
                                </li>
                            </ul>

                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Industrial Catering</h2>
                            </div>
                            <ul>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Breakfast{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" /> Lunch
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Dinner
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Snacks
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Employee Meal Plan{" "}
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3">
                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Institutional Catering</h2>
                            </div>
                            <ul>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Academy{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" /> Colleges
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Institutional Events
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Schools
                                </li>
                            </ul>

                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Public Events</h2>
                            </div>
                            <ul>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Expo/ Trade Shows
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" /> Marathon
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    NGO Functions
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Public Awareness
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Sports Events
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    All types of public events
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3">
                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Family Events</h2>
                            </div>
                            <ul>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Birthday Party{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" /> Family Get Together
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Resort Party
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Betrothal{" "}
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    House Party
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    Wedding
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    House Warming Ceremony
                                </li>
                                <li>
                                    <ArrowRightIcon className="footer-icon-style" />
                                    All other Family Functions
                                </li>
                            </ul>

                            <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                <DocumentScannerIcon className="footer-icon-style" />{" "}
                                <h2 className="service-list-tit">Food Court & Cafeteria Setup</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesOffered;
