import { ADDMENUCOUNT } from "./index";

const initialState = null;

export default function addmenucountReducer(state = initialState, action) {
    if (action.type === ADDMENUCOUNT) {
        return action.addmenucount;
    }

    return state;
}
