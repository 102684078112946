import { ADDUSER_CHANGE, REMOVEUSER_CHANGE } from "./adduserActionTypes";

const initialState = null;

export default function adduserReducer(state = initialState, action) {
    if (action.type === ADDUSER_CHANGE) {
        return action.adduser;
    }
    if (action.type === REMOVEUSER_CHANGE) {
        return "Guest";
    }

    return state;
}
