// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn } from "mdb-react-ui-kit";

// application
import StroykaSlick from "../shared/StroykaSlick";

// data stubs
import theme from "../../../data/theme";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function OurStory() {
    return (
        <div className="block about-us txt-colr txt-justify">
            <Helmet>
                <title>{`Our Story — ${theme.name}`}</title>
            </Helmet>

            <div
                className="about-us__image"
                style={{
                    backgroundImage: 'url("images/banners/abg.png")',
                }}
            >
                <div className="mask" style={{ backgroundColor: "rgb(0 0 0 / 68%)", height: "100% " }}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <h1 className="text-white mb-0">About Us</h1>
                    </div>
                </div>
            </div>

            <div className="block block-banner">
                <div className="container-fluid">
                    <div className="row pdside pt-5">
                        <div className="col-lg-4">
                            <div className="teammate__avatar">
                                <img
                                    src="https://www.sunjaya.com.my/wp-content/uploads/2019/11/Wedding-Catering-Fusion-600x600.jpg"
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <h1>Welcome To Hogist</h1>
                            <p className="about__text">The Best Catering service in Chennai</p>
                            <div className="typography abpara" style={{ textAlign: "justify", paddingRight: "60px" }}>
                                <p>
                                    Our Story started with one word – FOOD. Now that everything is available under click
                                    of a button, we at HOGIST decided to bring a platform which virtually connects
                                    customers with Caterers, Restaurants, Professional Chefs and Homecooks fulfilling
                                    various food requirements. Being a Food Tech Company with roots lay down in the Food
                                    Industry since 2018, we had developed multiple tech platforms for our customers to
                                    harness the benefits of technology and utilise the Best in Class Services from
                                    HOGIST. We at HOGIST, have got Web Apps & Mobile Apps to service various food
                                    requirements to our customers. HOGIST provides an exclusive solution for the
                                    customers making the Web & Mobile App as one-stop solution for all their bulk food
                                    needs and also to the, vendors who want to digitize their business & increase their
                                    sales.
                                    <br /> To make much more easier and efficient for Industries & Corporates, HOGIST
                                    had come up with an innovative solution YOUR F&B App for Industrial / Corporate
                                    Catering. This App is implemented at the Client’s end to monitor from the phase of
                                    preparation to delivery of food at the customer’s plate thus enabling operations run
                                    smoothly & efficiently making customer fully satisfied. This app had been developed
                                    with Robust technology to act instantly to service the Industrial / Corporate
                                    Clients . Then comes, the Café Partner App, which is extensively used in the areas
                                    of Canteens, Cafeterias with Food Court Setup enables customers to order food from
                                    Cafe Partner App making the delivery possible at their desk or pick up at the
                                    counter.
                                    <br /> Here is a Breaking News for you all, The App's and Technology implementation
                                    comes at Zero Cost along with the food service from HOGIST.
                                </p>
                                <p>
                                    We had brought the first of its kind Online Bulk Food Service in Chennai, India
                                    which will now connect businesses with businesses. Be it a corporate event, a
                                    seminar, a conference, a family event, a wedding, your daily food needs or any other
                                    service which requires food or equivalent services, we at HOGIST have brought all
                                    the service providers under a single platform. The best part? It’s online! Now you
                                    don’t have to physically move from location to location to select your food services
                                    – just post your request on HOGIST Website or any social media pages and we will do
                                    the rest for you. HOGIST educates & take stringent steps & maintains industry
                                    standards towards the delivery of Safe & hygienic food to our customers.
                                    <br /> Having affiliated branches across Chennai & Licensed to serve PAN India, upon
                                    the requirements from the customer end, we can service you anywhere. With a strong
                                    need for building a single platform for all the services related to food and at the
                                    same time providing a platform for all the homemakers, chefs, restaurants and
                                    emerging caterers, we at HOGIST ensure that the right vendors meet the right
                                    customers and get their requests fulfilled. What do we get in return? Happy &
                                    Delighted Customers!
                                </p>
                                <p>
                                    <b>What we have in place for you?</b>
                                </p>
                                <p>
                                    We have brought in Monthly, Weekly, Daily & On-demand Food Services for you where
                                    you can pre-book your type of service and also select your service provider. At the
                                    same time, we accept bulk orders for a range of 25 to 100000+ people where you get a
                                    chance to select your order type, frequency, range and time. All you have to do is
                                    select your type of service and leave the rest to us. Happy with the services but
                                    looking for some quick services? Worry not, we have an express delivery option where
                                    we can deliver bulk orders from 25 persons in a shot to the requested quantity by
                                    the customer. We set up food trucks and food counters at events. Conducting an event
                                    or require a bulk food order? Focus on your event and leave the catering to us. With
                                    catering services now available online, you don’t need to visit caterer to caterer
                                    or check for quality food provider to finalize your deal – just tell us your budget
                                    and your requirement and We will bring the caterer Best to you. It’s YOU who will
                                    choose your caterer. With a single click, you can relax and enjoy your event and
                                    leave the rest to HOGIST. We will take care of your requirements.
                                </p>
                                <p>
                                    For any additional information on bulk bookings please email us to
                                    support@hogist.com or call / whatsapp at 9962667733.
                                </p>
                                <p>
                                    <b>How we work and why you should choose us?</b>
                                </p>
                                <p>
                                    We have a strong team to take care of complete operations & technology enabling the
                                    customer to sit and relax. We connect with people from all walks of life. Be it a
                                    small-scale caterer to a five-star hotel chef, anybody who is looking out for food
                                    would feel HOGIST beneficial. With HOGIST providing a platform for online bulk
                                    booking, it becomes easier for people to choose and plan their events accordingly
                                    without having to depend on anyone. With customer satisfaction being our goal, we at
                                    HOGIST strive hard to bring multiple cuisines under one roof at an affordable cost
                                    with top-class customer service.
                                </p>
                                <div className="cntbtn">
                                    <Link to="/" className="logbtn">
                                        Contact Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about__service_part">
                <div className="container">
                    {/* <h1 className="about__service_tit">Our Best Services</h1> */}
                    <div className="row">
                        <div className="col-lg-4">
                            <MDBCard>
                                <div >
                                    <i className="fas fa-crosshairs ab-main-txt-style rotate" />
                                </div>
                                <MDBCardBody >
                                    <MDBCardTitle style={{ textAlign: " center" }}>Mission</MDBCardTitle>
                                    <MDBCardText className="txt-space">
                                        To create delicious,hygienic, entertaining food experiences at affordable prices
                                        with impeccable service in a professional, consistent, flexible,and timely
                                        manner catering to the requirements of groups of people - corporates and
                                        individuals.
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-lg-4">
                            <MDBCard>
                                <div >
                                    {" "}
                                    <i className="	far fa-eye ab-main-txt-style rotate" />{" "}
                                </div>
                                <MDBCardBody >
                                    <MDBCardTitle style={{ textAlign: " center" }}>Vission</MDBCardTitle>
                                    <MDBCardText className="txt-space">
                                        To be a leading market place for all bulk food requirments- providing hygienic &
                                        quality food with impeccable, versatile, and flexible customer service with
                                        consistency and presentation that will leave a memorable experience.
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-lg-4">
                            <MDBCard>
                                <div>
                                    {" "}
                                    <i className="fas fa-award ab-main-txt-style rotate" />
                                </div>
                                <MDBCardBody >
                                    <MDBCardTitle style={{ textAlign: " center" }}>Core Values (4E)</MDBCardTitle>
                                    <MDBCardText className="txt-space">
                                        <p>
                                            <b>Employee First :</b> We are serious about creating a productive,
                                            cooperative,safe & rewarding environment for our team.
                                        </p>
                                        <p>
                                            <b>Ethics:</b>
                                            We want to play a fair game and not make any compromises concerning
                                            integrity and ethics.
                                        </p>
                                        <p>
                                            <b>Extra Mile :</b>
                                            While we want to maintain the highest levels of hygiene, taste, and customer
                                            service in our food experiences, we want to go that extra mile in delighting
                                            our customers.
                                        </p>
                                        <p>
                                            <b>Entertainment :</b>
                                            Food is entertainment. Food is experience. Food is fun. We will always keep
                                            this in mind when we deliver our services.
                                        </p>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">About Us</h1>
                            <div className="about-us__text typography">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Cras lacus metus, convallis ut leo nec, tincidunt
                                    eleifend justo. Ut felis orci, hendrerit a pulvinar et,
                                    gravida ac lorem. Sed vitae molestie sapien, at
                                    sollicitudin tortor.
                                </p>
                                <p>
                                    Duis id volutpat libero, id vestibulum purus.Donec euismod
                                    accumsan felis,egestas lobortis velit tempor vitae. Integer
                                    eget velit fermentum, dignissim odio non, bibendum velit.
                                </p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">Meat Our Team</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    Want to work in our friendly team?
                                    <br />
                                    <Link to="/contact-us">Contact us</Link>
                                    {' '}
                                    and we will consider your candidacy.
                                </div>
                                <div className="about-us__teammates teammates">
                                    <StroykaSlick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Michael Russo</div>
                                            <div className="teammate__position text-muted">Chief Executive Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Katherine Miller</div>
                                            <div className="teammate__position text-muted">Marketing Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Anthony Harris</div>
                                            <div className="teammate__position text-muted">Finance Director</div>
                                        </div>
                                    </StroykaSlick>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default OurStory;
