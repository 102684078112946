// react
import React, { useState } from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HomePageTwo from "../industry/home/HomePageTwo";

// application
import CartIndicator from "./IndicatorCart";
import Departments from "./Departments";
import Indicator from "./Indicator";
import IndicatorAccount from "./IndicatorAccount";
import IndicatorSearch from "./IndicatorSearch";
import NavLinks from "./NavLinks";
import { Heart20Svg, LogoSmallSvg } from "../../svg";
import Hlogo from "./../../img/logo1.png";
import Hlogo1 from "./../../img/logo.png";
import SidebarNav from "./SidebarNav";
import MainDropdownButton from "./MainDropdownButton";

function NavPanelOther(props) {
    const { layout, wishlist, homelayout } = props;

    let logo;
    let departments = null;
    let searchIndicator;

    if (layout === "default") {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/">
                    <img src={Hlogo1} alt="logo" style={{ width: "73%" }} />
                </Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === "compact") {
        departments = (
            <div className="nav-panel__departments">
                <Departments />
            </div>
        );
    }

    return (
        <div className="navpanelScrolled">
            <div className="nav-panel__container container-fluid">
                <div className="nav-panel__row">
                    <div className="col-lg-2">{logo}</div>

                    {/* {departments} */}
                    <div className="col-lg-8">
                        <div className="nav-panel__nav-links nav-links">
                            <NavLinks />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="row nav-panel__indicators">
                            <div className="container" style={{ display: "flex" }}>
                                <div className="col-lg-4">{/* <SidebarNav /> */}</div>
                                <div className="col-lg-4">
                                    <MainDropdownButton />
                                </div>
                                <div className="col-lg-4">
                                    <IndicatorAccount />
                                </div>
                            </div>
                        </div>
                        {/* {searchIndicator}

                        <Indicator url="/shop/wishlist" value={wishlist.length} icon={<Heart20Svg />} />

                        <CartIndicator /> */}
                        {/* <Indicator url="/shop/wishlist" value={wishlist.length} icon={<Heart20Svg />} /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

NavPanelOther.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

NavPanelOther.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanelOther);
