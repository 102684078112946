import firebase from 'firebase/app'
import 'firebase/auth'

var firebaseConfig = {
    apiKey: "AIzaSyC27vv8xiarVhhsK78bAOjsB4vYIiW5JgY",
    authDomain: "hogist-7f748.firebaseapp.com",
    databaseURL: "https://hogist-7f748-default-rtdb.firebaseio.com",
    projectId: "hogist-7f748",
    storageBucket: "hogist-7f748.appspot.com",
    messagingSenderId: "283298633771",
    appId: "1:283298633771:web:94607c2751ac89260bf26e",
    measurementId: "G-2FJ8C763RW"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
export default firebase;