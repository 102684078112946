/* eslint-disable no-unused-vars */
// react
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MDBIcon } from "mdb-react-ui-kit";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterInfo from "./FooterInfo";
import ToTop from "./ToTop";
import { Hgflogo } from "./../../svg";

//
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import BlockMap from "../industry/blocks/BlockMap";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";

// data stubs
// import theme from "../../data/theme";

export default function Footer() {
    const location = useLocation();
    const [isHomePage, setIsHomePage] = useState(false);
    const [isHomePage1, setIsHomePage1] = useState(false);
    const [isHomePage2, setIsHomePage2] = useState(false);
    const [isHomePage3, setIsHomePage3] = useState(false);
    // const [isScrolled, setIsScrolled] = useState(false);
    const [isPaymentPage, setIsPaymentPage] = useState(false);

    useEffect(() => {
        setIsHomePage(location.pathname === "/industry/home" || location.pathname === "/corporate/home");
        // setIsHomePage1(location.pathname === "/");
        // setIsPaymentPage(location.pathname === "/payment");
        setIsHomePage2(
            // location.pathname === "/" ||
            location.pathname === "/story" ||
                location.pathname === "/careers" ||
                location.pathname === "/FAQ" ||
                location.pathname === "/service-offered" ||
                location.pathname === "/become-a-vendor" ||
                location.pathname === "/become-a-Consultant" ||
                location.pathname === "/privacy-policy" ||
                location.pathname === "/term-condition" ||
                location.pathname === "/refund" ||
                location.pathname === "/site/login-page" ||
                location.pathname === "/about-us" ||
                location.pathname === "/service" ||
                location.pathname === "/contact-us"
        );
        setIsHomePage3(location.pathname === "/");
    }, [location]);
    const informationLinks = [
        { title: "Home", url: "/" },
        { title: "About Us", url: "/about-us" },
        { title: "Our Services", url: "/service" },
        { title: "Contact Us", url: "/contact-us" },
        { title: "Download App", url: "https://play.google.com/store/apps/details?id=com.hogist" },
    ];

    const accountLinks = [
        { title: "Catering Service", url: "/site/login-page" },
        { title: "Industrial Catering", url: "/site/login-page" },
        { title: "Corporate Catering", url: "/site/login-page" },
        { title: "Cafeteria", url: "/site/login-page" },
    ];

    const address = (
        <Popover id="popover-basic">
            <Link to="https://goo.gl/maps/9cBEEzGDGVWFh2QEA">
                {" "}
                <Popover.Body>
                    <strong> HOGIST TECHNOLOGIES PRIVATE LIMITED.</strong>
                    <br/>2nd Floor, Kakani Towers, 
                    <br/>No:34 Khader Nawaz Khan Road
                    <br/>Nungambakkam, Chennai 600 006.
                    
                </Popover.Body>{" "}
            </Link>
        </Popover>
    );

    const mobile = (
        <Popover id="popover-basic">
            <Link to="https://api.whatsapp.com/send?phone=+919962667733&text=Welcome HOGIST - The Catering service in Chennai.">
                {" "}
                <Popover.Body>+91 - 9962667733</Popover.Body>{" "}
            </Link>
        </Popover>
    );

    const email = (
        <Popover id="popover-basic">
            <Link to="mailto:support@hogist.com">
                {" "}
                <Popover.Body>support@hogist.com</Popover.Body>{" "}
            </Link>
        </Popover>
    );
    const currentPath = window.location.pathname;
    const firstPath = "/odc"; // Specify the first path here
    const secondPath = "/mainhome/odc"; // Specify the first path here
    const firstPath1 = "/payment";

    // Check if the currentPath starts with the firstPath
    const shouldShowDesign = currentPath.startsWith(firstPath);
    const showSecondPath = currentPath.startsWith(secondPath);
    const paymentPath = currentPath.startsWith(firstPath1);
    // console.log(user);
    return (
        <div>
            {isHomePage1 ? (
                <div style={{ color: "#fff" }}>
                    <div
                        className="mainhome-footer_container"
                        style={{
                            backgroundImage: 'url("images/footer-bg.png")',
                            backgroundSize: " 100% 100%",
                            marginBottom: "-18px",
                        }}
                    >
                        <section className="info_section ">
                            <div className="container">
                                <div className="contact_box">
                                    <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={address}>
                                        <a className="copyright-txt" href="https://goo.gl/maps/9cBEEzGDGVWFh2QEA">
                                            {" "}
                                            <MDBIcon fas icon="map-marker-alt" />{" "}
                                        </a>
                                    </OverlayTrigger>

                                    <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={mobile}>
                                        <a
                                            className="copyright-txt"
                                            href="https://api.whatsapp.com/send?phone=+919962667733&text=Welcome HOGIST - The Catering service in Chennai. "
                                        >
                                            {" "}
                                            <MDBIcon fas icon="phone-alt" />{" "}
                                        </a>
                                    </OverlayTrigger>

                                    <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={email}>
                                        <a className="copyright-txt" href="mailto:support@hogist.com">
                                            {" "}
                                            <MDBIcon fas icon="envelope" />{" "}
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <div className="info_links">
                                    <ul>
                                        <li>
                                            <Link className="copyright-txt btstyle" to="/">
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="copyright-txt btstyle" to="/about-us">
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="copyright-txt btstyle" to="/service">
                                                Our Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="copyright-txt btstyle" to="/contact-us">
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="social_box">
                                    <Link className="copyright-txt" to="https://www.facebook.com/hogisttechnologies/">
                                        {" "}
                                        <MDBIcon fab icon="facebook-f" />
                                    </Link>
                                    <Link className="copyright-txt" to="https://twitter.com/Hogistindia">
                                        <MDBIcon fab icon="twitter" />
                                    </Link>
                                    <Link className="copyright-txt" to="https://www.instagram.com/hogistindia">
                                        {" "}
                                        <MDBIcon fab icon="instagram" />
                                    </Link>
                                    <Link className="copyright-txt" to="">
                                        {" "}
                                        <MDBIcon fab icon="pinterest-p" />
                                    </Link>
                                    <Link className="copyright-txt" to="">
                                        {" "}
                                        <MDBIcon fab icon="google-plus-g" />
                                    </Link>
                                </div>
                            </div>
                        </section>

                        <footer className="footer_section">
                            <div className="container">
                                <p style={{ color: "black", paddingBottom: "2%" }}>
                                    &copy; <span id="displayYear"></span> All Rights Reserved By
                                    <Link className="copyright-txt" to="/">
                                        HOGIST
                                    </Link>
                                </p>
                            </div>
                        </footer>
                    </div>
                </div>
            ) : isHomePage3 ? (
                <div className="pt-4 p-4 m3-font-style" style={{ backgroundColor: "#000000" }}>
                    <div className="container-fluid ">
                        <div className="row  pb-4">
                            <div className="col-lg-3">
                                <img
                                    src="images/logo.png"
                                    alt="catering service"
                                    style={{ width: "55%", marginTop: "-5%" }}
                                />
                                <p>
                                    {" "}
                                    Hogist is the one-stop solution for all your event food needs. Get the best catering
                                    services through us. We offer best quality with different taste in our catering
                                    services.Hogist is the one-stop solution for all your event food needs.
                                </p>
                                <div className="row footer-social-itemcenter-style">
                                    <div className="mr-4">
                                        <a href="https://www.facebook.com/hogisttechnologies/">
                                            {" "}
                                            <FacebookIcon className="footer-social-icon-style" />
                                        </a>
                                    </div>
                                    <div className="mr-4">
                                        <a href="https://www.instagram.com/hogistindia">
                                            {" "}
                                            <InstagramIcon className="footer-social-icon-style" />
                                        </a>
                                    </div>
                                    <div className="mr-4">
                                        <a href="https://twitter.com/Hogistindia">
                                            {" "}
                                            <TwitterIcon className="footer-social-icon-style" />
                                        </a>
                                    </div>
                                    <div className="mr-4">
                                        <a href="https://www.linkedin.com/company/hogist/">
                                            {" "}
                                            <LinkedInIcon className="footer-social-icon-style" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 mg-10-tp">
                                <h2 className="footer-icon-txt-style">Our Services</h2>

                                <ul style={{ color: "#fff", paddingLeft: "0px" }}>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <span>
                                        <img
                                            src="images/list-icon.png"
                                            alt=""
                                            style={{ width: "15%", marginRight: " 3%" }}
                                        />
                                    </span>{" "} */}
                                        <Link to="/industrial-catering-services-near-me/" className="footer-color-txt">
                                            Industrial Catering
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <span>
                                        <img
                                            src="images/list-icon.png"
                                            alt=""
                                            style={{ width: "15%", marginRight: " 3%" }}
                                        />
                                    </span>{" "} */}
                                        <Link
                                            to="/corporate-catering-services-in-chennai/"
                                            className="footer-color-txt"
                                        >
                                            Corporate Catering
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <span>
                                        <img
                                            src="images/list-icon.png"
                                            alt=""
                                            style={{ width: "15%", marginRight: " 3%" }}
                                        />
                                    </span>{" "} */}
                                        <Link to="/catering-services-in-chennai/" className="footer-color-txt">
                                            Out Door Catering
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <span>
                                        <img
                                            src="images/list-icon.png"
                                            alt=""
                                            style={{ width: "15%", marginRight: " 3%" }}
                                        />
                                    </span>{" "} */}
                                        <Link
                                            to="/corporate-catering-services-in-chennai/"
                                            className="footer-color-txt"
                                        >
                                            Cafeteria Services{" "}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2">
                                <h2 className="footer-icon-txt-style">Company</h2>

                                <ul style={{ color: "#fff", paddingLeft: "0px" }}>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/story" className="footer-color-txt">
                                            {" "}
                                            Our Story
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" />{" "} */}
                                        <Link to="/careers" className="footer-color-txt">
                                            Careers
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" />{" "} */}
                                        <Link to="/FAQ" className="footer-color-txt">
                                            FAQ
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/service-offered" className="footer-color-txt">
                                            Services Offered
                                        </Link>{" "}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2">
                                <h2 className="footer-icon-txt-style">Legal</h2>

                                <ul style={{ color: "#fff", paddingLeft: "0px" }}>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/become-a-vendor" className="footer-color-txt">
                                            Become a Vendor
                                        </Link>{" "}
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/become-a-Consultant" className="footer-color-txt">
                                            {" "}
                                            Become a Consultant
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/privacy-policy" className="footer-color-txt">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/term-condition" className="footer-color-txt">
                                            Terms & Conditions
                                        </Link>{" "}
                                    </li>
                                    <li style={{ padding: "3% 0%" }}>
                                        {/* <ArrowRightIcon className="footer-icon-style" /> */}
                                        <Link to="/refund" className="footer-color-txt">
                                            Refunds & Cancellation
                                        </Link>{" "}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3">
                                <div className="d-flex" style={{ lineHeight: "33px" }}>
                                    <PlaceIcon className="footer-icon-style" />

                                    <a style={{ color: "#fff" }} href="https://goo.gl/maps/9cBEEzGDGVWFh2QEA">
                                        {" "}
                                        <span>
                                            {" "}
                                            HOGIST TECHNOLOGIES PVT. LTD. 2nd Floor, Kakani Towers, No:34 Khader Nawaz
                                            Khan Road Nungambakkam, Chennai 600 006.
                                        </span>
                                    </a>
                                </div>
                                <div className="d-flex" style={{ lineHeight: "33px", padding: "2% 0%" }}>
                                    <PhoneEnabledIcon className="footer-icon-style" />

                                    <a
                                        style={{ color: "#fff" }}
                                        href="https://api.whatsapp.com/send?phone=+919962667733&text=Welcome HOGIST - The Catering service in Chennai. "
                                    >
                                        {" "}
                                        <span> +91 - 9962667733</span>
                                    </a>
                                </div>
                                <div className="d-flex" style={{ lineHeight: "33px", padding: "2% 0%" }}>
                                    <EmailIcon className="footer-icon-style" />

                                    <a style={{ color: "#fff" }} href="mailto:support@hogist.com">
                                        <span> support@hogist.com</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <hr className="footer-line" />
                    </div>
                    <div className="container">
                        <div className="row pb-4 pt-4">
                            <div className="col-lg-12 iso-txt footer-hb txt-align-center">
                                {" "}
                                © 2024, Hogist technologies private limited. All rights reserved.
                            </div>
                        </div>
                    </div>
                </div>
            ) : isHomePage2 ? (
                <div style={{ backgroundColor: "#000" }}>
                    <div
                        style={{
                            backgroundImage:
                                'url("https://html.softtechitltd.com/khadyo/khadyo/assets/images/shapes/30.png")',
                            backgroundColor: "#000",
                        }}
                    >
                        <span className="ds-5 ">
                            <img
                                className="footer-b"
                                src="https://html.softtechitltd.com/khadyo/khadyo/assets/images/img/31.png"
                                alt=""
                            />
                        </span>
                        <div className="container">
                            <div className="row " style={{ paddingTop: "48px" }}>
                                <div className="col-lg-3">
                                    <div className="row" style={{ display: "flex", marginLeft: "7%" }}>
                                        <ApartmentIcon className="footer-icon-style" />{" "}
                                        <h2 className="footer-icon-txt-style">Our Services</h2>
                                    </div>
                                    <ul style={{ color: "#fff" }}>
                                        <li style={{ padding: "2% 0%" }}>
                                            <span>
                                                <img
                                                    src="images/list-icon.png"
                                                    alt=""
                                                    style={{ width: "15%", marginRight: " 3%" }}
                                                />
                                            </span>{" "}
                                            <Link to="/catering-services-in-chennai/" className="footer-color-txt">
                                                Industrial Catering
                                            </Link>
                                        </li>
                                        <li style={{ padding: "2% 0%" }}>
                                            <span>
                                                <img
                                                    src="images/list-icon.png"
                                                    alt=""
                                                    style={{ width: "15%", marginRight: " 3%" }}
                                                />
                                            </span>{" "}
                                            <Link
                                                to="/corporate-catering-services-in-chennai/"
                                                className="footer-color-txt"
                                            >
                                                Corporate Catering
                                            </Link>
                                        </li>
                                        <li style={{ padding: "2% 0%" }}>
                                            <span>
                                                <img
                                                    src="images/list-icon.png"
                                                    alt=""
                                                    style={{ width: "15%", marginRight: " 3%" }}
                                                />
                                            </span>{" "}
                                            <Link to="/order-now/" className="footer-color-txt">
                                                Out Door Catering
                                            </Link>
                                        </li>
                                        <li style={{ padding: "2% 0%" }}>
                                            <span>
                                                <img
                                                    src="images/list-icon.png"
                                                    alt=""
                                                    style={{ width: "15%", marginRight: " 3%" }}
                                                />
                                            </span>{" "}
                                            <Link to="/contactless-cafeteria-setup/" className="footer-color-txt">
                                                Cafeteria Services{" "}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-3">
                                    <div className="row" style={{ display: "flex", marginLeft: "7%" }}>
                                        <ApartmentIcon className="footer-icon-style" />{" "}
                                        <h2 className="footer-icon-txt-style">Company</h2>
                                    </div>
                                    <ul style={{ color: "#fff" }}>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/story" className="footer-color-txt">
                                                {" "}
                                                Our Story
                                            </Link>
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/careers" className="footer-color-txt">
                                                Careers
                                            </Link>
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/FAQ" className="footer-color-txt">
                                                FAQ
                                            </Link>
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/service-offered" className="footer-color-txt">
                                                Services Offered
                                            </Link>{" "}
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-3">
                                    <div className="row" style={{ display: "flex", marginLeft: "12%" }}>
                                        <DocumentScannerIcon className="footer-icon-style" />{" "}
                                        <h2 className="footer-icon-txt-style">Legal</h2>
                                    </div>
                                    <ul style={{ color: "#fff" }}>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/become-a-vendor" className="footer-color-txt">
                                                Become a Vendor
                                            </Link>{" "}
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/become-a-Consultant" className="footer-color-txt">
                                                {" "}
                                                Become a Consultant
                                            </Link>
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/privacy-policy" className="footer-color-txt">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/term-condition" className="footer-color-txt">
                                                Terms & Conditions
                                            </Link>{" "}
                                        </li>
                                        <li>
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/refund" className="footer-color-txt">
                                                Refunds & Cancellation
                                            </Link>{" "}
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-3">
                                    <ul style={{ color: "#fff" }}>
                                        <li
                                            className="footer-contact-p1"
                                            style={{ lineHeight: "33px", padding: "2% 0%" }}
                                        >
                                            <PlaceIcon className="footer-icon-style" style={{ marginLeft: "2%" }} />

                                            <span>
                                                {" "}
                                                HOGIST TECHNOLOGIES PVT. LTD. 2nd Floor, Kakani Towers, No:34 Khader
                                                Nawaz Khan Road Nungambakkam, Chennai 600 006.
                                            </span>
                                        </li>
                                        <li
                                            className="footer-contact-p1"
                                            style={{ lineHeight: "33px", padding: "2% 0%" }}
                                        >
                                            <PhoneEnabledIcon
                                                className="footer-icon-style"
                                                style={{ marginLeft: "2%" }}
                                            />

                                            <span> +91 - 9962667733</span>
                                        </li>
                                        <li
                                            className="footer-contact-p1"
                                            style={{ lineHeight: "33px", padding: "2% 0%" }}
                                        >
                                            <EmailIcon className="footer-icon-style" style={{ marginLeft: "2%" }} />

                                            <span> support@hogist.com</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className="footer-line" />

                        <div className="container">
                            <div className="row pb-4">
                                <div className="col-lg-6 iso-txt footer-hb">
                                    {" "}
                                    © 2024, Hogist technologies private limited. All rights reserved
                                </div>
                                <div className="col-lg-6">
                                    <div className="row view-center-footer-style">
                                        <div className="mr-4">
                                            <a href="https://www.facebook.com/hogisttechnologies/">
                                                <FacebookIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                        <div className="mr-4">
                                            <a href="https://www.instagram.com/hogistindia">
                                                <InstagramIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                        <div className="mr-4">
                                            <a href="https://twitter.com/Hogistindia">
                                                <TwitterIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                        <div className="mr-4">
                                            <a href="https://www.linkedin.com/company/hogist/">
                                                <LinkedInIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : shouldShowDesign || showSecondPath ? (
                <div>
                    <div style={{ backgroundColor: "rgb(14 14 14)" }}>
                        <div className="odc-clint-say-style">
                            <div className="row row-cols-1 row-cols-md-3  " style={{ paddingTop: "48px" }}>
                                <div className="col mb-place-service">
                                    <ul style={{ color: "#fff" }}>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                {" "}
                                                Food Order online Ambattur
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Anna Nagar
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Avadi
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Besant Nagar
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Chennai
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Chromepet
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online ECR
                                            </Link>{" "}
                                        </li>{" "}
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Guduvanchery
                                            </Link>{" "}
                                        </li>
                                    </ul>
                                </div>

                                <div className="col mb-place-service">
                                    <ul style={{ color: "#fff" }}>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                {" "}
                                                Food Order online Guindy
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Madipakkam
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Medavakkam
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Mylapore
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Nungambakkam
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online OMR
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Navalur
                                            </Link>{" "}
                                        </li>{" "}
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Pallavaram
                                            </Link>{" "}
                                        </li>
                                    </ul>
                                </div>
                                <div className="col mb-place-service">
                                    <ul style={{ color: "#fff" }}>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                {" "}
                                                Food Order online Porur
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Royapettah
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />{" "}
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Sholinganallur
                                            </Link>
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online T nagar
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Tambaram
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Teynampet
                                            </Link>{" "}
                                        </li>
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Thiruvanmiyur
                                            </Link>{" "}
                                        </li>{" "}
                                        <li className="mb-2">
                                            <ArrowRightIcon className="footer-icon-style" />
                                            <Link to="/" className="footer-color-txt">
                                                Food Order online Velachery
                                            </Link>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr className="footer-line" />

                        <div className="odc-clint-say-style">
                            <div className="row pb-4">
                                {/* <div className="col-lg-2 iso-txt footer-hb">
                                    <div className="row view-center-footer-style">
                                        <div className="mr-3">
                                            <Link to="/become-a-vendor" className="footer-color-txt">
                                                PARTNER WITH US
                                            </Link>
                                        </div>
                                        <div className="mr-3">
                                            <Link to="/story" className="footer-color-txt">
                                                STORY
                                            </Link>
                                        </div>
                                        <div className="mr-3">
                                            <Link to="/service-offered" className="footer-color-txt">
                                                SERVICES
                                            </Link>
                                        </div>
                                        
                                        <div className="mr-3">
                                            <Link to="/site/contact-us" className="footer-color-txt">
                                                CONTACT US
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="col-lg-12 odc-footerlogo-copy">
                                    {/* <img src="images/flogo.png" alt="Flogo" style={{width:"35%"}}/> */}{" "}
                                    <div className="row view-center-footer-style mb-4">
                                        <div className="mr-4">
                                            <a href="https://www.facebook.com/hogisttechnologies/">
                                                <FacebookIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                        <div className="mr-4">
                                            <a href="https://www.instagram.com/hogistindia">
                                                <InstagramIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                        <div className="mr-4">
                                            <a href="https://twitter.com/Hogistindia">
                                                <TwitterIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                        <div className="mr-4">
                                            <a href="https://www.linkedin.com/company/hogist/">
                                                <LinkedInIcon className="footer-icon-style" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row view-center-footer-style mb-4">
                                        <div className="mr-3">
                                            <Link to="/become-a-vendor" className="footer-color-txt">
                                                PARTNER WITH US
                                            </Link>
                                        </div>
                                        <div
                                            style={{ borderLeft: "2px solid white", height: "20px", marginRight: "1%" }}
                                        ></div>
                                        <div className="mr-3">
                                            <Link to="/story" className="footer-color-txt">
                                                STORY
                                            </Link>
                                        </div>
                                        <div
                                            style={{ borderLeft: "2px solid white", height: "20px", marginRight: "1%" }}
                                        ></div>
                                        <div className="mr-3">
                                            <Link to="/service-offered" className="footer-color-txt">
                                                SERVICES
                                            </Link>
                                        </div>
                                        <div
                                            style={{ borderLeft: "2px solid white", height: "20px", marginRight: "1%" }}
                                        ></div>
                                        {/* <div className="mr-3">
                                        BLOGS
                                        </div> */}
                                        <div className="mr-3">
                                            <Link to="/site/contact-us" className="footer-color-txt">
                                                CONTACT US
                                            </Link>
                                        </div>
                                    </div>
                                    <p>© 2024, Hogist technologies private limited. All rights reserved</p>
                                </div>
                                {/* <div className="col-lg-4 view-center-footer-style">
                                    <div className="row view-center-footer-style">
                                        <div className="mr-4">
                                            <FacebookIcon className="footer-icon-style" />
                                        </div>
                                        <div className="mr-4">
                                            <InstagramIcon className="footer-icon-style" />
                                        </div>
                                        <div className="mr-4">
                                            <TwitterIcon className="footer-icon-style" />
                                        </div>
                                        <div className="mr-4">
                                            <LinkedInIcon className="footer-icon-style" />
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            ) : paymentPath ? null : (
                <div style={{ backgroundColor: " #c60800" }}>
                    <div
                        className="site-footer"
                        style={{ backgroundImage: 'url("images/banners/ftbg.png")', backgroundSize: " cover" }}
                    >
                        <div className="container cntwidth">
                            <div className="site-footer__widgets">
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-4">
                                        <FooterContacts />
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-2">
                                        <FooterLinks title="Our Info" items={informationLinks} />
                                    </div>
                                    <div className="col-6 col-md-3 col-lg-2">
                                        {/* <i className="footer-contacts__icon fas fa-globe-americas" /> */}
                                        <FooterLinks title="Our Services" items={accountLinks} />
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-4">
                                        <FooterInfo />
                                    </div>
                                </div>
                            </div>

                            <div className="site-footer__bottom">
                                <div className="container">
                                    <div className="row">
                                        <div
                                            className="col-12 col-md-12 col-lg-6"
                                            style={{ display: "flex", alignItems: "center" }}
                                        >
                                            <div className="site-footer__copyright" style={{ color: "black" }}>
                                                © 2024 Copyright{" "}
                                                <a
                                                    href="#"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    style={{ color: "white", fontWeight: 800 }}
                                                >
                                                    {" "}
                                                    HOGIST
                                                </a>{" "}
                                                {/* <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a> */}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6">
                                            <div>
                                                <img className="ftlogo" src="images/flogo.png" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToTop />
                    </div>
                </div>
            )}
        </div>
    );
}
