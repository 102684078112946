/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GetAppIcon from "@mui/icons-material/GetApp";
import { apiurl } from "../../../api/api_url";
import { formatTime, formattedDateTime } from "../../../helper/dateformate";

function AnalyticsPage() {
    const [menulistapi, setMenulistapi] = useState([]);
    const [getData, setGetData] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const handleDateRangeChange = (event, picker) => {
        setStartDate(picker.startDate);
        setEndDate(picker.endDate);
    };
    const isInvalidDate = (date) => {
        return date > new Date();
      };
    
    const exportToExcel = () => {
        const data = [[startDate + 1, endDate]];
        data.push([startDate + 1, endDate]);
    };

    
    const handleApply = async (event, picker) => {
        // setStartDate(picker.startDate);
        // setEndDate(picker.endDate);
        // console.log(picker.startDate.toString());
        await fetch(`${apiurl}/order-analytics`, {
            method: "POST",
            body: JSON.stringify({
                start: picker.startDate,
                end: picker.endDate,
            }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
                const filter = json.data;

                const result = [];

                filter.forEach((element) => {
                    element.services.forEach((item) => {
                        const item1 = {
                            servicedate: element.serviceDate,
                            companyname: element.customer.fullName,
                            companymail: element.customer.email,
                            orderdate: formattedDateTime(element.createdAt),
                            orderid: element._id,
                            servicetype: item.label,
                            menutype: item.vegType,
                            menuitem: item.menuItems && item.menuItems.map((x) => x.label).join(", "),
                            menuprice: item.price,
                            menucount: item.count,
                            totalprice: item.total,
                            orderstatus: element.status,
                            kitchentime: formatTime(item.dispatched.time),
                            deliverytime: formatTime(item.delivered.time),
                            safetycheck: item.covidcheck.status === true ? "Completed" : "Pending",
                            deliveryaddress: element.customer.address,
                        };
                        result.push(item1);
                        console.log(item1);
                    });
                });

                console.log(filter);

                setGetData(result);
                // console.log(fieldData);
                // const fieldData=response.json()
                // setMenulistapi(json);
            });
    };

    const header = [
        { label: "Service Date", key: "servicedate" },
        { label: "Company Name", key: "companyname" },
        { label: "Mail ID", key: "companymail" },
        { label: "Order Date", key: "orderdate" },
        { label: "Order ID", key: "orderid" },
        { label: "Services Type", key: "servicetype" },
        { label: "Menu Type", key: "menutype" },
        { label: "Menu Item", key: "menuitem" },
        { label: "Price", key: "menuprice" },
        { label: "Menu Quantity", key: "menucount" },
        { label: "Total Price", key: "totalprice" },
        { label: "Order Status", key: "orderstatus" },
        { label: "Kitchen Pick up Time", key: "kitchentime" },
        { label: "Delivery Time", key: "deliverytime" },
        { label: "Safety Check Status", key: "safetycheck" },
        { label: "Delivery Address", key: "deliveryaddress" },
    ];

    return (
        <div className="container">
            <DateRangePicker
                // initialSettings={{ startDate: '01/01/2023', endDate: '01/15/2023' }}
                onApply={handleApply}
                maxDate={new Date()}
                onCancel={() => {
                    setStartDate("");
                    setEndDate("");
                }}
                startDate={startDate}
                endDate={endDate}
                showCustomRangeLabel={false}
                alwaysShowCalendars={true}
            >
                <Button variant="contained" style={{ backgroundColor: "#000" }} startIcon={<CalendarMonthIcon />}>
                    Select Date Range
                </Button>

                {/* <input type="text" ranges={[range]} onChange={handleSelect} className="form-control" /> */}
            </DateRangePicker>

            <Button
                variant="contained"
                style={{ backgroundColor: "#dc0000", marginLeft: "1%" }}
                endIcon={<GetAppIcon />}
            >
                {" "}
                <CSVLink
                    style={{ color: "#fff" }}
                    filename={"Hogist_Analytics_Report.csv"}
                    data={getData}
                    headers={header}
                >
                    Export Report
                </CSVLink>
            </Button>
        </div>
    );
}
export default AnalyticsPage;
