import { combineReducers } from "redux";

// reducers
import cartReducer from "./cart";
import compareReducer from "./compare";
import currencyReducer from "./currency";
import localeReducer from "./locale";
import mobileMenuReducer from "./mobile-menu";
import quickviewReducer from "./quickview";
import sidebarReducer from "./sidebar";
import version from "./version";
import wishlistReducer from "./wishlist";
import orderdataReducer from "./orderdata";
import adduserReducer from "./adduser";
import addmenucountReducer from "./addmenucount";
import orderdetailsReducer from "./orderdetails";
import addedmenuReducer from "./selectedmenu";
import cardStateReducer from "./selectedmenu";
import menuCountsReducer from "./selectedmenu";
import addodcuserReducer from "./addodcuser";
import guestReducer from "./guestuser";

export default combineReducers({
    version: (state = version) => state,
    cart: cartReducer,
    compare: compareReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    quickview: quickviewReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
    orderdata: orderdataReducer,
    user: adduserReducer,
    odcuser: addodcuserReducer,
    guestuser:guestReducer,
    addmenucount: addmenucountReducer,
    menuadded: addedmenuReducer,
    cardState: cardStateReducer,
    menuCounts: menuCountsReducer,
    orderdetails: orderdetailsReducer,
    
});
