import React from "react";
import Mobile from "./../../../img/Mobile-app.png";
import PlayStore from "./../../../img/play-store.png";
import AppleStore from "./../../../img/apple-store.png";
import { Link } from "react-router-dom";
// import MobileEffect from "./MobileEffect";

function AppBanner() {
    return (
        <div
            className="container txt-colr txt-justify"
            style={{
                backgroundImage: 'url("images/banners/mbbg.png")',
                backgroundSize: "cover",
                borderRadius: 20,
                marginBottom: 21,
                marginTop: 21,
                paddingBottom: "5%",
            }}
        >
            <div className="row">
                <div className="col-lg-6">
                    {/* <MobileEffect/> */}
                    <img className="width-50 rotate " src={Mobile} alt="" />
                </div>
                <div className="col-lg-6  play-store-txt  ">
                    <h1>Get the all new Hogist App</h1>
                    <p>Order from your favourite vendor and track your order. </p>
                    <div className="row container-fluid">
                        <div className="col-lg-12 itemcenter">
                          
                            <a
                                                    className="topbar-link"
                                                    href="https://play.google.com/store/apps/details?id=com.hogist"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                <img style={{width:"40%"}} src={PlayStore} alt="Play store" />
                                </a>
                           
                        </div>
                        {/* <div className="col-lg-6">
                            <Link to="">
                                {" "}
                                <img className="width-100" src={AppleStore} alt="Apple Store" />
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppBanner;
