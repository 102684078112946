/* eslint-disable no-unused-vars */
// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn } from "mdb-react-ui-kit";

// application
import StroykaSlick from "../shared/StroykaSlick";

//pages
import ServiceSlide from "./ServiceSlide";

// data stubs
import theme from "../../../data/theme";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function Services() {
    return (
        <div className="block about-us txt-colr txt-justify">
            <Helmet>
                <title>{` ${theme.name}-Our Services`}</title>
            </Helmet>

            <div
                className="about-us__image"
                style={{
                    backgroundImage: 'url("images/banners/abg.png")',
                }}
            >
                <div className="mask" style={{ backgroundColor: "rgb(0 0 0 / 68%)", height: "100% " }}>
                    <div className="ab-d-flex justify-content-center align-items-center h-100">
                        <h1 className="text-white mb-0">Our Services</h1>
                    </div>
                </div>
            </div>

            <div className="block block-banner">
                <div className="container-fluid">
                    <div className="row pdside pt-4">
                        <div className="col-lg-4">
                            <div className="teammate__avatar">
                                <img
                                    src="https://www.sunjaya.com.my/wp-content/uploads/2019/11/Wedding-Catering-Fusion-600x600.jpg"
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <h1>Best Catering service in Chennai</h1>
                            <p className="about__text">The Best Catering service in Chennai</p>
                            <div className="typography abpara">
                                <p>
                                    Whenever you plan for a delightful event for your friends and family, you solely
                                    require trustworthy people or a team to rely on. That’s where we come in. Hogist,
                                    the best catering service in Chennai online, offers a perfect catering service for
                                    all kinds of events by revealing the theme of your event at yourself.
                                </p>
                                <p>
                                    Catering services take responsibility for bringing you the best quality services you
                                    could ask for. As food usually becomes the pinnacle of an event. And being a vogue
                                    topic, it becomes crucial to update new varieties and bring in new dishes. We at
                                    Hogist come up with unique dishes that favor your taste buds. Also, since many
                                    people embrace seasonal eating, we bring out the essence of the season in our food.
                                    In addition to the assortment of the most exquisite food, we have knowledgeable and
                                    experienced staff.
                                </p>
                                <p>
                                    Our customer service staff members serve as the face of our company. They are the
                                    first people our customers reach out to whenever they encounter any problem
                                    regarding our services. We ensure our personnel can handle customer requirements
                                    with excellence and give you the best catering service for your events. Thus, all
                                    you need to do is simply order online, relax, and enjoy, while our dedicated team
                                    will work out the details for you. We always focus on the quality of food and service
                                    and make sure that you are hassle-free throughout the event. In simple words, you
                                    search "catering services near me" and there you get to know about HOGIST.
                                </p>
                                <div className="cntbtn">
                                    <Link to="/" className="logbtn">
                                        Contact Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about__service_part">
                <h1 className="about__service_tit">Our Best Service in Chennai</h1>
                <ServiceSlide />
            </div>

            {/* <div className="about__service_part">
                <div className="container">
                    <h1 className="about__service_tit">Our Best Services</h1>
                    <div className="row">
                        <div className="col-lg-3">

                        <MDBCard>
                        <div style={{    height: '50%'}}> 
                         <MDBCardImage src='https://media.istockphoto.com/id/650655146/photo/catering-food-wedding-event-table.jpg?s=612x612&w=0&k=20&c=ATGYgW8bM_559jJ5aUNO4HlJqpkOWUmNNMMflx5kajo=' position='top' alt='...'  style={{    height: '100%'}}/>
      </div>
      <MDBCardBody>
        <MDBCardTitle>Customer Catering</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </MDBCardText>
        
      </MDBCardBody>
    </MDBCard>
                            
                        </div>
                        <div className="col-lg-3">

                        <MDBCard>
                        <div style={{    height: '50%'}}>   <MDBCardImage src='https://img.freepik.com/premium-photo/scooping-food-catering-dinner-timexa_41969-5203.jpg' position='top' alt='...' style={{    height: '100%'}} />
      </div>
      <MDBCardBody>
        <MDBCardTitle>Industrial Catering</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </MDBCardText>
        
      </MDBCardBody>
    </MDBCard>
                           
                        </div>
                        <div className="col-lg-3">

                        <MDBCard>
    <div style={{    height: '50%'}}>  <MDBCardImage src='https://dailybox.co.in/assets/images/services_corporate.jpg' position='top' alt='...'  style={{    height: '100%'}}/>
    </div>
     <MDBCardBody>
        <MDBCardTitle>Corporate Catering</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </MDBCardText>
        
      </MDBCardBody>
    </MDBCard>

                        </div>

                        <div className="col-lg-3">
                        <MDBCard>
     <div style={{    height: '50%'}}> <MDBCardImage src='https://images.unsplash.com/photo-1552566626-52f8b828add9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fHJlc3RhdXJhbnQlMjBtYW5hZ2VyfGVufDB8fDB8fA%3D%3D&w=1000&q=80' position='top' alt='...' style={{    height: '100%'}} />
     </div>
     <MDBCardBody>
        <MDBCardTitle>Cafeteria</MDBCardTitle>
        <MDBCardText>
          Some quick example text to build on the card title and make up the bulk of the card's content.
        </MDBCardText>
        
      </MDBCardBody>
    </MDBCard>
                            
                           
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">About Us</h1>
                            <div className="about-us__text typography">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Cras lacus metus, convallis ut leo nec, tincidunt
                                    eleifend justo. Ut felis orci, hendrerit a pulvinar et,
                                    gravida ac lorem. Sed vitae molestie sapien, at
                                    sollicitudin tortor.
                                </p>
                                <p>
                                    Duis id volutpat libero, id vestibulum purus.Donec euismod
                                    accumsan felis,egestas lobortis velit tempor vitae. Integer
                                    eget velit fermentum, dignissim odio non, bibendum velit.
                                </p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">Meat Our Team</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    Want to work in our friendly team?
                                    <br />
                                    <Link to="/contact-us">Contact us</Link>
                                    {' '}
                                    and we will consider your candidacy.
                                </div>
                                <div className="about-us__teammates teammates">
                                    <StroykaSlick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Michael Russo</div>
                                            <div className="teammate__position text-muted">Chief Executive Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Katherine Miller</div>
                                            <div className="teammate__position text-muted">Marketing Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Anthony Harris</div>
                                            <div className="teammate__position text-muted">Finance Director</div>
                                        </div>
                                    </StroykaSlick>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Services;
