import { ADDODCUSER_CHANGE, REMOVEODCUSER_CHANGE } from "./addodcuserActionTypes";

const initialState = null;

export default function addodcuserReducer(state = initialState, action) {
    if (action.type === ADDODCUSER_CHANGE) {
        return action.addodcuser;
    }
    if (action.type === REMOVEODCUSER_CHANGE) {
        return "Guest";
    }

    return state;
}
