/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

// import VisibilityIcon from "@mui/icons-material/Visibility";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// import AddIcon from "@mui/icons-material/Add";
// import{Veg} from './../../../../public/images/veg.png'
// import{NonVeg} from 'images/nonveg.png'
// //
import { url } from "../../../api/api_url";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBIcon } from "mdb-react-ui-kit";
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";
import { useHistory } from "react-router-dom";
import Alert from "react-bootstrap/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AddCount(props) {
    const { addmenucount, layout } = props;
    // const menuservicecount=addmenucount;

    const totalCount = addmenucount.services.map((service) => parseInt(service.count));
    const [inputCountValues, setInputCountValues] = useState(addmenucount);
    // const [count, setCount] = useState(addmenucount);
    // const [count1, setCount1] = useState(25);
    // const [count2, setCount2] = useState(25);
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(false);
    const [addcount, setAddcount] = useState([]);
    const history = useHistory();
    const date = new Date();
    // const [count, setCount] = useState([])

    const services = [
        { label: "Lunch", count: 10 },
        { label: "Dinner", count: 20 },
    ];
    const handleClickOpen = () => {
        setOpen(true);
        setAddcount();
    };

    const handleClose = () => {
        toast.error("Oops your Order is not Success!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
    };

    const notify = async (e) => {
        e.preventDefault();

        toast.success("Your Order is Success!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
        setAddcount();

        //api

        await fetch(`${url}/order`, {
            method: "POST",
            body: JSON.stringify(inputCountValues),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
            });
        history.push("/site/my-order");
    };

    const [isVisible, setIsVisible] = useState(-1);
    // const [isVisible1, setIsVisible1] = useState(false);
    // const [isVisible2, setIsVisible2] = useState(false);

    const toggleVisibility = (index) => {
        setIsVisible(index === isVisible ? -1 : index);
    };

    // const toggleVisibility1 = () => {
    //     setIsVisible1(!isVisible1);
    // };

    // const toggleVisibility2 = () => {
    //     setIsVisible2(!isVisible2);
    // };

    //

    // const handleIncrement1 = (index) => {
    //     setCount(count + 1);
    // };
    // const handleDecrement1 = () => {
    //     if (count > 25) {
    //         setCount(count - 1);
    //     }
    // };
    ///
    const handleIncrement12 = (index) => {
        // setInputCountValues(index === inputCountValues + 1 ? "" : index);
        const newInputServices = inputCountValues.services;
        newInputServices[index].count = newInputServices[index].count * 1 + 1;
        setInputCountValues({ ...inputCountValues, services: newInputServices });
    };
    const handleDecrement12 = (index) => {
        // if (index === inputCountValues > 25) {
        //     setInputCountValues(index === inputCountValues - 1);
        // }
        const newInputServices = inputCountValues.services;
        newInputServices[index].count = newInputServices[index].count * 1 - 1;
        setInputCountValues({ ...inputCountValues, services: newInputServices });
    };
    const handleChange12 = (e, index) => {
        const value = e.target.value;
        // const value = e.target.value<26 ? 25 : e.target.value;
        const newInputServices = inputCountValues.services;
        newInputServices[index].count = value;
        setInputCountValues({ ...inputCountValues, services: newInputServices });
    };
    //
    // const handleIncrement2 = () => {
    //     setCount1(count1 + 1);
    // };
    // const handleDecrement2 = () => {
    //     if (count1 > 25) {
    //         setCount1(count1 - 1);
    //     }
    // };

    // //
    // const handleIncrement3 = () => {
    //     setCount2(count2 + 1);
    // };
    // const handleDecrement3 = () => {
    //     if (count2 > 25) {
    //         setCount2(count2 - 1);
    //     }
    // };

    ////
    // const handleChange = (e, index) => {
    //     let newCount = e.target.value;
    //     if (newCount < 0) {
    //         newCount = 20;
    //     }
    //     setCount(newCount++);
    // };

    // const handleChange1 = (e) => {
    //     let newCount1 = e.target.value;
    //     if (newCount1 < 0) {
    //         newCount1 = 25;
    //     }
    //     setCount1(newCount1++);
    // };

    // const handleChange2 = (e) => {
    //     let newCount2 = e.target.value;
    //     if (newCount2 < 0) {
    //         newCount2 = 25;
    //     }
    //     setCount2(newCount2++);
    // };

    console.log(inputCountValues);
    const dateStr = addmenucount.serviceDate;
    const dateObj = new Date(dateStr);
    const monthStr = dateObj.toLocaleString("default", { month: "long" });
    const yearStr = dateObj.getFullYear();

    return (
        <div className="container ">
            <form>
                {/* {menucountdata.services.map((item,index) => ( */}
                <MDBRow>
                    <div>
                        <h4 className="count-date-style">
                            {" "}
                            {addmenucount.day} {addmenucount.date} {monthStr} {yearStr}
                        </h4>
                    </div>
                    {inputCountValues.services &&
                        inputCountValues.services.map((item, index) => (
                            <div key={index} style={{ width: "100%" }}>
                                <MDBCol lg={12} className="mrbt">
                                    <MDBCard
                                        className="service-tab-style border-styles mbcard"
                                        style={{ textAlign: "center" }}
                                    >
                                        <MDBCardBody>
                                            <div className="d-flex align-items-center">
                                                <div className="col-lg-4">
                                                    <div className="ms-3 mb-ml-10">
                                                        <h2 className="fw-bold mb-1" style={{ textAlign: "initial" }}>
                                                            <span>{item.label}</span>
                                                        </h2>
                                                        <div style={{ display: "flex", marginTop: "40px" }}>
                                                            <img
                                                                className="menu-veg-non"
                                                                src={
                                                                    item.vegType === "VEG"
                                                                        ? "images/veg.png"
                                                                        : "images/nonveg.png"
                                                                }
                                                                alt=""
                                                            />
                                                            <p className="amount-txt mb-0"> ₹ {item.price} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-3 col-lg-6 mt-25">
                                                    <h4 className=" mb-1">Quantity</h4>
                                                    <div className="package-counter count-brt">
                                                        <div className="counter-input">
                                                            <Button
                                                                disabled={item.count < 26}
                                                                onClick={() => handleDecrement12(index)}
                                                            >
                                                                <MDBIcon
                                                                    className="count-icon-style"
                                                                    fas
                                                                    icon="minus-circle"
                                                                />
                                                            </Button>

                                                            <input
                                                                type="text"
                                                                value={item.count}
                                                                onChange={(e) => handleChange12(e, index)}
                                                                placeholder="count"
                                                                className="count-input-value"
                                                                // value={count[index]}
                                                                name="count"
                                                                // onChange={handleChange}
                                                            />
                                                            <Button onClick={() => handleIncrement12(index)}>
                                                                <MDBIcon
                                                                    className="count-icon-style"
                                                                    fas
                                                                    icon="plus-circle"
                                                                />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-2 mb-mt-30">
                                                    <Button
                                                        className="menu-details-view-btn"
                                                        onClick={() => toggleVisibility(index)}
                                                    >
                                                        view Menu
                                                    </Button>
                                                </div>
                                            </div>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>

                                {isVisible === index ? (
                                    <MDBCol lg={6} className="container mrbt">
                                        <MDBCard className="service-tab-style ">
                                            <MDBCardBody>
                                                <h2
                                                    className=" mb-4 mt-2"
                                                    style={{ textAlign: "center", color: "#dc0000" }}
                                                >
                                                    Menu Items
                                                </h2>

                                                <MDBListGroup light className="menuitem-list-style12">
                                                    {item.menuItems.map((items, indexed) => (
                                                        <MDBListGroupItem
                                                            key={indexed}
                                                            tag="a"
                                                            action
                                                            noBorders
                                                            className="px-3 rounded-3 mb-2 display-flex "
                                                        >
                                                            <div className="col-lg-2">
                                                                <img
                                                                    src="https://w7.pngwing.com/pngs/101/874/png-transparent-food-computer-icons-spoon-restaurant-food-heart-fast-food-restaurant.png"
                                                                    alt=""
                                                                    style={{ width: "50px", height: "50px" }}
                                                                    className="rounded-circle spinning"
                                                                />
                                                            </div>
                                                            <div className="ms-3 col-lg-10 pt-lf-14 display-flex ">
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <div className="col-lg-8">
                                                                        {" "}
                                                                        <h4
                                                                            className=" mb-1"
                                                                            style={{ textAlign: "left" }}
                                                                        >
                                                                            {items.label}
                                                                        </h4>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <p
                                                                            style={{
                                                                                // marginLeft: "10%",
                                                                                paddingTop: "10%",
                                                                                fontSize: "18px",
                                                                                fontWeight: "600",
                                                                                width: "95px",
                                                                            }}
                                                                        >
                                                                            {`( ${items.size} ${items.unit} )`}
                                                                            {/* ( {items.size} {items.unit}) */}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </MDBListGroupItem>
                                                    ))}
                                                </MDBListGroup>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                ) : null}
                            </div>
                        ))}
                </MDBRow>

                {/* ))} */}

                {/* <div> */}
                {/* {inputCountValues.services &&
                        inputCountValues.services.map((produ, index12) => { */}
                {/* <div > */}
                {/* {services &&
                       services.map((produ, index12) => {  */}
                {/* <input
                                key={index12}
                                    type="text"
                                    // value={produ.label}
                                    // onChange={() => handleChange12(index12)}
                                    placeholder="count"
                                /> */}
                {/* })} */}
                {/* </div> */}
                {/* })} */}
                {/* </div> */}

                <div className="count-ord-btn">
                    <Button className="ord-btn-menu" endIcon={<SendIcon />} onClick={handleClickOpen}>
                        Order Now
                    </Button>

                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{"Please Confirm your Order"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                Are you sure you need to confirm this order count.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>No</Button>
                            <Button onClick={notify}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </form>
        </div>
    );
}
AddCount.propTypes = {
    /**
     * product object
     */
    addmenucount: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = (state) => ({
    addmenucount: state.addmenucount,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddCount);
