/* eslint-disable no-unused-vars */
// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./industry/mobile/MobileHeader";
import MobileMenu from "./industry/mobile/MobileMenu";
import Quickview from "./industry/shared/Quickview";
import Login from "./home/Login";

// pages

//
import AboutUs from "./industry/site/AboutUs";
import ContactUs from "./industry/site/ContactUs";
import Services from "./industry/site/Services";
// import Login from './account/Login';

import HomePageTwo from "./industry/home/HomePageTwo";
import CorporateHomePage from "./industry/home/CorporateHomePage";

//Industry
import MyOrderMenu from "./industry/site/MyOrderMenu";
import OrderCount from "./industry/shared/OrderCount";
import MyOrderStatus from "./industry/site/MyOrderStatus";
import MyOrderDetails from "./industry/shared/MyOrderDetails";
import MyOrderDelivery from "./industry/shared/MyOrderDelivery";
import MyOrderCancel from "./industry/shared/MyOrderCancel";
import FoodSafetyCheck from "./industry/site/FoodSafetyCheck";

import Layout from "./Layout";
import CancelOrders from "./industry/site/CancelOrders";
import CancelledOrderDetailPage from "./industry/shared/CancelledOrderDetailPage";
import CancelledOrdersList from "./industry/shared/CancelledOrdersList";
import SpecialOrders from "./industry/site/SpecialOrders";
import SpecialOrderDetails from "./industry/shared/SpecialOrderDetails";
import MenuChange from "./industry/site/MenuChange";
import MenuChangedDetails from "./industry/shared/MenuChangedDetails";
import AnalyticsReport from "./industry/site/AnalyticsReport";
import Livemap from "./industry/shared/Livemap";

//ODC
import ODCHomepage from "./industry/odc/Homepage";
import Addmenu from "./industry/odc/Addmenu";
// import Addtocard from "./industry/odc/Addtocart"
// import Checkout from "./industry/odc/Checkout";
import Checkout1 from "./industry/odc/Checkout1";
// import Cart from "./industry/odc/Cart";
// import Cart1 from "./industry/odc/Cart1";
import Mainhome from "./industry/odc/Mainhome";
import Addcard from "./industry/odc/Addcart";
import Payment from "./industry/odc/Payment";
import Payment1 from "./industry/odc/Payment1";
import Tmainhome from "./industry/odc/Tmain";
import Singin from "./industry/odc/Singin";
import ODCLogin from "./industry/odc/Login1";
// import ODCLogin from "./industry/odc/Login";
import Profile from "./industry/odc/Profile";
import Orderlist from "./industry/odc/Orderlist";
import Orderdetailsdata from "./industry/odc/Orderdetailsdata";
import Location1 from "./industry/odc/Location1";
import Test from "./industry/odc/Test1";

// data stubs
import theme from "./../data/theme";
// import Services from './services';

// test
import PageCart from "./industry/shop/ShopPageCart";
import PageCheckout from "./industry/shop/ShopPageCheckout";
import PageCompare from "./industry/shop/ShopPageCompare";
import PageWishlist from "./industry/shop/ShopPageWishlist";
import ShopPageCategory from "./industry/shop/ShopPageCategory";
import ShopPageOrderSuccess from "./industry/shop/ShopPageOrderSuccess";
import ShopPageProduct from "./industry/shop/ShopPageProduct";
import ShopPageTrackOrder from "./industry/shop/ShopPageTrackOrder";
import AccountLayout from "./industry/account/AccountLayout";

//
import OurStory from "./industry/site/OurStory";
import Career from "./industry/site/Career";
import ServicesOffered from "./industry/site/ServicesOffered";
import Faqs from "./industry/site/Faqs";
import BecomeVendor from "./industry/site/BecomeVendor";
import BecomeConsultant from "./industry/site/BecomeConsultant";
import PrivacyPolicy from "./industry/site/PrivacyPolicy";
import TermsAndConditions from "./industry/site/TermsAndConditions";
import CancellationAndRefund from "./industry/site/CancellationsAndRefund";

function HomeLayout(props) {
    const { match, headerLayout, homeComponent } = props;

    return (
        <React.Fragment>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        <Route exact path="/site/login-page" component={Login} />
                        <Route exact path="/industry/home" component={HomePageTwo} />
                        <Route exact path="/corporate/home" component={CorporateHomePage} />

                        {/*
                        //  Industrial Site
                        */}
                        {/* <Redirect exact from="/site" to="/about-us" /> */}
                        <Route exact path="/about-us" component={AboutUs} />
                        <Route exact path="/service" component={Services} />
                        <Route exact path="/contact-us" component={ContactUs} />

                        <Route exact path="/story" component={OurStory} />
                        <Route exact path="/careers" component={Career} />
                        <Route exact path="/FAQ" component={Faqs} />
                        <Route exact path="/service-offered" component={ServicesOffered} />
                        <Route exact path="/become-a-vendor" component={BecomeVendor} />
                        <Route exact path="/become-a-Consultant" component={BecomeConsultant} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/term-condition" component={TermsAndConditions} />
                        <Route exact path="/refund" component={CancellationAndRefund} />
                        {/* <Route exact path="/account/login-page" component={Login} /> */}

                        <Route exact path="/site/my-menu" component={MyOrderMenu} />
                        <Route exact path="/site/my-order-count" component={OrderCount} />

                        {/* order status */}
                        <Route exact path="/site/my-order" component={MyOrderStatus} />
                        <Route exact path="/site/my-order-detail-map/:lat/:long" component={Livemap} />
                        <Route exact path="/site/my-order-details/:id" component={MyOrderDetails} />
                        <Route exact path="/site/delivered-orders/:id" component={MyOrderDelivery} />
                        <Route exact path="/site/canceled-orders/:id" component={MyOrderCancel} />

                        {/* Food safety */}

                        <Route exact path="/site/food-safety-check" component={FoodSafetyCheck} />

                        {/* Menu Change */}

                        {/* Cancel Orders */}
                        <Route exact path="/site/cancel-orders" component={CancelOrders} />
                        <Route exact path="/site/cancel-orders-details/:id" component={CancelledOrderDetailPage} />
                        <Route exact path="/site/cancelled-orders-list" component={CancelledOrdersList} />

                        {/* Special Orders*/}
                        <Route exact path="/site/special-orders" component={SpecialOrders} />
                        <Route exact path="/site/special-order-details/:id" component={SpecialOrderDetails} />
                        {/* Menu Change*/}
                        <Route exact path="/site/menu-change" component={MenuChange} />
                        <Route exact path="/site/menu-changed-details" component={MenuChangedDetails} />

                        <Route exact path="/site/analytics" component={AnalyticsReport} />

                        {/* ODC*/}
                        <Route exact path="/mainhome/odc" component={Location1} />
                        <Route exact path="/odc/home" component={ODCHomepage} />
                        <Route exact path="/odc/resturent/:slug" component={Addmenu} />
                        {/* <Route exact path="/odc/cart" component={Addtocard} /> */}
                        <Route exact path="/odc/addcart" component={Addcard} />
                        {/* <Route exact path="/odc/addedcart" component={Cart1} /> */}
                        {/* <Route exact path="/odc/checkoutpage" component={Checkout} /> */}
                        <Route exact path="/odc/checkout" component={Checkout1} />
                        <Route exact path="/odc/signin" component={Singin} />
                        <Route exact path="/odc/login" component={ODCLogin} />
                        <Route exact path="/odc/profile" component={Profile} />
                        <Route exact path="/payment/:id/:date/:total" component={Payment} />
                        <Route exact path="/odc/orderlist" component={Orderlist} />
                        <Route exact path="/odc/order-details/:id" component={Orderdetailsdata} />
                        <Route exact path="/odc/test" component={Test} />

                        <Redirect to="/" />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

HomeLayout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

HomeLayout.defaultProps = {
    headerLayout: "default",
};

export default HomeLayout;
