// react
import React, { useEffect, useState } from "react";

// third-party
import { connect } from "react-redux";
import PropTypes from "prop-types";

// application
import Indicator from "./Indicator";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { apiurl } from "../../api/api_url";
import { useHistory } from "react-router-dom";
import { formattedDate } from "../../helper/dateformate";

function Notificationodc(props) {
    const { odcuser } = props;
    const [menulistapi, setMenulistapi] = useState([]);
    const history = useHistory();
    useEffect(() => {
        getApiData();
    }, []);
    // order
    const getApiData = async () => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("tokenodc") };
        const response = await fetch(`${apiurl}/odc-unpaid-list`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(response.data);
    };

    // const { menuadded } = "props";
    let dropdown;

    const menusAdded = odcuser && odcuser.fullName ?menulistapi && menulistapi.length:0;

    const menuDetails = (item) => {

        history.push("/odc/order-details/" + item._id);
    };


    if (menusAdded > 0) {
        dropdown = (
            <div className="dropcart pay-notify-style" >
                <div className="dropcart " style={{width:"100%"}}>
                    {/* <div className="dropcart__products-list">{items}</div> */}

                    <div className="dropcart__totals " style={{overflow: "auto",height: "355px"}}>
                            {menulistapi?menulistapi.map(
                                (item, indxed) => (
                                    <div key={indxed} onClick={()=>menuDetails(item)} style={{paddingBottom: "6%"}}>
                                    <div className="card number-font" style={{textAlign:"center",paddingTop:"3%",paddingBottom:"2%"}}>
                                        
                                        <h5 style={{ color: "#c60800",paddingBottom: "2%" }}>Payment is Pending </h5>
                                        <p>Order ID : {item.id}</p>
                                        <div className="d-flex" style={{justifyContent:"center"}}>
                                        <p>Service Date : {formattedDate(item.serviceDate)}</p>
                                        <p style={{paddingLeft:"7%",}}>Balance Amount : <span style={{ color: "#c60800", fontWeight: "800" }}> {item.balance}</span></p>
                                        </div>
                                       
                                    </div>
                                    </div>
                                )
                            ):
                            <div>
                                <h4 style={{textAlign:"center",padding:"2% 0%",color: "#c60800"}}>Nothing to show</h4>
                                <div style={{
                                    display:"flex",justifyContent:"center"
                                }}>
                                <img src="images/notificationnone.png" alt="notification" style={{ width: "55%"}}/>
                                </div>

 
                            </div>
                            }                   
                    </div>

                   
                </div>
            </div>
        );
    }else{
        dropdown = (
            <div className="dropcart" style={{ color: "#000" }}>
            <div className="dropcart">
                {/* <div className="dropcart__products-list">{items}</div> */}

                <div className="dropcart__totals">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img src="images/notificationnone.png" alt="Menu" style={{ width: "60%" }} />
                    </div>
                    <h4 style={{textAlign:"center",paddingBottom:"10%",color: "#949495"}}>Nothing to show</h4>
                </div>

            </div>
        </div>
        );
    }

    return (
        <Indicator
            className="cart-icon-style"
            dropdown={dropdown}
            value={menusAdded}
            icon={<NotificationsIcon />}
        />
    );
}
Notificationodc.propTypes = {
    odcuser: PropTypes.object.isRequired,
   
};

const mapStateToProps = (state) => ({
    odcuser: state.odcuser,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Notificationodc);

