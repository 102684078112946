import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import StatusStep from "./StatusStep";
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";
// import { useSelector } from "react-redux";
import Livemap from "./Livemap";
import { useHistory } from "react-router-dom";

export default function StatusSteps(props) {
    // const orderdetails = useSelector(state => state.orderdetails);
    const { orderdetails,userdetails } = props;
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClosed = () => setOpen(false);
    // const handleShowed = () => setOpen(true);
    const handleShowed = () => {
        if (orderdetails.dispatched.location != null || orderdetails.dispatched.location.lat != null || orderdetails.dispatched.location.long != null) {
            history.push(`/site/my-order-detail-map/${orderdetails.dispatched.location.lat}/${orderdetails.dispatched.location.long}?address=${userdetails.customer.address}`);
            // do something with latitude
          } else {
            // handle the case when myObject is null
            toast.error("Your Order Delivery not Started Yet!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
          }
       
    };
console.log(orderdetails);
    const [activeStep] = React.useState(8);

    // console.log(orderdetails.dispatched.location.lat);

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    // console.log(orderdetails.prodstart.status === true ? 'Completed':'Pending' );

    // const { lat, lng } = this.props;
    // let lat;
    // let lng;
    // const googleMapsScript = document.createElement('script');
    // googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC27vv8xiarVhhsK78bAOjsB4vYIiW5JgY`;

    // window.document.body.appendChild(googleMapsScript);

    // googleMapsScript.addEventListener('load', () => {
    //   const map = new window.google.maps.Map(document.getElementById('map'), {
    //     center: {  lat: 37.774929, lng: -122.419416 },
    //     zoom: 12
    //   });

    //   const marker = new window.google.maps.Marker({
    //     position: { lat, lng },
    //     map: map
    //   });

    // //   this.setState({ map, marker });
    // });

    // marker && marker.setMap(null);

    return (
        <div className="container">
            {/* {orderdetails.st.map((item, index) => ( */}
            <Box sx={{ maxWidth: "100%", marginLeft: "20px" }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Order Approved</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        className={
                                            orderdetails && orderdetails.approved && orderdetails.approved.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.approved && orderdetails.approved.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Raw Material Purchased</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        className={
                                            orderdetails && orderdetails.purchased && orderdetails.purchased.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.purchased && orderdetails.purchased.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Production Started</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        className={
                                            orderdetails && orderdetails.prodstart && orderdetails.prodstart.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.prodstart && orderdetails.prodstart.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Production Done</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        className={
                                            orderdetails && orderdetails.prodend && orderdetails.prodend.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.prodend && orderdetails.prodend.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">RE/KM checking</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        className={
                                            orderdetails && orderdetails.rekm && orderdetails.rekm.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.rekm && orderdetails.rekm.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Food safety Check</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        className={
                                            orderdetails && orderdetails.covidcheck && orderdetails.covidcheck.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.covidcheck && orderdetails.covidcheck.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                    {/* <StatusStep/>  */}
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Hogist Delivery Challan</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        onClick={handleShow}
                                        className={
                                            orderdetails && orderdetails.dlchallan && orderdetails.dlchallan.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.dlchallan && orderdetails.dlchallan.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                    {/* <StatusStep/>  */}
                                </div>
                            </div>
                        </StepLabel>
                    </Step>

                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Food is Dispatched</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                        onClick={handleShowed}
                                        className={
                                            orderdetails && orderdetails.dispatched && orderdetails.dispatched.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.dispatched && orderdetails.dispatched.status
                                            ? "View Live Location"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <div className="row">
                                <div className="col-lg-6 display-flex detail-ft-size">Food is Delivered</div>
                                <div className="col-lg-6 " style={{ textAlign: "center" }}>
                                    <Button
                                      onClick={handleShow1}
                                        className={
                                            orderdetails && orderdetails.delivered && orderdetails.delivered.status
                                                ? "details-btn-done"
                                                : "details-btn-notdone"
                                        }
                                    >
                                        {orderdetails && orderdetails.delivered && orderdetails.delivered.status
                                            ? "Completed"
                                            : "Pending"}
                                    </Button>
                                </div>
                            </div>
                        </StepLabel>
                        {orderdetails && orderdetails.delivered && orderdetails.delivered.status === true ? (
                            <div
                                className={
                                    orderdetails && orderdetails.delivered && orderdetails.delivered.status
                                        ? "stper-end-txt-done"
                                        : "stper-end-txt"
                                }
                            >
                                <span>Your Order has been delivered Successfully.</span>
                                <span>Enjoy Your Food.!</span>
                            </div>
                        ) : null}
                    </Step>
                </Stepper>

                <Modal show={show} onHide={handleClose}>
                    <form>
                        <Modal.Header className="pop_head">
                            <img className="detail-popup-logo" src={Hlogo} alt="logo" />

                            <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClose}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <img className="dcimagestyle"
                                    src={
                                        orderdetails && orderdetails.dlchallan && orderdetails.dlchallan.url
                                            ? orderdetails.dlchallan.url
                                            : "https://vyaparwebsiteimages.vypcdn.in/marketing-images/images/delivery-challan/index/delivery-challan-material.webp"
                                    }
                                    alt="Delivery Challan"
                                />
                            </div>
                        </Modal.Body>

                       
                    </form>
                </Modal>

                <Modal show={show1} onHide={handleClose1}>
                    <form>
                        <Modal.Header className="pop_head">
                            <img className="detail-popup-logo" src={Hlogo} alt="logo" />

                            <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClose1}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <img className="dcimagestyle"
                                    src={
                                        orderdetails && orderdetails.delivered && orderdetails.delivered.url
                                            ? orderdetails.delivered.url
                                            : "https://img.freepik.com/free-vector/man-delivering-food-while-wearing-medical-mask_52683-39764.jpg"
                                    }
                                    alt="Delivery Challan"
                                />
                            </div>
                        </Modal.Body>

                       
                    </form>
                </Modal>

                <Modal show={open} onHide={handleClosed}>
                    <form>
                        <Modal.Header className="pop_head">
                            <img className="detail-popup-logo" src={Hlogo} alt="logo" />

                            <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClosed}>
                                X
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{marginLeft: "-3.4%",color:"#000",textAlign:'center',fontWeight:"600",height: "500px"}}>
                               
                                { orderdetails && orderdetails.dispatched && orderdetails.dispatched.status? <Livemap  />:'Delivery not Started Yet!'}
                               
                                {/* <div id="map" style={{ width: '100%', height: '400px' }}></div> */}
                            </div>
                        </Modal.Body>

                       
                    </form>
                </Modal>
            </Box>

          
        </div>
    );
}
