// eslint-disable-next-line
export default [
    { image: "images/client/client1.jpeg" },
    { image: "images/client/client2.jpeg" },
    { image: "images/client/client3.jpeg" },
    { image: "images/client/client4.jpeg" },
    { image: "images/client/client5.jpeg" },
    { image: "images/client/client6.jpeg" },
    { image: "images/client/client7.jpeg" },
    { image: "images/client/client8.jpeg" },
    { image: "images/client/client9.jpeg" },
    { image: "images/client/client10.jpeg" },
    { image: "images/client/client11.jpeg" },
    { image: "images/client/client12.jpeg" },
    { image: "images/client/client13.jpeg" },
    { image: "images/client/client14.jpeg" },
    { image: "images/client/client15.jpeg" },

];
