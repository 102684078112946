/* eslint-disable no-unused-vars */
// react
import React, { useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import Rating from "./Rating";
import { cartAddItem } from "../../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../../svg";
import { compareAddItem } from "../../../store/compare";
import { quickviewOpen } from "../../../store/quickview";
// import { url } from "../../../services/utils";
import { wishlistAddItem } from "../../../store/wishlist";
// import MonthlyMenu from "../site/MonthlyMenu";
import LoginBanner from "../../home/LoginBanner";
import { useHistory } from "react-router-dom";
import { addmenucountChange } from "../../../store/addmenucount";
import { useDispatch } from "react-redux";

function OrderDateList(props) {
    const dispatch = useDispatch();
    const { menudate, product, layout, quickviewOpen, cartAddItem, wishlistAddItem, compareAddItem } = props;
    const containerClasses = classNames("product-card", "product-card-lst", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    const history = useHistory();

    // console.log(menudate);
    const dateStr = menudate.serviceDate;
    const dateObj = new Date(dateStr);
    const monthStr = dateObj.toLocaleString("default", { month: "long" });
    const yearStr = dateObj.getFullYear();

    const countChange = () => {
        dispatch(addmenucountChange(menudate));

        // const menucountdata =
        // menudate.services.filter(item=> item.id=="your ID")

        // history.push("/site/my-order-count", menucountdata={ menudateid }) ;
        if (noservices[0]) {
            history.push(`/site/my-order-count`);
        }

        // console.log(menucountdata);
    };
    const noservices = menudate.services.map((item) => item.label);
    //  console.log(noservices[0]);
    return (
        <div>
            {/* {menulistapi && menulistapi.map((item,index)=>{ */}
            <div className={containerClasses}>
                {/* <Link onChange={countChange} to="/site/my-order-count"> */}
                <div onClick={countChange}>
                    <div className=" container- fluid row">
                        <div display-flex="true" className="col-lg-6">
                            <div>
                                <p className="menudate-list-txt-style1">
                                    {monthStr} {yearStr}
                                </p>
                            </div>
                            <div className="calunder-data">
                                <div className="date-bg">
                                    <h1 style={{ fontSize: 43 }}>{menudate.date}</h1>
                                    <p style={{ fontSize: 22 }}>{menudate.day}</p>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 product-card__info">
                            {noservices[0] ? (
                                <div className="product-card__name" style={{ marginTop: "20%" }}>
                                    <div className="menu-txt">
                                        {/* {menudate.services.map((item, index) => (
                                        <span key={index}>
                                            {item.label }
                                            {" , "}
                                        </span>
                                    ))} */}
                                        {noservices.join(" , ")}
                                    </div>
                                </div>
                            ) : (
                                <div className="product-card__name" style={{ marginTop: "20%" }}>
                                    <div className="menu-txt">
                                        <span style={{ color: "red" }}>No Service for the Date</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* })} */}
        </div>
    );
}

OrderDateList.propTypes = {
    /**
     * product object
     */
    menudate: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDateList);
