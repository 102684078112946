import { ADDGUESTUSER_CHANGE } from "./guestActionTypes";


const initialState = "";

export default function guestReducer(state = initialState, action) {
    if (action.type === ADDGUESTUSER_CHANGE) {
      return action.guestData;
      }
    
      return state;
    }

// export default function addedmenuReducer(state = initialState, action) {
//     switch (action.type) {
//         case ADDGUESTUSER_CHANGE :
//           return state.map((menu) =>[ ...menu, {guestData: action.payload.guestData,clatitude:action.payload.clatitude,clatitude:action.payload.clatitude }]
             
//           );
//         default:
//             return state;
//     }
// }