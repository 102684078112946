import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";

function BecomeVendor() {
    return (
        <div>
            <Helmet>
                <title>{`Become a Vendor — ${theme.name}`}</title>
            </Helmet>

            <h4 className="faq-tit-txt" style={{ backgroundColor: "aliceblue", marginBottom: "2%" }}>
                <span style={{ color: "#c60800" }}>B</span>ecome a Vendor
            </h4>

            <div
                style={{
                    backgroundImage:
                        'url("https://png.pngtree.com/background/20220726/original/pngtree-indian-cuisine-sketch-pattern-background-picture-image_1814318.jpg")',
                }}
            >
                <div style={{ backgroundColor: "#ffffffed" }}>
                    <div className="container-fluid">
                        <div style={{ padding: "0% 2%" }}>
                            <div
                                className="card "
                                style={{
                                    filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)",
                                    padding: "4%",
                                    marginBottom: "4%",
                                }}
                            >
                                <div className="row">
                                    <div className="col-lg-5">
                                        <img
                                            src="https://st4.depositphotos.com/5934840/29994/v/450/depositphotos_299942186-stock-illustration-executive-business-entrepreneur-couple.jpg"
                                            alt=""
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                    <div className="col-lg-7">
                                        <h4>HOGIST - A Business Game Changer</h4>
                                        <p>
                                            Our market strategy is simple – Build a single platform for all the services
                                            related to food and float it in the market where consumers can directly
                                            choose their services. At the same time, build a platform for Vendors, who
                                            need not spend a bomb on offline or online advertising methods – just a
                                            click and the customers are ready. We at HOGIST have built a digital
                                            platform with enormous opportunities where the customers can select their
                                            service – be it for a corporate event, a family event, public event or a day
                                            to day service, everything is now available with the click of a button.
                                            Planning to promote your catering business? Are you a Caterer looking for
                                            potential customers? Are you a Restaurant owner looking for daily parcel
                                            services? If your answer is YES, then you are in the right place.
                                        </p>
                                        <p>
                                            We at Hogist connect customers to Food Service vendors. The best part? All
                                            this is possible using an online platform where you need not spend any money
                                            to onboard to the platform (Subject to schedule A in vendor agreement). You
                                            just have to place your request and we will bring in leads for you. All you
                                            need is either a system or a mobile phone to connect to us. We are available
                                            both through website and Mobile App.
                                        </p>
                                        <Link to="/vendorregistration">
                                            <button className="vjoinbtn">partner with us</button>
                                        </Link>
                                    </div>
                                    <div className="col-lg-12 mt-4">
                                        <h4>Vendor Registration Process</h4>
                                        <p>
                                            A valid FSSAI License Certificate is mandatory for registering with HOGIST.
                                            You can register through online by submitting your details at :
                                            https://www.hogist.com/vendor/#2 You can send email to support@hogist.com
                                            with your business details for registration. Our vendor Support Executive
                                            would call back to complete the registration process.
                                        </p>

                                        <h4>How our Market place works</h4>
                                        <p>
                                            If you are a Vendor looking for business opportunities in Food Industry,
                                            then Congratulations! You are at the right place. At no hidden cost, we will
                                            promote your business, do relevant marketing and will also ensure that you
                                            grow along with us. Our strategy is simple. Grow and let grow. If you get
                                            business through us, we get more business through you – it’s that simple.
                                        </p>

                                        <p>Our marketplace works in the following pattern:</p>
                                        <ul>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You get orders through HOGIST Online Platform - A customer places his
                                                order to you through HOGIST Website / Mobile App.{" "}
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> You as a vendor will
                                                get the complete details about the order - order number, description,
                                                quantity, etc. You will be notified through our Vendor App.
                                                Additionally, you will also be notified in advance in case your order is
                                                pre-booked. In case of same-day orders, you get notified well in
                                                advance.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You fulfill the order request placed and deliver order to the customer.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Order successfully delivered? – You get paid.{" "}
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                You will receive your payment as per the payment terms in vendor
                                                agreement (Schedule B) after successful order completion. Payments will
                                                be electronically transferred to your current account under agreed
                                                timeframe. Your payment receipt will contain the complete details of
                                                your order.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Please write to us at support@hogist.com in case of any further queries
                                                on payments.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> Interesting in joining
                                                our Market place? – All you have to do is follow two simple steps:
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" /> Sign up as a vendor –
                                                It's Free (Subject to Schedule A as per vendor agreement)
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Submit your details like your business information and verified
                                                documents to emailing to us at support@hogist.com / call to 9962667733
                                                or register on our website.
                                            </li>
                                        </ul>

                                        <h4>Benefits From HOGIST</h4>

                                        <p>
                                            {" "}
                                            <b>Customer Support :</b> Interested to take up but confused about who will
                                            answer the customer queries? Worry not, we have a customer support team who
                                            will be handling all the queries placed by the customer. We have a team of
                                            trained professionals who will addresses, assign and resolve all customer
                                            queries at the earliest.
                                        </p>
                                        <p>
                                            <b>Generate more Revenue :</b> Being an online platform, HOGIST provides you
                                            a wider reach with minimum efforts from your end. All we need is your basic
                                            details and we will set up your profile. Your business will automatically be
                                            marketed and you will get wider audience visibility, which results in more
                                            revenue generation.
                                        </p>
                                        <p>
                                            <b>Operational Support :</b> We make sure that we make your customer journey
                                            more comfortable.in cases where the customer demands a face to face meeting
                                            with you before placing the order, we will travel along with you to ensure
                                            that your order gets accepted with the best budget and time frame.
                                        </p>
                                        <p>
                                            <b>Digital Marketing Support :</b> We have a brilliant team of digital
                                            marketing experts who will use the digital strategy to help promote your
                                            business and get more orders. The moment you associate with us, your
                                            brand/business will automatically get promoted.
                                        </p>

                                        <h4>Vendor Access to Technology Tools</h4>
                                        <p>
                                            Once you are on-boarded to our digital platform, you will be provided with
                                            vendor portal access. You will be able to access the portal via laptop or
                                            computer or App [ Android / iOS]. This app and portal will have all the
                                            required functionality and access to run the business efficiently and manage
                                            the orders. In a nutshell, it would be your digital system to run your
                                            business on an online platform. Join with us and Grow your business
                                            Digitally.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BecomeVendor;
