import { ADDEDMENU_CHANGE, REMOVEMENU_CHANGE,UPDATEMENU_COUNT,CLEAR_CART  } from "./addedmenuActionTypes";

const initialState = [];

function removeItem(state, itemId) {
    return state.filter(menu => menu._id !== itemId);
}

export default function addedmenuReducer(state = initialState, action) {
    switch (action.type) {
        case ADDEDMENU_CHANGE:
          return action.selectedItems || [];
        case REMOVEMENU_CHANGE:
          return removeItem(state, action.itemId);
        case UPDATEMENU_COUNT:
          return state.map((menu) =>
            menu._id === action.payload.itemId
              ? { ...menu, count: action.payload.count,tprice:action.payload.tprice }
              : menu
          );
          case CLEAR_CART:
            return [];
        default:
            return state;
    }
}