import React from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function TermsAndConditions() {
    return (
        <div>
            <Helmet>
                <title>{`Terms And Conditions — ${theme.name}`}</title>
            </Helmet>
            <h4 className="faq-tit-txt" style={{ backgroundColor: "aliceblue", marginBottom: "2%" }}>
                <span style={{ color: "#c60800" }}>T</span>erms And Conditions
            </h4>

            <div
                style={{
                    backgroundImage:
                        'url("https://png.pngtree.com/background/20220726/original/pngtree-indian-cuisine-sketch-pattern-background-picture-image_1814318.jpg")',
                }}
            >
                <div style={{ backgroundColor: "#ffffffed" }}>
                    <div className="container-fluid">
                        <div style={{ padding: "0% 6%" }}>
                            <div className="row">
                                <div className="col-lg-12 mt-4 mb-4">
                                    <div
                                        className="card "
                                        style={{
                                            filter: "drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px)",
                                            padding: "4%",
                                        }}
                                    >
                                        <p>
                                            This document is an electronic record in terms of Information Technology
                                            Act, 2000 and rules there under as applicable and the amended provisions
                                            pertaining to electronic records in various statutes as amended by the
                                            Information Technology Act, 2000. This document is published in accordance
                                            with the provisions of Rule 3 (1) of the Information Technology
                                            (Intermediaries guidelines) Rules, 2011 that require publishing the rules
                                            and regulations, privacy policy and Terms of Use for access or usage of
                                            www.hogist.com website and HOGIST application for mobile and handheld
                                            devices.
                                        </p>

                                        <h4>Terms Of Use</h4>
                                        <p>
                                            {" "}
                                            <b> i ) </b> These terms of use (the “ Terms of Use”) govern your use of our
                                            website www.hogist.com (the “ Website”) and our “through our mobile
                                            application (the “ App”). The Website and the App are jointly referred to as
                                            the “Platform”. Please read these Terms of Use carefully before you use the
                                            services.
                                        </p>
                                        <p>
                                            {" "}
                                            <b> ii ) </b> The Platform is operated and owned by Hogist Technologies
                                            Private Limited, a company incorporated under the Companies Act, 2013 and
                                            having its registered office at No. at No.110, Lower Ground Floor, Mahatma
                                            Gandhi Road, Nungambakkam, Chennai – 34. For the purpose of these Terms of
                                            Use, wherever the context so requires, ”you” shall mean any natural or legal
                                            person who has agreed to become a buyer or customer on the Platform by
                                            providing Registration Data while registering on the Platform as a
                                            registered user using any computer systems. The terms “Hogist”, “we”, “us”
                                            or “our” shall mean Hogist Technologies Private Limited.
                                        </p>
                                        <p>
                                            {" "}
                                            <b> iii ) </b> Hogist enables transactions between food service providers
                                            and buyers, dealing in prepared food and beverages (“Platform Services ”).
                                            The buyers (“Buyer/s”) can choose and place orders (“ Orders”) from food
                                            menu listed and offered for sale by the food service providers including but
                                            not limited to the restaurants, caterers, Chefs, eateries and etc., (“Food
                                            Service Provider/s”), on the Platform and Hogist enables delivery of such
                                            orders serviceable cities across India (“ Delivery Services ”). The Platform
                                            Services and Delivery Services are collectively referred to as “ Services ”.
                                        </p>

                                        <ul style={{ marginLeft: "-3%" }}>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b>AMENDMENTS</b>
                                                <p>
                                                    These Terms of Use are subject to modifications at any time. We
                                                    reserve the right to modify or change these Terms of Use and other
                                                    HOGIST policies at any time by posting changes on the Platform, and
                                                    you shall be liable to update yourself of such changes, if any, by
                                                    accessing the changes on the Platform. You shall, at all times, be
                                                    responsible for regularly reviewing the Terms of Use and the other
                                                    HOGIST policies and note the changes made on the Platform. Your
                                                    continued usage of the services after any change is posted
                                                    constitutes your acceptance of the amended Terms of Use and other
                                                    HOGIST policies. As long as you comply with these Terms of Use,
                                                    HOGIST grants you a personal, non-exclusive, non-transferable,
                                                    limited privilege to access, enter, and use the Platform. By
                                                    accepting these Terms of Use, you also accept and agree to be bound
                                                    by the other terms and conditions and HOGIST policies (including but
                                                    not limited to Cancellation & Refund Policy, Privacy Policy and
                                                    Notice) as may be posted on the Platform from time to time.
                                                </p>
                                            </li>

                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Use of Platform and Services</b>
                                                <p>
                                                    {" "}
                                                    <b> i ) </b> All commercial/contractual terms are offered by and
                                                    agreed to between Buyers and food service providers alone. The
                                                    commercial/contractual terms include without limitation price,
                                                    taxes, payment terms and methods, date, period and mode of delivery,
                                                    warranties related to products and services and after sales services
                                                    related to products and services. HOGIST does not have any control
                                                    or does not determine or advise or in any way involve itself in the
                                                    offering or acceptance of such commercial/contractual terms between
                                                    the Buyers and Food Service Providers. HOGIST may, however, offer
                                                    support services to Food Service Providers in respect to order
                                                    fulfilment, payment collection, customer support, and other
                                                    services, pursuant to independent contracts executed by it with the
                                                    Food Service Providers.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> ii ) </b> HOGIST does not make any representation or warranty as
                                                    to the item-specifics (such as legal title, creditworthiness,
                                                    identity, etc.) of any of the Food Service Providers. You are
                                                    advised to independently verify the bona fides of any particular
                                                    Food Service Provider that you choose to deal with on the Platform
                                                    and use your best judgment in that behalf. All Food Service Provider
                                                    offers and third party offers are subject to respective party terms
                                                    and conditions. HOGIST takes no responsibility for such offers.
                                                    HOGIST neither make any representation or warranty as to specifics
                                                    (such as quality, value, scalability, etc.) of the products or
                                                    services proposed to be sold or offered to be sold or purchased on
                                                    the Platform nor does implicitly or explicitly support or endorse
                                                    the sale or purchase of any products or services on the Platform.
                                                    HOGIST accepts no liability for any errors or omissions, whether on
                                                    behalf of itself or third parties.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iii ) </b> HOGIST is not responsible for any non-performance or
                                                    breach of any contract entered into between Buyers and Food Service
                                                    Providers on the Platform. HOGIST cannot and does not guarantee that
                                                    the concerned Buyers and/or Food Service Providers will perform any
                                                    transaction concluded on the Platform. HOGIST is not responsible for
                                                    unsatisfactory or non-performance of services or damages or delays
                                                    as a result of products which are out of stock, unavailable or back
                                                    ordered.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iv ) </b>
                                                    HOGIST is operating an online marketplace and assumes the role of
                                                    facilitator / bulk food aggregator, and does not take responsibility
                                                    at any point of time during any transaction between Buyer and Food
                                                    Service Provider on the Platform come into or take possession of any
                                                    of the products or services offered by Food Service Provider. At no
                                                    time shall HOGIST hold any right, title or interest over the
                                                    products nor shall HOGIST have any obligations or liabilities in
                                                    respect of such contract entered into between Buyer and Food Service
                                                    Provider.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> v ) </b> HOGIST is only providing a platform for communication
                                                    and it is agreed that the contract for sale of any of the products
                                                    or services shall be a strictly bipartite contract between the Food
                                                    Service Provider and the Buyer. In case of complaints from the Buyer
                                                    pertaining to food efficacy, quality, or any other such issues,
                                                    HOGIST shall notify the same to Food Service Provider and shall also
                                                    redirect the Buyer to the consumer call centre of the Food Service
                                                    Provider. The Food Service Provider shall be liable for redressing
                                                    Buyer complaints. In the event you raise any complaint on any Food
                                                    Service Provider accessed using our Platform, we shall assist you to
                                                    the best of our abilities by providing relevant information to you,
                                                    such as details of the Food Service Provider and the specific Order
                                                    to which the complaint relates, to enable satisfactory resolution of
                                                    the complaint.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> vi ) </b> Please note that there could be risks in dealing with
                                                    underage persons or people acting under false pretence.
                                                </p>
                                            </li>

                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Hogist - Use of the Website and mobile applications </b>

                                                <p>
                                                    You agree, undertake and confirm that your use of Platform shall be
                                                    strictly governed by the following binding principles:
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> i ) </b> You may access the Platform by registering to create an
                                                    account (“HOGIST Account”) and become a member (“ Membership”); via
                                                    our Platform, as described below. The Membership is limited for the
                                                    purpose and are subject to the terms, and strictly not transferable.
                                                    As part of the functionality of the Platform services, you may link
                                                    your HOGIST Account with Third Party Accounts, by either:{" "}
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> ii ) </b> providing your Third Party Account login information
                                                    to us through the Platform; or
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iii ) </b> allowing us to access your Third Party Account, as is
                                                    permitted under the applicable terms and conditions that govern your
                                                    use of each Third Party Account.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iv ) </b> You represent that you are entitled to disclose your
                                                    Third Party Account login information to us and/or grant us access
                                                    to your Third Party Account (including, but not limited to, for use
                                                    for the purposes described herein), without breach by you of any of
                                                    the terms and conditions that govern your use of the applicable
                                                    Third Party Account and without obligating us to pay any fees or
                                                    making us subject to any usage limitations imposed by such third
                                                    party service providers.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> v ) </b> By granting us access to any Third Party Accounts, you
                                                    understand that we will access, make available and store (if
                                                    applicable) any content or information that you have provided to and
                                                    stored in your Third Party Account (“SNS Content”) so that it is
                                                    available on and through the Platform via your HOGIST Account.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> vi ) </b> Unless otherwise specified in these Terms of Use, all
                                                    SNS Content, if any, will be considered to be your content for all
                                                    purposes of these Terms of Use.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> vii ) </b> Depending on the Third Party Accounts, you choose,
                                                    and subject to the privacy settings that you have set in such Third
                                                    Party Accounts, personally identifiable information that you post to
                                                    your Third Party Accounts will be available on and through your
                                                    HOGIST Account on the Platform.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> viii ) </b> Please note that if a Third Party Account or
                                                    associated service becomes unavailable or our access to such Third
                                                    Party Account is terminated by the third party service provider,
                                                    then SNS Content will no longer be available on and through the
                                                    Platform.
                                                </p>

                                                <p>
                                                    {" "}
                                                    <b> ix ) </b> We will create your HOGIST Account for your use of the
                                                    Platform services based upon the personal information you provide to
                                                    us or that we obtain via an SNS, as described above. You may only
                                                    have one HOGIST Account and not permitted to create multiple
                                                    accounts. If found, you having multiple accounts, HOGIST reserves
                                                    right to suspend such multiple account without being liable for any
                                                    compensation.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> x ) </b> You agree to provide accurate, current and complete
                                                    information during the registration process and to update such
                                                    information to keep it accurate, current and complete.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> xi ) </b> We reserve the right to suspend or terminate your
                                                    Hogist Account and your access to the Services
                                                    <br />
                                                    <span>
                                                        1 ) if any information provided during the registration process
                                                        or thereafter proves to be inaccurate, not current or
                                                        incomplete;
                                                    </span>
                                                    <br />
                                                    <span>
                                                        2 ) if it is believed that your actions may cause legal
                                                        liability for you, other users or us; and/or
                                                    </span>
                                                    <br />
                                                    <span>
                                                        3 ) if you are found to be non- compliant with the Terms of Use.
                                                    </span>
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> xii ) </b> You are responsible for safeguarding your password.
                                                    You agree that you will not disclose your password to any third
                                                    party and that you will take sole responsibility for any activities
                                                    or actions under your HOGIST Account, whether or not you have
                                                    authorized such activities or actions. You will immediately notify
                                                    us of any unauthorized use of your HOGIST Account.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> xiii ) </b> Goods and services purchased from the Platform are
                                                    intended for your personal use and you represent that the same are
                                                    not for resale or you are not acting as an agent for other parties.
                                                </p>
                                            </li>

                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Bookings and Financial Terms </b>

                                                <p>
                                                    {" "}
                                                    <b> i ) </b>
                                                    The Platform allows you to place food order bookings and we will,
                                                    subject to the terms and conditions set out herein, enable delivery
                                                    of such order to you.
                                                </p>

                                                <p>
                                                    {" "}
                                                    <b> ii ) </b>
                                                    Hogist do not own, sell, resell on its own and/or do not control the
                                                    Food Service Providers or the related services provided in
                                                    connection thereof. You understand that any order that you place
                                                    shall be subject to the terms and conditions set out in these Terms
                                                    of Use including, but not limited to, product availability and
                                                    delivery location serviceability.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iii ) </b>
                                                    As a general rule, all food orders placed on the Platform are
                                                    treated as confirmed.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iv ) </b>
                                                    However, upon your successful completion of booking an order, we may
                                                    call you on the telephone or mobile number provided to confirm the
                                                    details of the order, the price to be paid and the estimated
                                                    delivery time. For this purpose, you will be required to share
                                                    certain information with us, including but not limited to
                                                    <ul>
                                                        <li>1 ) your first and last name</li>
                                                        <li>2 ) mobile number; and</li>
                                                        <li>
                                                            3 )email address. It shall be your sole responsibility to
                                                            bring any incorrect details to our attention.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> v ) </b> In addition to the foregoing, we may also contact you
                                                    by phone and / or email to inform and confirm any change in the
                                                    order, due to availability or unavailability or change in price of
                                                    the order as informed by the Food Service Provider. Please note
                                                    change or confirmation of the order shall be treated as final. It is
                                                    clarified that Hogist reserves the right to not to process your
                                                    order in the event you are unavailable on the phone at the time we
                                                    call you for confirming the order and such event the provisions of
                                                    the cancellation and refund policy below shall be applicable.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> vi ) </b> All payments made against the purchases/services on
                                                    the Platform by you shall be compulsorily in Indian Rupees
                                                    acceptable in the Republic of India. The Platform will not
                                                    facilitate transactions with respect to any other form of currency
                                                    with respect to the purchases made on Platform. You can pay by or
                                                    <ul>
                                                        <li> 1 ) credit card or debit card or net banking;</li>
                                                        <li>
                                                            {" "}
                                                            2 ) any other RBI approved payment method at the time of
                                                            booking an order;
                                                        </li>
                                                        <li>
                                                            {" "}
                                                            3 ) credit or debit card or cash at the time of delivery.
                                                            You understand, accept and agree that the payment facility
                                                            provided by Hogist is neither a banking nor financial
                                                            service but is merely a facilitator providing an electronic,
                                                            automated online electronic payment, receiving payment on
                                                            delivery, collection and remittance facility for the
                                                            transactions on the Platform using the existing authorized
                                                            banking infrastructure and credit card payment gateway
                                                            networks. Further, by providing payment facility, Hogist is
                                                            neither acting as trustees nor acting in a fiduciary
                                                            capacity with respect to the transaction or the transaction
                                                            price.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> vii ) </b>
                                                    You acknowledge and agree that we act as the Food Service Provider’s
                                                    payment agent for the limited purpose of accepting payments from you
                                                    on behalf of the Food Service Provider. Upon your payment of amounts
                                                    to us, which are due to the Food Service Provider, your payment
                                                    obligation to the Food Service Provider for such amounts is
                                                    completed, and we are responsible for remitting such amounts, to the
                                                    Food Service Provider. You shall not, under any circumstances
                                                    whatsoever, make any payment directly to the Food Service Provider
                                                    for Order bookings made using the Platform.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> viii ) </b>
                                                    You agree to pay us for the total amount for the order placed by you
                                                    on the Platform. We will collect the total amount in accordance with
                                                    the terms and conditions of these Terms of Use and the pricing terms
                                                    set forth in the applicable menu listing for the particular Food
                                                    Service Provider. Please note that we cannot control any amount that
                                                    may be charged to you by your bank related to our collection of the
                                                    total amount, and we disclaim all liability in this regard.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> ix ) </b>
                                                    In connection with your order, you will be asked to provide
                                                    customary billing information such as name, billing address and
                                                    credit card information either to us or our third party payment
                                                    processor. You agree to pay us for the order placed by you on the
                                                    Platform, in accordance with these Terms, using of the methods
                                                    described under clause VII (6) above. You hereby authorize the
                                                    collection of such amounts by charging the credit card provided as
                                                    part of requesting the booking, either directly by us or indirectly,
                                                    via a third party online payment processor or by one of the payment
                                                    methods described on the Platform. If you are directed to our
                                                    third-party payment processor, you may be subject to terms and
                                                    conditions governing use of that third party’s service and that
                                                    third party’s personal information collection practices. Please
                                                    review such terms and conditions and privacy policy before using the
                                                    Platform services. Once your confirmed booking transaction is
                                                    complete you will receive a confirmation email summarizing your
                                                    confirmed booking.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> x ) </b>
                                                    The final tax bill will be issued by the Food Service Provider to
                                                    the Buyer along with the order and Hogist is merely collecting the
                                                    payment on behalf of such Food Service Provider. All applicable
                                                    taxes and levies, the rates thereof and the manner of applicability
                                                    of such taxes on the bill are being charged and determined by the
                                                    Food Service Provider. Hogist holds no responsibility for the legal
                                                    correctness/validity of the levy of such taxes. The sole
                                                    responsibility for any legal issue arising on the taxes shall reside
                                                    with the Food Service Provider.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> xi ) </b>
                                                    The prices reflected on the Platform are determined solely by the
                                                    Food Service Provider and are listed based on Food Service
                                                    Provider’s information. Very rarely, prices may change at the time
                                                    of placing order due to Food Service Provider changing the menu
                                                    price without due intimation and such change of price are at the
                                                    sole discretion of the Food Service Provider attributing to various
                                                    factors beyond control. Disclaimer: Prices on any product(s) as is
                                                    reflected on the Platform may due to some technical issue,
                                                    typographical error or product information supplied by Food Service
                                                    Provider be incorrectly reflected and in such an event Food Service
                                                    Provider may cancel such your order(s).
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> xii ) </b>
                                                    The Food Service Provider shall be solely responsible for any
                                                    warrantee/guarantee of the food products sold to the Buyers and in
                                                    no event shall be the responsibility of Hogist.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> xiii ) </b>
                                                    The transaction is bilateral between the Food Service Provider and
                                                    Buyer and therefore, Hogist is not liable to charge or deposit any
                                                    taxes applicable on such transaction.
                                                </p>
                                            </li>

                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Cancellations and Refunds </b>

                                                <p>
                                                    <b>1 )</b> Cancellation
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> i ) </b> As a general rule you shall not be entitled to cancel
                                                    your order once you have received confirmation of the same. If you
                                                    cancel your order after it has been confirmed, Hogist shall have a
                                                    right to charge you cancellation fee of a minimum INR 75 upto the
                                                    order value, with a right to either not to refund the order value or
                                                    recover from your subsequent order, the complete/ deficit
                                                    cancellation fee, as applicable, to compensate our restaurant and
                                                    delivery partners. Hogist shall also have right to charge you
                                                    cancellation fee for the orders cancelled by Hogist for the reasons
                                                    specified under clause 1(iii) of this cancellation and refunds
                                                    policy. In case of cancellations for the reasons attributable to
                                                    Hogist or its restaurant and delivery partners, Hogist shall not
                                                    charge you any cancellation fee.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> ii ) </b> However, in the unlikely event of an item on your
                                                    order being unavailable, we will contact you on the phone number
                                                    provided to us at the time of placing the order and inform you of
                                                    such unavailability. In such an event you will be entitled to cancel
                                                    the entire order and shall be entitled to a refund in accordance
                                                    with our refund policy.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iii ) </b> We reserve the sole right to cancel your order in the
                                                    following circumstance:
                                                    <ul>
                                                        <li>
                                                            a ) in the event of the designated address falls outside the
                                                            delivery zone offered by us;
                                                        </li>
                                                        <li>
                                                            b ) failure to contact you by phone or email at the time of
                                                            confirming the order booking;
                                                        </li>
                                                        <li>
                                                            c ) failure to deliver your order due to lack of
                                                            information, direction or authorization from you at the time
                                                            of delivery; or
                                                        </li>
                                                        <li>
                                                            d ) unavailability of all the items ordered by you at the
                                                            time of booking the order; or
                                                        </li>
                                                        <li>
                                                            e ) unavailability of all the items ordered by you at the
                                                            time of booking the order; or
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> 2 ) </b> Refunds
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> i ) </b> You shall be entitled to a refund only if you pre-pay
                                                    for your order at the time of placing your order on the Platform and
                                                    only in the event of any of the following circumstances: ?
                                                    <ul>
                                                        <li>
                                                            {" "}
                                                            a ) your order packaging has been tampered or damaged at the
                                                            time of delivery;
                                                        </li>
                                                        <li>
                                                            {" "}
                                                            b ) us cancelling your order due to (A) your delivery
                                                            location following outside our designated delivery zones;
                                                            (B) failure to contact you by phone or email at the time of
                                                            confirming the order booking; or (C) failure to contact you
                                                            by phone or email at the time of confirming the order
                                                            booking; or
                                                        </li>
                                                        <li>
                                                            {" "}
                                                            c ) you cancelling the order at the time of confirmation due
                                                            to unavailability of the items you ordered for at the time
                                                            of booking.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> ii ) </b> Our decision on refunds shall be at our sole
                                                    discretion and shall be final and binding.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iii ) </b> All refund amounts shall be credited to your account
                                                    within 3-4 business days in accordance with the terms that may be
                                                    stipulated by the bank which has issued the credit / debit card.
                                                </p>
                                                <p>
                                                    {" "}
                                                    <b> iv ) </b> In case of payment at the time of delivery, you will
                                                    not be required to pay for:
                                                    <ul>
                                                        <li>
                                                            1. orders where the packaging has been tampered or damaged
                                                            by us;
                                                        </li>
                                                        <li>2. wrong order being delivered; or</li>
                                                        <li>
                                                            3. items missing from your order at the time of delivery.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Terms of Service </b>
                                                <p>
                                                    <b>i )</b> You agree and acknowledge that we shall not be
                                                    responsible for:
                                                    <ul>
                                                        <li>
                                                            <b>1 )</b> The services or goods provided by the Food
                                                            Service Provider including, but not limited, serving of food
                                                            orders suiting your requirements and needs;
                                                        </li>
                                                        <li>
                                                            <b>2 )</b> The Food Service Provider’s services or goods not
                                                            being up to your expectations or leading to any loss, harm
                                                            or damage to you;
                                                        </li>
                                                        <li>
                                                            <b>3 )</b> The availability or unavailability of certain
                                                            items on the menu; or
                                                        </li>
                                                        <li>
                                                            <b>4 )</b> The Food Service Provider serving the incorrect
                                                            orders.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    <b>ii )</b>
                                                    The details of the menu and price list available on the Platform are
                                                    based on the information provided by the Food Service Providers and
                                                    we shall not be responsible for any change or cancellation or
                                                    unavailability.
                                                </p>
                                                <p>
                                                    <b>iii )</b>
                                                    You may not be able to avail our Services if your delivery location
                                                    is outside our current scope of Service. We will keep you informed
                                                    of the same at the time of confirming your order booking.
                                                </p>
                                                <p>
                                                    <b>iv )</b>
                                                    You understand that delivery periods quoted to you at the time of
                                                    confirming the order is an approximate estimate and may vary. We
                                                    will not be responsible for any delay in the delivery of your order.
                                                </p>
                                                <p>
                                                    <b>v )</b>
                                                    You order will be only delivered to the address designated by you at
                                                    the time of placing the order on the Platform. We reserve the right
                                                    to cancel the order, in our sole discretion, in the event of any
                                                    change to the place of delivery and you shall not be entitled to any
                                                    refund for the same. Delivery in the event of change of the delivery
                                                    location shall be at our sole discretion.
                                                </p>
                                                <p>
                                                    <b>vi )</b>
                                                    You shall undertake to provide adequate directions, information and
                                                    authorisations to accept delivery. In the event of any failure to
                                                    accept delivery, failure to deliver within the estimated time due to
                                                    your failure to provide appropriate instructions, or authorizations,
                                                    then such goods shall be deemed to have been delivered to you and
                                                    all risk and responsibility in relation to such goods shall pass to
                                                    you and you shall not be entitled to any refund for the same. Our
                                                    decision in relation to this shall be final and binding.
                                                </p>
                                                <p>
                                                    <b>vii )</b>
                                                    You understand that our liability ends once your order has been
                                                    delivered to you.
                                                </p>
                                                <p>
                                                    <b>viii )</b>
                                                    Services provided:
                                                    <ul>
                                                        <li>
                                                            1 ) You confirm that we shall not be responsible for any
                                                            deficiency in payment of consideration payable towards the
                                                            goods purchased from the Services.
                                                        </li>
                                                        <li>
                                                            2 ) Each purchase on the Service shall contain necessary
                                                            instructions to redeem the Services. The terms of the
                                                            Services shall be governed by these Terms of Use and any
                                                            other terms as set out in such document confirm such sale of
                                                            Service. You shall not be entitled to receive any credit,
                                                            refund or cash back for the value of the goods sold if you
                                                            fail to redeem the goods within the expiry date or in
                                                            accordance with the terms therein.
                                                        </li>
                                                        <li>
                                                            3 ) You agree and acknowledge that neither us nor the Food
                                                            Service Provider shall be liable in the event of you failing
                                                            to adhere to the Terms of Use.
                                                        </li>
                                                        <li>
                                                            4 ) You might be required to provide your credit or debit
                                                            card details to the approved payment gateways while making
                                                            the payment. In this regard, you agree to provide correct
                                                            and accurate credit/ debit card details to the approved
                                                            payment gateways for availing the Services. You shall not
                                                            use the credit/ debit card which is not lawfully owned by
                                                            you, i.e. in any transaction, you must use your own credit/
                                                            debit card. The information provided by you will not be
                                                            utilized or shared with any third party unless required in
                                                            relation to fraud verifications or by law, regulation or
                                                            court order. You will be solely responsible for the security
                                                            and confidentiality of your credit/ debit card details. We
                                                            expressly disclaim all liabilities that may arise as a
                                                            consequence of any unauthorized use of your credit/ debit
                                                            card.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    <b>xi )</b>
                                                    We do not offer any refunds against goods already purchased from the
                                                    Platform unless an error that is directly attributable to us has
                                                    occurred during the purchase of such product or services.
                                                </p>
                                                <p>
                                                    <b>x )</b>
                                                    We constantly strive to provide you with accurate information on the
                                                    Platform. However, in the event of an error, we may, in our sole
                                                    discretion, contact you with further instructions.
                                                </p>
                                                <p>
                                                    <b>xi )</b>
                                                    If you use the Platform, you do the same at your own risk.
                                                </p>
                                                <p>
                                                    <b>xii )</b>
                                                    You agree that the Services shall be provided by us only during the
                                                    working hours of the relevant Food Service Providers.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> No Endorsement</b>
                                                <p>
                                                    We do not endorse any Food Service Provider. In addition, although
                                                    these Terms of Use require you to provide accurate information, we
                                                    do not attempt to confirm, and do not confirm if it is purported
                                                    identity. We will not be responsible for any damage or harm
                                                    resulting from your interactions with other Members.
                                                </p>
                                                <p>
                                                    By using the Services, you agree that any legal remedy or liability
                                                    that you seek to obtain for actions or omissions of other Members or
                                                    other third parties will be limited to a claim against the
                                                    particular Members or other third parties who caused you harm and
                                                    you agree not to attempt to impose liability on, or seek any legal
                                                    remedy from us with respect to such actions or omissions.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> General terms of use </b>

                                                <p>
                                                    <b> i )</b>
                                                    Persons who are “incompetent to contract” within the meaning of the
                                                    Indian Contract Act, 1872 including minors, un-discharged insolvents
                                                    etc. are not eligible to use the Platform. Only individuals who are
                                                    18 years of age or older may use the Platform and avail Services. If
                                                    you are under 18 years of age and you wish to download, install,
                                                    access or use the Platform, your parents or legal guardian must
                                                    acknowledge and agree to the Terms of Use and Privacy Policy. Should
                                                    your parents or legal guardian fail to agree or acknowledge the
                                                    Terms of Use and Hogist policies, you shall immediately discontinue
                                                    its use. Hogist reserves the right to terminate your Membership and
                                                    / or deny access to the platform if it is brought Hogist’s notice
                                                    that you are under the age of 18 years.
                                                </p>
                                                <p>
                                                    <b> ii )</b>
                                                    If you choose to use the Platform, it shall be your responsibility
                                                    to treat your user identification code, password and any other piece
                                                    of information that we may provide, as part of our security
                                                    procedures, as confidential and not disclose the same to any person
                                                    or entity other than us. We shall at times and at our sole
                                                    discretion reserve the right to disable any user identification code
                                                    or password if you have failed to comply with any of the provisions
                                                    of these Terms of Use.
                                                </p>
                                                <p>
                                                    <b> iii )</b>
                                                    As we are providing services in the select cities in India, we have
                                                    complied with applicable laws of India in making the Platform and
                                                    its content available to you. In the event the Platform is accessed
                                                    from outside India or outside our delivery zones, it shall be
                                                    entirely at your risk. We make no representation that the Platform
                                                    and its contents are available or otherwise suitable for use outside
                                                    select cities. If you choose to access or use the Platform from or
                                                    in locations outside select cities, you do so on your own and shall
                                                    be responsible for the consequences and ensuring compliance of
                                                    applicable laws, regulations, byelaws, licenses, registrations,
                                                    permits, authorisations, rules and guidelines.
                                                </p>
                                                <p>
                                                    <b> iv )</b>
                                                    You shall at all times be responsible for the use of the Services
                                                    through your computer or mobile device and for bringing these Terms
                                                    of Use and Hogist policies to the attention of all such persons
                                                    accessing the Platform on your computer or mobile device.
                                                </p>
                                                <p>
                                                    <b> v )</b>
                                                    You understand and agree that the use of the Services does not
                                                    include the provision of a computer or mobile device or other
                                                    necessary equipment to access it. You also understand and
                                                    acknowledge that the use of the Platform requires internet
                                                    connectivity and telecommunication links. You shall bear the costs
                                                    incurred to access and use the Platform and avail the Services, and
                                                    we shall not, under any circumstances whatsoever, be responsible or
                                                    liable for such costs.
                                                </p>
                                                <p>
                                                    <b> vi )</b>
                                                    You agree and grant permission to Hogist to receive promotional SMS
                                                    and e-mails from Hogist or allied partners. In case you wish to opt
                                                    out of receiving promotional SMS or email please send a mail to
                                                    support@Hogist.in.
                                                </p>
                                                <p>
                                                    <b> vii )</b>
                                                    By using the Platform you represent and warrant that:
                                                    <ul>
                                                        <li>
                                                            <b>1 )</b>
                                                            All registration information you submit is truthful, lawful
                                                            and accurate and that you agree to maintain the accuracy of
                                                            such information.
                                                        </li>
                                                        <li>
                                                            <b>2 )</b>
                                                            Your use of the Platform shall be solely for your personal
                                                            use and you shall not authorize others to use your account,
                                                            including your profile or email address and that you are
                                                            solely responsible for all content published or displayed
                                                            through your account, including any email messages, and your
                                                            interactions with other users and you shall abide by all
                                                            applicable local, state, national and foreign laws, treaties
                                                            and regulations, including those related to data privacy,
                                                            international communications and the transmission of
                                                            technical or personal data.
                                                        </li>
                                                        <li>
                                                            <b>3 )</b>
                                                            You will not submit, post, upload, distribute, or otherwise
                                                            make available or transmit any content that: (a) is
                                                            defamatory, abusive, harassing, insulting, threatening, or
                                                            that could be deemed to be stalking or constitute an
                                                            invasion of a right of privacy of another person; (b) is
                                                            bigoted, hateful, or racially or otherwise offensive; (c) is
                                                            violent, vulgar, obscene, pornographic or otherwise sexually
                                                            explicit; (d) is illegal or encourages or advocates illegal
                                                            activity or the discussion of illegal activities with the
                                                            intent to commit them.
                                                        </li>
                                                        <li>
                                                            <b>4 )</b>
                                                            All necessary licenses, consents, permissions and rights are
                                                            owned by you and there is no need for any payment or
                                                            permission or authorization required from any other party or
                                                            entity to use, distribute or otherwise exploit in all
                                                            manners permitted by these Terms of Use and Privacy Policy,
                                                            all trademarks, copyrights, patents, trade secrets, privacy
                                                            and publicity rights and / or other proprietary rights
                                                            contained in any content that you submit, post, upload,
                                                            distribute or otherwise transmit or make available.
                                                        </li>
                                                        <li>
                                                            <b>5 )</b>
                                                            You will not (a) use any services provided by the Platform
                                                            for commercial purposes of any kind, or (b) advertise or
                                                            sell any products, services or otherwise (whether or not for
                                                            profit), or solicit others (including, without limitation,
                                                            solicitations for contributions or donations) or use any
                                                            public forum for commercial purposes of any kind. In the
                                                            event you want to advertise your product or service contact
                                                            contact@Hogist.in.
                                                        </li>
                                                        <li>
                                                            <b>6 )</b>
                                                            You will not use the Platform in any way that is unlawful,
                                                            or harms us or any other person or entity, as determined in
                                                            our sole discretion.
                                                        </li>
                                                        <li>
                                                            <b>7 )</b>
                                                            By using the Platform you represent and warrant that:
                                                        </li>
                                                        <li>
                                                            <b>8 )</b>
                                                            You will not use another person’s username, password or
                                                            other account information, or another person’s name,
                                                            likeness, voice, image or photograph or impersonate any
                                                            person or entity or misrepresent your identity or
                                                            affiliation with any person or entity.
                                                        </li>
                                                        <li>
                                                            <b>9 )</b>
                                                            You will not engage in any form of antisocial, disrupting,
                                                            or destructive acts, including “flaming,” “spamming,”
                                                            “flooding,” “trolling,” and “griefing” as those terms are
                                                            commonly understood and used on the Internet.
                                                        </li>
                                                        <li>
                                                            <b>10 )</b>
                                                            You will not delete or modify any content of the Platform,
                                                            including but not limited to, legal notices, disclaimers or
                                                            proprietary notices such as copyright or trademark symbols,
                                                            logos, that you do not own or have express permission to
                                                            modify.
                                                        </li>
                                                        <li>
                                                            <b>11 )</b>
                                                            You will not post or contribute any information or data that
                                                            may be obscene, indecent, pornographic, vulgar, profane,
                                                            racist, sexist, discriminatory, offensive, derogatory,
                                                            harmful, harassing, threatening, embarrassing, malicious,
                                                            abusive, hateful, menacing, defamatory, untrue or political
                                                            or contrary to our interest.
                                                        </li>
                                                        <li>
                                                            <b>12 )</b>
                                                            You shall not access the Platform without authority or use
                                                            the Platform in a manner that damages, interferes or
                                                            disrupts:
                                                            <p>
                                                                <b>a )</b> any part of the Platform or the Platform
                                                                software; or
                                                            </p>
                                                            <p>
                                                                <b> b )</b> any equipment or any network on which the
                                                                Platform is stored or any equipment of any third party
                                                                viii. Your
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>
                                                    <b> vii )</b>
                                                    You release and fully indemnify Hogist and/or any of its officers
                                                    and representatives from any cost, damage, liability or other
                                                    consequence of any of the actions of the Users of the Platform and
                                                    specifically waive any claims that you may have in this behalf under
                                                    any applicable laws of India. Notwithstanding its reasonable efforts
                                                    in that behalf, Hogist cannot take responsibility or control the
                                                    information provided by other Users which is made available on the
                                                    Platform. You may find other User’s information to be offensive,
                                                    harmful, inconsistent, inaccurate, or deceptive. Please use caution
                                                    and practice safe trading when using the Platform.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Access to the Platform, Accuracy and security </b>
                                                <p>
                                                    <b>i )</b>
                                                    We endeavour to make the Platform available to during Food Service
                                                    Provider working hours. However, we do not represent that access to
                                                    the Platform will be uninterrupted, timely, error free, free of
                                                    viruses or other harmful components or that such defects will be
                                                    corrected.
                                                </p>
                                                <p>
                                                    <b>ii )</b>
                                                    We do not warrant that the Platform will be compatible with all
                                                    hardware and software which you may use. We shall not be liable for
                                                    damage to, or viruses or other code that may affect, any equipment
                                                    (including but not limited to your mobile device), software, data or
                                                    other property as a result of your download, installation, access to
                                                    or use of the Platform or your obtaining any material from, or as a
                                                    result of using, the Platform. We shall also not be liable for the
                                                    actions of third parties.
                                                </p>
                                                <p>
                                                    <b>iii )</b>
                                                    We do not represent or warranty that the information available on
                                                    the Platform will be correct, accurate or otherwise reliable.
                                                </p>
                                                <p>
                                                    <b>iv )</b>
                                                    We reserve the right to suspend or withdraw access to the Platform
                                                    to you personally, or to all users temporarily or permanently at any
                                                    time without notice. We may any time at our sole discretion
                                                    reinstate suspended users. A suspended User may not register or
                                                    attempt to register with us or use the Platform in any manner
                                                    whatsoever until such time that such user is reinstated by us.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b>
                                                    {" "}
                                                    Relationship with operators if the Platform is accessed on mobile
                                                    devices{" "}
                                                </b>

                                                <p>
                                                    <b> i ) </b> In the event the Platform is accessed on a mobile
                                                    device, it is not associated, affiliated, sponsored, endorsed or in
                                                    any way linked to any platform operator, including, without
                                                    limitation, Apple, Google, Android or RIM Blackberry (each being an
                                                    “ Operator”).
                                                </p>
                                                <p>
                                                    <b> ii ) </b> Your download, installation, access to or use of the
                                                    Platform is also bound by the terms and conditions of the Operator.
                                                </p>
                                                <p>
                                                    <b> iii ) </b> You and we acknowledge that these Terms of Use are
                                                    concluded between you and us only, and not with an Operator, and we,
                                                    not those Operators, are solely responsible for the Platform and the
                                                    content thereof to the extent specified in these Terms of Use.
                                                </p>
                                                <p>
                                                    <b> iv ) </b> The license granted to you for the Platform is limited
                                                    to a non-transferable license to use the Platform on a mobile device
                                                    that you own or control and as permitted by these Terms of Use.
                                                </p>
                                                <p>
                                                    <b> v ) </b> We are solely responsible for providing any maintenance
                                                    and support services with respect to the Platform as required under
                                                    applicable law. You and we acknowledge that an Operator has no
                                                    obligation whatsoever to furnish any maintenance and support
                                                    services with respect to the Platform.
                                                </p>
                                                <p>
                                                    <b> vi ) </b> You and we acknowledge that we, not the relevant
                                                    Operator, are responsible for addressing any claims of you or any
                                                    third party relating to the Platform or your possession and/or use
                                                    of the Platform, including, but not limited to: (i) any claim that
                                                    the Platform fails to conform to any applicable legal or regulatory
                                                    requirement; and (ii) claims arising under consumer protection or
                                                    similar legislation.
                                                </p>
                                                <p>
                                                    <b> vii ) </b> You and we acknowledge that, in the event of any
                                                    third party claim that the Platform or your possession and use of
                                                    the Platform infringes that third party’s intellectual property
                                                    rights, we, not the relevant Operator, will be solely responsible
                                                    for the investigation, defence, settlement and discharge of any such
                                                    intellectual property infringement claim
                                                </p>
                                                <p>
                                                    <b> viii ) </b> You must comply with any applicable third party
                                                    terms of agreement when using the Platform (e.g. you must ensure
                                                    that your use of the Platform is not in violation of your mobile
                                                    device agreement or any wireless data service agreement).{" "}
                                                </p>
                                                <p>
                                                    <b> ix ) </b> You and we acknowledge and agree that the relevant
                                                    Operator, and that Operator’s subsidiaries, are third party
                                                    beneficiaries of these Terms of Use, and that, upon your acceptance
                                                    of these Terms of Use, that Operator will have the right (and will
                                                    be deemed to have accepted the right) to enforce these Terms of Use
                                                    against you as a third party beneficiary thereof.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Disclaimers </b>
                                                <p>
                                                    <b>i )</b>
                                                    THE PLATFORM MAY BE UNDER CONSTANT UPGRADES, AND SOME FUNCTIONS AND
                                                    FEATURES MAY NOT BE FULLY OPERATIONAL.
                                                </p>
                                                <p>
                                                    <b>ii )</b>
                                                    DUE TO THE VAGARIES THAT CAN OCCUR IN THE ELECTRONIC DISTRIBUTION OF
                                                    INFORMATION AND DUE TO THE LIMITATIONS INHERENT IN PROVIDING
                                                    INFORMATION OBTAINED FROM MULTIPLE SOURCES, THERE MAY BE DELAYS,
                                                    OMISSIONS, OR INACCURACIES IN THE CONTENT PROVIDED ON THE PLATFORM
                                                    OR DELAY OR ERRORS IN FUNCTIONALITY OF THE PLATFORM. AS A RESULT, WE
                                                    DO NOT REPRESENT THAT THE INFORMATION POSTED IS CORRECT IN EVERY
                                                    CASE.
                                                </p>
                                                <p>
                                                    <b>iii )</b>
                                                    WE EXPRESSLY DISCLAIM ALL LIABILITIES THAT MAY ARISE AS A
                                                    CONSEQUENCE OF ANY UNAUTHORIZED USE OF CREDIT/ DEBIT CARDS.
                                                </p>
                                                <p>
                                                    <b>iv )</b>
                                                    YOU ACKNOWLEDGE THAT THIRD PARTY SERVICES ARE AVAILABLE ON THE
                                                    PLATFORM. WE MAY HAVE FORMED PARTNERSHIPS OR ALLIANCES WITH SOME OF
                                                    THESE THIRD PARTIES FROM TIME TO TIME IN ORDER TO FACILITATE THE
                                                    PROVISION OF CERTAIN SERVICES TO YOU. HOWEVER, YOU ACKNOWLEDGE AND
                                                    AGREE THAT AT NO TIME ARE WE MAKING ANY REPRESENTATION OR WARRANTY
                                                    REGARDING ANY THIRD PARTY’S SERVICES NOR WILL WE BE LIABLE TO YOU OR
                                                    ANY THIRD PARTY FOR ANY CONSEQUENCES OR CLAIMS ARISING FROM OR IN
                                                    CONNECTION WITH SUCH THIRD PARTY INCLUDING, AND NOT LIMITED TO, ANY
                                                    LIABILITY OR RESPONSIBILITY FOR, DEATH, INJURY OR IMPAIRMENT
                                                    EXPERIENCED BY YOU OR ANY THIRD PARTY. YOU HEREBY DISCLAIM AND WAIVE
                                                    ANY RIGHTS AND CLAIMS YOU MAY HAVE AGAINST US WITH RESPECT TO THIRD
                                                    PARTY’S / FOOD SERVICE PROVIDERS SERVICES.
                                                </p>
                                                <p>
                                                    <b>v )</b>
                                                    HOGIST DISCLAIMS AND ALL LIABILITY THAT MAY ARISE DUE TO ANY
                                                    VIOLATION OF THE FOOD SAFETY AND STANDARDS ACT, 2006 AND APPLICABLE
                                                    RULES AND REGULATIONS MADE THEREUNDER AND SUCH LIABILITY SHALL BE
                                                    ATTRIBUTABLE TO THE FOOD SERVICE PROVIDER.
                                                </p>
                                                <p>
                                                    <b>vi )</b>
                                                    WHILE THE MATERIALS PROVIDED ON THE PLATFORM WERE PREPARED TO
                                                    PROVIDE ACCURATE INFORMATION REGARDING THE SUBJECT DISCUSSED, THE
                                                    INFORMATION CONTAINED IN THESE MATERIALS IS BEING MADE AVAILABLE
                                                    WITH THE UNDERSTANDING THAT WE MAKE NO GUARANTEES, REPRESENTATIONS
                                                    OR WARRANTIES WHATSOEVER, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT
                                                    TO PROFESSIONAL QUALIFICATIONS, EXPERTISE, QUALITY OF WORK OR OTHER
                                                    INFORMATION HEREIN. FURTHER, WE DO NOT, IN ANY WAY, ENDORSE ANY
                                                    SERVICE OFFERED OR DESCRIBED HEREIN. IN NO EVENT SHALL WE BE LIABLE
                                                    TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE OR ACTION TAKEN IN
                                                    RELIANCE ON SUCH INFORMATION.
                                                </p>
                                                <p>
                                                    <b>vii )</b>
                                                    THE INFORMATION PROVIDED HEREUNDER IS PROVIDED “AS IS”. WE AND / OR
                                                    OUR EMPLOYEES MAKE NO WARRANTY OR REPRESENTATION REGARDING THE
                                                    TIMELINESS, CONTENT, SEQUENCE, ACCURACY, EFFECTIVENESS OR
                                                    COMPLETENESS OF ANY INFORMATION OR DATA FURNISHED HEREUNDER OR THAT
                                                    THE INFORMATION OR DATA PROVIDED HEREUNDER MAY BE RELIED UPON.
                                                    MULTIPLE RESPONSES MAY USUALLY BE MADE AVAILABLE FROM DIFFERENT
                                                    SOURCES AND IT IS LEFT TO THE JUDGEMENT OF USERS BASED ON THEIR
                                                    SPECIFIC CIRCUMSTANCES TO USE, ADAPT, MODIFY OR ALTER SUGGESTIONS OR
                                                    USE THEM IN CONJUNCTION WITH ANY OTHER SOURCES THEY MAY HAVE,
                                                    THEREBY ABSOLVING US AS WELL AS OUR CONSULTANTS, BUSINESS
                                                    ASSOCIATES, AFFILIATES, BUSINESS PARTNERS AND EMPLOYEES FROM ANY
                                                    KIND OF PROFESSIONAL LIABILITY.
                                                </p>
                                                <p>
                                                    <b>viii )</b>
                                                    WE SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSSES OR
                                                    INJURY ARISING OUT OF OR RELATING TO THE INFORMATION PROVIDED ON THE
                                                    PLATFORM. IN NO EVENT WILL WE OR OUR EMPLOYEES, AFFILIATES, AUTHORS
                                                    OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DECISION MADE
                                                    OR ACTION TAKEN BY YOUR RELIANCE ON THE CONTENT CONTAINED HEREIN.
                                                </p>
                                                <p>
                                                    <b>ix )</b>
                                                    IN NO EVENT WILL WE BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT
                                                    LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                                                    EXEMPLARY DAMAGES, DAMAGES ARISING FROM PERSONAL INJURY/WRONGFUL
                                                    DEATH, AND DAMAGES RESULTING FROM LOST PROFITS, LOST DATA OR
                                                    BUSINESS INTERRUPTION), RESULTING FROM ANY SERVICES PROVIDED BY ANY
                                                    THIRD PARTY OR FOOD SERVICE PROVIDER ACCESSED THROUGH THE PLATFORM,
                                                    WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY
                                                    AND WHETHER OR NOT WE ARE ADVISED OF THE POSSIBILITY OF SUCH
                                                    DAMAGES.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Intellectual property</b>

                                                <p>
                                                    <b>i )</b>
                                                    We are either the owner of intellectual property rights or have the
                                                    non-exclusive, worldwide, perpetual, irrevocable, royalty free,
                                                    sub-licensable (through multiple tiers) right to exercise the
                                                    intellectual property, in the Platform, and in the material
                                                    published on it.
                                                </p>
                                                <p>
                                                    <b>ii )</b>
                                                    You may print off one copy, and may download extracts, of any
                                                    page(s) from the Platform for your personal reference and you may
                                                    draw the attention of others within your organisation to material
                                                    available on the Platform.
                                                </p>
                                                <p>
                                                    <b>iii )</b>
                                                    You must not modify the paper or digital copies of any materials you
                                                    have printed off or downloaded in any way, and you must not use any
                                                    illustrations, photographs, video or audio sequences or any graphics
                                                    separately from any accompanying text.
                                                </p>

                                                <p>
                                                    <b>iv )</b>
                                                    You must not use any part of the materials on the Platform for
                                                    commercial purposes without obtaining a licence to do so from us or
                                                    our licensors.
                                                </p>
                                                <p>
                                                    <b>v)</b>
                                                    If you print off, copy or download any part of the Platform in
                                                    breach of these Terms of Use, your right to use the Platform will
                                                    cease immediately and you must, at our option, return or destroy any
                                                    copies of the materials you have made.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Treatment of information provided by you </b>
                                                <p>
                                                    We process information provided by you to us in accordance with our
                                                    Privacy Policy.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Third Party Content </b>

                                                <p>
                                                    <b>i )</b>
                                                    We cannot and will not assure that other users are or will be
                                                    complying with the foregoing rules or any other provisions of these
                                                    Terms of Use, and, as between you and us, you hereby assume all risk
                                                    of harm or injury resulting from any such lack of compliance.
                                                </p>
                                                <p>
                                                    <b>ii )</b>
                                                    You acknowledge that when you access a link that leaves the
                                                    Platform, the site you will enter into is not controlled by us and
                                                    different terms of use and privacy policy may apply. By assessing
                                                    links to other sites, you acknowledge that we are not responsible
                                                    for those sites. We reserve the right to disable links to and / or
                                                    from third-party sites to the Platform, although we are under no
                                                    obligation to do so.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Severability </b>
                                                <p>
                                                    If any of these Terms of Use should be determined to be illegal,
                                                    invalid or otherwise unenforceable by reason of the laws of any
                                                    state or country in which these Terms of Use are intended to be
                                                    effective, then to the extent and within the jurisdiction where that
                                                    term is illegal, invalid or unenforceable, it shall be severed and
                                                    deleted and the remaining Terms of Use shall survive, remain in full
                                                    force and effect and continue to be binding and enforceable.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Non-assignment </b>
                                                <p>
                                                    You shall not assign or transfer or purport to assign or transfer
                                                    the contract between you and us to any other person.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Governing law and dispute resolution</b>
                                                <p>
                                                    These Terms of Use are governed by the laws of India. Any action,
                                                    suit, or other legal proceeding, which is commenced to resolve any
                                                    matter arising under or relating to this Platform, shall be subject
                                                    to the jurisdiction of the courts at Bangalore, India.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> IP Notice and Take Down Policy </b>
                                                <p>
                                                    {" "}
                                                    Hogist has put in place IP Notice and Take Down Policy (“ Take Down
                                                    Policy”) so that intellectual property owners could easily report
                                                    listings that infringe their right to ensure that infringing
                                                    products are removed from the site, as they erode Buyer and good
                                                    Seller trust.
                                                </p>
                                                <p>
                                                    Only the intellectual property rights owner can report potentially
                                                    infringing products or listings through Taeke Down Policy by way of
                                                    Notice of infringement in the specified format. If you are not the
                                                    intellectual property rights owner, you can still help by getting in
                                                    touch with the rights owner and encouraging them to contact
                                                    us.(Note: Hogist does not and cannot verify that Food Service
                                                    Providers have the right or ability to sell or distribute their
                                                    listed products. However, Hogist is committed ensure that item
                                                    listings do not infringe upon intellectual property rights of third
                                                    parties once an authorized representative of the rights owner
                                                    properly reports them to Hogist.)
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Contact Us </b>
                                                <p>
                                                    {" "}
                                                    Please contact us for any questions or comments (including all
                                                    inquiries unrelated to copyright infringement) regarding this
                                                    Platform.Grievance officer In accordance with Information Technology
                                                    Act, 2000 and rules made there under, the name and contact details
                                                    of the Grievance Officer are provided below: Mr. Arun Cyril Hogist
                                                    Technologies Private Limited Reg Office: 3rd Floor (Internally
                                                    designated as 4th Floor) Maruthi Chambers, Survey No 17/9B, Roopena
                                                    Agrahara Bangalore- 560068, India Phone: +91 - 080- 6000 6600 Email:
                                                    grievances@Hogist.in Time: Mon - Sat (9:00 - 18:00)
                                                </p>
                                            </li>
                                        </ul>

                                        <h4>HOGIST SUPER MEMBERSHIP PROGRAM</h4>
                                        <p>
                                            {" "}
                                            Welcome to the terms and conditions ("Terms") for Hogist SUPER (“Hogist
                                            SUPER”). These terms are between you and Hogist Technologies Private Limited
                                            and/or its affiliates ("Hogist" or " Us") and govern our respective rights
                                            and obligations. Hogist SUPER is a paid membership program for registered
                                            Hogist users. If you sign up for a Hogist SUPER membership, you accept these
                                            terms, conditions, limitations and requirements.
                                        </p>
                                        <p>
                                            <b>About Hogist SUPER membership program</b>
                                            <ul>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    <b> Benefits: </b>
                                                    <p>Free delivery on all orders above INR 99</p>
                                                    <p>
                                                        No surge on all orders above INR 99 which is applicable during
                                                        periods of rains / special occassions
                                                    </p>
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    <b> Payment: </b>
                                                    <p>
                                                        can use Credit Card, Debit Card, Net Banking & other third-party
                                                        payment options available to subscribe to the Hogist SUPER
                                                        program. However, Cash on Delivery (COD) payment option can be
                                                        used to subscribe to the Hogist SUPER program only when clubbed
                                                        together with the placed order.
                                                    </p>
                                                    <p>
                                                        You can access the detailed Terms & Conditions in the Help
                                                        Section.
                                                    </p>
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    <b> Membership & Activation </b>
                                                    <p>
                                                        Hogist users can purchase Hogist SUPER membership program
                                                        through all available online payment options. The subscription
                                                        shall be activated upon successful payment through the Hogist
                                                        platform.
                                                    </p>
                                                    <p>
                                                        In case Hogist SUPER membership is purchased with a placed order
                                                        and the same is cancelled, the membership shall also stand
                                                        cancelled.
                                                    </p>
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    <b> Cancellation of membership by you </b>
                                                    <p>Hogist SUPER once purchased, cannot be cancelled.</p>
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    <b> Termination / Cancellation of membership by Hogist</b>
                                                    <p>
                                                        We may terminate your Hogist SUPER Membership at our discretion
                                                        without notice. If we do so, we will issue a credit note to you
                                                        and give you a prorated refund based on the number of full
                                                        months remaining in your membership. However, we will not give
                                                        any refund for termination related to conduct that we determine,
                                                        in our discretion, violates these Terms or any applicable law,
                                                        involves fraud or misuse of the Hogist SUPER Membership, or is
                                                        harmful to our interests or another user. Our failure to insist
                                                        upon or enforce your strict compliance with these Terms will not
                                                        constitute a waiver of any of our rights.
                                                    </p>
                                                </li>
                                            </ul>
                                        </p>
                                        <h4>TERMS OF USE</h4>
                                        <ul>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                These terms of use (the “Terms”) govern your use of our service Hogist
                                                SUPER Membership Program (“Hogist SUPER”) on the Platform. Please read
                                                these Terms carefully before you use the Hogist SUPER Program. If you do
                                                not agree to these Terms, you may not use the Hogist SUPER Program. By
                                                using the program, you signify your acceptance to the Terms and creates
                                                a legally binding arrangement to abide by the same.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                The Hogist Services are made available on a best effort basis but not as
                                                obligation by HOGIST TECHNOLOGIES PRIVATE LIMITED (hereinafter referred
                                                to as “Hogist”, “ we”, “ us” or “ our”).. Hogist shall reserve the right
                                                to deny any part or full services without prior notice. “ you” or “
                                                user” shall mean user/customer.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                These Terms are special terms and as such restricted to only customer
                                                orders placed through Hogist SUPER and as such does not supersede the
                                                Hogist Terms of Use, these Terms shall be read in conjunction with
                                                Website’s Terms of Use.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                We reserve the right to modify these Terms at any time without notice
                                                posting changes on the Website and you shall be responsible to update
                                                yourself of such changes, if any, by accessing the changes on the
                                                Website. Your continued usage of the Hogist SUPER Program after any
                                                change is posted constitutes your acceptance of the amended Terms.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b>
                                                    General Terms & Conditions pertaining to the Hogist SUPER Program:
                                                </b>
                                                <ul>
                                                    <li>
                                                        <b>i )</b>
                                                        We reserve the right to accept or refuse membership in our
                                                        discretion
                                                    </li>
                                                    <li>
                                                        <b>ii )</b>
                                                        We may send you email and other communication related to Hogist
                                                        SUPER and your Hogist SUPER Membership (regardless of any
                                                        settings or preferences related to your Hogist account).
                                                    </li>
                                                    <li>
                                                        <b>iii )</b>
                                                        You may not transfer or assign your Hogist SUPER Membership or
                                                        any Hogist SUPER benefits, except as allowed in these Terms.
                                                    </li>

                                                    <li>
                                                        <b>iv )</b>
                                                        From time to time, We may choose in its sole discretion to add
                                                        or remove Hogist SUPER Membership benefits.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b>Termination by Us</b>
                                                <p>
                                                    We may terminate your Hogist SUPER membership at our discretion
                                                    without notice. If we do so, we will issue a credit note to you and
                                                    give you a prorated refund based on the number of full months
                                                    remaining in your membership. However, we will not give any refund
                                                    for termination related to conduct that we determine, in our
                                                    discretion, violates these Terms or any applicable law, involves
                                                    fraud or misuse of the Hogist SUPER membership, or is harmful to our
                                                    interests or another user. Our failure to insist upon or enforce
                                                    your strict compliance with these Terms will not constitute a waiver
                                                    of any of our rights.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                <b> Limitation of Liability</b>
                                                <p>
                                                    IN ADDITION TO OTHER LIMITATIONS AND EXCLUSIONS IN HOGIST'S
                                                    CONDITIONS OF USE AND SALE, IN NO EVENT WILL WE OR OUR DIRECTORS,
                                                    OFFICERS, EMPLOYEES, AGENTS OR OTHER REPRESENTATIVES BE LIABLE FOR
                                                    ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                                                    PUNITIVE DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, ARISING OUT OF
                                                    OR RELATED TO HOGIST. OUR TOTAL LIABILITY, WHETHER IN CONTRACT,
                                                    WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL NOT EXCEED
                                                    THE LAST MEMBERSHIP FEE YOU PAID. THESE EXCLUSIONS AND LIMITATIONS
                                                    OF LIABILITY WILL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW AND
                                                    WILL SURVIVE CANCELLATION OR TERMINATION OF YOUR HOGIST SUPER
                                                    MEMBERSHIP.
                                                </p>
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Hogist reserves its right to alter/ withdraw/ extend any offers/
                                                promotions at any time without giving any prior notice & without
                                                assigning any reason whatsoever.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                All disputes related to this Hogist SUPER Membership will be subject to
                                                the exclusive jurisdiction of court of Bangalore only.
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                These Terms and Conditions are co-extensive and concurrent with Hogist’s
                                                other Terms and Conditions and the Privacy Policy listed on this page.
                                                As such, all other Terms and Conditions listed hereinbefore shall also
                                                be applicable to this Hogist SUPER Membership and the same need not be
                                                expressly repeated herein.
                                            </li>
                                        </ul>
                                        <p>
                                            <b>Introducing 30 mins or Free</b>
                                        </p>
                                        <p>
                                            <b>
                                                30 mins or Free - Guaranteed delivery in 30 mins* or your food is on us
                                            </b>
                                        </p>
                                        <p>
                                            <b>
                                                Hand-picked restaurants - Great food from popular/top-rated restaurants
                                                near you
                                            </b>
                                        </p>
                                        <h4>Terms & Conditions*</h4>
                                        <ul>
                                            <li>Applicable only on online payments & orders below INR 500</li>
                                            <li>
                                                30 mins is based on delivery boy reaching consumer location/first
                                                barrier point (security guard/reception etc.)
                                            </li>
                                            <li>
                                                Not eligible for 30 mins or Free if the consumer requests for order edit
                                            </li>
                                            <li>
                                                For more information, refer to detailed Terms & Conditions in the Help
                                                Section
                                            </li>
                                        </ul>

                                        <h4>TERMS OF USE – 30 mins or Free</h4>

                                        <p>
                                            <b>1.</b>
                                            These terms of use (the “ Terms”) that govern your use of our service 30
                                            mins or Free (“ 30 mins or Free”) on Platform. The services on Assured
                                            available on our Website and the App are jointly referred to as the “30 mins
                                            or Free” morefully defined hereunder. Please read these Terms carefully
                                            before you use the 30 mins or Free Services. If you do not agree to these
                                            Terms, you may not use the 30 mins or Free Services. By using the Services,
                                            you signify your acceptance to the Terms and creates a legally binding
                                            arrangement to abide by the same.
                                        </p>
                                        <p>
                                            <b>2.</b>
                                            The 30 mins or Free Services are made available on a best effort basis but
                                            not as obligation by HOGIST TECHNOLOGIES PRIVATE LIMITED (hereinafter
                                            referred to as “Hogist”, “ we”, “ us” or “ our”). The 30 mins or Free
                                            Services made available are subject to various factors beyond control.
                                            Hogist shall reserve the right to deny any part or full services without
                                            prior notice. “ you” or “ user” shall mean user/customer.
                                        </p>
                                        <p>
                                            <b>3.</b>
                                            These Terms are special terms and as such restricted to only customer orders
                                            placed by customers through restaurants identified as 30 mins or Free
                                            restaurants and as such does not supersede the Hogist Terms of Use, these
                                            Terms shall be read in conjunction with Website’s Terms of Use.
                                        </p>
                                        <p>
                                            <b>4.</b>
                                            We reserve the right to modify these Terms at any time without notice
                                            posting changes on the Website and you shall be responsible to update
                                            yourself of such changes, if any, by accessing the changes on the Website.
                                            Your continued usage of the 30 mins or Free Services after any change is
                                            posted constitutes your acceptance of the amended Terms.
                                        </p>
                                        <p>
                                            <b>5.</b>
                                            General Terms & Conditions pertaining to the products available on 30 mins
                                            or Free:
                                            <ul>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    In case the customer chooses to avail the 30 mins or Free of the
                                                    restaurants listed on Assured, Hogist shall guarantee the user
                                                    delivery of the Order within 30 mins
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    In case Hogist is unable to deliver the Order in 30 mins, it
                                                    guarantees refund to the user subject to these Terms and Hogist’s
                                                    discretion (the “Refund Guarantee”) *.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The Refund Guarantee* is applicable only for delay in the delivery
                                                    time.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    We do not own, sell, resell, furnish, provide, prepare, manage
                                                    and/or control the Restaurants or the related services provided in
                                                    connection thereof.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    All customers with a Hogist Account can avail the 30 mins or Free,
                                                    if they so choose to.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The delivery time is the time period between the customer placing
                                                    the order and the delivery executive arriving at the first point of
                                                    entry at the user’s location, as shown on the map at the time of
                                                    placing the order (the “ Delivery time”).
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    You are entitled for only 2 cashbacks in the last 7 days.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The Refund Guarantee* on 30 mins or Free is applicable only on
                                                    online payments (ie., payments made through Net Banking,
                                                    Credit/Debit cards, Electronic Wallet Services) & 30 mins or Free
                                                    restaurants and you shall be duly notified upon confirmation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The 30 mins or Free is not applicable if customer tries to change
                                                    and/or edit the Order, subsequent to having placed it on our
                                                    Platform.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The refund amount will be credited to source account.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The refund process will depend on the source account that it needs
                                                    to be credited to. It can typically take upto 7 days for account
                                                    credit.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves the right to withdraw the 30 mins or Free Services
                                                    without prior intimation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist further reserves the sole right to withdraw the 30 mins or
                                                    Free in the following circumstance:
                                                    <p>
                                                        <b>i )</b>
                                                        in the event of the designated address falling outside the
                                                        delivery zone offered by us;
                                                    </p>
                                                    <p>
                                                        <b>ii )</b>
                                                        failure on the part of the customer to respond by phone or email
                                                        at the time of confirming the order booking;
                                                    </p>
                                                    <p>
                                                        <b>iii )</b>
                                                        failure to deliver your order due to lack of information,
                                                        direction or authorization from you at the time of delivery; or
                                                    </p>
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves the right not to enable Refund Guarantee* if
                                                    customer location is different than indicated.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist is not investing or circulating any cash into any entity,
                                                    restaurant or customer by way of providing this 30 mins or Free.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    This 30 mins or Free may not be applicable during holiday due to
                                                    regional festivals or local events or at times of rain in any
                                                    particular location, Hogist reserves the right to pause the 30 mins
                                                    or Free without prior intimation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist is not investing or circulating any cash into any entity,
                                                    restaurant or customer by way of providing this 30 mins or Free.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    This 30 mins or Free may not be applicable during holiday due to
                                                    regional festivals or local events or at times of rain in any
                                                    particular location, Hogist reserves the right to pause the 30 mins
                                                    or Free without prior intimation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves its right to alter/ withdraw/ extend any offers/
                                                    promotions at any time without giving any prior notice & without
                                                    assigning any reason whatsoever.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    This is 30 mins or Free is subject to Force Majeure conditions.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    All disputes related to this 30 mins or Free will be subject to the
                                                    exclusive jurisdiction of court of Bangalore only.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The 30 mins or Free shall not be available for bulk orders or orders
                                                    exceeding Rs.500/- (Rupees Five hundred).
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    These Terms and Conditions are co-extensive and concurrent with
                                                    Hogist’s other Terms and Conditions and the Privacy Policy listed on
                                                    this page. As such, all other Terms and Conditions listed
                                                    hereinbefore shall also be applicable to this 30 mins or Freeand the
                                                    same need not be expressly repeated herein.
                                                </li>
                                            </ul>
                                        </p>
                                        <p>
                                            Refund Guarantee shall be provided to the Customer at the sole discretion of
                                            Hogist and shall vary from city to city.
                                        </p>
                                        <h4>TERMS OF USE – HOGIST ASSURED</h4>
                                        <p>
                                            <b>i )</b>
                                            These terms of use (the “ Terms”) that govern your use of our service Hogist
                                            Assured (“ Hogist Assured”) on Platform. The services on Assured available
                                            on our Website and the App are jointly referred to as the “ On-Time Delivery
                                            ” morefully defined hereunder. Please read these Terms carefully before you
                                            use the Hogist Assured Services. If you do not agree to these Terms, you may
                                            not use the Hogist Assured Services. By using the Services, you signify your
                                            acceptance to the Terms and creates a legally binding arrangement to abide
                                            by the same.
                                        </p>
                                        <p>
                                            <b>ii )</b>
                                            The Hogist Assured Services are made available on a best effort basis but
                                            not as obligation by HOGIST TECHNOLOGIES PRIVATE LIMITED (hereinafter
                                            referred to as “ Hogist”, “ we”, “ us” or “ our”). The Hogist Assured
                                            Services made available are subject to various factors beyond control.
                                            Hogist shall reserve the right to deny any part or full services without
                                            prior notice. “ you” or “ user” shall mean user/customer.
                                        </p>
                                        <p>
                                            <b>iii )</b>
                                            These Terms are special terms and as such restricted to only customer orders
                                            placed by customers through restaurants identified as Hogist Assured
                                            restaurants and as such does not supersede the Hogist Terms of Use, these
                                            Terms shall be read in conjunction with Website’s Terms of Use.
                                        </p>
                                        <p>
                                            <b>iv )</b>
                                            We reserve the right to modify these Terms at any time without notice
                                            posting changes on the Website and you shall be responsible to update
                                            yourself of such changes, if any, by accessing the changes on the Website.
                                            Your continued usage of the Hogist Assured Services after any change is
                                            posted constitutes your acceptance of the amended Terms.
                                        </p>
                                        <p>
                                            <b>
                                                v ) General Terms & Conditions pertaining to the products available on
                                                Hogist Assured:
                                            </b>
                                            <ul>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    In case the customer chooses to avail the On-Time Delivery of the
                                                    restaurants listed on Assured, Hogist shall guarantee the user
                                                    delivery of the Order, in the estimated time mentioned at the time
                                                    of placing the Order, which time shall be communicated to the
                                                    customer by Hogist (the “ ETA”), subject to these Terms.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    In case Hogist is unable to deliver the Order in the committed ETA,
                                                    it guarantees cashback to the user subject to these Terms and
                                                    Hogist’s discretion (the “ Cashback Guarantee”)
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The Cashback Guarantee* is applicable only for delay in the delivery
                                                    time as against the ETA defined as above.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    We do not own, sell, resell, furnish, provide, prepare, manage
                                                    and/or control the Restaurants or the related services provided in
                                                    connection thereof.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    All customers with a Hogist Account can avail the On-Time Delivery
                                                    of Hogist Assured, if they so choose to.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The delivery time is the time period between the customer placing
                                                    the order and the delivery executive arriving at the first point of
                                                    entry at the user’s location, as shown on the map at the time of
                                                    placing the order (the “ Delivery time”).
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    You are entitled for only 2 cashbacks in the last 7 days.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The Cashback Guarantee* on On-Time Delivery is applicable only on
                                                    online payments (ie., payments made through Net Banking,
                                                    Credit/Debit cards, Electronic Wallet Services and Hogist Money) &
                                                    Hogist Assured restaurants and you shall be duly notified upon
                                                    confirmation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The On-Time Delivery is not applicable if customer tries to change
                                                    and/or edit the Order, subsequent to having placed it on our
                                                    Platform.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The cashback amount will be credited to Hogist Money only and not to
                                                    any other source that you may have used to make such payment.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The cashback process will take time between 24-72 hours.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves the right to withdraw the On-Time Delivery and
                                                    Hogist Assured Services without prior intimation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist further reserves the sole right to withdraw the On-Time
                                                    Delivery in the following circumstance:
                                                    <ul>
                                                        <li>
                                                            <b>i )</b>
                                                            in the event of the designated address falling outside the
                                                            delivery zone offered by us;
                                                        </li>
                                                        <li>
                                                            <b>ii )</b>
                                                            failure on the part of the customer to respond by phone or
                                                            email at the time of confirming the order booking;
                                                        </li>
                                                        <li>
                                                            <b>iii )</b>
                                                            failure to deliver your order due to lack of information,
                                                            direction or authorization from you at the time of delivery;
                                                            or
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves the right not to enable Cashback Guarantee* if
                                                    customer location is different than indicated.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves rights to not enable Cashback Guarantee* in case of
                                                    suspect fraudulent activity with a right to initiate appropriate
                                                    legal recourse.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist does not penalize its Delivery Executives for late delivery.
                                                    Nor does it charge the customer or the Restaurant anything extra for
                                                    providing this On-Time Delivery.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist is not investing or circulating any cash into any entity,
                                                    restaurant or customer by way of providing this On-Time Delivery.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    This On-Time Delivery may not be applicable during holiday due to
                                                    regional festivals or local events or at times of rain in any
                                                    particular location, Hogist reserves the right to pause the On-Time
                                                    Delivery without prior intimation.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    Hogist reserves its right to alter/ withdraw/ extend any offers/
                                                    promotions at any time without giving any prior notice & without
                                                    assigning any reason whatsoever.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    This is On-Time Delivery is subject to Force Majeure conditions.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    All disputes related to this On-Time Delivery will be subject to the
                                                    exclusive jurisdiction of court of Bangalore only.
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    The On-Time Delivery shall not be available for bulk orders or
                                                    orders exceeding Rs.1000/- (Rupees One Thousand).
                                                </li>
                                                <li>
                                                    <ArrowRightIcon className="footer-icon-style" />
                                                    These Terms and Conditions are co-extensive and concurrent with
                                                    Hogist’s other Terms and Conditions and the Privacy Policy listed on
                                                    this page. As such, all other Terms and Conditions listed
                                                    hereinbefore shall also be applicable to this On-Time Deliveryand
                                                    the same need not be expressly repeated herein.
                                                </li>
                                            </ul>
                                        </p>
                                        <p>
                                            Cashback Guarantee shall be provided to the Customer at the sole discretion
                                            of Hogist and shall vary from city to city.
                                        </p>

                                        <h4>Times Food Awards - Event Pass Winners</h4>
                                        <ul>
                                            <li>
                                                <b>1.</b>
                                                Winners shall randomly be chosen by Hogist Technologies Pvt. Ltd.
                                                (Hogist) among users who have successfully submitted their votes on the
                                                www.Hogist.com
                                            </li>
                                            <li>
                                                <b>2.</b>
                                                Each event pass will be valid for two personnel only
                                            </li>
                                            <li>
                                                <b>3.</b>
                                                Each event pass will be valid only on the day of the event
                                            </li>
                                            <li>
                                                <b>4.</b>
                                                The event pass cannot be sold or exchanged for cash or vouchers
                                            </li>
                                            <li>
                                                <b>5.</b>
                                                Hogist Technologies Pvt. Ltd. (Hogist) reserves the right to cancel/
                                                change/modify/add/delete any of the terms and conditions of the contest
                                                at any time withoutprior notice
                                            </li>
                                            <li>
                                                <b>6.</b>
                                                Hogist Technologies Pvt. Ltd. (Hogist) reserves the right to terminate
                                                the contest at any time without prior notice
                                            </li>
                                            <li>
                                                <b>7.</b>
                                                Hogist Technologies Pvt. Ltd. (Hogist) reserves the right to deny
                                                honouring the winners on the grounds of suspicion or abuse of the
                                                contest by any customer without providing customer any explanation
                                                thereof
                                            </li>
                                            <li>
                                                <b>8.</b>
                                                In no event shall Hogist Technologies Pvt. Ltd. (Hogist) be liable for
                                                any abuse or misuse of the pass due to the negligence of the customer
                                            </li>
                                            <li>
                                                <b>9.</b>
                                                The services shall be governed by the terms and conditions set out in
                                                https://www.Hogist.com/terms-and-conditions
                                            </li>
                                            <li>
                                                <b>10.</b>
                                                In case of any query regarding the voting contest, please email us at
                                                social@Hogist.in
                                            </li>
                                        </ul>
                                        <h4>For more details on our sellers:</h4>
                                        <p>
                                            <b>Notice and Take Down Policy Template and Procedures</b>
                                        </p>
                                        <p>
                                            Hogist Technologies Private Limited (“ Hogist”) respects third-party
                                            intellectual property rights, statutorily registered or common law rights,
                                            and has adopted the following policy towards intellectual property rights
                                            protection on its website www.Hogist.com (the “, Website”) and the ‘Hogist’
                                            application for mobile and handheld devices (the “ App”). The Website and
                                            the App are jointly referred to as the “ Platform”.
                                        </p>
                                        <p>
                                            <b>Reporting Instances of Intellectual Property Violations:</b>
                                        </p>
                                        <p>
                                            {" "}
                                            To file a notice of infringement with Hogist, you must provide a written
                                            communication (by email or regular mail) that sets out the information
                                            specified in the list below. Please note that you will be liable for damages
                                            (including costs and lawyer's fees) if you materially misrepresent that
                                            material is infringing your intellectual property right(s). Accordingly, if
                                            you are not sure if you are the proprietor of the intellectual property
                                            right(s), we suggest that you first contact a lawyer.
                                        </p>
                                        <p>
                                            To expedite our ability to process your request, please use the following
                                            format:
                                        </p>
                                        <ul>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Identify with sufficient detail the protected work that you believe has
                                                been infringed. This includes identification of the web page or specific
                                                posts, as opposed to entire sites. Posts must be referenced by either
                                                the dates in which they appear or by the permalink of the post. Include
                                                the URL to the concerned material infringing your intellectual property
                                                right(s) [URL of a website or URL to a post, with title, date, name of
                                                the emitter], or link to initial post with sufficient data to find it;
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Identification of the material that is claimed to be infringing or to be
                                                the subject of infringing activity and that is to be removed or access
                                                to which is to be disabled, and information reasonably sufficient to
                                                permit Hogist to locate the material;
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Information reasonably sufficient to permit Hogist to contact the
                                                complaining party, such as an address, telephone number, and, if
                                                available, an email address at which the complaining party may be
                                                contacted;
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                The following mandatory statement: “I have a good faith belief that use
                                                of the material in the manner complained of is not authorized by the
                                                intellectual property right(s) owner, its agent, or the law”;
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                The following mandatory statement: “I swear, under penalty of perjury,
                                                that the information in the notification is accurate, and that I am the
                                                intellectual property right(s) owner or authorized to act on behalf of
                                                the owner of an exclusive intellectual property right(s) that is
                                                allegedly infringed”; and
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Sign the document with either your physical or electronic signature; and
                                            </li>
                                            <li>
                                                <ArrowRightIcon className="footer-icon-style" />
                                                Send the written communication to: Email to ______@Hogist.in
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
