
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { apiurl } from "../../../api/api_url";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from "react-router-dom";
const Payment = () => {
  const { id,date,total} = useParams();
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    history.push("/odc/profile");

  };

  const [formData, setFormData] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    upiID: '',
    bankName: '',
  });

  useEffect(() => {
    setFormData({
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      upiID: '',
      bankName: '',
    });
  }, [paymentMethod]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const date1 = new Date(date);

  const year = date1.getFullYear();
  const month = date1.getMonth() + 1;
  const day = date1.getDate();

  const payeddate = `${year}-${month}-${day}`;
  const handlePayment = async() => {
    if (paymentMethod === 'card') {
      const { cardNumber, expiryDate, cvv } = formData;
      // Handle card payment
    } else if (paymentMethod === 'upi') {
      const { upiID } = formData;
      // Handle UPI payment
    } else if (paymentMethod === 'netbanking') {
      const { bankName } = formData;
      // Handle netbanking payment
    }

    await fetch(`${apiurl}/odc-order-pay/${id}`, {
      method: "PUT",
      body: JSON.stringify({payId:Date.now(), amount:total, status:"success", mode:"card", date:payeddate}),
      headers: {
          "Content-type": "application/json",
          Authorization: localStorage.getItem("tokenodc"),
      },
  })
      .then((response) => response.json())
      .then((json) => {
          if(json.status===true){
            setOpen(true);
           
          }
        
      });
  };


  // console.log(`${year}-${month}-${day}`);

  return (
    <div className="payment-container">
      <h2>Payment Page</h2>
      <select
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value)}
        className="payment-method-select"
      >
        <option value="card">Card</option>
        <option value="upi">UPI</option>
        <option value="netbanking">Netbanking</option>
      </select>

      {paymentMethod === 'card' && (
        <>
          <input
            type="text"
            placeholder="Card Number"
            name="cardNumber"
            value={formData.cardNumber}
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Expiry Date"
            name="expiryDate"
            value={formData.expiryDate}
            onChange={handleInputChange}
            className="input-field"
          />
          <input
            type="text"
            placeholder="CVV"
            name="cvv"
            value={formData.cvv}
            onChange={handleInputChange}
            className="input-field"
          />
        </>
      )}

      {paymentMethod === 'upi' && (
        <input
          type="text"
          placeholder="UPI ID"
          name="upiID"
          value={formData.upiID}
          onChange={handleInputChange}
          className="input-field"
        />
      )}

      {paymentMethod === 'netbanking' && (
        <input
          type="text"
          placeholder="Bank Name"
          name="bankName"
          value={formData.bankName}
          onChange={handleInputChange}
          className="input-field"
        />
      )}

      <button onClick={handlePayment} className="submit-button">
        Submit
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{textAlign:"center"}}>
           <CheckCircleIcon style={{color:"green",fontSize: "60px",
    marginBottom: "9%"}}/>

           <h4>Payment Successfully</h4>
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    </div>
  );
};

export default Payment;
