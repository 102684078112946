// react
import React, { Component } from "react";

// third-party
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import { Menu18x14Svg } from "../../../svg";
import { mobileMenuOpen } from "../../../store/mobile-menu";
import Hlogo from "./../../../img/logo.png";
import MainDropdownButton from "../../header/MainDropdownButton";
import IndicatorAccount from "../../header/IndicatorAccount";

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    render() {
        const { openMobileMenu, user } = this.props;
        // const { searchOpen } = this.state;
        // const searchClasses = classNames("mobile-header__search", {
        //     "mobile-header__search--open": searchOpen,
        // });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel" style={{ display: "flex", alignItems: "center" }}>
                    {/* <div className="col-lg-12"> */}
                    {/* <div className="container"> */}
                    <div className=" mobile-header__body">
                        {/* <div className="col-lg-2"> */}
                        <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                            <Menu18x14Svg />
                        </button>
                        {/* </div> */}
                        {/* <div className="col-lg-4"> */}
                        <Link to="/" className="mobile-header__logo">
                            {" "}
                            <img style={{ width: "80%" }} src={Hlogo} alt="logo" />
                        </Link>
                        {/* </div> */}
                        {/* <div className="col-lg-3"> */}
                        <MainDropdownButton />
                        {/* </div> */}
                        {/* <div className="col-lg-3"> */}
                        <IndicatorAccount user={user} />
                        {/* </div> */}

                        {/* <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            /> 
                            
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                             <Indicator
                                    className="indicator--mobile d-sm-flex d-none"
                                    url="/shop/wishlist"
                                    value={wishlist.length}
                                    icon={<Heart20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile"
                                    url="/shop/cart"
                                    value={cart.quantity}
                                    icon={<Cart20Svg />}
                                /> 
                            </div> */}
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
    user: state.user,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
