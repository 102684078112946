// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImage, MDBBtn } from "mdb-react-ui-kit";

// application
import StroykaSlick from "../shared/StroykaSlick";

// data stubs
import theme from "../../../data/theme";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function AboutUs() {
    return (
        <div className="block about-us txt-colr txt-justify">
            <Helmet>
                <title>{` ${theme.name} - About Us`}</title>
            </Helmet>

            <div
                className="about-us__image"
                style={{
                    backgroundImage: 'url("images/banners/abg.png")',
                }}
            >
                <div className="mask" style={{ backgroundColor: "rgb(0 0 0 / 68%)", height: "100% " }}>
                    <div className="ab-d-flex justify-content-center align-items-center h-100">
                        <h1 className="text-white mb-0">About Us</h1>
                    </div>
                </div>
            </div>

            <div className="block block-banner">
                <div className="container-fluid">
                    <div className="row pdside pt-5">
                        <div className="col-lg-4">
                            <div className="teammate__avatar">
                                <img
                                    src="https://www.sunjaya.com.my/wp-content/uploads/2019/11/Wedding-Catering-Fusion-600x600.jpg"
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="col-lg-8">
                            <h1>Welcome To Hogist</h1>
                            <p className="about__text">The Best Catering service in Chennai</p>
                            <div className="typography abpara">
                                <p>
                                    We have a strong team to take care of complete operations & technology enabling the
                                    customer to sit and relax. We connect with people from all walks of life. Be it a
                                    small-scale caterer to a five-star hotel chef, anybody who is looking out for food
                                    would feel HOGIST beneficial. With HOGIST providing a platform for online bulk
                                    booking, it becomes easier for people to choose and plan their events accordingly
                                    without having to depend on anyone. With customer satisfaction being our goal, we at
                                    HOGIST strive hard to bring multiple cuisines under one roof at an affordable cost
                                    with top-class customer service.
                                </p>
                                <p>
                                    Our customer service staff members serve as the face of our company. They are the
                                    first people our customers reach out to whenever they encounter any problem
                                    regarding our services. We ensure our personnel can handle customer requirements
                                    with excellence and give you the best catering service for your events. Thus, all
                                    you need to do is simply order online, relax, and enjoy, while our dedicated team
                                    will work out the details for you. We always focus on the quality of food and service
                                    and make sure that you are hassle-free throughout the event. In simple words, you
                                    search "catering services near me" and there you get to know about HOGIST.
                                </p>
                                <div className="cntbtn">
                                    <Link to="/" className="logbtn">
                                        Contact Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about__service_part">
                <div className="container">
                    {/* <h1 className="about__service_tit">Our Best Services</h1> */}
                    <div className="row">
                        <div className="col-lg-4">
                            <MDBCard>
                                <div >
                                    <i className="fas fa-crosshairs ab-main-txt-style rotate" />
                                </div>
                                <MDBCardBody >
                                    <MDBCardTitle style={{ textAlign: " center" }}>Mission</MDBCardTitle>
                                    <MDBCardText className="txt-space">
                                        To create delicious,hygienic, entertaining food experiences at affordable prices
                                        with impeccable service in a professional, consistent, flexible,and timely
                                        manner catering to the requirements of groups of people - corporates and
                                        individuals.
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-lg-4">
                            <MDBCard>
                                <div >
                                    {" "}
                                    <i className="	far fa-eye ab-main-txt-style rotate" />{" "}
                                </div>
                                <MDBCardBody >
                                    <MDBCardTitle style={{ textAlign: " center" }}>Vission</MDBCardTitle>
                                    <MDBCardText className="txt-space">
                                        To be a leading market place for all bulk food requirments- providing hygienic &
                                        quality food with impeccable, versatile, and flexible customer service with
                                        consistency and presentation that will leave a memorable experience.
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div className="col-lg-4">
                            <MDBCard>
                                <div>
                                    {" "}
                                    <i className="fas fa-award ab-main-txt-style rotate" />
                                </div>
                                <MDBCardBody >
                                    <MDBCardTitle style={{ textAlign: " center" }}>Core Values (4E)</MDBCardTitle>
                                    <MDBCardText className="txt-space">
                                        <p>
                                            <b>Employee First :</b> We are serious about creating a productive,
                                            cooperative,safe & rewarding environment for our team.
                                        </p>
                                        <p>
                                            <b>Ethics:</b>
                                            We want to play a fair game and not make any compromises concerning
                                            integrity and ethics.
                                        </p>
                                        <p>
                                            <b>Extra Mile :</b>
                                            While we want to maintain the highest levels of hygiene, taste, and customer
                                            service in our food experiences, we want to go that extra mile in delighting
                                            our customers.
                                        </p>
                                        <p>
                                            <b>Entertainment :</b>
                                            Food is entertainment. Food is experience. Food is fun. We will always keep
                                            this in mind when we deliver our services.
                                        </p>
                                    </MDBCardText>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">About Us</h1>
                            <div className="about-us__text typography">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Cras lacus metus, convallis ut leo nec, tincidunt
                                    eleifend justo. Ut felis orci, hendrerit a pulvinar et,
                                    gravida ac lorem. Sed vitae molestie sapien, at
                                    sollicitudin tortor.
                                </p>
                                <p>
                                    Duis id volutpat libero, id vestibulum purus.Donec euismod
                                    accumsan felis,egestas lobortis velit tempor vitae. Integer
                                    eget velit fermentum, dignissim odio non, bibendum velit.
                                </p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">Meat Our Team</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    Want to work in our friendly team?
                                    <br />
                                    <Link to="/contact-us">Contact us</Link>
                                    {' '}
                                    and we will consider your candidacy.
                                </div>
                                <div className="about-us__teammates teammates">
                                    <StroykaSlick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Michael Russo</div>
                                            <div className="teammate__position text-muted">Chief Executive Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Katherine Miller</div>
                                            <div className="teammate__position text-muted">Marketing Officer</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Anthony Harris</div>
                                            <div className="teammate__position text-muted">Finance Director</div>
                                        </div>
                                    </StroykaSlick>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default AboutUs;
