import { FeedSharp, ReceiptLongOutlined, SafetyCheckSharp, SwipeLeftSharp } from "@mui/icons-material";

export const formattedDate = (_date) => {
    let rdate = new Date(_date);
    let d = rdate.getDate();
    d < 10 && (d = "0" + d);
    let m = rdate.getMonth() + 1;
    m < 10 && (m = "0" + m);
    let y = rdate.getFullYear();
    rdate = d + "-" + m + "-" + y;

    return rdate;
};

export const formatTime = (_date) => {
    let rdate = new Date(_date);
    let hours = rdate.getHours();
    let amPm = hours < 12 ? "AM" : "PM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours < 10 && (hours = "0" + hours);
    let minutes = rdate.getMinutes();
    minutes < 10 && (minutes = "0" + minutes);
    let seconds = rdate.getSeconds();
    seconds < 10 && (seconds = "0" + seconds);
    rdate = hours + ":" + minutes + ":" + seconds + " " + amPm;

    return rdate;
};

export const formattedDateTime = (_date) => {
    let rdate = new Date(_date);
    let d = rdate.getDate();
    d < 10 && (d = "0" + d);
    let m = rdate.getMonth() + 1;
    m < 10 && (m = "0" + m);
    let y = rdate.getFullYear();
    let hours = rdate.getHours();
    let amPm = hours < 12 ? "AM" : "PM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours < 10 && (hours = "0" + hours);
    let minutes = rdate.getMinutes();
    minutes < 10 && (minutes = "0" + minutes);
    let seconds = rdate.getSeconds();
    seconds < 10 && (seconds = "0" + seconds);
    rdate = d + "-" + m + "-" + y + " " + hours + ":" + minutes + ":" + amPm;

    return rdate;
};

// export function convertTime(time) {
//     const [hour, minute] = time.split(":");
//     let convertedHour = hour % 12;
//     if (convertedHour === 0) {
//         convertedHour = 12;
//     }
//     const period = hour < 12 ? "AM" : "PM";
//     return `${convertedHour}:${minute} ${period}`;
// }

export function convertTime(time) {
    // Check if time is undefined or null
    if (!time) {
        return "Invalid Time";
    }

    const [hour, minute] = time.split(":");
    
    // Check if hour and minute are undefined or null
    if (hour === undefined || minute === undefined) {
        return "Invalid Time Format";
    }

    let convertedHour = parseInt(hour, 10) % 12;
    if (convertedHour === 0) {
        convertedHour = 12;
    }
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${convertedHour}:${minute} ${period}`;
}
