import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { IconButton } from "@mui/material";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import EventIcon from "@mui/icons-material/Event";
import DomainVerificationIcon from "@mui/icons-material/DomainVerification";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GroupsIcon from "@mui/icons-material/Groups";
import VerifiedIcon from "@mui/icons-material/Verified";
import HomeIcon from "@mui/icons-material/Home";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import LogoutIcon from "@mui/icons-material/Logout";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { removeodcuserChange } from "../../../store/addodcuser";
import { useDispatch } from "react-redux";
import { apiurl } from "../../../api/api_url";
import { formattedDate } from "../../../helper/dateformate";
// import ListPagination from "../ListPagination";
import Pagination from "@mui/material/Pagination";

function Profile(props) {
    const { odcuser } = props;
    const [selectedImage, setSelectedImage] = useState(null);
    const [isVisible, setIsVisible] = useState(true);
    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(!odcuser.fullName ? true : false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [menulistapi, setMenulistapi] = useState([]);
    //
    const [pages, setPages] = useState({ pages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;

    useEffect(() => {
        getApiData(currentPage);
    }, []);
    // order

    const getApiData = async (pageno) => {
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("tokenodc") };
        const response = await fetch(`${apiurl}/odc-orders?page=${pageno}&limit=${limit}`, {
            headers,
            method: "GET",
        }).then((response) => response.json());

        // update the state
        setMenulistapi(response.data);
        setPages(response);
        console.log(response);
    };

    // const paginate = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    //   };

    const paginate = (event, value) => {
        getApiData(value);
        setCurrentPage(value);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(URL.createObjectURL(file));
    };

    const handleButtonClick = () => {
        document.getElementById("imageInput").click();
    };
    const handleOrdersClick = () => {
        history.push("/odc/home");
    };

    const handleLogoutodc = (e) => {
        e.preventDefault();

        dispatch(removeodcuserChange());
        history.push("/odc/login");
    };
    const odchandleLogin = (e) => {
        e.preventDefault();

        history.push("/odc/login");
    };
    const odchandleSignin = (e) => {
        e.preventDefault();

        history.push("/odc/signin");
    };

    const toggleVisibility = (e) => {
        setIsVisible(!isVisible);
        // setIsVisible(false);
        setIsVisible1(false);
        setIsVisible2(emticon.length === 0 || !odcuser.fullName ? true : false);
    };
    const toggleVisibility1 = (e) => {
        setIsVisible1(true);
        setIsVisible(false);
        setIsVisible2(false);
    };

    const handlemenuDetails = (item) => {
        history.push("/odc/order-details/" + item._id);
        // const checkid=menuclick===item._id;
        console.log(item._id);
    };
    const emticon = menulistapi.map((item) => item.services);

    console.log(menulistapi);

    return (
        <div style={{ backgroundImage: "url(images/body-bg12.jpg)", backgroundSize: "cover", paddingTop: " 5%" }}>
            <div className="container-fluid" style={{ padding: "0% 5%", marginBottom: "3%" }}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="profile-card  ">
                            <div className="container">
                                <div className=" row " style={{ backgroundColor: "#c60800", padding: "10%" }}>
                                    <div className="col-lg-4" style={{ display: "flex", alignItems: "center" }}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={{ display: "none" }}
                                            id="imageInput"
                                        />
                                        <IconButton
                                            onClick={handleButtonClick}
                                            component="label"
                                            style={{ position: "absolute", left: "75%", color: "#000000", top: "25%" }}
                                        >
                                            <ModeEditIcon />
                                        </IconButton>
                                        {selectedImage ? (
                                            <img
                                                src={selectedImage}
                                                alt="Profile"
                                                style={{ width: "100%", borderRadius: "50%", height: "90%" }}
                                            />
                                        ) : (
                                            <img
                                                src="images/avatars/avatar-4.png"
                                                alt="Profile"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                    </div>

                                    <div className="col-lg-8" style={{ color: "#fff" }}>
                                        <h4 className="mt-4">{odcuser.fullName ? odcuser.fullName : "Guest"}</h4>
                                        <p>{odcuser.email ? odcuser.email : null}</p>
                                        {/* <p>
                                    310, Sarthik - II, Opp. Rajpath Club, Sarkhej - Gandhinagar Hwy, Bodakdev,
                                    Ahmedabad, Gujarat 380054
                                </p> */}
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <ul>
                                        <li style={{ padding: "3% 0%" }}>
                                            <div className=" pro-listtxt-style">
                                                <BookmarkAddedIcon />{" "}
                                                <button
                                                    className="pro-list-txt"
                                                    onClick={toggleVisibility}
                                                    style={{ background: "none", border: "none" }}
                                                >
                                                    My Orders
                                                </button>
                                                {/* <p className="pro-list-txt" onClick={toggleVisibility}>
                                                    My Orders
                                                </p> */}
                                            </div>
                                        </li>
                                        <li style={{ padding: "3% 0%" }}>
                                            <div className=" pro-listtxt-style">
                                                <ContactSupportIcon />{" "}
                                                <button
                                                    className="pro-list-txt"
                                                    onClick={toggleVisibility1}
                                                    style={{ background: "none", border: "none" }}
                                                >
                                                    FAQ's
                                                </button>
                                                {/* <p className="pro-list-txt" onClick={toggleVisibility1}>
                                                    FAQ's
                                                </p> */}
                                            </div>
                                        </li>
                                        <li style={{ padding: "3% 0%" }}>
                                            <div className=" pro-listtxt-style">
                                                <HomeIcon />{" "}
                                                <button
                                                    className="pro-list-txt"
                                                    onClick={handleOrdersClick}
                                                    style={{ background: "none", border: "none" }}
                                                >
                                                    Home
                                                </button>
                                            </div>
                                        </li>

                                        {odcuser && odcuser.fullName ? (
                                            <li style={{ padding: "3% 0%" }}>
                                                <div className=" pro-listtxt-style">
                                                    <LogoutIcon />{" "}
                                                    <button
                                                        className="pro-list-txt"
                                                        onClick={handleLogoutodc}
                                                        style={{ background: "none", border: "none" }}
                                                    >
                                                        Logout
                                                    </button>
                                                </div>
                                            </li>
                                        ) : (
                                            <div>
                                                <li style={{ padding: "3% 0%" }}>
                                                    <div className=" pro-listtxt-style">
                                                        <HowToRegIcon />{" "}
                                                        <button
                                                            className="pro-list-txt"
                                                            onClick={odchandleSignin}
                                                            style={{ background: "none", border: "none" }}
                                                        >
                                                            Register Now
                                                        </button>
                                                    </div>
                                                </li>
                                                <li style={{ padding: "3% 0%" }}>
                                                    <div className=" pro-listtxt-style">
                                                        <LogoutIcon />{" "}
                                                        <button
                                                            className="pro-list-txt"
                                                            onClick={odchandleLogin}
                                                            style={{ background: "none", border: "none" }}
                                                        >
                                                            Login
                                                        </button>
                                                    </div>
                                                </li>
                                            </div>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        {isVisible === true && odcuser.fullName ? (
                            <div className="profile-card1">
                                <h2 style={{ textAlign: "center", paddingTop: "7%", paddingBottom: "5%" }}>
                                    Your Orders
                                </h2>
                                <div className="container-fluid " style={{ padding: "0% 7%" }}>
                                    {menulistapi.map((item, index) => (
                                        <div key={index}>
                                            <div onClick={() => handlemenuDetails(item)} className="odc-link-txt-color">
                                                <div
                                                    className="card p-4"
                                                    style={{ borderRadius: "10px", marginBottom: "2%" }}
                                                >
                                                    <div className="row line-height-order-li">
                                                        <div className="col-lg-6 number-font">
                                                            <span>
                                                                <ViewListIcon />
                                                            </span>{" "}
                                                            Order ID : {item.id}
                                                        </div>
                                                        {/* <div className="col-lg-4 number-font">
                                                            <span>
                                                                <DomainVerificationIcon />
                                                            </span>{" "}
                                                            Order Date : {formattedDate(item.createdAt)}
                                                        </div> */}
                                                        <div className="col-lg-6 number-font">
                                                            <span>
                                                                <EventIcon />
                                                            </span>{" "}
                                                            Sevice Date : {formattedDate(item.serviceDate)}
                                                        </div>
                                                        {item.services.map((items, indexed) => (
                                                            <div
                                                                key={indexed}
                                                                className="container-fluid row mt-3"
                                                                style={{ marginLeft: " 0%" }}
                                                            >
                                                                {/* <div className="col-lg-4 number-font">
                                                                    <span>
                                                                        <LocalDiningIcon />
                                                                    </span>{" "}
                                                                    Menu : {items.label}
                                                                </div> */}
                                                                <div className="col-lg-6 number-font">
                                                                    <span>
                                                                        <GroupsIcon />
                                                                    </span>{" "}
                                                                    Order Count : {items.count}
                                                                </div>
                                                                <div className="col-lg-6 number-font">
                                                                    <span>
                                                                        <VerifiedIcon />
                                                                    </span>{" "}
                                                                    Status :{" "}
                                                                    <button className="odc-ord-list-status-btn">
                                                                        {item.status}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <Pagination
                                    count={pages.pages}
                                    page={currentPage}
                                    onChange={paginate}
                                    variant="outlined"
                                    color="primary"
                                    style={{ justifyContent: "center", display: "flex", marginTop: "6%" }}
                                />

                                {/* 
                                <ListPagination
                                 totalPages={5}
                                 currentPage={currentPage}
                                 paginate={paginate}
                                //  limit={limit}
                                //  getApiData={getApiData}
                                /> */}
                                {/* <Pagination count={pages.pages} limit={25} page={pages.page} color="primary" style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "3%"
                                }} /> */}
                            </div>
                        ) : null}
                        {isVisible2 === true ? (
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="images/emcart.png"
                                    alt="empty cart"
                                    style={{
                                        borderRadius: "7px",
                                        width: "60%",
                                        boxShadow: "rgba(0, 0, 0, 0.12) 2px 3px 5px 2px",
                                    }}
                                />
                            </div>
                        ) : null}
                        {isVisible1 === true ? (
                            <div className="profile-card123">
                                <div className="container " style={{ paddingTop: "1px" }}>
                                    <div>
                                        <h4 className="faq-tit-txt">
                                            <span style={{ color: "#c60800" }}>F</span>AQ's
                                        </h4>
                                        <div>
                                            <h4 className="mb-4">General Info</h4>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I use Hogist’s online services for free?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Hogist’s services are free. We will never charge you beyond the
                                                        pricing listed on the website for the service opted by you. Our
                                                        online catering service is free of cost and after every
                                                        successful transaction, you will be receiving the complete
                                                        breakup of charges incurred. There are no hidden charges.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>What kind of food can I get?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        You name it and we have it. We have collaborated with a wide
                                                        range of restaurants, caterers, renowned chefs, Homecooks. All
                                                        you have to do is get in touch with them, choose the product,
                                                        set your quantity and make the payment. If you have a caterer in
                                                        mind and you don’t find them on Hogist, please feel free to drop
                                                        their details at support@hogist.com. and we will get in touch
                                                        with them.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>
                                                            Do you have home delivery option or I will have to pick it
                                                            up?
                                                        </b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        All the orders will be delivered to you at your
                                                        doorstep.Anyways, if you wish to pick the order by yourself,
                                                        then you can set your order as a takeaway and pick it up from
                                                        the Vendor’s place.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I expect 100% satisfaction from Hogist?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Definitely with Top Class Customer Service! You can contact us
                                                        anytime if you feel our services are not as expected.We will
                                                        make sure that we deliver our services with 100% customer
                                                        satisfaction. Also, if you find it appropriate, we can even
                                                        serve your guests on your behalf.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Do you follow a secure process for accepting orders?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Yes. We use 128-bit SSL encryption method to capture your data.
                                                        The information you provide us is stored using secured mediums
                                                        so that there is no chance of data theft. Refer our Privacy
                                                        Policy to get more information on how we store and use your
                                                        information.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="mb-4">Orders</h4>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I use Hogist’s online services for free?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Hogist’s services are free. We will never charge you beyond the
                                                        pricing listed on the website for the service opted by you. Our
                                                        online catering service is free of cost and after every
                                                        successful transaction, you will be receiving the complete
                                                        breakup of charges incurred. There are no hidden charges.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>What kind of food can I get?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        You name it and we have it. We have collaborated with a wide
                                                        range of restaurants, caterers, renowned chefs, Homecooks. All
                                                        you have to do is get in touch with them, choose the product,
                                                        set your quantity and make the payment. If you have a caterer in
                                                        mind and you don’t find them on Hogist, please feel free to drop
                                                        their details at support@hogist.com. and we will get in touch
                                                        with them.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>
                                                            Do you have home delivery option or I will have to pick it
                                                            up?
                                                        </b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        All the orders will be delivered to you at your
                                                        doorstep.Anyways, if you wish to pick the order by yourself,
                                                        then you can set your order as a takeaway and pick it up from
                                                        the Vendor’s place.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I expect 100% satisfaction from Hogist?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Definitely with Top Class Customer Service! You can contact us
                                                        anytime if you feel our services are not as expected.We will
                                                        make sure that we deliver our services with 100% customer
                                                        satisfaction. Also, if you find it appropriate, we can even
                                                        serve your guests on your behalf.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Do you follow a secure process for accepting orders?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Yes. We use 128-bit SSL encryption method to capture your data.
                                                        The information you provide us is stored using secured mediums
                                                        so that there is no chance of data theft. Refer our Privacy
                                                        Policy to get more information on how we store and use your
                                                        information.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="mb-4">My Account</h4>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I use Hogist’s online services for free?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Hogist’s services are free. We will never charge you beyond the
                                                        pricing listed on the website for the service opted by you. Our
                                                        online catering service is free of cost and after every
                                                        successful transaction, you will be receiving the complete
                                                        breakup of charges incurred. There are no hidden charges.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>What kind of food can I get?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        You name it and we have it. We have collaborated with a wide
                                                        range of restaurants, caterers, renowned chefs, Homecooks. All
                                                        you have to do is get in touch with them, choose the product,
                                                        set your quantity and make the payment. If you have a caterer in
                                                        mind and you don’t find them on Hogist, please feel free to drop
                                                        their details at support@hogist.com. and we will get in touch
                                                        with them.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>
                                                            Do you have home delivery option or I will have to pick it
                                                            up?
                                                        </b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        All the orders will be delivered to you at your
                                                        doorstep.Anyways, if you wish to pick the order by yourself,
                                                        then you can set your order as a takeaway and pick it up from
                                                        the Vendor’s place.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I expect 100% satisfaction from Hogist?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Definitely with Top Class Customer Service! You can contact us
                                                        anytime if you feel our services are not as expected.We will
                                                        make sure that we deliver our services with 100% customer
                                                        satisfaction. Also, if you find it appropriate, we can even
                                                        serve your guests on your behalf.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Do you follow a secure process for accepting orders?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Yes. We use 128-bit SSL encryption method to capture your data.
                                                        The information you provide us is stored using secured mediums
                                                        so that there is no chance of data theft. Refer our Privacy
                                                        Policy to get more information on how we store and use your
                                                        information.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <div className="mt-4">
                                            <h4 className="mb-4">Pricing & Payment</h4>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I use Hogist’s online services for free?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Hogist’s services are free. We will never charge you beyond the
                                                        pricing listed on the website for the service opted by you. Our
                                                        online catering service is free of cost and after every
                                                        successful transaction, you will be receiving the complete
                                                        breakup of charges incurred. There are no hidden charges.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>What kind of food can I get?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        You name it and we have it. We have collaborated with a wide
                                                        range of restaurants, caterers, renowned chefs, Homecooks. All
                                                        you have to do is get in touch with them, choose the product,
                                                        set your quantity and make the payment. If you have a caterer in
                                                        mind and you don’t find them on Hogist, please feel free to drop
                                                        their details at support@hogist.com. and we will get in touch
                                                        with them.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>
                                                            Do you have home delivery option or I will have to pick it
                                                            up?
                                                        </b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        All the orders will be delivered to you at your
                                                        doorstep.Anyways, if you wish to pick the order by yourself,
                                                        then you can set your order as a takeaway and pick it up from
                                                        the Vendor’s place.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Can I expect 100% satisfaction from Hogist?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Definitely with Top Class Customer Service! You can contact us
                                                        anytime if you feel our services are not as expected.We will
                                                        make sure that we deliver our services with 100% customer
                                                        satisfaction. Also, if you find it appropriate, we can even
                                                        serve your guests on your behalf.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion style={{ paddingBottom: "1%" }}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>
                                                        <b>Do you follow a secure process for accepting orders?</b>
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        Yes. We use 128-bit SSL encryption method to capture your data.
                                                        The information you provide us is stored using secured mediums
                                                        so that there is no chance of data theft. Refer our Privacy
                                                        Policy to get more information on how we store and use your
                                                        information.
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
Profile.propTypes = {
    odcuser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    menuadded: state.menuadded,
    odcuser: state.odcuser,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
// export default Profile;
