// react
import React from "react";

// third-party
import PropTypes from "prop-types";

// application
import { Fi24Hours48Svg, FiFreeDelivery48Svg, FiPaymentSecurity48Svg, FiTag48Svg } from "../../../svg";
import Delivery from "./../../../img/delivery.png";
import Covid from "./../../../img/covid-check.png";
import BestQulity from "./../../../img/qulity.png";
import Support from "./../../../img/service.png";

export default function BlockFeatures(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container-fluid">
                <div className="block-features__list">
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <img className="icon-imgs" src={Delivery} alt="delivery" />
                        </div>
                        <div className="block-features__content futr-coloe">
                            <div className="block-features__title">Fastest Delivery</div>
                            <div className="block-features__subtitle">Every order will have a on time delivery</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <img className="icon-imgs" src={Support} alt="delivery" />
                        </div>
                        <div className="block-features__content futr-coloe">
                            <div className="block-features__title">Support 24/7</div>
                            <div className="block-features__subtitle">Call us anytime</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <img className="icon-imgs" src={Covid} alt="delivery" />
                        </div>
                        <div className="block-features__content futr-coloe">
                            <div className="block-features__title">100% Safety</div>
                            <div className="block-features__subtitle">Covid Free Food Services</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <img className="icon-imgs" src={BestQulity} alt="delivery" />
                        </div>
                        <div className="block-features__content futr-coloe">
                            <div className="block-features__title">The Best Quality Foods</div>
                            <div className="block-features__subtitle">Food quality at it best.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(["classic", "boxed"]),
};

BlockFeatures.defaultProps = {
    layout: "classic",
};
